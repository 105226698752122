/*    
<summary>
   This File contains all the routes constant. 
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
*/
import config from "../../helpers/config-helper";

const appConfig = config();
export const baseURL = appConfig.REACT_APP_PUBLIC_URL;
const RoutesConstants = {
  Login: baseURL + "/login",
  Logout: baseURL + "/logout",
  User: baseURL + "/user",
  Device: baseURL + "/Device",
  AddBulk: baseURL + "/AddBulk",
  Dashboard: baseURL+ "/Dashboard",
  Group: baseURL+"/Group",
  Configurations: baseURL+"/Configuration",
  Tenants:baseURL+"/Tenants",
  Bill:baseURL+"/Bill",
  TenantCharge:baseURL+"/TenantCharge",
  Transaction:baseURL+"/Transaction",
  Documentation:baseURL+"/Documentation",
  SingleView:baseURL+"/SingleView",
  AuditLog:baseURL+"/AuditLog",
  ForgetPassword: baseURL+"/ForgetPassword",
  CreateAccount: baseURL+"/CreateAccount",
  UserRegistration: baseURL + "/UserResgistration",
  PricingMenu: baseURL + "/BillingStatistics"
};
export default RoutesConstants;
