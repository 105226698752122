/*    
<summary>
   This typescript file we can load en.json & jp.json JSON files and pass one of them to IntlProvider, depending on 
   the language the user has configured in the browser.
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
*/
import en_messages from './languages/en.json';
import jp_messages from './languages/jp.json';

const translations: { [key: string]: any } = {
  en: {
    messages: en_messages
  },

  jp: {
    messages: jp_messages
  }
};

export default translations
