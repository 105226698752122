/*    
<summary>
  This functional component "AddPayment" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:27-Oct-2023
</summary>
<returns>Returns JSX</returns>
*/

import { observer } from "mobx-react";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useStore } from "../../../contexts/StoreProvider";
import { formatMessage } from "../../../translations/format-message";
import { cloneDeep } from "lodash";
import AddPaymentForm from "../form/add-payment-form";
import { IBillingList } from "../../../models/response/IBillingResponse";
import IAddPayment from "../../../models/Form/IPayment";

interface IProps {
  selectedData: IBillingList;
  modalClosed: () => void;
}

const AddPayment = (props: IProps) => {
  const { billingStore } = useStore();
  const {
    addPaymentState,
    AddTenantBillPaymentReceviedService,
    resetAddPaymentState
  } = billingStore;
  
  const initialValues: IAddPayment = {
    PaymentRecieved: '',
    Remarks: ''
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "addPaymentState.Success" of the useEffect changed.
   */
  useEffect(() => {
    if (addPaymentState.success) {
      toast.success(formatMessage("added_success"));
      onModalClosed();
    }
  }, [addPaymentState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "addUpdateUserError" of the useEffect changed.
   */
  useEffect(() => {
    if (addPaymentState.error) {
      toast.error(formatMessage(addPaymentState.error));
      resetAddPaymentState();
    }
  }, [addPaymentState.error]);

  /**
   * This function adds the user when "Id < 0" and updates the Existing user when Id > 0 by providing form values to the
   * correponding function 
   */
  const onSubmitForm = (values: any, currencyId: number) => {
    let tempValues = cloneDeep(values);
    tempValues.PaymentRecieved = Number(values.PaymentRecieved)
    tempValues.Remarks = values.Remarks ? values.Remarks.trim() : "";
    AddTenantBillPaymentReceviedService({BillId: props.selectedData.Id, ...tempValues, CurrencyId: currencyId})
  };

  /**
   * This function closes the pop up modal.
   */
  const onModalClosed = () => {
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <AddPaymentForm
        initialValues={initialValues}
        selectedData={props.selectedData}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        addPaymentLoading={addPaymentState.inProgress}
      />
    </React.Fragment>
  );
};

export default observer(AddPayment);
