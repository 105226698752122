/*    
<summary>
  This functional component "ResetSimPopupHOC" provides props to the child component.
  1. State "setShowResetSimPopup" that manages the show/hide of pop up
  2. Function "deviceFilterToggleHandler" to toggle the show/hide popup state "showConfirmPopup"
  Developer: Aashish Singh, Created Date:18-Nov-2023
</summary>
<returns>Returns JSX</returns>
*/

import React, { useState } from "react";

const ResetSimPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showResetSimPopup, setShowResetSimPopup] =
      useState<boolean>(false);

    const resetSimToggleHandler = () => {
      setShowResetSimPopup(!showResetSimPopup);
    };

    return (
      <Component
        showResetSimPopup={showResetSimPopup}
        resetSimToggleHandler={resetSimToggleHandler}
        {...props}
      />
    );
  };

export default ResetSimPopupHOC;
