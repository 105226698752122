/*    
<summary>
  This functional component "DEviceDetails" renders the device details.
  Developer:Aashish Singh, Created Date: 05-Sept-2023
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { observer } from "mobx-react";
import { useStore } from "../../contexts/StoreProvider";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import toast from "react-hot-toast";
import { CategoryScale } from "chart.js";
import { formatMessage } from "../../translations/format-message";
import moment from "moment";
import ConfirmPopupHOC from "../../shared-components/popup/confirm/confirm-popup-hoc";
import ConfirmPopup from "../../shared-components/popup/confirm/confirm-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../../helpers/config-helper";
import userTypeEnum from "../../constants/user-type-enum";
import {
  lineChartOptions,
  packetsCountChartConfig,
  volumeChartConfig,
} from "../../shared-components/line-chart/line-chart-types";
import { cloneDeep } from "lodash";
import LineChart from "../../shared-components/line-chart/line-chart";
import ActiveDeviceConfirmPopupHOC from "./hoc/device-active-popup-hoc";
import no_device from "../../assets/Images/svg/no-device.svg";
import ResetSimPopupHOC from "./hoc/reset-sim-popup-hoc";
import EnableDisableSimPopupHOC from "./hoc/enable-disable-sim-popup-hoc";
import nodevicesvg from "../../assets/Images/svg/pie-chart.svg";
import OneNceDefaultError from "../../shared-components/Tooltip/1NceDefaultError";
import ratTypeIdEnum from "../../constants/rat-type-id-enum";
import { emptyValue } from "../../constants/common-constants";
const appConfig = config();
const dateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT;
const dateFormat = appConfig.REACT_APP_DATE_FORMAT;

const DeviceDetials = (props: any) => {
  const { oneNceStore, authStore, preferencesStore } = useStore();
  const {
    oneNceDeviceSimDetail,
    oneNceDeviceSimDataQuota,
    oneNceDeviceSimUsageDaily,
    oneNceDeviceSimUsageMonthly,
    getOneNceDeviceDataQuotaByICCIDService,
    getOneNceDeviceSimDetailByICCIDService,
    oneNceDeviceSimDetailState,
    oneNceDeviceSimUsageState,
    oneNceDeviceSimDataQuotaState,
    getOneNceSimStatusByICCIDService,
    oneNceDeviceSimStatus,
    oneNceDeviceSimStatusState,
    updateIMEILockState,
    updateSimIMEILockByICCIDService,
    isErrorPresent,
    GetDailySimUsagebByICCIDService,
    GetMonthlySimUsageByICCIDService,
    GetDeviceMonthlyPacketsCountByICCIDService,
    GetDeviceDailyPacketsCountByICCIDService,
    oneNceDevicePacketsCountDaily,
    chartDevicePacketsCountDaily,
    oneNceDevicePacketsCountMonthly,
    chartDevicePacketsCountMonthly,
    resetUpdateIMEILockState,
    chartSimUsageDaily,
    chartSimUsageMonthly,
    deviceChartDetails,
    setDeviceChartDetails,
    ResetSimConnectivityService,
    resetOneNceResetSimState,
    oneNceDeviceResetSimState,
    EnableDisableSimService,
    resetOneNceEnableSimState,
    oneNceEnableSimState,
    simDataLoading,
    GetDeviceDLMessageCountByICCIDService,
    GetDeviceMonthlyDLMessageCountByICCIDService,
    oneNceDeviceDLMessageCountDaily,
    oneNceDeviceDLMessageCountMonthly,
    chartDeviceDLMessageCountDaily,
    chartDeviceDLMessageCountMonthly,
    resetOneNceDeviceSimUsageState
  } = oneNceStore;
  const { getUserType } = authStore;
  const {
    getLanguage,
    islanguageChanged,
    updateLanguageChangedFlag,
    getTheme,
    isThemeChanged,
    updateThemeChangedFlag,
  } = preferencesStore;
  const [charts, setCharts] = useState<any>([]);
  const [imeiLockStatus, setImeiLockStatus] = useState<boolean>(false);
  const [isSimEnabled, setIsSimEnabled] = useState<boolean>(false);
  // const [simConectivity, setSimConnectivity] = useState<boolean>(false);
  const [selectedChartDetails, setSelectedChartDetails] = useState<any>({
    ...deviceChartDetails,
    isChartDetailsChanged: false,
  });
  let chartCollection: any = [];

  const getChartDataDetails = () => {
    if (selectedChartDetails.chartType == 0) {
      if (selectedChartDetails.rangeType == 0)
        GetDailySimUsagebByICCIDService(props.selectedData.Iccid, 29);
      else GetMonthlySimUsageByICCIDService(props.selectedData.Iccid);
    } else if (selectedChartDetails.chartType == 1) {
      if (selectedChartDetails.rangeType == 0)
      GetDeviceDailyPacketsCountByICCIDService(props.selectedData.Iccid);
      else GetDeviceMonthlyPacketsCountByICCIDService(props.selectedData.Iccid);
    }else {
      if (selectedChartDetails.rangeType == 0)
        GetDeviceDLMessageCountByICCIDService(props.selectedData.Iccid);
      else GetDeviceMonthlyDLMessageCountByICCIDService(props.selectedData.Iccid);
    }
  };

  useEffect(() => {
    if (props?.selectedData.Id > 0) {
      getOneNceDeviceSimDetailByICCIDService(props?.selectedData.Iccid);
      getOneNceDeviceDataQuotaByICCIDService(props?.selectedData.Iccid);
      getOneNceSimStatusByICCIDService(props?.selectedData.Iccid);
      getChartDataDetails();
    }
  }, [props?.selectedData.Id]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "updateIMEILockState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (updateIMEILockState.success) {
      toast.success(formatMessage("updated_success"));
      getOneNceDeviceSimDetailByICCIDService(props.selectedData.Iccid);
      imeiLockModalCloseHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateIMEILockState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "oneNceDeviceResetSimState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (oneNceDeviceResetSimState.success) {
      toast.success(formatMessage("request_created_successfully"));
      getOneNceSimStatusByICCIDService(props.selectedData.Iccid);
      resetSimModalCloseHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneNceDeviceResetSimState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "oneNceEnableSimState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (oneNceEnableSimState.success) {
      toast.success(formatMessage("updated_success"));
      getOneNceDeviceSimDetailByICCIDService(props.selectedData.Iccid);
      enableSimModalCloseHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneNceEnableSimState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "oneNceDeviceSimUsageDaily" of the useEffect changed.
   */
  useEffect(() => {
    if (oneNceDeviceSimUsageDaily) {
      createSimUsageLineChart(chartSimUsageDaily.chartPoints, false, chartSimUsageDaily?.totalVolumeDetails?.maxValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneNceDeviceSimUsageDaily]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "oneNceDeviceSimUsageMonthly" of the useEffect changed.
   */
  useEffect(() => {
    if (oneNceDeviceSimUsageMonthly) {
      createSimUsageLineChart(chartSimUsageMonthly.chartPoints, true, chartSimUsageMonthly?.totalVolumeDetails?.maxValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneNceDeviceSimUsageMonthly]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "oneNceDevicePacketsCountDaily" of the useEffect changed.
   */
  useEffect(() => {
    if (oneNceDevicePacketsCountDaily) {
      createPacketsCountLineChart(
        chartDevicePacketsCountDaily.chartPoints,
        false,
        chartDevicePacketsCountDaily.maxValue
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneNceDevicePacketsCountDaily]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "oneNceDevicePacketsCountMonthly" of the useEffect changed.
   */
  useEffect(() => {
    if (oneNceDevicePacketsCountMonthly) {
      createPacketsCountLineChart(
        chartDevicePacketsCountMonthly.chartPoints,
        true,
        chartDevicePacketsCountMonthly.maxValue
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneNceDevicePacketsCountMonthly]);
  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "oneNceDeviceDLMessageCountDaily" of the useEffect changed.
   */
  useEffect(() => {
    if (oneNceDeviceDLMessageCountDaily) {
      createPacketsCountLineChart(
        chartDeviceDLMessageCountDaily.chartPoints,
        true,
        chartDeviceDLMessageCountDaily.maxValue
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneNceDeviceDLMessageCountDaily]);
  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "oneNceDeviceDLMessageCountMonthly" of the useEffect changed.
   */
  useEffect(() => {
    if (oneNceDeviceDLMessageCountMonthly) {
      createPacketsCountLineChart(
        chartDeviceDLMessageCountMonthly.chartPoints,
        true,
        chartDeviceDLMessageCountMonthly.maxValue
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneNceDeviceDLMessageCountMonthly]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "selectedChartDetails.isChartDetailsChanged" of the useEffect changed.
   */
  useEffect(() => {
    if (selectedChartDetails.isChartDetailsChanged) {
      getChartDataDetails();
      setSelectedChartDetails({
        ...selectedChartDetails,
        isChartDetailsChanged: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChartDetails]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "updateIMEILockState.error" of the useEffect changed.
   */
  useEffect(() => {
    if (updateIMEILockState.error) {
      toast.error(formatMessage(updateIMEILockState.error));
      resetUpdateIMEILockState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateIMEILockState.error]);
  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "oneNceEnableSimState.error" of the useEffect changed.
   */
  useEffect(() => {
    if (oneNceEnableSimState.error) {
      toast.error(formatMessage(oneNceEnableSimState.error));
      resetOneNceEnableSimState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneNceEnableSimState.error]);
  
  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "oneNceDeviceResetSimState.error" of the useEffect changed.
   */
  useEffect(() => {
    if (oneNceDeviceResetSimState.error) {
      toast.error(formatMessage(oneNceDeviceResetSimState.error));
      resetOneNceResetSimState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneNceDeviceResetSimState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "islanguageChanged" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      setCharts([]);
      getChartDataDetails();
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "isThemeChanged" of the useEffect changed.
   */
  useEffect(() => {
    if (isThemeChanged) {
      setCharts([]);
      getChartDataDetails();
      updateThemeChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isThemeChanged]);

  const doughnutOption = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: { yLabel: any }) {
          return tooltipItem.yLabel;
        },
      },
    },
  };
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  ChartJS.register(ArcElement, Tooltip, Legend, Filler);
  const data2 = (usedVolume: number, UnUsedVolume: number) => {
    return {
      labels: [formatMessage("remaining_volume"), formatMessage("used_volume")],
      datasets: [
        {
          label: "Volume",
          data: [usedVolume, UnUsedVolume],
          backgroundColor: ["rgba(243, 152, 16, 1)", "rgba(179, 178, 178, 1)"],
          borderColor: ["rgba(243, 152, 16, 1)", "rgba(179, 178, 178, 1)"],
          borderWidth: 0,
        },
      ],
    };
  };

  /**
   * This function returns the xAxisInterval based on the range.t.
   */
  const getXAxisLabelInterval = (range: number) => {
    let xAxisInterval: number = 2;
    let totalDays = range;
    if (!isNaN(totalDays)) {
      if (totalDays <= 7) xAxisInterval = 1;
      else if (totalDays <= 15) xAxisInterval = 2;
      else xAxisInterval = 3;
    }
    return xAxisInterval;
  };
  
  /**
   * This function returns the xAxisInterval based on the range.t.
   */
  const getYAxisLabelInterval = (range: number) => {
    let yAxisInterval: number | undefined = undefined;
    if (!isNaN(range) && range > 0) {
      if (range <= 0.1) yAxisInterval = 0.01;
      else if (range <= 1) yAxisInterval = 0.2;
      else if (range <= 10) yAxisInterval = 1;
    }
    return yAxisInterval;
  };

  /**
   * This function maps the charts points for sim Usage to the data suitable for line chart component.
   */
  const createSimUsageLineChart = (allChartData: any, showlabel: boolean, maxValue: number) => {
    var xAxisInterval = getXAxisLabelInterval(
      allChartData[0]?.dataPoints?.length
    );
    if (allChartData !== undefined) {
      volumeChartConfig.culture = getLanguage === "en" ? "en" : "jp";

      let config: lineChartOptions = cloneDeep(volumeChartConfig);
      config.data = config.data.map((data: any, index1: number) => {
        allChartData.forEach((item: any, index2: number) => {
          if (index1 == index2) {
            data.name = formatMessage(item.KPIName);
            data.yValueFormatString = "#0.###### MB";
            data.dataPoints = [...item.dataPoints];
            // item.dataPoints.forEach((point: any) => { data.dataPoints.push(
            //   showlabel ? { label: point.label, y: point.y } : { x: new Date(point.x), y: point.y });});
          }
        });
        return data;
      });
      config.exportFileName =
        "SimUsageChart_" + moment(Date.now()).format(dateTimeFormat);
      config.axisY.title = formatMessage("chart_yaxis_title");
      // config.title.text = formatMessage("sim_usage");
      config.axisX.interval = xAxisInterval;
      debugger 
      config.axisY.interval = getYAxisLabelInterval(maxValue);
      if (getTheme == "true") {
        config.backgroundColor = "#212121";
        config.theme = "dark1";
      }
      chartCollection.push(cloneDeep(config));
      setCharts(chartCollection);
    }
  };

  /**
   * This function maps the charts points to the data suitable for line chart component.
   */
  const createPacketsCountLineChart = (
    allChartData: any,
    showlabel: boolean,
    maxValue: number
  ) => {
    var xAxisInterval = getXAxisLabelInterval(
      allChartData[0]?.dataPoints?.length
    );
    let yaxisInterval: number | undefined = getYAxisLabelInterval(maxValue);
    if (allChartData !== undefined) {
      volumeChartConfig.culture = getLanguage === "en" ? "en" : "jp";

      let config: lineChartOptions = cloneDeep(packetsCountChartConfig);
      config.data = config.data.map((data: any, index1: number) => {
        allChartData.forEach((item: any, index2: number) => {
          data.name = formatMessage(item.KPIName);
          data.dataPoints = [...item.dataPoints];
        });
        return data;
      });
      config.exportFileName =
        "PacketsCountChart_" + moment(Date.now()).format(dateTimeFormat);
      config.axisY.title = formatMessage("packets_count");
      // config.title.text = formatMessage("data_packets");
      config.axisX.interval = xAxisInterval;
      config.axisY.interval = yaxisInterval != undefined ? (yaxisInterval <= 1 ? 1 : undefined) : undefined;  
      if (getTheme == "true") {
        config.backgroundColor = "#212121";
        config.theme = "dark1";
      }
      chartCollection.push(cloneDeep(config));
      setCharts(chartCollection);
    }
  };

  /**
   * This fiunction saves the imei_lock state and open the confirmation popup.
   * @param lockStatus boolean status of imei_lock
   */
  const lockClickHandler = (lockStatus: boolean) => {
    setImeiLockStatus(lockStatus);
    props.confirmPopupToggleHandler();
  };

  /**
   * This function actually update the imei_lock of device by calling api.
   */
  const lockHandler = () => {
    updateSimIMEILockByICCIDService(props.selectedData.Iccid, imeiLockStatus);
  };

  /**
   * This function resets the imei_lockState, imei_lockUpdate observables state & close the confirm pop up modal.
   */
  const imeiLockModalCloseHandler = () => {
    setImeiLockStatus(false);
    resetUpdateIMEILockState();
    props.confirmPopupToggleHandler();
  };

  /**
   * This fiunction saves the sim connectivity state and open the confirmation popup.
   * @param connectivity boolean status of sim connectivity
   */
  const resetSimClickHandler = (connectivity: boolean = false ) => {
    // setSimConnectivity(connectivity);
    props.resetSimToggleHandler();
  };

  /**
   * This function creates the resets sim connectivity request by calling api.
   */
  const resetSimHandler = () => {
    ResetSimConnectivityService(props.selectedData.Iccid);
  };

  /**
   * This function resets the resetSim observables state & close the confirm pop up modal.
   */
  const resetSimModalCloseHandler = () => {
    // setSimConnectivity(false);
    resetOneNceResetSimState();
    props.resetSimToggleHandler();
  };

  /**
   * This fiunction saves the  sim enable/disable state and open the confirmation popup.
   * @param isEnabled boolean status of isEnabled
   */
  const enableSimClickHandler = (isEnabled: boolean) => {
    setIsSimEnabled(isEnabled);
    props.enableDisableSimToggleHandler();
  };

  /**
   * This function resets the resetSim observables state & close the confirm pop up modal.
   */
  const enableSimHandler = () => {
    EnableDisableSimService(props.selectedData.Iccid, isSimEnabled);
  };

  /**
   * This function resets the sim enable/Disable observables state & close the confirm pop up modal.
   */
  const enableSimModalCloseHandler = () => {
    setIsSimEnabled(false);
    resetOneNceEnableSimState();
    props.enableDisableSimToggleHandler();
  };

  return (
    <React.Fragment>
      <div className="row bb p-2 align-items-center">
        <div className="col-lg-8">
          <h2 className="fw-bold mb-0 mt-1">
            {props.selectedData.Iccid}
          </h2>
        </div>
        <div className="col-lg-4">
          {
            oneNceDeviceSimDetail && (getUserType != userTypeEnum.ReadOnly) && !(isErrorPresent.flag && isErrorPresent.message) &&
            <div className="dropdown">
              <button
                data-testid="DeviceActions"
                className="btn btn-primary btn-sm"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <FormattedMessage id={"actions"} />
                <i className="fa fa-caret-down ml-1"></i>
              </button>
              <ul className="dropdown-menu lockdropDown">
                <li>
                    <a
                      data-toggle="modal"
                      onClick={() => {
                        lockClickHandler(
                          !oneNceDeviceSimDetail.imei_lock
                        );
                      }}
                      data-testid="exportAll"
                      data-target="#exportAll"
                    >
                      <span className="ml-2">
                      <FontAwesomeIcon
                          className="mr-2 largeData"
                          icon={
                            oneNceDeviceSimDetail.imei_lock ? faUnlock : faLock
                          }
                        />
                        {formatMessage(
                          oneNceDeviceSimDetail.imei_lock
                            ? "un_lock"
                            : "lock_menu"
                        )}
                      </span>
                    </a>
                </li>
                <li>
                    <a
                      data-toggle="modal"
                      onClick={() => {
                        enableSimClickHandler(
                          !(oneNceDeviceSimDetail.status == "Enabled")
                        );
                      }}
                      data-testid="exportAll"
                    >
                      <span className="ml-2">
                        {formatMessage(
                          oneNceDeviceSimDetail.status == "Enabled"
                            ? "sim_disable"
                            : "sim_enable"
                        )}
                      </span>
                    </a>
                </li>
                <li>
                    <a
                      data-toggle="modal"
                      onClick={() => {
                        resetSimClickHandler();
                      }}
                      data-testid="exportAll"
                    >
                      <span className="ml-2">
                        {formatMessage("sim_reset")}
                      </span>
                    </a>
                </li>
              </ul>
            </div>
          }
          <svg
            onClick={props.closePanelHandler}
            role="button"
            xlinkTitle="close"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            className="bi bi-x float-right"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </div>
      </div>
      {/* {(oneNceDeviceSimDetailState.error && oneNceDeviceSimDataQuotaState.error && oneNceDeviceSimStatusState.error) ? ( */}
        {/* <div className="message text-center">
          <img src={no_device} alt="" className="no_message" />
          <p className="noData text-dark text-center h4 mb-0">
            <FormattedMessage id="no_data" />
          </p>
          <p className="text-danger mb-3">
            {formatMessage(oneNceDeviceSimDetailState.error)}
          </p>
        </div> */}
        {/* ) : 
        ( */}
          <>
          {props.showConfirmPopup && (
            <ConfirmPopup
              className="imei-lock-modal delete_popup"
              title={
                imeiLockStatus
                  ? "imei_lock_dailog_title"
                  : "imei_unlock_dailog_title"
              }
              modalSubmit={lockHandler}
              modalClosed={imeiLockModalCloseHandler}
              message={
                imeiLockStatus
                  ? "imei_lock_confirm_message"
                  : "imei_unlock_confirm_message"
              }
              isLoading={updateIMEILockState.inProgress}
            />
          )}
          {props.showResetSimPopup && (
            <ConfirmPopup
              className="imei-lock-modal delete_popup"
              title="reset_sim_dailog_title"
              modalSubmit={resetSimHandler}
              modalClosed={resetSimModalCloseHandler}
              message="reset_sim_confirm_message"
              isLoading={oneNceDeviceResetSimState.inProgress}
            />
          )}
          {props.showEnableDisableSimPopup && (
            <ConfirmPopup
              className="imei-lock-modal delete_popup"
              title={
                isSimEnabled
                  ? "sim_enable"
                  : "sim_disable"
              }
              modalSubmit={enableSimHandler}
              modalClosed={enableSimModalCloseHandler}
              message={
                isSimEnabled
                  ? "enbale_sim_confirm_message"
                  : "disable_sim_confirm_message"
              }
              isLoading={oneNceEnableSimState.inProgress}
            />
          )}
          {(
            oneNceDeviceSimDetailState.inProgress ||
            oneNceDeviceSimUsageState.inProgress ||
            oneNceDeviceSimDataQuotaState.inProgress ||
            updateIMEILockState.inProgress ||
            oneNceDeviceSimStatusState.inProgress ) && (
            <FormLoader
              loading={
                oneNceDeviceSimDetailState.inProgress ||
                oneNceDeviceSimUsageState.inProgress ||
                oneNceDeviceSimDataQuotaState.inProgress ||
                updateIMEILockState.inProgress ||
                oneNceDeviceSimStatusState.inProgress
              }
            />
          )}
          <div className="device_detailsWrapper">
            <div
              className="deviceDetailCard mb-2"
              // id={oneNceDeviceSimDetail.imei_lock?.toString() + "lock"}
            >
              {(oneNceDeviceSimDetailState.error || oneNceDeviceSimDataQuotaState.error || oneNceDeviceSimStatusState.error) ? 
              <>
              <div className="nceError ps-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="#dc3545" className="bi bi-exclamation-triangle oneNCEDefaultError"
                viewBox="0 0 16 16">
                <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"/>
                <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                </svg>
                <span className="py-1 px-2">
                  <FormattedMessage id="oneNceError" />
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise ml-1" viewBox="0 0 16 16" onClick={()=>{
                    oneNceDeviceSimDetailState.error && getOneNceDeviceSimDetailByICCIDService(props?.selectedData.Iccid);
                    oneNceDeviceSimDataQuotaState.error && getOneNceDeviceDataQuotaByICCIDService(props?.selectedData.Iccid);
                    oneNceDeviceSimStatusState.error && getOneNceSimStatusByICCIDService(props?.selectedData.Iccid);
                    getChartDataDetails();
                  }
                  }>
                    <title><FormattedMessage id="refresh" /></title>
                    <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                  </svg>
                </span>
              </div>
               
              </>
              
              :""}
              <div className="row no-gutters justify-content-between align-items-center  p-3">
                <div className="col-5 volumeChart">
                  {
                    oneNceDeviceSimDataQuota ? 
                    <Doughnut
                      data={data2(
                        Number(oneNceDeviceSimDataQuota.volume),
                        Number(oneNceDeviceSimDataQuota.total_volume) -
                          Number(oneNceDeviceSimDataQuota.volume)
                      )}
                      options={doughnutOption}
                    /> : 
                    <img src={nodevicesvg} alt=" " />
                  }
                </div>
                <div className="col-6">
                  <div>
                    <h3 className="text-muted">
                      <FormattedMessage id="volume" />
                    </h3>
                    <h2>
                      {
                        oneNceDeviceSimDataQuota ? 
                        <>
                          {Number(oneNceDeviceSimDataQuota.volume).toFixed(2)}/
                          <small className="text-dark">
                            {Number(oneNceDeviceSimDataQuota.total_volume)}
                          </small>
                        </> : 
                        <OneNceDefaultError id="volume" message={formatMessage('oneNceError')} />
                      }
                    </h2>
                  </div>
                  <div className="no-gutters mb-2">
                    <div className="col-12">
                      <label className="text-muted">
                        <FormattedMessage id={"connectivity"} />{" "}
                      </label>
                    </div>
                    <div className="col-12">
                      <p className="largeData mb-0">{" "}
                        {
                          oneNceDeviceSimStatus ? oneNceDeviceSimStatus.status : <OneNceDefaultError id="connectivity" message={formatMessage('oneNceError')} />
                        }
                      </p>
                    </div>
                  </div>
                  <div className="no-gutters mb-2">
                    <div className="col-6">
                      <label className="text-muted">
                        <FormattedMessage id={"session"} />
                      </label>
                    </div>
                    <div className="col-12">
                      <p className="largeData mb-0">
                        {
                          (oneNceDeviceSimStatus && oneNceDeviceSimStatus.location )?
                            <>
                              { oneNceDeviceSimStatus.location?.operator.name}{" "}/
                              {" "}{oneNceDeviceSimStatus.location?.operator.country.name }
                              {
                                (oneNceDeviceSimStatus.pdp_context && oneNceDeviceSimStatus.pdp_context?.rat_type) ? ("/" + ratTypeIdEnum[parseInt(oneNceDeviceSimStatus.pdp_context.rat_type?.rat_type_id)]) : ""
                              }
                            </> :
                          <OneNceDefaultError id="session" message={formatMessage('oneNceError')} />
                        }
                      </p>
                    </div>
                  </div>
                  <div className="no-gutters mb-2">
                    <div className="col-12">
                      <label className="text-muted">
                        <FormattedMessage id={"last_updated_gprs"} />{" "}
                      </label>
                    </div>
                    <div className="col-12">
                      <p className="largeDataDate mb-0">{" "}
                        {
                          (oneNceDeviceSimStatus && oneNceDeviceSimStatus.location) ? oneNceDeviceSimStatus.location.last_updated_gprs : <OneNceDefaultError id="last_updated_gprs" message={formatMessage('oneNceError')} />
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-light p-3">
                <div className="row">
                  <div className="col-6 mb-3">
                    <label className="text-muted">
                      <FormattedMessage id={"imei"} />
                      {
                        oneNceDeviceSimDetail ? 
                        <FontAwesomeIcon
                          className="ml-2 largeData"
                          icon={
                            oneNceDeviceSimDetail.imei_lock ? faLock : faUnlock
                          }
                        /> : ""
                        // <OneNceDefaultError message={formatMessage('oneNceError')} />
                      }
                    </label>
                    <p className="">
                      {
                        oneNceDeviceSimDetail ? (oneNceDeviceSimDetail.imei !== null ? oneNceDeviceSimDetail.imei : emptyValue) : <OneNceDefaultError id="imei" message={formatMessage('oneNceError')} />
                      }
                    </p>
                  </div>
                  <div className="col-6 mb-3">
                    <label className="text-muted">
                      <FormattedMessage id={"device_ip"} />
                    </label>
                    <p className="">{oneNceDeviceSimDetail ? oneNceDeviceSimDetail.ip_address : <OneNceDefaultError id="ip_address" message={formatMessage('oneNceError')} />}</p>
                  </div>
                  <div className="col-6 date">
                    <label className="text-muted">
                      <FormattedMessage id="sim_status" />
                    </label>
                    <p>
                      {
                        oneNceDeviceSimDetail ? oneNceDeviceSimDetail.status : <OneNceDefaultError id="sim_status" message={formatMessage('oneNceError')} />
                      }
                    </p>
                  </div>
                  <div className="col-6 date">
                    <label className="text-muted">
                      <FormattedMessage id="expiry_date" />
                    </label>
                    <p>
                      {oneNceDeviceSimDataQuota ? moment(oneNceDeviceSimDataQuota.expiry_date).format(dateFormat) : <OneNceDefaultError id="expiry_date" message={formatMessage('oneNceError')} />}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className=" p-3">
              <div className="select-tag-container mb-2 mx-1 row">
                <select
                  className="form-control col-md-4"
                  name="range"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    resetOneNceDeviceSimUsageState();
                    setDeviceChartDetails({
                      ...selectedChartDetails,
                      rangeType: e.target.value,
                    });
                    setSelectedChartDetails({
                      ...selectedChartDetails,
                      rangeType: e.target.value,
                      isChartDetailsChanged: true,
                    });
                  }}
                  defaultValue={selectedChartDetails.rangeType}
                >
                  <option value="0">{formatMessage("label_daily")}</option>
                  <option value="1">{formatMessage("label_monthly")}</option>
                </select>
                <select
                  className="form-control col-md-6"
                  name="chart"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    resetOneNceDeviceSimUsageState();
                    setDeviceChartDetails({
                      ...selectedChartDetails,
                      chartType: e.target.value,
                    });
                    setSelectedChartDetails({
                      ...selectedChartDetails,
                      chartType: e.target.value,
                      isChartDetailsChanged: true,
                    });
                  }}
                  defaultValue={selectedChartDetails.chartType}
                >
                  <option value="0">
                    {formatMessage("label_data_usage")}
                  </option>
                  <option value="1">
                    {formatMessage("data_packets_count")}
                  </option>
                  <option value="2">
                    {formatMessage("dl_message_count")}
                  </option>
                </select>
              </div>
              {(!simDataLoading && (
                oneNceDeviceSimUsageDaily == undefined &&
                oneNceDeviceSimUsageMonthly == undefined &&
                oneNceDevicePacketsCountDaily == undefined &&
                oneNceDevicePacketsCountMonthly == undefined &&
                oneNceDeviceDLMessageCountDaily == undefined &&
                oneNceDeviceDLMessageCountMonthly == undefined )) ?
                <div className="message text-center">
                  <img src={no_device} alt="" className="no_message" />
                  <p className="noData text-dark text-center h4 mb-0">
                    <FormattedMessage id={selectedChartDetails.chartType == "0" ? "no_device_usage" : (selectedChartDetails.chartType == "1" ? "no_device_packet_count" : "no_dl_message_count")} />
                  </p>
                  {/* {
                    (selectedChartDetails.chartType == "0" && selectedChartDetails.rangeType == "0") &&
                    <p className="text-danger mb-3">
                      {formatMessage(oneNceDeviceSimUsageState.error)}
                    </p>
                  } */}
                </div> :
                  <>
                    {(oneNceDeviceSimUsageDaily ||
                      oneNceDeviceSimUsageMonthly ||
                      oneNceDevicePacketsCountDaily ||
                      oneNceDevicePacketsCountMonthly ||
                      oneNceDeviceDLMessageCountDaily ||
                      oneNceDeviceDLMessageCountMonthly) && (
                      <div className="row">
                        {charts.map((option: any, index: number) => {
                          return (
                            <div key={index} className={"my-3 ipadchart"}>
                              <LineChart index={index} options={option}></LineChart>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {oneNceDeviceSimUsageDaily &&
                      selectedChartDetails.chartType == 0 && (
                        <div className="row">
                          <div className="col-4 d-flex align-items-start">
                            <i className="fa fa-square text-danger  mt-1 mr-1"></i>
                            <div>
                              <small className="text-muted">
                                {formatMessage("volume_chart")}:{" "}
                              </small>
                              <br /> 
                              {selectedChartDetails.rangeType == 0 ? chartSimUsageDaily.totalVolumeDetails.volume : chartSimUsageMonthly.totalVolumeDetails.volume}
                            </div>{" "}
                          </div>
                          <div className="col-4 d-flex align-items-start">
                            <i className="fa fa-square text-success mt-1  mr-1"></i>
                            <div>
                              <small className="text-muted">
                                {formatMessage("volume_tx_chart")}:{" "}
                              </small>
                              <br />{" "}
                              {selectedChartDetails.rangeType == 0 ? chartSimUsageDaily.totalVolumeDetails.volume_tx : chartSimUsageMonthly.totalVolumeDetails.volume_tx}
                            </div>{" "}
                          </div>
                          <div className="col-4 d-flex align-items-start">
                            <i className="fa fa-square text-warning mt-1  mr-1"></i>
                            <div>
                              <small className="text-muted">
                                {formatMessage("volume_rx_chart")}:{" "}
                              </small>
                              <br />{" "}
                              {selectedChartDetails.rangeType == 0 ? chartSimUsageDaily.totalVolumeDetails.volume_rx : chartSimUsageMonthly.totalVolumeDetails.volume_rx}
                            </div>{" "}
                          </div>
                        </div>
                      )}
                  </>
              }
            </div>
          </div>
        </>
        {/* )
      }   */}
    </React.Fragment>
  );
};

export default ResetSimPopupHOC(EnableDisableSimPopupHOC(ActiveDeviceConfirmPopupHOC(
  ConfirmPopupHOC(observer(DeviceDetials)))));
