/*    
<summary>
   This file is used to provide initial values or state to the transactions Module observables.
    Developer:Aashish Singh, Created Date:31-Oct-2023
</summary>
*/

import { IPaymentTransactionListVM } from "../../models/response/ITransactionResponse";


export const initialState: IPaymentTransactionListVM = {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage: 1,
        PageSize: 10,
    },
    Transactions: []
}