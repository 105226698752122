/*    
<summary>
   This typescript file renders our component and unregisters service worker.
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { StoreProvider } from './contexts/StoreProvider';
import IntlProviderNew from './translations/IntlProviderNew'; 
import { StyledEngineProvider } from '@mui/material/styles';

ReactDOM.render(
  <StyledEngineProvider injectFirst>
  <StoreProvider>
    <IntlProviderNew>
      <Router>
        <App />
      </Router>
    </IntlProviderNew>
  </StoreProvider>
  </StyledEngineProvider>,
  document.getElementById('root')
);
 
serviceWorker.unregister();