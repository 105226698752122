/*    
<summary>
   This is the Users Component which shows the Users Data.
   Developer: Aashish Singh, Created Date:29-August-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useRef, useState } from "react";
import "./singleView.css";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../../contexts/StoreProvider";
import { observer } from "mobx-react";
import arrowicon from "../../../../assets/Images/arrow.svg";
import {
  faKey,
  faPencilAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

const SingleView = (props: any) => {
  const docHomeRef = useRef<HTMLDivElement | null>(null);
  const docConfigRef = useRef<HTMLDivElement | null>(null);
  const docGroupRef = useRef<HTMLDivElement | null>(null);
  const docDeviceRef = useRef<HTMLDivElement | null>(null);
  const helpBodyConfig = useRef<HTMLDivElement | null>(null);
  const path = props.location.state ? props.location.state.div_id: "default";


  useEffect(() => {

    switch (path) {
      case "doc_configure":
        if (docConfigRef.current) {
          docConfigRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
        break;
      case "doc_group":
        if (docGroupRef.current) {
          docGroupRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
        break;
      case "doc_device":
        if (docDeviceRef.current) {
          docDeviceRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
        break;
      case "doc_home":
        if (docHomeRef.current) {
          docHomeRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
        break;   
      case "doc_config_help":
        if (helpBodyConfig.current) {
          helpBodyConfig.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }
        break;        
      default:
        if (docHomeRef.current) {
          docHomeRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
        break;
    }
  }, [path]);
  
  return (
    <React.Fragment>
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div id="moveContent">
          <div className="container-fluid">
            <div className="row no-gutters  align-items-center">
              <div className="col-sm-6 col-12">
                <div className="filter-left-area">
                  <ul className="d-flex align-items-center">
                    <li>
                      <p className="h5 pl-5 mb-0">Documentation</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="add-list"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="article-container">
        <div className="row justify-content-center no-gutters">
          <div className="col-lg-2 col-md-3 col-sm-4 col-12">
            <div className="sidebar-wrapper">
              <div className="sidebar-widget">
                <div className="doc-nav">
                  <ul id="faq">
                    <li>
                      {" "}
                      <a href="#doc_home">
                        <img src={arrowicon} /> Getting started
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a href="#doc_configure">
                        <img src={arrowicon} /> Configuration
                      </a>
                    </li>
                   
                    <li>
                      {" "}
                      <a href="#doc_group">
                        <img src={arrowicon} /> Groups
                      </a>
                    </li>
                   
                    <li>
                      {" "}
                      <a href="#doc_device">
                        <img src={arrowicon} /> Devices
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-10 col-md-9 col-sm-8 col-12">
            <div className="px-5 py-4 documentations" id="doc_home" ref={docHomeRef}>
              <div className="text-wrap p-lg-6 text-justify">
                <h2 className="mt-0 mb-2 fs50">Introduction to monoZ</h2>
                <p>
                  <strong>monoZ</strong> is a dedicated partner for System
                  Integrators and Enterprises, committed to helping them
                  navigate industry challenges by seamlessly connecting their
                  assets to the internet in a manner that is both rapid and
                  cost-effective. Their approach encompasses a comprehensive
                  range of solutions, covering everything from Hardware and
                  Firmware to Connectivity and Cloud services, creating an
                  all-encompassing, one-stop offering. What sets monoZ apart
                  is their focus on "Edge 2 Cloud" solutions, recognizing the
                  critical importance of bridging the gap between edge devices
                  and the cloud. They tailor their solutions to individual
                  user needs, drawing from a rich palette of technologies such
                  as{" "}
                  <strong>LTE-M, NB-IoT, Wi-Fi, BLE, LoRA, and more</strong>.{" "}
                  <br></br>At the heart of monoZ's capability is their team of
                  over 150 talented professionals, each bringing a unique
                  skill set to the table. With a rich 20-year history in the
                  telecommunications domain, they offer invaluable knowledge
                  and expertise to drive digital transformation. monoZ also
                  takes pride in a robust global partner ecosystem, enhancing
                  their ability to deliver tailored solutions effectively.
                  Moreover, monoZ places a strong emphasis on customer support
                  and services. Their global presence, spanning Japan, India,
                  North America, and Europe, ensures that clients receive
                  multi-language support. Additionally, they offer
                  professional services designed to guide and support
                  customers on their digital transformation journey, providing
                  a holistic experience.
                </p>
                {/* <div className="alert alert-danger" role="alert">
                  <strong>Note:-</strong> jQuery plugin dependencies may relay
                  on used platform and their versions.
                </div> */}

                
                <h3 className="mt-4">monoZ IoT Ecosystem</h3>
                <div
                  className="slate-table-wrapper"
                  data-slate-node="element"
                >
                  <table className="table border-0">
                    <tbody>
                      <tr
                        data-slate-node="element"
                        className="TableRowElement___StyledTr-sc-718caz-0 fnTgiE slate-tr"
                      >
                        <th
                          data-slate-node="element"
                          className="StyledElement___StyledDiv-sc-2e063k-0 iwrqJg slate-th"
                        >
                          <span data-slate-node="text">
                            <span data-slate-leaf="true">
                              <span data-slate-string="true">Product</span>
                            </span>
                          </span>
                        </th>
                        <th
                          data-slate-node="element"
                          className="StyledElement___StyledDiv-sc-2e063k-0 iwrqJg slate-th"
                        >
                          <span data-slate-node="text">
                            <span data-slate-leaf="true">
                              <span data-slate-string="true">
                                Description
                              </span>
                            </span>
                          </span>
                        </th>
                      </tr>
                      <tr
                        data-slate-node="element"
                        className="TableRowElement___StyledTr-sc-718caz-0 fnTgiE slate-tr"
                      >
                        <td
                          data-slate-node="element"
                          className="TableCellElement___StyledTd-sc-r69en2-0 ojsWl slate-td"
                        >
                          <div className="TableCellElement___StyledDiv-sc-r69en2-1 kcNcvY slate-TableCellElement-content">
                            <span data-slate-node="text">
                              <span data-slate-leaf="true">
                                <code
                                  data-slate-leaf="true"
                                  className="StyledLeaf___StyledSpan-sc-129cvv1-0 clfaXn slate-code"
                                >
                                  <span data-slate-string="true">
                                    monoZLink
                                  </span>
                                </code>
                              </span>
                            </span>
                          </div>
                          <div className="TableCellElement___StyledDiv2-sc-r69en2-2 ceiliP slate-TableCellElement-resizableWrapper group">
                            <div className="TableCellElement___StyledResizable-sc-r69en2-3 ZWxFm">
                              <div></div>
                            </div>
                            <div className="TableCellElement___StyledDiv3-sc-r69en2-4 lihlKB slate-TableCellElement-handle"></div>
                          </div>
                        </td>
                        <td
                          data-slate-node="element"
                          className="TableCellElement___StyledTd-sc-r69en2-0 ojsWl slate-td"
                        >
                          <div className="TableCellElement___StyledDiv-sc-r69en2-1 kcNcvY slate-TableCellElement-content">
                            <span data-slate-node="text">
                              <span data-slate-leaf="true">
                                <span data-slate-string="true">
                                  IoT Platform for 1NCE users that securely
                                  enable bidirectional data flow between IoT
                                  device and cloud application in a closed
                                  network
                                </span>
                              </span>
                            </span>
                          </div>
                          <div className="TableCellElement___StyledDiv2-sc-r69en2-2 ceiliP slate-TableCellElement-resizableWrapper group">
                            <div className="TableCellElement___StyledResizable-sc-r69en2-3 ZWxFm">
                              <div></div>
                            </div>
                            <div className="TableCellElement___StyledDiv3-sc-r69en2-4 lihlKB slate-TableCellElement-handle"></div>
                          </div>
                        </td>
                      </tr>
                      <tr
                        data-slate-node="element"
                        className="TableRowElement___StyledTr-sc-718caz-0 fnTgiE slate-tr"
                      >
                        <td
                          data-slate-node="element"
                          className="TableCellElement___StyledTd-sc-r69en2-0 ojsWl slate-td"
                        >
                          <div className="TableCellElement___StyledDiv-sc-r69en2-1 kcNcvY slate-TableCellElement-content">
                            <span data-slate-node="text">
                              <span data-slate-leaf="true">
                                <code
                                  data-slate-leaf="true"
                                  className="StyledLeaf___StyledSpan-sc-129cvv1-0 clfaXn slate-code"
                                >
                                  <span data-slate-string="true">
                                    monoZero
                                  </span>
                                </code>
                              </span>
                            </span>
                          </div>
                          <div className="TableCellElement___StyledDiv2-sc-r69en2-2 ceiliP slate-TableCellElement-resizableWrapper group">
                            <div className="TableCellElement___StyledResizable-sc-r69en2-3 ZWxFm">
                              <div></div>
                            </div>
                            <div className="TableCellElement___StyledDiv3-sc-r69en2-4 lihlKB slate-TableCellElement-handle"></div>
                          </div>
                        </td>
                        <td
                          data-slate-node="element"
                          className="TableCellElement___StyledTd-sc-r69en2-0 ojsWl slate-td"
                        >
                          <div className="TableCellElement___StyledDiv-sc-r69en2-1 kcNcvY slate-TableCellElement-content">
                            <span data-slate-node="text">
                              <span data-slate-leaf="true">
                                <span data-slate-string="true">
                                  Comprehensive and flexible IoT development
                                  kit designed to support the construction of
                                  a wide range of M2M cellular IoT
                                  applications
                                </span>
                              </span>
                            </span>
                          </div>
                          <div className="TableCellElement___StyledDiv2-sc-r69en2-2 ceiliP slate-TableCellElement-resizableWrapper group">
                            <div className="TableCellElement___StyledResizable-sc-r69en2-3 ZWxFm">
                              <div></div>
                            </div>
                            <div className="TableCellElement___StyledDiv3-sc-r69en2-4 lihlKB slate-TableCellElement-handle"></div>
                          </div>
                        </td>
                      </tr>
                      <tr
                        data-slate-node="element"
                        className="TableRowElement___StyledTr-sc-718caz-0 fnTgiE slate-tr"
                      >
                        <td
                          data-slate-node="element"
                          className="TableCellElement___StyledTd-sc-r69en2-0 ojsWl slate-td"
                        >
                          <div className="TableCellElement___StyledDiv-sc-r69en2-1 kcNcvY slate-TableCellElement-content">
                            <span data-slate-node="text">
                              <span data-slate-leaf="true">
                                <code
                                  data-slate-leaf="true"
                                  className="StyledLeaf___StyledSpan-sc-129cvv1-0 clfaXn slate-code"
                                >
                                  <span data-slate-string="true">Mesimo</span>
                                </code>
                              </span>
                            </span>
                          </div>
                          <div className="TableCellElement___StyledDiv2-sc-r69en2-2 ceiliP slate-TableCellElement-resizableWrapper group">
                            <div className="TableCellElement___StyledResizable-sc-r69en2-3 ZWxFm">
                              <div></div>
                            </div>
                            <div className="TableCellElement___StyledDiv3-sc-r69en2-4 lihlKB slate-TableCellElement-handle"></div>
                          </div>
                        </td>
                        <td
                          data-slate-node="element"
                          className="TableCellElement___StyledTd-sc-r69en2-0 ojsWl slate-td"
                        >
                          <div className="TableCellElement___StyledDiv-sc-r69en2-1 kcNcvY slate-TableCellElement-content">
                            <span data-slate-node="text">
                              <span data-slate-leaf="true">
                                <span data-slate-string="true">
                                  Global MVNO service that offers secure,
                                  flexible, and affordable cellular
                                  connectivity solutions tailored for IoT
                                  devices
                                </span>
                              </span>
                            </span>
                          </div>
                          <div className="TableCellElement___StyledDiv2-sc-r69en2-2 ceiliP slate-TableCellElement-resizableWrapper group">
                            <div className="TableCellElement___StyledResizable-sc-r69en2-3 ZWxFm">
                              <div></div>
                            </div>
                            <div className="TableCellElement___StyledDiv3-sc-r69en2-4 lihlKB slate-TableCellElement-handle"></div>
                          </div>
                        </td>
                      </tr>
                      <tr
                        data-slate-node="element"
                        className="TableRowElement___StyledTr-sc-718caz-0 fnTgiE slate-tr"
                      >
                        <td
                          data-slate-node="element"
                          className="TableCellElement___StyledTd-sc-r69en2-0 ojsWl slate-td"
                        >
                          <div className="TableCellElement___StyledDiv-sc-r69en2-1 kcNcvY slate-TableCellElement-content">
                            <span data-slate-node="text">
                              <span data-slate-leaf="true">
                                <code
                                  data-slate-leaf="true"
                                  className="StyledLeaf___StyledSpan-sc-129cvv1-0 clfaXn slate-code"
                                >
                                  <span data-slate-string="true">
                                    Centra-IoT
                                  </span>
                                </code>
                              </span>
                            </span>
                          </div>
                          <div className="TableCellElement___StyledDiv2-sc-r69en2-2 ceiliP slate-TableCellElement-resizableWrapper group">
                            <div className="TableCellElement___StyledResizable-sc-r69en2-3 ZWxFm">
                              <div></div>
                            </div>
                            <div className="TableCellElement___StyledDiv3-sc-r69en2-4 lihlKB slate-TableCellElement-handle"></div>
                          </div>
                        </td>
                        <td
                          data-slate-node="element"
                          className="TableCellElement___StyledTd-sc-r69en2-0 ojsWl slate-td"
                        >
                          <div className="TableCellElement___StyledDiv-sc-r69en2-1 kcNcvY slate-TableCellElement-content">
                            <span data-slate-node="text">
                              <span data-slate-leaf="true">
                                <span data-slate-string="true">
                                  Powerful data visualization and device
                                  management platform to connect, control,
                                  monitor, and manage devices in real-time
                                </span>
                              </span>
                            </span>
                          </div>
                          <div className="TableCellElement___StyledDiv2-sc-r69en2-2 ceiliP slate-TableCellElement-resizableWrapper group">
                            <div className="TableCellElement___StyledResizable-sc-r69en2-3 ZWxFm">
                              <div></div>
                            </div>
                            <div className="TableCellElement___StyledDiv3-sc-r69en2-4 lihlKB slate-TableCellElement-handle"></div>
                          </div>
                        </td>
                      </tr>
                      <tr
                        data-slate-node="element"
                        className="TableRowElement___StyledTr-sc-718caz-0 fnTgiE slate-tr"
                      >
                        <td
                          data-slate-node="element"
                          className="TableCellElement___StyledTd-sc-r69en2-0 ojsWl slate-td"
                        >
                          <div className="TableCellElement___StyledDiv-sc-r69en2-1 kcNcvY slate-TableCellElement-content">
                            <span data-slate-node="text">
                              <span data-slate-leaf="true">
                                <code
                                  data-slate-leaf="true"
                                  className="StyledLeaf___StyledSpan-sc-129cvv1-0 clfaXn slate-code"
                                >
                                  <span data-slate-string="true">
                                    monoZ:Jet
                                  </span>
                                </code>
                              </span>
                            </span>
                          </div>
                          <div className="TableCellElement___StyledDiv2-sc-r69en2-2 ceiliP slate-TableCellElement-resizableWrapper group">
                            <div className="TableCellElement___StyledResizable-sc-r69en2-3 ZWxFm">
                              <div></div>
                            </div>
                            <div className="TableCellElement___StyledDiv3-sc-r69en2-4 lihlKB slate-TableCellElement-handle"></div>
                          </div>
                        </td>
                        <td
                          data-slate-node="element"
                          className="TableCellElement___StyledTd-sc-r69en2-0 ojsWl slate-td"
                        >
                          <div className="TableCellElement___StyledDiv-sc-r69en2-1 kcNcvY slate-TableCellElement-content">
                            <span data-slate-node="text">
                              <span data-slate-leaf="true">
                                <span data-slate-string="true">
                                  1NCE compatible System-on-Module that
                                  enables secure IoT connectivity to your
                                  system
                                </span>
                              </span>
                            </span>
                          </div>
                          <div className="TableCellElement___StyledDiv2-sc-r69en2-2 ceiliP slate-TableCellElement-resizableWrapper group">
                            <div className="TableCellElement___StyledResizable-sc-r69en2-3 ZWxFm">
                              <div></div>
                            </div>
                            <div className="TableCellElement___StyledDiv3-sc-r69en2-4 lihlKB slate-TableCellElement-handle"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
              <div id="doc_configure" ref={docConfigRef}>
              <hr className="my-5"/>
              </div>
              <div className="text-wrap p-lg-6">
                <h2 className="mt-0 mb-2 fs50">Configurations</h2>
                <p>
                  In<strong> monoZ</strong>, a configuration refers to a set
                  of defined settings and parameters that determine how
                  devices or groups of devices behave within the monoZ
                  ecosystem. These configurations are crucial for managing and
                  controlling devices effectively, ensuring they operate
                  according to specific requirements.
                </p>

                <h2 className="mt-3 mb-2">Create Configuration </h2>
                
                <div className="">
                  <h3 className=" d-flex align-items-center">
                    Source Protocol
                    <small>
                      <span className="mx-2 title_code">MQTT</span>
                      <i className="fa fa-arrow-right text-muted"></i>
                      <span className="ml-2 title_code">HTTPS</span>
                    </small>
                  </h3>
                  <p>
                    Combining MQTT and HTTPS involves using MQTT for efficient
                    messaging in IoT or other applications, while securing the
                    communication using HTTPS. This can be achieved by having
                    an MQTT broker for message handling and communication
                    between devices, and using HTTPS for secure communication
                    between the broker and other systems (e.g., a backend
                    server, web application).
                  </p>

                  <div className="docsDetailsWrap">
                    <div className="card p-3 mb-2 mt-2">
                      <div className="row">
                        <div className="col-md-4 col-12">
                          <h6>
                            Topic Pattern
                          </h6>
                        </div>
                        <div className="col-md-8 col-12">
                          <p>
                            This is a pattern for MQTT topics, illustrating how
                            topics are structured, including placeholders like orgid
                            and <code>iccid</code> that can be dynamically filled
                            with actual values.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card p-3 mb-2 mt-2">
                      <div className="row">
                        <div className="col-md-4 col-12">
                          <h6>
                            Destination Protocol
                          </h6>
                        </div>
                        <div className="col-md-8 col-12">
                          <p>
                            The protocol used for the destination, in this case,{" "}
                            <strong>
                              HTTPS (HyperText Transfer Protocol Secure)
                            </strong>
                            , the standard for secure communication over the web.
                          </p>
                        </div>
                      </div>
                    </div>
                    
                    <div className="card p-3 mb-2 mt-2">
                      <div className="row">
                        <div className="col-md-4 col-12">
                          <h6>
                            Configuration Details
                          </h6>
                        </div>
                        <div className="col-md-8 col-12">
                          <h3 className="mb-0">Configuration Name</h3>
                          <p className="mb-4">
                            A user-defined name for this particular configuration,
                            providing a reference for easy identification and
                            management.
                          </p>

                          <h3 className="mb-0">Description</h3>
                          <p className="mb-4">
                            A brief, user-provided description of this
                            configuration, offering additional context or notes.
                          </p>

                          <h3 className="mb-0">Host</h3>
                          <p className="mb-4">
                            The base URL to which HTTP requests will be made,
                            typically starting with <code>https://</code>.
                          </p>

                          <h3 className="mb-0">Parameters</h3>
                          <ul className="mb-4 pl-3">
                            <li className="bullets">
                              <strong>Authorization:</strong>
                              <p>
                                Specifies the type of authorization required for the
                                request, including options like no authorization or
                                custom headers.
                              </p>
                            </li>
                            <li className="bullets">
                              <strong>Custom Headers:</strong>
                              <p>
                                Additional headers that can be customized and
                                included in the HTTP request, often used for
                                authentication or specifying content types.
                              </p>
                            </li>
                          </ul>

                          <div id="doc_config_help" ref={helpBodyConfig}>
                          <h3 className="mb-0">Send Options [Body]</h3>
                          <ul className="pl-3">
                            <li className="bullets">
                              <strong>ICCID:</strong>
                              <p>
                                An option to include the ICCID (Integrated Circuit
                                Card Identifier) in the JSON data being sent to the
                                destination URL.
                              </p>
                            </li>
                            <li className="bullets">
                              <strong>Topic:</strong>
                              <p>
                                An option to include the entire MQTT topic in the
                                JSON data being sent to the destination URL.
                              </p>
                            </li>
                          </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
 
                  {/* <h3 className="mt-5 d-flex align-items-center">
                    Source Protocol
                    <small>
                      <span className="mx-2 title_code">HTTPS</span>
                      <i className="fa fa-arrow-right text-muted"></i>
                      <span className="ml-2 title_code">MQTT</span>
                    </small>
                  </h3>
                  <p>
                    Using HTTPS to communicate with an MQTT broker involves
                    setting up an HTTP-to-MQTT bridge, allowing communication
                    between HTTP-based applications and MQTT-enabled devices
                    or systems. The HTTP-to-MQTT bridge translates HTTP
                    requests into MQTT messages and vice versa.
                  </p>

                  <div className="docsDetailsWrap">
                    <div className="card p-3 mb-2 mt-2">
                      <div className="row">
                        <div className="col-md-4 col-12">
                          <h6>
                            Parameters
                          </h6>
                        </div>
                        <div className="col-md-8 col-12">
                          <ul>
                            <li className="mb-4">
                              <strong>Host:</strong>
                              <p>The MQTT broker host URL in mqtts:// format.</p>
                            </li>
                            <li className="mb-4">
                              <strong>Client ID:</strong>
                              <p>Unique identifier for the MQTT client.</p>
                            </li>
                            <li className="mb-4">
                              <strong>User ID:</strong>
                              <p>
                                User identifier for authentication with the MQTT
                                broker.
                              </p>
                            </li>
                            <li>
                              <strong>Password:</strong>
                              <p>
                                Password for authentication with the MQTT broker.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card p-3 mb-2 mt-2">
                      <div className="row">
                        <div className="col-md-4 col-12">
                          <h6>
                          Advanced Options
                          </h6>
                        </div>
                        <div className="col-md-8 col-12">
                          <ul>
                            <li className="mb-4">
                              <strong>Use Certificate:</strong>
                              <p>
                                Indicates whether SSL/TLS certificates are used for
                                secure communication.
                              </p>
                            </li>
                            <li className="mb-4">
                              <strong>CA File (Server Certificate):</strong>
                              <p>
                                Path to the Certificate Authority (CA) file for
                                server certificate validation.
                              </p>
                            </li>
                            <li className="mb-4">
                              <strong>Client Certificate File:</strong>
                              <p>
                                Path to the client certificate file for
                                authentication.
                              </p>
                            </li>
                            <li>
                              <strong>Client Key File:</strong>
                              <p>
                                Path to the client private key file for
                                authentication.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card p-3 mb-2 mt-2">
                      <div className="row">
                        <div className="col-md-4 col-12">
                          <h6>
                          Topic
                          </h6>
                        </div>
                        <div className="col-md-8 col-12">
                          <ul>
                            <li className="mb-4">
                              <strong>Same as Incoming</strong>
                              <p>
                              Specifies that the outgoing topic should be the same
                          as the incoming topic.
                              </p>
                            </li>
                            <li>
                              <strong>Custom</strong>
                              <p>
                              User-defined custom topic.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="card p-3 mb-2 mt-2">
                      <div className="row">
                        <div className="col-md-4 col-12">
                          <h6>
                          QOS
                          </h6>
                        </div>
                        <div className="col-md-8 col-12">
                          <ul>
                            <li className="mb-4">
                              <strong>Zero</strong>
                              <p>
                              Quality of Service level 0 (QoS 0) - At most once
                          message delivery.
                              </p>
                            </li>
                            <li>
                              <strong>One</strong>
                              <p>
                              Quality of Service level 1 (QoS 1) - At least once
                          message delivery.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                
                <h2 className="mt-5 mb-2">Update Configuration:</h2>
                
                <div className="">
                  <p>
                    To keep your monoZ project up-to-date and in sync with
                    evolving requirements, you may need to modify existing
                    configurations. This section provides guidance on updating
                    an existing configuration, whether it involves tweaking
                    parameters, changing the communication protocol, or
                    adapting to new scenarios.
                  </p>

                  <h4>Steps to Update a Configuration:</h4>

                  <div className="progress-steps">
                    <div>
                      <h1>
                        <span className="numbers">01</span>
                        Access the Configuration Dashboard
                      </h1>
                      <p>Log in to your monoZ account.</p>
                      <p>Navigate to the Configuration Management section.</p>
                    </div>

                    <div>
                      <h1>
                        <span className="numbers">02</span>
                        Select the Configuration to Update
                      </h1>
                      <p>
                        Locate the configuration you wish to update from the
                        list of existing configurations.
                      </p>
                      <p>Click on the configuration to open its details.</p>
                    </div>
                    <div className="active">
                      <h1>
                        <span className="numbers">03</span>
                        Edit Configuration Details
                      </h1>
                      <p>
                        Modify the configuration details according to your
                        requirements. You can update the following aspects:
                      </p>
                      <ul>
                        <li className="p-2">
                          <strong>Configuration Name:</strong>
                          <p>
                            Change the user-defined name for this configuration,
                            providing a reference for easy identification and
                            management.
                          </p>
                        </li>
                        <li className="p-2">
                          <strong>Description:</strong>
                        
                          <p>
                            Update the brief user-provided description to offer
                            additional context or notes regarding the
                            configuration.
                          </p>
                        </li>
                      </ul>
                    </div>

                    <div>
                      <h1>
                        <span className="numbers">04</span>
                        Update Parameters
                      </h1>
                      <p>
                        Depending on the source and destination protocols, you may need to update specific parameters. Here are the key parameters you can modify:
                      </p>
                      <h3 className=" d-flex align-items-center">
                        For
                        <small>
                          <span className="mx-2 title_code">MQTT</span>
                          <i className="fa fa-arrow-right text-muted"></i>
                          <span className="ml-2 title_code">HTTPS</span>
                        </small>
                      </h3>
                      <div className="docsDetailsWrap">
                        <div className="card p-3 mb-2 mt-2">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <h6>
                              Host
                              </h6>
                            </div>
                            <div className="col-md-8 col-12">
                              <p>
                              Update the base URL to which HTTP requests will be made. Ensure it starts with <code>https://</code>.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card p-3 mb-2 mt-2">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <h6>
                              Parameters
                              </h6>
                            </div>
                            <div className="col-md-8 col-12">
                              <p>
                                
                              </p>
                            </div>
                          </div>
                        </div>
                        
                        <div className="card p-3 mb-2 mt-2">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <h6>
                              Authorization
                              </h6>
                            </div>
                            <div className="col-md-8 col-12">
                              <p>
                              Specify the type of authorization required for the request, including options like no authorization or custom headers.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="card p-3 mb-2 mt-2">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <h6>
                              Custom Headers
                              </h6>
                            </div>
                            <div className="col-md-8 col-12">
                              <p>
                              Update or customize additional headers that can be included in the HTTP request, often used for authentication or specifying content types.
                              </p>
                            </div>
                          </div>
                        </div>


                        <div className="card p-3 mb-2 mt-2">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <h6>
                              Send Options
                              </h6>
                            </div>
                            <div className="col-md-8 col-12">
                              <h4 className="mb-0">ICCID</h4>
                              <p className="mb-4">
                              Choose whether to include the ICCID (Integrated Circuit Card Identifier) in the JSON data being sent to the destination URL.
                              </p>
                              <h4 className="mb-0">Topic</h4>
                              <p className="mb-4">
                              Decide whether to include the entire MQTT topic in the JSON data being sent to the destination URL.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <h3 className="mt-4 d-flex align-items-center">
                        For
                        <small>
                          <span className="mx-2 title_code">HTTPS</span>
                          <i className="fa fa-arrow-right text-muted"></i>
                          <span className="ml-2 title_code">MQTT</span>
                        </small>
                      </h3>
                      <div className="docsDetailsWrap">
                        <div className="card p-3 mb-2 mt-2">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <h6>
                              Host
                              </h6>
                            </div>
                            <div className="col-md-8 col-12">
                              <p>
                              Update the MQTT broker host URL in the <code>mqtts://</code> format.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card p-3 mb-2 mt-2">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <h6>
                              Client ID
                              </h6>
                            </div>
                            <div className="col-md-8 col-12">
                              <p>
                              Modify the unique identifier for the MQTT client.
                              </p>
                            </div>
                          </div>
                        </div>
                        
                        <div className="card p-3 mb-2 mt-2">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <h6>
                              User ID
                              </h6>
                            </div>
                            <div className="col-md-8 col-12">
                              <p>
                              Update the user identifier for authentication with the MQTT broker.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="card p-3 mb-2 mt-2">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <h6>
                              Password  
                              </h6>
                            </div>
                            <div className="col-md-8 col-12">
                              <p className="mb-0">
                              Change the password for authentication with the MQTT broker.
                              </p>
                            </div>
                          </div>
                        </div>

                      </div> 
                      <h3 className=" d-flex align-items-center">
                        Advanced Options:
                      </h3>
                      <div className="docsDetailsWrap">
                        <div className="card p-3 mb-2 mt-2">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <h6>
                              Use Certificate
                              </h6>
                            </div>
                            <div className="col-md-8 col-12">
                              <p>
                              Toggle whether SSL/TLS certificates are used for secure communication.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card p-3 mb-2 mt-2">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <h6>
                              CA File (Server Certificate)
                              </h6>
                            </div>
                            <div className="col-md-8 col-12">
                              <p>
                              Update the path to the Certificate Authority (CA) file for server certificate validation.
                              </p>
                            </div>
                          </div>
                        </div>
                        
                        <div className="card p-3 mb-2 mt-2">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <h6>
                              Client Certificate File
                              </h6>
                            </div>
                            <div className="col-md-8 col-12">
                              <p>
                              Update the path to the client certificate file for authentication.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="card p-3 mb-2 mt-2">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <h6>
                              Client Key File
                              </h6>
                            </div>
                            <div className="col-md-8 col-12">
                              <p>
                              Update the path to the client private key file for authentication.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card p-3 mb-2 mt-2">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <h6>
                              Topic
                              </h6>
                            </div>
                            <div className="col-md-8 col-12">
                              <p>
                              Depending on your use case, you can update the topic to either be the same as the incoming topic or customize a user-defined custom topic.
                              </p>
                            </div>
                          </div>
                        </div> 


                        <div className="card p-3 mb-2 mt-2">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <h6>
                              QOS
                              </h6>
                            </div>
                            <div className="col-md-8 col-12">
                              <p>Choose the desired Quality of Service level:</p>
                              <h4 className="mb-0">Zero (QoS 0)</h4>
                              <p className="mb-4">
                              At most once message delivery.
                              </p>
                              <h4 className="mb-0">One (QoS 1)</h4>
                              <p>
                              At least once message delivery.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>*/}
                    </div>

                    <div>
                      <h1>
                        <span className="numbers">05</span>
                        Save Changes
                      </h1>
                      <p>After updating the configuration details and parameters, make sure to save the changes.</p>
                    </div>

                    <div>
                      <h1>
                        <span className="numbers">06</span>
                        Test the Updated Configuration
                      </h1>
                      <p>Before applying the changes in a production environment, it's a good practice to test the updated configuration. Verify that it works as intended and does not introduce any issues.</p>
                    </div>

                    <div>
                      <h1>
                        <span className="numbers">07</span>
                        Apply the Updated Configuration
                      </h1>
                      <p>Once you're satisfied with the updated configuration, apply the changes to your monoZ project. This ensures that the new settings take effect and facilitate seamless communication.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div id="doc_group" ref={docGroupRef}>
              <hr className="my-5"/>
              </div>
              <div className="text-wrap p-lg-6">
                <h2 className="mt-0 mb-4 fs50">Groups</h2>
                <p>
                  <strong>Groups in monoZ </strong> are a vital organizational
                  tool for efficiently managing configurations and
                  categorizing devices based on specific criteria. They allow
                  you to assign configurations to sets of devices,
                  streamlining management and ensuring that changes or updates
                  are automatically applied to all devices within a group.
                  This categorization offers granular control, scalability,
                  targeted communication, and optimized workflows, making it
                  an essential component for effective management of IoT and
                  device networks.
                </p>

                <h2 className="mt-5 mb-2">Create Groups</h2>
                
                <div className="">
                  <p>
                    When setting up your monoZ project, it's essential to
                    create and manage groups. Groups serve as a way to
                    organize your devices based on specific criteria, making
                    it easier to apply configurations and streamline device
                    management. Here are the key steps for creating a group:
                  </p>

                  <h4>Steps to Create a Group:</h4>

                  <div className="progress-steps">
                    <div>
                      <h1>
                        <span className="numbers">01</span>
                        Group Name 
                      </h1>
                      <p>Enter a descriptive name for the group, which
                          clearly represents the purpose or category of
                          devices in this group.</p>
                    </div>

                    <div>
                      <h1>
                        <span className="numbers">02</span>
                        Available Configuration
                      </h1>
                      <p>
                      Select the configuration to assign to this group
                          from the available options.</p>
                    </div>

                    <div>
                      <h1>
                        <span className="numbers">03</span>
                        Description
                      </h1>
                      <p>
                      You can provide additional context or notes by
                          entering a description for the group.</p>
                    </div>
                  </div>
                </div>
                
                <h2 className="mt-5 mb-2">Update Groups:</h2>
                
                <div>
                  <p>
                    To keep your monoZ project well-organized, you may need to
                    update existing groups with new configurations, details,
                    or devices. Here are the key steps for updating a group:
                  </p>

                  <h4>Steps to Update a Configuration:</h4>
                  <div className="progress-steps">
                    <div>
                      <h1>
                        <span className="numbers">01</span>
                        Access the Group Dashboard
                      </h1>
                      <p>Log in to your monoZ account. Navigate to the Group Management section.</p>
                    </div>

                    <div>
                      <h1>
                        <span className="numbers">02</span>
                        Select the Group to Update
                      </h1>
                      <p>
                      Locate the group you wish to update from the list of existing groups.</p>
                      <p>Click on the group to open its details.</p>
                    </div>

                    <div>
                      <h1>
                        <span className="numbers">03</span>
                        Edit Group Details
                      </h1>
                      <p>
                      Modify the group details according to your requirements. You can update the following aspects:</p>
                      
                      <div className="docsDetailsWrap">
                        <div className="card p-3 mb-2 mt-2">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <h6>
                              Group Name *
                              </h6>
                            </div>
                            <div className="col-md-8 col-12">
                              <p>Change the descriptive name for the group, providing a clear representation of its purpose or category.</p>
                            </div>
                          </div>
                        </div>

                        <div className="card p-3 mb-2 mt-2">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <h6>
                              Available Configuration *
                              </h6>
                            </div>
                            <div className="col-md-8 col-12">
                              <p>Select a new configuration for the group, replacing the existing one.</p>
                            </div>
                          </div>
                        </div>

                        <div className="card p-3 mb-2 mt-2">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <h6>
                              Description
                              </h6>
                            </div>
                            <div className="col-md-8 col-12">
                              <p>Update the description to provide additional context or notes about the group.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                    </div>

                    <div>
                      <h1>
                        <span className="numbers">04</span>
                        Add or Remove Devices
                      </h1>
                      <p>
                      Depending on your requirements, you can add new devices to the group or remove existing ones. This helps in keeping the group's composition up-to-date.</p>
                    </div>

                    <div>
                      <h1>
                        <span className="numbers">05</span>
                        Save Changes
                      </h1>
                      <p>
                      After updating the group details, be sure to save the changes to apply the modifications</p>
                    </div>
                  </div>
                </div>
              </div>
              <div id="doc_device" ref={docDeviceRef}>
              <hr className="my-5"/>
              </div>
              <div className="text-wrap p-lg-6">
                <h2 className="mt-0 mb-4 fs50">Devices</h2>
                <p>
                  <strong>In monoZ,</strong> devices are added to groups, and
                  each group is associated with a particular configuration.
                  This allows for efficient management and communication of
                  devices based on their common characteristics or
                  requirements. Devices inherit the configuration of the group
                  to which they belong, simplifying the process of applying
                  specific communication settings to multiple devices at once.
                  This grouping and configuration system helps streamline
                  device management and ensures that devices are appropriately
                  configured for their intended purposes.
                </p>

                <h2 className="mt-5 mb-2">Create Device:</h2>
                
                <div className="">
                  <p>
                    To add a device in monoZ, you need to follow a few simple
                    steps. Begin by logging into your monoZ account, then
                    access the device creation section. Fill in essential
                    details, such as the device's name, ICCID (Integrated
                    Circuit Card Identifier), and assign it to a specific
                    group. You can also add up to five tags to categorize and
                    filter your devices effectively. After submitting the
                    form, the device will be created and organized within the
                    chosen group, ready for use in your monoZ project. This
                    process ensures your devices are well-managed and
                    categorized for efficient monitoring and control.
                  </p>

                  <div>
                    <h4>
                      Steps to Create a Device:
                    </h4>
                    
                    <div className="progress-steps">
                      <div>
                        <h1>
                          <span className="numbers">01</span>
                          Log In to monoZ
                        </h1>
                        <p>To begin, log in to your monoZ account using your login credentials.</p>
                      </div>

                      <div>
                        <h1>
                          <span className="numbers">02</span>
                          Access Device Creation
                        </h1>
                        <p>
                        Once logged in, navigate to the device creation or management section within your monoZ dashboard.</p>
                      </div>

                      <div>
                        <h1>
                          <span className="numbers">03</span>
                          Fill in Device Information
                        </h1>
                        <p>
                        You will encounter a form for creating a new device. Here's what to do in this step:</p>
                        
                        <div className="docsDetailsWrap">
                          <div className="card p-3 mb-2 mt-2">
                            <div className="row">
                              <div className="col-md-4 col-12">
                                <h6>
                                Device Name *
                                </h6>
                              </div>
                              <div className="col-md-8 col-12">
                                <p>Enter a name for the device. This name should be a user-friendly label that helps you identify the device among others in your system. This field is marked as required, so you must provide a name for the device.</p>
                                <p>Example Content: For instance, if the device is a temperature sensor, you might name it "Temperature Sensor A" or provide a more descriptive name like "Office Thermostat."</p>
                              </div>
                            </div>
                          </div>

                          <div className="card p-3 mb-2 mt-2">
                            <div className="row">
                              <div className="col-md-4 col-12">
                                <h6>
                                ICCID *
                                </h6>
                              </div>
                              <div className="col-md-8 col-12">
                                <p>ICCID stands for "Integrated Circuit Card Identifier." It's a unique identifier associated with the SIM card used in the device. This is an essential detail for mobile and IoT devices that require cellular connectivity. This field is marked as required, so you need to input the ICCID.</p>
                                <p>Example Content: You should enter the actual ICCID of the SIM card installed in the device. For example, "8912345678901234567."</p>
                              </div>
                            </div>
                          </div>

                          <div className="card p-3 mb-2 mt-2">
                            <div className="row">
                              <div className="col-md-4 col-12">
                                <h6>
                                Group *
                                </h6>
                              </div>
                              <div className="col-md-8 col-12">
                                <p>In this dropdown menu, you should select the group to which you want to assign the device. Groups are used to categorize and organize devices based on common characteristics or purposes. This field is marked as required because you must choose an existing group or create a new one.</p>
                                <p>Example Content: You can select an existing group, such as "Production Devices" or "Office Sensors," or create a new group based on your project's needs.</p>
                              </div>
                            </div>
                          </div>
                          <div className="card p-3 mb-2 mt-2">
                            <div className="row">
                              <div className="col-md-4 col-12">
                                <h6>
                                Tags (Max 5 Tags)
                                </h6>
                              </div>
                              <div className="col-md-8 col-12">
                                <p>Tags are labels or keywords that you can associate with the device for better organization and filtering. You can add up to five tags. These tags help you classify and filter devices based on specific attributes or characteristics.</p>
                                <p>Example Content: You can add tags like "Temperature Sensor," "Location: Office," "IoT," "Active," and "Sensor Type: XYZ" to describe the device.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      
                      </div>

                      <div>
                        <h1>
                          <span className="numbers">04</span>
                          Add or Remove Devices
                        </h1>
                        <p>
                        Depending on your requirements, you can add new devices to the group or remove existing ones. This helps in keeping the group's composition up-to-date.</p>
                      </div>

                      <div>
                        <h1>
                          <span className="numbers">05</span>
                          Save Changes
                        </h1>
                        <p>
                        After updating the group details, be sure to save the changes to apply the modifications</p>
                      </div>
                    </div>
                  </div>
                </div>
                
                <h2 className="mt-5 mb-2">Update Device:</h2>
                
                <div>
                  <p>
                    To keep your monoZ devices up-to-date and aligned with
                    evolving requirements, you may need to modify existing
                    device information. This section provides guidance on
                    updating an existing device, whether it involves tweaking
                    parameters, changing device settings, or adapting to new
                    scenarios.
                  </p>

                  <div>
                    <h4>
                      Steps to Update a Device:
                    </h4>
                    
                    <div className="progress-steps">
                      <div>
                        <h1>
                          <span className="numbers">01</span>
                          Access the Device Dashboard
                        </h1>
                        <p>Start by logging in to your monoZ account. Once logged in, navigate to the Device Management section. This is where you'll find all your devices listed, ready for updates.</p>
                      </div>

                      <div>
                        <h1>
                          <span className="numbers">02</span>
                          Select the Device to Update
                        </h1>
                        <p>
                        Locate the specific device you wish to update from the list of existing devices. Click on the device's name to access its details. This is where you can view and edit the device's information.</p>
                      </div>

                      <div>
                        <h1>
                          <span className="numbers">03</span>
                          Edit Device Details
                        </h1>
                        <p>
                        In this step, you can modify various device details to better suit your current needs. Here's what you can update:</p>
                        
                        <div className="docsDetailsWrap">
                          <div className="card p-3 mb-2 mt-2">
                            <div className="row">
                              <div className="col-md-4 col-12">
                                <h6>
                                Device Name *
                                </h6>
                              </div>
                              <div className="col-md-8 col-12">
                                <p>Change the device's name to make it more descriptive or in line with the device's current purpose. This name serves as an identifier for your device.</p>
                              </div>
                            </div>
                          </div>

                          <div className="card p-3 mb-2 mt-2">
                            <div className="row">
                              <div className="col-md-4 col-12">
                                <h6>
                                ICCID (Integrated Circuit Card Identifier)
                                </h6>
                              </div>
                              <div className="col-md-8 col-12">
                                <p>Update the ICCID associated with the device. The ICCID is vital for devices that require cellular connectivity.</p>
                              </div>
                            </div>
                          </div>

                          <div className="card p-3 mb-2 mt-2">
                            <div className="row">
                              <div className="col-md-4 col-12">
                                <h6>
                                Group *
                                </h6>
                              </div>
                              <div className="col-md-8 col-12">
                                <p>Reassign the device to a different group. Groups are used to categorize devices based on common attributes or use cases.</p>
                              </div>
                            </div>
                          </div>
                          <div className="card p-3 mb-2 mt-2">
                            <div className="row">
                              <div className="col-md-4 col-12">
                                <h6>
                                Tags (Max 5 Tags)
                                </h6>
                              </div>
                              <div className="col-md-8 col-12">
                                <p>You can add, modify, or remove tags to enhance how your devices are organized. Tags are like keywords that help classify devices.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      
                      </div>

                      <div>
                        <h1>
                          <span className="numbers">04</span>
                          Save Changes
                        </h1>
                        <p>
                        After updating the device's details, it's crucial to save the changes. This ensures that the new information is applied to the device.</p>
                      </div>

                      <div>
                        <h1>
                          <span className="numbers">05</span>
                          Test the Updated Device
                        </h1>
                        <p>
                        Before deploying the updated device in a production environment, it's a best practice to test it. Ensure that the device functions as intended and that the changes don't introduce any issues.</p>
                      </div>
                      <div>
                        <h1>
                          <span className="numbers">06</span>
                          Apply the Updated Device Information
                        </h1>
                        <p>
                        Once you're satisfied with the updated device information, apply the changes to your monoZ project. This step ensures that the new settings take effect, contributing to a seamless and optimized device management experience.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default observer(SingleView);
