import "./AddBulk.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import RoutesConstants from "../../../shared-components/Routes/routes.constants";
import AddBulkForm from "./forms/AddBulkForm";
import { useStore } from "../../../contexts/StoreProvider";
import IAddDeviceBulk from "../../../models/Form/IAddDeviceInBulk";

const AddBulk = (props: any) => {
  const { deviceStore, groupStore } = useStore();
  const { GetAllGroupService, allAvailableGroups } = groupStore;
  const { addDeviceInBulk,addBulkDeviceState } = deviceStore;
  const onSubmitForm = (values: IAddDeviceBulk) => {
    addDeviceInBulk(values);
  };

  const initialValue = {
    GroupId: 0,
    Name:"",
    StartICCID:"",
    EndICCID:"",
    IsEnable: true,
    api_type:1,
    AddDevices: [{
      Name:"",
      Iccid:0
    }],
  };

  useEffect(() => {
    GetAllGroupService();
  }, []);

  /* Returning JSX */
  return (
    <React.Fragment>
      <div className="icon-nav navbar-fixed-top device-nav-bar deviceTop">
        <div id="moveContent">
          <div className="container-fluid">
            <div className="filter-left-area pl-5">
              <ul className="d-flex align-items-center mt-1">
                <li className="">
                  <a className="btn btn-light btn-sm px-1" onClick={() => props.history.push(RoutesConstants.Device)}>
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="css-i6dzq1"
                    >
                      <line x1="19" y1="12" x2="5" y2="12"></line>
                      <polyline points="12 19 5 12 12 5"></polyline>
                    </svg>
                  </a>
                </li>
                <li className="ml-2">
                  <p className="mb-0 h5">
                    <FormattedMessage id="add_bulk_devices" />
                  </p>
                </li>
              </ul>
            </div>
            <div className="add-list p-0">
              <ul></ul>
            </div>
          </div>
        </div>
      </div>
      <AddBulkForm
        submitHandler={onSubmitForm}
        groupList={allAvailableGroups}
        isAdding={addBulkDeviceState.inProgress}
        initialValues={initialValue}
      />
    </React.Fragment>
  );
};

export default observer(AddBulk);
