/*    
<summary>
   This file is used to provide initial values or state to the AddGroupForm.
   Developer:Mohammad Saquib Khan, Created Date: 24-April-2023
</summary>
*/
import IAddGroup from "../../models/Form/IAddGroup";

export const initialState: IAddGroup = {
    Name: "",
    Description: "",
    IsDefault: false,
    IsLocked: false,
    ConfigurationProtocolId: 0,
}