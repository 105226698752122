/*    
<summary>
  This functional component "UserProfilePopupHOC" provides props to the child component.
  1. State "showUserProfilePopup" that manages the show/hide of pop up
  2. Function "UserProfileToggleHandler" to toggle the show/hide popup state "showUserProfilePopup"
  Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";

const UserProfilePopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showUserProfilePopup, setShowUserProfilePopup] =
      useState<boolean>(false);

    const UserProfileToggleHandler = () => {
      setShowUserProfilePopup(!showUserProfilePopup);
    };

    return (
      <Component
        showUserProfilePopup={showUserProfilePopup}
        UserProfileToggleHandler={UserProfileToggleHandler}
        {...props}
      />
    );
  };

export default UserProfilePopupHOC;
