/*    
<summary>
  This functional component "ChangePassword" defines the props like initial values and functions for the child form 
  component.
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/

import base64 from "base-64";
import { observer } from "mobx-react";
import toast from "react-hot-toast";
import React, { useEffect } from "react";
import { useStore } from "../../../contexts/StoreProvider";
import { initialState } from "../../../core/initial-state/change-password-state";
import { formatMessage } from "../../../translations/format-message";
import ChangePasswordTenantForm from "./forms/change-password-tenant-form";

interface IProps {
  id: number;
  modalClosed: () => void;
}

let ChangePasswordTenant: React.FC<IProps> = (props) => {
  const { tenantStore } = useStore();
  const {
    UpdatePasswordService,
    changePasswordState,
    resetChangePassword,
  } = tenantStore;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "changePasswordSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (changePasswordState.success) {
      toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [changePasswordState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "changePasswordError" of the useEffect changed.
   */
  useEffect(() => {
    if (changePasswordState.error) {
      toast.error(formatMessage(changePasswordState.error));
      resetChangePassword();
    }
  }, [changePasswordState.error]);

  /**
   * This function changes the user password by provides new password and user Id  to "chagePassword" store funtion that 
   * send the request to Api.
   */
  const onSubmitForm = (values: any) => {
    let submitData = {};
    submitData = {
      TenantUserId: props.id,
      NewPassword: base64.encode(values.NewPassword),
    };
    UpdatePasswordService(submitData);
  };

  /**
   * This function closes the pop up modal and reset ChangePassword observables (success, inprogress and error).
   */
  const onModalClosed = () => {
    resetChangePassword();
    props.modalClosed();
  };

  return (
    <ChangePasswordTenantForm
      id={props.id}
      initialValues={initialState}
      submitHandler={onSubmitForm}
      onModalClosed={onModalClosed}
      loading={changePasswordState.inProgress}
    />
  );
};

export default observer(ChangePasswordTenant);
