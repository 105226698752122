/*    
<summary>
   This file is used to provide initial values or state to the Device Module observables.
   Developer: Aashish Singh, Created Date: 07-April-2023
</summary>
*/
import { IGroupListVM } from "../../models/response/IGroupResponse";

export const initialState: IGroupListVM = {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage: 1,
        PageSize: 10,
    },
    Groups: []
}