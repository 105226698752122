/*    
<summary>
  This functional component "AddUpdateConfiguration" defines the props like initial values & functions for the child form component.
  Developer:Mohammad Saquib Khan, Created Date: 20 April 2023
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../contexts/StoreProvider";
import IAddConfiguration from "../../../models/Form/IAddUpdateConfiguration";
import { formatMessage } from "../../../translations/format-message";
import toast from "react-hot-toast";
import AddMQTTSConfigForm from "./forms/mqtts/add-edit-mqtts-config-form";
import AddHTTPSConfigForm from "./forms/https/add-edit-https-config-form";
import cloneDeep from "lodash.clonedeep";
import AddAWSCoreConfigForm from "./forms/aws/add-edit-aws-core-config-form";

interface IProps {
  id: number;
  actionId: number;
  modalClosed: () => void;
}

const AddConfiguration = (props: IProps) => {
  const { configurationStore, preferencesStore, authStore, tenantStore } =
    useStore();
  const {
    configDetail,
    AddConfigurationService,
    UpdateConfigurationService,
    GetConfigurationByIdService,
    resetAddUpdateConfiguration,
    resetGetConfigurationDetail,
    configurationDetailState,
    addUpdateConfigurationstate,
  } = configurationStore;

  const { successToast } = preferencesStore;
  const { getTenantId, getUserId } = authStore;
  const { resetGetTenantDetail, GetTenantService, tenantDetails, tenantState } = tenantStore;
  const [isDataPakcetsStoreInDB, setIsDataPakcetsStoreInDB] = useState<boolean>(false);

  const onSubmitHTTPConfigForm = (values: IAddConfiguration) => {
    let tempValues: IAddConfiguration = cloneDeep(values);
    tempValues.DestinationProtocol = 2;
    tempValues.Name = values.Name.trim();
    tempValues.PayloadFormat = 1;
    tempValues.MqttsParameters = null;
    tempValues.AwsIoTCoreParameters = null;
    tempValues.IsSuccessPacketsStoreInDb = isDataPakcetsStoreInDB == true ? values.IsSuccessPacketsStoreInDb : false;
    tempValues.IsFailedPacketsStoreInDb = isDataPakcetsStoreInDB == true ? values.IsFailedPacketsStoreInDb : false;
    tempValues.Description = values.Description?.trim();
    if (tempValues.HttpsParameters && values.HttpsParameters) {
      tempValues.SourceProtocol = Number(values.SourceProtocol);
      tempValues.HttpsParameters.SendType = Number(
        values.HttpsParameters.SendType
      );
      tempValues.HttpsParameters.AuthorizationType = Number(
        values.HttpsParameters.AuthorizationType
      );
      tempValues.HttpsParameters.SendApiKeyValueType =
        values.HttpsParameters?.AuthorizationType != 2
          ? -1
          : values.HttpsParameters?.SendApiKeyValueType;
    }
    if (values.HttpsParameters != null && tempValues.HttpsParameters != null) {
      tempValues.HttpsParameters.CustomHeaders =
        values.HttpsParameters != null
          ? values.HttpsParameters.CustomHeaders?.length > 0
            ? values.HttpsParameters.CustomHeaders
            : []
          : [];
      if (values.HttpsParameters.SendType == 1) {
        tempValues.HttpsParameters.IsSendICCID = false;
        tempValues.HttpsParameters.IsSendTopic = true;
      } else {
        tempValues.HttpsParameters.IsSendICCID = true;
        tempValues.HttpsParameters.IsSendTopic = false;
      }
      if (values.HttpsParameters.AuthorizationType == 0) {
        tempValues.HttpsParameters.UserId =
          tempValues.HttpsParameters.Password =
          tempValues.HttpsParameters.Key =
            null;
        tempValues.HttpsParameters.Value =
          tempValues.HttpsParameters.Token =
          tempValues.HttpsParameters.AccessKey =
            null;
        tempValues.HttpsParameters.SecretKey = null;
      } 
      else if (values.HttpsParameters.AuthorizationType == 1) {
        tempValues.HttpsParameters.Key =
          tempValues.HttpsParameters.Value =
          tempValues.HttpsParameters.Token =
            null;
        tempValues.HttpsParameters.AccessKey =
          tempValues.HttpsParameters.SecretKey = null;
        tempValues.HttpsParameters.UserId =
          values.HttpsParameters.UserId != null
            ? values.HttpsParameters.UserId.trim()
            : values.HttpsParameters.UserId;
      } 
      else if (values.HttpsParameters.AuthorizationType == 2) {
        tempValues.HttpsParameters.Token =
          tempValues.HttpsParameters.UserId =
          tempValues.HttpsParameters.Password =
          tempValues.HttpsParameters.AccessKey =
          tempValues.HttpsParameters.SecretKey =
            null;
        tempValues.HttpsParameters.Value =
          values.HttpsParameters.Value != null
            ? values.HttpsParameters.Value.trim()
            : values.HttpsParameters.Value;
        tempValues.HttpsParameters.SendApiKeyValueType = Number(values.HttpsParameters.SendApiKeyValueType);
      } 
      else if (values.HttpsParameters.AuthorizationType == 3) {
        tempValues.HttpsParameters.Key =
          tempValues.HttpsParameters.Value =
          tempValues.HttpsParameters.UserId =
          tempValues.HttpsParameters.Password =
          tempValues.HttpsParameters.AccessKey =
          tempValues.HttpsParameters.SecretKey =
            null;
      } 
      else if (values.HttpsParameters.AuthorizationType == 4) {
        tempValues.HttpsParameters.Token =
          tempValues.HttpsParameters.Key =
          tempValues.HttpsParameters.Value =
          tempValues.HttpsParameters.UserId =
          tempValues.HttpsParameters.Password =
            null;
      }
      delete tempValues.KeyCustom;
    delete tempValues.ValueCustom;
    }

    if (props.id < 0) {
      AddConfigurationService(tempValues);
    } else UpdateConfigurationService(props.id, tempValues);
  };

  const onSubmitMQTTSConfigForm = (values: any) => {
    let tempValues: IAddConfiguration = cloneDeep(values);
    tempValues.DestinationProtocol = 3;
    tempValues.Name = values.Name.trim();
    tempValues.PayloadFormat = 1;
    tempValues.HttpsParameters = null;
    tempValues.AwsIoTCoreParameters = null;
    tempValues.IsSuccessPacketsStoreInDb = isDataPakcetsStoreInDB == true ? values.IsSuccessPacketsStoreInDb : false;
    tempValues.IsFailedPacketsStoreInDb =  isDataPakcetsStoreInDB == true ? values.IsFailedPacketsStoreInDb : false;
    if (tempValues.MqttsParameters && values.MqttsParameters) {
      tempValues.SourceProtocol = Number(values.SourceProtocol);
      tempValues.MqttsParameters.Topic = values.MqttsParameters.TopicType == "0" ? values.TopicPattern : values.MqttsParameters.CustomTopic;
      tempValues.MqttsParameters.ClientCertificate = values.MqttsParameters.ClientCertificate;
      tempValues.MqttsParameters.ClientId = values.MqttsParameters.ClientId;
      tempValues.MqttsParameters.ClientKey = values.MqttsParameters.ClientKey;
      tempValues.MqttsParameters.Host = values.MqttsParameters.Host;
      tempValues.MqttsParameters.ServerCertificate = values.MqttsParameters.ServerCertificate;
      tempValues.MqttsParameters.UseCertificate = values.MqttsParameters.UseCertificate;
      tempValues.MqttsParameters.UserId = values.MqttsParameters.UserId;
      tempValues.MqttsParameters.Password = values.MqttsParameters.Password;
      tempValues.MqttsParameters.Qos = Number(values.MqttsParameters.Qos);
    }
    if (props.id < 0) {
      AddConfigurationService(tempValues);
    } else UpdateConfigurationService(props.id, tempValues);
  }

  const onSubmitAWSCoreConfigForm = (values: any) => {
    let tempValues: any = cloneDeep(values);
    tempValues.DestinationProtocol = 4;
    tempValues.Name = values.Name.trim();
    tempValues.PayloadFormat = 1;
    tempValues.HttpsParameters = null;
    tempValues.MqttsParameters = null;
    tempValues.IsSuccessPacketsStoreInDb = isDataPakcetsStoreInDB == true ? values.IsSuccessPacketsStoreInDb : false;
    tempValues.IsFailedPacketsStoreInDb = isDataPakcetsStoreInDB == true ? values.IsFailedPacketsStoreInDb : false;
    if (tempValues.AwsIoTCoreParameters && values.AwsIoTCoreParameters) {
      tempValues.SourceProtocol = Number(values.SourceProtocol);
      tempValues.AwsIoTCoreParameters.Topic = values.AwsIoTCoreParameters.TopicType == "0" ? values.TopicPattern : values.AwsIoTCoreParameters.CustomTopic;
      // tempValues.AwsIoTCoreParameters.ClientCertificate = values.AwsIoTCoreParameters.ClientCertificate;
      // tempValues.AwsIoTCoreParameters.ClientId = values.AwsIoTCoreParameters.ClientId;
      // tempValues.AwsIoTCoreParameters.ClientKey = values.AwsIoTCoreParameters.ClientKey;
      tempValues.AwsIoTCoreParameters.Host = values.AwsIoTCoreParameters.Host;
      tempValues.AwsIoTCoreParameters.ServerCertificate = values.AwsIoTCoreParameters.ServerCertificateData;
      // tempValues.AwsIoTCoreParameters.UseCertificate = values.AwsIoTCoreParameters.UseCertificate;
      tempValues.AwsIoTCoreParameters.UserId = values.AwsIoTCoreParameters.UserId;
      tempValues.AwsIoTCoreParameters.Password = values.AwsIoTCoreParameters.Password;
      tempValues.AwsIoTCoreParameters.Qos = Number(values.AwsIoTCoreParameters.Qos);
      delete tempValues.AwsIoTCoreParameters.ServerCertificateData;
    }
    if (props.id < 0) {
      AddConfigurationService(tempValues);
    } else UpdateConfigurationService(props.id, tempValues);
  }

  useEffect(() => {
    if(tenantDetails.UserId > 0){
      setIsDataPakcetsStoreInDB(tenantDetails?.IsDataPakcetsStoreInDB);
    }
  }, [tenantDetails]);

  /**
   * This function closes the pop up modal and reset add update configuration protocol, get configuration detail observables (success, inprogress and error).
   */
  const onModalClosed = () => {
    resetAddUpdateConfiguration();
    resetGetConfigurationDetail();
    resetGetTenantDetail();
    setIsDataPakcetsStoreInDB(false);
    props.modalClosed();
  };
  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    if (props.id > 0){ GetConfigurationByIdService(props.id);}
    GetTenantService(getTenantId, getUserId);
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateConfigurationstate.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateConfigurationstate.success) {
      if (props.id < 0) {
        toast.success(formatMessage("added_success"), {
          style: successToast,
        });
      } else {
        toast.success(formatMessage("updated_success"), {
          style: successToast,
        });
      }
      onModalClosed();
    }
  }, [addUpdateConfigurationstate.success]);

  return (
    <React.Fragment>
      <Modal
        className="correction-modal fancy-modal"
        show={true}
        centered={false}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            {props.id < 0 ? (
              <FormattedMessage id="add_configuration" />
            ) : (
              <FormattedMessage id="update_configuration" />
            )}
          </Modal.Title>
          <button onClick={onModalClosed} className="Crossicons">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <div className="p-0">
          {props.actionId == 2 ? (
            <AddHTTPSConfigForm
              Id={props.id}
              initialValues={configDetail}
              IsDataPakcetsStoreInDB={isDataPakcetsStoreInDB}
              isLoading={
                props.id < 0
                  ? addUpdateConfigurationstate.inProgress
                  : (configurationDetailState.inProgress || tenantState.inProgress)
              }
              isDataLoading={addUpdateConfigurationstate.inProgress}
              submitHandler={onSubmitHTTPConfigForm}
              modalClosed={onModalClosed}
            />
          ) : props.actionId == 4 ? (
            <AddAWSCoreConfigForm
              Id={props.id}
              IsDataPakcetsStoreInDB={isDataPakcetsStoreInDB}
              isLoading={
                props.id < 0
                  ? addUpdateConfigurationstate.inProgress
                  : (configurationDetailState.inProgress || tenantState.inProgress)
              }
              initialValues={configDetail}
              submitHandler={onSubmitAWSCoreConfigForm}
              modalClosed={onModalClosed}
            />
          ) : props.actionId == 3 ? (
            <AddMQTTSConfigForm
              Id={props.id}
              IsDataPakcetsStoreInDB={isDataPakcetsStoreInDB}
              isLoading={
                props.id < 0
                  ? addUpdateConfigurationstate.inProgress
                  : (configurationDetailState.inProgress || tenantState.inProgress)
              }
              initialValues={configDetail}
              submitHandler={onSubmitMQTTSConfigForm}
              modalClosed={onModalClosed}
            />  
          ):""}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default observer(AddConfiguration);
