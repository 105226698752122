/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined constant.
   This constant is used in displaying error messages according to the error code recieved from the backend API.
      Developer: Mohammad Saquib Khan, Created Date: 29 March 2023
</summary>
*/
export const Group = [
   { id: -1, value: "please_select" },
   { id: 1, value: "Group 1" },
   { id: 2, value: "Group 2" },
   { id: 3, value: "Group 3" },
   { id: 4, value: "Group 4" },
   { id: 5, value: "Group 5" },
]

export const Protocol = [
   { id: -1, value: "please_select" },
   { id: 0, value: "HTTP" },
   { id: 1, value: "HTTPS" },
   { id: 2, value: "MQTTS" },
]

export const PayloadFormat = [
   { id: 0, value: "Text" },
   { id: 1, value: "JSON" },
]

export const SourceProtocol = [
   { id: 0, disabled:true, value: "HTTP  (Currently Unsupported)" },
   { id: 1, disabled:false ,value: "MQTT" },
]

export const Configs = [
   { id: -1, value: "please_select" },
   { id: 0, value: "MonoZ Link Basic Configuration" },
   { id: 1, value: "Monoz Default Config 1" },
   { id: 2, value: "Monoz Default Config 2" },
]

export const Groups = [
   { id: -1, value: "please_select" },
   { id: 0, value: "MonoZ Link Basic Group" },
]

export const Authorization = [
   {id: 0, disabled:false, value: "No Authorization"},
   {id: 1, disabled:false, value: "Basic Authorization (Default)"},
   {id: 2, disabled:false, value: "API Key"},
   {id: 3, disabled:false, value: "Bearer Token"},
   {id: 4, disabled:true, value: "AWS Signature"},
]

export const QOS = [
   {id: 0, disabled:false, value: "0"},
   {id: 1, disabled:false, value: "1"},
]



