/*    
<summary>
  This functional component shows the auditLog details.
  Developer:Aashish Singh, Created Date: 09-Oct-2023
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/StoreProvider";
import moduleEnum from "../../constants/module-enum";
import userActionEnum from "../../constants/user-action-eum";
import { emptyValue, infiniteDate } from "../../constants/common-constants";
import moment from "moment";
import config from "../../helpers/config-helper";
import { formatMessage } from "../../translations/format-message";

const appConfig = config();
const dateTimeFormat =  appConfig.REACT_APP_DATE_TIME_FORMAT;

interface IProps {
  selectedData: any;
}
const AuditLogDetails = (props: IProps) => {
  const { auditLogStore } = useStore();
  const { auditLogDetail, GetAuditLogDetailsService } = auditLogStore;

  /**
   * This function calls the "getBillingByIdService" store funtion that get Billing data from API
   */
  const callGetAuditLogDetailsById = () => {
    GetAuditLogDetailsService(props.selectedData.Id);
  };

  useEffect(() => {
    if(props.selectedData.Id > 0)
    callGetAuditLogDetailsById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedData.Id]);

  return (
    <React.Fragment>
      <div className="p-3 device_detailsWrapper">
          {
            auditLogDetail && 
            <div className="codeBlock">
              <div className="d-flex">
                <p className="date_code text-center"><small>{(auditLogDetail.ActionTimestamp && auditLogDetail.ActionTimestamp !== infiniteDate) ? 
                      (moment(auditLogDetail.ActionTimestamp).format(dateTimeFormat)) : emptyValue}</small></p>
                
                <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g id="_01" data-name="01"><path d="m12 2a10 10 0 1 0 10 10 10 10 0 0 0 -10-10zm-3.47 11.47a.75.75 0 1 1 -1.06 1.06l-2-2a.749.749 0 0 1 0-1.06l2-2a.75.75 0 1 1 1.06 1.06l-1.469 1.47zm5.159-3.507-2 4.666a.75.75 0 0 1 -1.378-.592l2-4.666a.75.75 0 0 1 1.378.592zm4.841 2.567-2 2a.75.75 0 1 1 -1.06-1.06l1.469-1.47-1.469-1.47a.75.75 0 1 1 1.06-1.06l2 2a.749.749 0 0 1 0 1.06z"/></g></svg>

                <div className="detailBlock">
                  <span className="text-dark">{ auditLogDetail.Module}{" Module"}</span>
                  {" is used by "}
                  <a href="javascript:;" title={auditLogDetail.UserEmail && (auditLogDetail.UserEmail)}>{auditLogDetail.UserName}</a> {" "}
                  {" to perform "}
                  <strong className="text-dark">{formatMessage(auditLogDetail.Action)}</strong>{" operation "}
                  {
                    auditLogDetail.ActionPerformedOn &&
                    <div className="codeline">
                      <span className="action_on">On</span>
                        <span className="text-dark">
                          {auditLogDetail.ActionPerformedOn}
                        </span>
                    </div>
                  }
                  {
                    auditLogDetail.RemarksType &&
                    <div className="codeline">
                      <span className="action_on bg-danger">
                        <svg viewBox="0 0 24 24" fill="#ffffff" xmlns="http://www.w3.org/2000/svg"><path d="m15 17.75a6.75 6.75 0 1 1 6.75-6.75 6.758 6.758 0 0 1 -6.75 6.75zm0-12a5.25 5.25 0 1 0 5.25 5.25 5.256 5.256 0 0 0 -5.25-5.25z"/><path d="m22 19.75a.751.751 0 0 1 -.565-.256l-3.05-3.494a.75.75 0 0 1 1.13-.987l3.05 3.49a.75.75 0 0 1 -.565 1.247z"/><path d="m8 6.75h-6a.75.75 0 0 1 0-1.5h6a.75.75 0 0 1 0 1.5z"/><path d="m6 10.75h-4a.75.75 0 0 1 0-1.5h4a.75.75 0 0 1 0 1.5z"/><path d="m7 14.75h-5a.75.75 0 0 1 0-1.5h5a.75.75 0 0 1 0 1.5z"/></svg>
                      </span>
                      <div className="codecol">
                        <label className="text-muted bold">
                          <FormattedMessage id={"detail"} />:{" "}
                        </label>
                      </div>
                      <div className="codecol">
                        <p className="text-dark">
                          {auditLogDetail.RemarksType}
                        </p>
                      </div>
                    </div>
                  } 
                  {
                    auditLogDetail.Remarks &&
                    <div className="codeline">
                      <span className="action_on bg-warning">
                        <svg viewBox="0 0 24 24" fill="#ffffff" xmlns="http://www.w3.org/2000/svg"><path d="m15 17.75a6.75 6.75 0 1 1 6.75-6.75 6.758 6.758 0 0 1 -6.75 6.75zm0-12a5.25 5.25 0 1 0 5.25 5.25 5.256 5.256 0 0 0 -5.25-5.25z"/><path d="m22 19.75a.751.751 0 0 1 -.565-.256l-3.05-3.494a.75.75 0 0 1 1.13-.987l3.05 3.49a.75.75 0 0 1 -.565 1.247z"/><path d="m8 6.75h-6a.75.75 0 0 1 0-1.5h6a.75.75 0 0 1 0 1.5z"/><path d="m6 10.75h-4a.75.75 0 0 1 0-1.5h4a.75.75 0 0 1 0 1.5z"/><path d="m7 14.75h-5a.75.75 0 0 1 0-1.5h5a.75.75 0 0 1 0 1.5z"/></svg>
                      </span>
                      <div className="codecol">
                        <label className="text-muted bold">
                          <FormattedMessage id={"remarks"} />:{" "}
                        </label>
                      </div>
                      <div className="codecol">
                        <p className="text-dark">
                          {auditLogDetail.Remarks}
                        </p>
                      </div>
                    </div>
                  }  
                </div>
              </div>          
            </div>
          }
    </div>
    </React.Fragment>
  );
};

export default observer(AuditLogDetails);
