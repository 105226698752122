/*    
<summary>
  This functional component "AddDevicePopupHOC" provides props to the child component.
  1. State "showAddDevicePopup" that manages the show/hide of pop up
  2. Function "addDeviceToggleHandler" to toggle the show/hide popup state "showAddDevicePopup"
  Developer:Aashish Singh, Created Date:29-March-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";

const AddGroupPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showAddGroupPopup, setShowAddGroupPopup] =
      useState<boolean>(false);

    const addGroupToggleHandler = () => {
      setShowAddGroupPopup(!showAddGroupPopup);
    };

    return (
      <Component
        showAddGroupPopup={showAddGroupPopup}
        addGroupToggleHandler={addGroupToggleHandler}
        {...props}
      />
    );
  };

export default AddGroupPopupHOC;
