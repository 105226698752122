/*    
<summary>
  This functional component "FilterPopupHOC" provides props to the child component.
  1. State "showFilterPopup" that manages the show/hide of pop up
  2. Function "filterToggleHandler" to toggle the show/hide popup state "showFilterPopup"
  Developer:Aashish Singh, Created Date:21-Nov-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";

const FilterPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showFilterPopup, setShowFilterPopup] = useState<boolean>(false);

    const FilterToggleHandler = () => {
      setShowFilterPopup(!showFilterPopup);
    };

    return (
      <Component
        showFilterPopup={showFilterPopup}
        filterToggleHandler={FilterToggleHandler}
        {...props}
      />
    );
  };

export default FilterPopupHOC;
