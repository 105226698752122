/*    
<summary>
  This functional component "AddUpdateDevice" defines the props like initial values & functions for the child form component.
  Developer:Mohammad Saquib Khan, Created Date: 29 March 2023, Updated By: Aashish Singh, Last Updated Date: 10-April-2023
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../contexts/StoreProvider";
import IAddTenant from "../../../models/Form/IAddUpdateTenant";
import toast from "react-hot-toast";
import AddUpdateTenantForm from "./forms/add-edit-tenant-form";
import { formatMessage } from "../../../translations/format-message";
import { ITenantList } from "../../../models/response/ITenantResponse";
import { cloneDeep } from "lodash";

interface IProps {
  selectedRow: ITenantList;
  modalClosed: () => void;
}

const AddUpdateTenant = (props: IProps) => {
  const { tenantStore, tenantChargeStore } = useStore();
  const { AddTenantService, UpdateTenantService, resetGetTenantDetail, GetTenantService ,resetAddUpdateTenant, tenantState ,tenantDetails ,addUpdateTenantState} = tenantStore;
  const {
    allCountryCurrencyOptions,
    GetAllCountryCurrencyService,
    countryCurrencyState
  } = tenantChargeStore;
  const onSubmitForm = (values: IAddTenant) => {
    let tempValues = cloneDeep(values);
    tempValues.TenantName = values.TenantName.trim();
    tempValues.OrganizationName = values.OrganizationName.trim();
    tempValues.ContactPersonName = values.ContactPersonName.trim();
    tempValues.Address = values.Address != "" ? values.Address.trim() : values.Address.trim();
    if (props.selectedRow.Id < 0) {
      AddTenantService(tempValues); 
    } else {
      console.log(tenantDetails);
      UpdateTenantService(props.selectedRow.Id, tempValues);
    } 
  };

  /**
   * This function closes the pop up modal and reset add update tenant, get tenant detail observables (success, inprogress and error).
   */
  const onModalClosed = () => {
    resetAddUpdateTenant();
    resetGetTenantDetail();
    props.modalClosed();
  };

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    if (props.selectedRow.Id > 0) GetTenantService(props.selectedRow.Id, props.selectedRow.UserId);
    GetAllCountryCurrencyService();
  }, []);
  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateDevicestate.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateTenantState.success) {
      if (props.selectedRow.Id < 0) toast.success(formatMessage("added_success"));
      else toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [addUpdateTenantState.success]);

  return (
    <React.Fragment>
      <Modal
        className="correction-modal fancy-modal"
        size="lg"
        show={true}
        centered={false}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            {props.selectedRow.Id < 0 ? (
              <FormattedMessage id="add_tenant" />
            ) : (
              <FormattedMessage id="update_tenant" />
            )}
          </Modal.Title>
          <button onClick={onModalClosed} data-testid="Close" className="Crossicons">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <div className="modal-body">
          <AddUpdateTenantForm
            Id={props.selectedRow.Id}
            initialValues={tenantDetails}
            submitHandler={onSubmitForm}
            countryCurrencyOptions={allCountryCurrencyOptions}
            isLoading={
              (props.selectedRow.Id < 0
                ? addUpdateTenantState.inProgress
                : tenantState.inProgress) || countryCurrencyState.inProgress
            }
            modalClosed={onModalClosed}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default observer(AddUpdateTenant);
