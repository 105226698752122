/*    
<summary>
   This functional common component provides protected routing if user want to access the login route after 
   logging in than it restricts the user. 
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import LoginLayout from '../Layout/LoginLayout';

const LoginLayoutRoute: FC<any> = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      <LoginLayout>
        <Component {...matchProps} />
      </LoginLayout>
    )} />
  )
};

export default LoginLayoutRoute;  