/*    
<summary>
   This is the Dashboard Component which shows the monitoring activites of Device Flow.
   Developer: Mohammad Saquib Khan, Created Date:17-April-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { FormattedMessage } from "react-intl";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
} from "chart.js";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { useStore } from "../../contexts/StoreProvider";
import { IDeviceList } from "../../models/response/IDeviceResponse";
import { observer } from "mobx-react";
import { CategoryScale } from "chart.js";
import { formatMessage } from "../../translations/format-message";
import RoutesConstants from "../../shared-components/Routes/routes.constants";
import { emptyValue } from "../../constants/common-constants";
import moment from "moment";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import config from "../../helpers/config-helper";
import nodevicesvg from "../../assets/Images/svg/no-device-data.svg";
import resetZoomSVG from "../../assets/Images/svg/zoom-reset.svg";
import no_bill from "../../assets/Images/svg/no-bill.svg";
import tenantStore from "../../core/stores/tenant-store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import cloneDeep from "lodash.clonedeep";
import { initialState as deviceSummaryChartInitial } from "../../core/initial-state/device-summary-graph-state";
import { initialState as dataPacketsChartInitial } from "../../core/initial-state/device-packet-count-graph-state";
import { initialState as dataPublishCountChartInitial } from "../../core/initial-state/device-publish-count-graph-state";
import { initialState as dataFailedPacketCountChartInitial } from "../../core/initial-state/device-failed-packet-count-graph-state";
import { IGroupList } from "../../models/response/IGroupResponse";
import zoomPlugin from 'chartjs-plugin-zoom';

const appConfig = config();
const dateFormat = appConfig.REACT_APP_DATE_FORMAT;
const isGroupBlockUnblockActive = appConfig.IS_GROUP_BLOCK_UNBLOCK_ACTIVE;

const Dashboard = (props: any) => {
  const { deviceStore, billingStore, authStore, groupStore } =
    useStore();
  const {
    deviceCount,
    getAllDevicesCount,
    getRecentlyAddedDevices,
    getRecentDevices,
    inProgress,
    getAllMonthsTotalPacketCounts,
    getAllMonthPacketsGraphData,
    allMonthTotalPacketCountsState,
    resetAllMonthTotalPacketsCountState,
    getDeviceSummaryGraphData,
    getAllPublishMessageCount,
    getAllMonthPublishMessageGraphData,
    getAllFailedPacketCounts,
    getAllMonthFailedPacketsGraphData,
    selectedDashboardChartTypeDetails, 
    setSelectedDashboardChartTypeDetails,
    resetChartDataStates,
    failedPacketCountsState,
    publishCountState
  } = deviceStore;
  const {
    allBlockedGroupList,
    GetBlockedGroupsService,
  } = groupStore;
  const {
    GetTenantBillByTenantIdService,
    getTenantPendingDueAmount,
    reset,
    tenantBillDetailState,
  } = billingStore;
  const { GetDashboardTenantByIdService, dashboardTenantInfo } = tenantStore;
  const { getTenantId, getUserId, getIsTrialTenant } = authStore;
  const lastDevices: Array<IDeviceList> = getRecentDevices;
  const [barChartData, setBarChartData] = useState(deviceSummaryChartInitial);
  const [lineChartData, setLineChartData] = useState(dataPacketsChartInitial);
  const [publishCountLineChartData, setPublishCountLineChartData] = useState(dataPublishCountChartInitial);
  const [failedPacketCountLineChartData, setFailedPacketCountLineChartData] = useState(dataFailedPacketCountChartInitial);

  const [deviceAddedLegend, setDeviceAddedLegend] = useState<boolean>(false);
  const [deviceDeletedLegend, setDeviceDeleteLegend] = useState<boolean>(false);
  const [deviceActivatedLegend, setDeviceActivatedLegend] = useState<boolean>(false);
  const [deviceInActiveLegend, setDeviceInActiveLegend] = useState<boolean>(false);

  const deviceAdded: number[] = getDeviceSummaryGraphData?.DeviceAdded;
  const deviceDeleted: number[] = getDeviceSummaryGraphData?.DeviceDeleted;
  const deviceActivated: number[] = getDeviceSummaryGraphData?.DeviceActivated;
  const deviceInActive: number[] = getDeviceSummaryGraphData?.DeviceInActive;
  const deviceSummaryLabels: string[] = getDeviceSummaryGraphData?.Labels;
  const devicePacketCountLabels: string[] = getAllMonthPacketsGraphData?.Labels;
  const devicePacketCountData: number[] = getAllMonthPacketsGraphData?.DataPackets;

  const devicePublishCountLabels: string[] = getAllMonthPublishMessageGraphData?.Labels;
  const devicePublishCountData: number[] = getAllMonthPublishMessageGraphData?.DataPackets;

  const deviceFailedPacketCountLabels: string[] = getAllMonthFailedPacketsGraphData?.Labels;
  const deviceFailedPacketCountData: number[] = getAllMonthFailedPacketsGraphData?.DataPackets;

  useEffect(() => {
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    getAllDevicesCount(currentMonthIndex, currentYear);
    GetDashboardTenantByIdService(getTenantId, getUserId);
    GetTenantBillByTenantIdService(getTenantId);
    getChartDataDetails();
    // getAllMonthsTotalPacketCounts();
    // getAllPublishMessageCount();
    // getAllFailedPacketCounts();
    getRecentlyAddedDevices(5);
    GetBlockedGroupsService();
  }, []);

  const getChartDatafromType = () =>{
    switch(selectedDashboardChartTypeDetails.chartType){
      case "0":
        return publishCountLineChartData.data;
      case "1":
        return failedPacketCountLineChartData.data;
      case "2":
        return lineChartData.data;
    }
  }
  
  const getChartOptionsfromType = () =>{
    switch(selectedDashboardChartTypeDetails.chartType){
      case "0":
        return publishCountLineChartData.options;
      case "1":
        return failedPacketCountLineChartData.options;
      case "2":
        return lineChartData.options;
    }
  }

  const getChartDataDetails = () =>{
    switch(selectedDashboardChartTypeDetails.chartType){
      case "0":
        getAllPublishMessageCount();
        break;
      case "1":
        getAllFailedPacketCounts();
        break;
      case "2":
        getAllMonthsTotalPacketCounts();
        break;
    }
  }
  
  useEffect(() => {
    let arr:any = [];
    const barChart = cloneDeep(barChartData);
    //Object.keys(barChart.options.scales).forEach(scale => Object.assign(barChart.options.scales[scale], scaleOpts));
    // barChart.options.plugins.zoom.limits.y.max = Math.ceil(Math.max(...arr.concat(deviceAdded,deviceActivated,deviceDeleted,deviceInActive)) / 100) * 100;
    // barChart.options.plugins.zoom.limits.y.min = Math.min(...arr.concat(deviceAdded,deviceActivated,deviceDeleted,deviceInActive));
    // console.log("mil gya zooom:" +barChart.options.plugins.zoom.zoom?.onZoom());
    barChart.data.datasets[0].data = deviceAdded;
    barChart.data.datasets[1].data = deviceDeleted;
    barChart.data.datasets[2].data = deviceActivated;
    // barChart.data.datasets[3].data = deviceInActive;
    barChart.data.labels = deviceSummaryLabels;
    setBarChartData(barChart);
  }, [deviceAdded,deviceActivated,deviceDeleted,deviceInActive]);

  useEffect(() => {
    const barChart = cloneDeep(barChartData);
    barChart.data.datasets[0].data = deviceAddedLegend == true ? [] : deviceAdded;
    barChart.data.datasets[1].data = deviceDeletedLegend == true ? [] : deviceDeleted;
    barChart.data.datasets[2].data = deviceActivatedLegend == true ? [] : deviceActivated;
    // barChart.data.datasets[3].data = deviceInActiveLegend == true ? [] : deviceInActive;
    barChart.data.labels = deviceSummaryLabels;
    setBarChartData(barChart);
  }, [deviceAddedLegend,deviceActivatedLegend,deviceDeletedLegend,deviceInActiveLegend]);

  useEffect(() => {
    const lineChart = cloneDeep(lineChartData);
    lineChart.data.datasets[0].data = devicePacketCountData;
    // lineChart.data.datasets[1].data = deviceFailedPacketCountData;
    lineChart.data.labels = devicePacketCountLabels;
    setLineChartData(lineChart);
  }, [devicePacketCountData.length>0]);

  useEffect(() => {
    const lineChart = cloneDeep(publishCountLineChartData);
    lineChart.data.datasets[0].data = devicePublishCountData;
    lineChart.data.labels = devicePublishCountLabels;
    setPublishCountLineChartData(lineChart);
  }, [devicePublishCountData?.length > 0]);
  
  useEffect(() => {
    const lineChart = cloneDeep(failedPacketCountLineChartData);
    lineChart.data.datasets[0].data = deviceFailedPacketCountData;
    lineChart.data.labels = deviceFailedPacketCountLabels;
    setFailedPacketCountLineChartData(lineChart);
  }, [deviceFailedPacketCountData?.length > 0]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "selectedDashboardChartTypeDetails.isTypeChanged" of the useEffect changed.
   */
  useEffect(() => {
    if (selectedDashboardChartTypeDetails.isTypeChanged) {
      getChartDataDetails();
      setSelectedDashboardChartTypeDetails({
        ...selectedDashboardChartTypeDetails,
        isTypeChanged: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDashboardChartTypeDetails]);

  ChartJS.register(ArcElement, Tooltip, Legend, Filler, zoomPlugin);
  const data2 = () => {
    return {
      labels: [
        formatMessage("active_device"),
        formatMessage("inactive_device"),
      ],
      datasets: [
        {
          label: "Devices",
          data: [deviceCount?.ActiveDevices, deviceCount?.InActiveDevices],
          backgroundColor: ["rgba(243, 152, 16, 1)", "rgba(179, 178, 178, 1)"],
          borderColor: ["rgba(243, 152, 16, 1)", "rgba(179, 178, 178, 1)"],
          borderWidth: 3,
        },
      ],
    };
  };

  const resetChart = () => {
    const barChart = cloneDeep(deviceSummaryChartInitial);
    setDeviceActivatedLegend(false);
    setDeviceAddedLegend(false);
    setDeviceDeleteLegend(false);
    setDeviceInActiveLegend(false);
    barChart.data.datasets[0].data = deviceAddedLegend == true ? [] : deviceAdded;
    barChart.data.datasets[1].data = deviceDeletedLegend == true ? [] : deviceDeleted;
    barChart.data.datasets[2].data = deviceActivatedLegend == true ? [] : deviceActivated;
    // barChart.data.datasets[3].data = deviceInActiveLegend == true ? [] : deviceInActive;
    barChart.data.labels = deviceSummaryLabels;
    setBarChartData(barChart);
  }

  const kFormatter = (num: any): string => {
    let t =
      Math.abs(num) > 999
        ? Math.sign(num) * Math.floor(Math.abs(num) / 1000) + "k+"
        : Math.sign(num) * Math.abs(num);
    return t.toString();
  };

  const option2 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: { yLabel: any }) {
          return tooltipItem.yLabel;
        },
      },
    },
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement, Tooltip, Legend, Filler
  );

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const redirectToGroupHandler = () => {
    props.history.push(RoutesConstants.Group);
  };

  const refreshHandler = () => {
    GetTenantBillByTenantIdService(getTenantId);
  };

  useEffect(() => {
    if (tenantBillDetailState.success) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantBillDetailState.success]);

  useEffect(() => {
    if (allMonthTotalPacketCountsState.success) {
      resetAllMonthTotalPacketsCountState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMonthTotalPacketCountsState.success]);

  useEffect(() => {
    if (tenantBillDetailState.error) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantBillDetailState.error]);

  useEffect(() => {
    if (allMonthTotalPacketCountsState.error) {
      resetAllMonthTotalPacketsCountState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMonthTotalPacketCountsState.error]);

  return (
    <React.Fragment>
      <FormLoader loading={allMonthTotalPacketCountsState.inProgress} />
      <FormLoader loading={tenantBillDetailState.inProgress} />
      <FormLoader loading={inProgress} />
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div id="moveContent">
          <div className="container-fluid">
            <div className="row no-gutters align-items-center">
              <div className="col-sm-6 col-12">
                <div className="filter-left-area">
                  <ul className="d-flex align-items-center">
                    <li>
                      <p className="h5 pl-5 mb-0">
                        <FormattedMessage id="dashboard" />
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 text-right">
                {dashboardTenantInfo?.IsTrialTenant == true ||
                getIsTrialTenant === "true" ? (
                  <p className="text-danger">
                    <i className="fas fa-exclamation-triangle"></i>
                    <FormattedMessage id="trial_user_message" /> &nbsp;
                    <strong>
                      <FormattedMessage id="trial_user" /> &nbsp;
                    </strong>
                    <FormattedMessage id="created_at" /> &nbsp;
                    {moment(dashboardTenantInfo?.CreatedDate).format(
                      dateFormat
                    )}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="illustration-text dashboard_heading">
        <div className="row align-items-center no-gutters">
          <div className="col-lg-3">
            <h2 className="heading_card mb-0">
              <FormattedMessage id={"welcome_label"} />
            </h2>
            <p className="">
              <FormattedMessage id={"connect_devices"} />
            </p>
          </div>
          <div className="col-lg-9">
            <div className="row dashboard_blocks">
              <div className="col-sm-4">
                <a
                  className="card"
                  onClick={() =>
                    props.history.push(RoutesConstants.Configurations)
                  }
                >
                  <span className="number">1</span>
                  <h3 className="mb-0 font-weight-normal">
                    <FormattedMessage id={"step_1"} />
                  </h3>
                  <p>
                    <FormattedMessage id={"step_1_detail"} />
                  </p>
                </a>
              </div>
              <div className="col-sm-4">
                <a
                  className="card"
                  onClick={() => props.history.push(RoutesConstants.Group)}
                >
                  <span className="number">2</span>
                  <h3 className="mb-0 font-weight-normal">
                    <FormattedMessage id={"step_2"} />
                  </h3>
                  <p>
                    <FormattedMessage id={"step_2_detail"} />
                  </p>
                </a>
              </div>
              <div className="col-sm-4">
                <a
                  className="card"
                  onClick={() => props.history.push(RoutesConstants.Device)}
                >
                  <span className="number">3</span>
                  <h3 className="mb-0 font-weight-normal">
                    <FormattedMessage id={"step_3"} />
                  </h3>
                  <p>
                    <FormattedMessage id={"step_3_detail"} />
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      { lastDevices.length > 0 ? (
        <>
          <div className="illustration-text py-3">
          {allBlockedGroupList.length > 0 && isGroupBlockUnblockActive === true ? (
           <div className="alert alert-danger mb-4" key={allBlockedGroupList[0].Id}>
                <div className="row align-items-center">
                  
                  <div className="col-7">
                    <h4 className="mb-0"><FormattedMessage id="group_block_title" /></h4>
                    <small>
                      <FormattedMessage id="block_timestamp" />
                      {": "}{" "}
                      {allBlockedGroupList[0].BlockedTimestamp
                        ? allBlockedGroupList[0].BlockedTimestamp
                        : emptyValue}
                    </small>
                  </div>
                  <div className="col-4">
                    <p className="mb-0 blockedgroupName">
                      {/* {allBlockedGroupList[0].Name} */}
                      {/* {
                        allBlockedGroupList.length > 1 &&
                        <span className="badge badge-danger ml-2">+{allBlockedGroupList.length -1} </span>
                      } */}
                      {
                        allBlockedGroupList.map((group:IGroupList, index:number)=> {
                          return(
                            <>
                              <span>{group.Name}</span>{allBlockedGroupList.length - 1 != index? " , ": "" } 
                            </>                            
                          )

                        }) 
                      }
                    </p>
                  </div>
                  <div className="col-1 text-right">
                    <button
                        className={`btn btn-outline-danger btn-sm`}
                        title={formatMessage("groups")}
                        type="button"
                        onClick={redirectToGroupHandler}
                      >
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </button>
                  </div>
                </div>
              </div>
          ) : (
            ""
          )}
            <div className="row">
              <div className="col-lg-7">
                <div className="card">
                  <div className="card-head d-flex align-items-center p-3 pt-2 border-bottom">
                    <h3 className="m-0">
                      <FormattedMessage id={"device_title"} />
                    </h3>
                    <a
                      href={RoutesConstants.Device}
                      title={formatMessage("refresh")}
                      className={`btn btn-outline-secondary btn-sm`}
                    >
                      <FormattedMessage id="view_devices" />
                    </a>
                  </div>
                  <div className=" card-body p-0">
                    <div className="row px-3">
                      <div className="col-lg-4  bg-light ">
                        <div className="row mb-4 mt-3 pt-4 pb-4">
                          <div className="col-6 border-right">
                            <h2 className="h4 mb-0">
                              {kFormatter(deviceCount?.ActiveDevices)}
                            </h2>
                            <p className="text-uppercase xsmall text-muted">
                              <FormattedMessage id={"active_device"} />
                            </p>
                          </div>
                          <div className="col-6">
                            <h2 className="h4 mb-0">
                              {kFormatter(deviceCount?.InActiveDevices)}
                            </h2>
                            <p className="text-uppercase xsmall text-muted">
                              <FormattedMessage id={"inactive_device"} />
                            </p>
                          </div>
                        </div>
                        {deviceCount?.TotalDevices == 0 ? (
                          <React.Fragment>
                            <div className="mt-3 position-relative">
                              <div className="no_devices_pie">
                                <img
                                  src={nodevicesvg}
                                  alt=""
                                  className="nosvgs"
                                />

                                <p>
                                  <FormattedMessage id="dashboard_no_device" />
                                </p>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          <div className="mt-3">
                            <div className="doughnut_wrap">
                              <Doughnut data={data2()} options={option2} />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-8"> 
                        <div className="px-3 align-items-center pt-4 d-flex justify-content-between">
                          <p className={deviceAddedLegend == true ? "crossline" : ""} onClick={()=>{ setDeviceAddedLegend(!deviceAddedLegend) }}><i className={deviceAddedLegend == true ? "far fa-circle" : "far fa-check-circle"} style={{color: "#00d1ba", cursor:"pointer"}}></i><FormattedMessage id="devices_added" /></p>
                          <p className={deviceDeletedLegend == true ? "crossline" : ""} onClick={()=>{ setDeviceDeleteLegend(!deviceDeletedLegend) }}><i className={deviceDeletedLegend == true ? "far fa-circle" : "far fa-check-circle"} style={{color: "#ff6384", cursor:"pointer"}}></i> <FormattedMessage id="device_deleted" /></p>
                          <p className={deviceActivatedLegend == true ? "crossline" : ""} onClick={()=>{ setDeviceActivatedLegend(!deviceActivatedLegend) }}><i className={deviceActivatedLegend == true ? "far fa-circle" : "far fa-check-circle"} style={{color: "#ff8700", cursor:"pointer"}}></i> <FormattedMessage id="devices_activated" /></p>
                          {/* <p className={deviceInActiveLegend == true ? "crossline" : ""} onClick={()=>{ setDeviceInActiveLegend(!deviceInActiveLegend) }}><i className={deviceInActiveLegend == true ? "far fa-circle" : "far fa-check-circle"} style={{color: "#c3c2ba", cursor:"pointer"}}></i> <FormattedMessage id="in_active_devices" /></p> */}
                          <button type="button" className="btn btn-outline-secondary btn-sm" title="Reset Zoom"  onClick={()=> {resetChart()}}>
                            <img src={resetZoomSVG} alt="" className="resetzoomsvgs"/>
                            {/* <span className="resettext">Reset Zoom</span> */}
                          </button>
                        </div>
                        <div className="barchartwrapper">
                          <Bar
                            options={barChartData.options}
                            data={barChartData.data}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-5">
                {getTenantPendingDueAmount?.Currency != null &&
                getTenantPendingDueAmount?.Tenant != null ? (
                  <>
                    <div className="card mb-3">
                      <div className="card-head d-flex justify-content-between align-items-center px-3 py-2 border-bottom">
                        <h3 className="m-0">
                        <FormattedMessage id={"current_billing"} />{Number(getTenantPendingDueAmount?.TotalOutstandingAmount) > 0 ? <span className="badge badge-danger mx-2"><FormattedMessage id="pending" /></span>  :  ""  }
                          <br />
                          <small className="text-muted">
                            <FormattedMessage id={"last_updated"} />:
                            {" " +
                              moment(
                                getTenantPendingDueAmount?.LastBillGenerationDate
                              ).format(dateFormat)}
                          </small>
                        </h3>
                        <a
                          onClick={() => {
                            refreshHandler();
                          }}
                          title={formatMessage("refresh")}
                          className={`btn btn-outline-secondary btn-sm`}
                        >
                          <i className="fa fa-sync-alt"></i>
                        </a>
                      </div>
                      <div className="card-body py-2">
                        <h2 className="fs50 m-0">
                        {getTenantPendingDueAmount?.BillStateType ==
                        "Pending" ? (
                          <span>{getTenantPendingDueAmount?.BillStateType ==
                            "Pending" &&
                            getTenantPendingDueAmount?.TotalOutstandingAmount}</span>
                        ) : (
                          "0.00"
                        )}
                          <small className="text-muted ml-1">
                            {getTenantPendingDueAmount?.Currency?.Symbol} (
                            {
                              getTenantPendingDueAmount?.Currency
                                ?.Abbrevation
                            }
                            )
                          </small>
                        </h2>

                        {/* <p className="">
                          <small className="text-muted">
                            <FormattedMessage id={"billing_footer"} />
                          </small>
                        </p> */}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="card mb-3">
                    <div className="card-body pt-2">
                      <div className="cardNomessage text-center">
                        <img src={no_bill} alt="" className="no_message_card" />
                        <p className="noData text-muted text-center h5 mb-0">
                          <FormattedMessage id="no_bill" />
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="card mb-3">
                  <div className="card-head d-block px-3 py-1 border-bottom">
                    <div className="row align-items-center">
                      <div className="col-8">
                        <h3 className="m-0">
                          <FormattedMessage id={"data_packets_dashboard"} />
                        </h3>
                      </div>
                      <div className="col-4">
                        <select
                          className="form-control border-dark col-md-12"
                          name="range"
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            resetChartDataStates();
                            setSelectedDashboardChartTypeDetails({chartType: e.target.value, isTypeChanged: true});
                          }}
                          defaultValue={selectedDashboardChartTypeDetails.chartType}
                        >
                          <option value="0">{formatMessage("dl_message_count")}</option>
                          <option value="1">{formatMessage("total_failed_packet")}</option>
                          <option value="2">{formatMessage("total_packets")}</option>
                        </select>
                      </div>
                    </div>
                      
                      
                  </div>
                  <div className="card-body">
                    <div className="chartwrapper">
                      <Line data={getChartDatafromType()} options={getChartOptionsfromType()} />
                    </div>
                  </div>
                </div>
                {/* <div className="card mb-3">
                  <div className="card-head d-flex align-items-center p-3 pt-2 border-bottom">
                    <h3 className="m-0">
                      <FormattedMessage id="dl_message_count" />
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="chartwrapper">
                      <Line data={publishCountLineChartData.data} options={publishCountLineChartData.options} />
                    </div>
                  </div>
                </div> */}
                
              </div>
            </div>
          </div>
          {/* <div className="illustration-text py-3">
          <div className="row">
            <div className="col-lg-12">
              <div className="card mb-3">
                <div className="card-head d-flex align-items-center p-3 pt-2 border-bottom">
                  <h3 className="m-0">
                    <FormattedMessage id="data_packets_dashboard" />
                  </h3>
                </div>
                <div className="row no-gutter">
                  <div className="col-lg-7 col-12 bg-light p-4">
                  <div className="d-flex justify-content-between align-items-center">
                        <p className="h5 m-0"><FormattedMessage id="total_failed_packet" /></p>
                      </div>
                      <hr className="my-4"/>
                    <div className="row gutters-2">

                      {deviceFailedPacketCountLabels?.map((label,index)=>{
                        return (
                          <div className="col-xxl-2 col-lg-2 col-md-2 col-sm-3 col-4 mb-3">
                            <div className="card text-center">
                              <div className="p-1 card-header m-0">
                                <p className="m-0 text-uppercase small">{label}</p>
                              </div>
                              <div className="p-2">
                                <h2 className="mb-0">{deviceFailedPacketCountData[index]}</h2>
                              </div>
                              
                            </div>
                          </div>
                        )
                      })}

                    </div>
                  </div>
                  <div className="col-lg-5 col-12 p-4">
                  <div className="d-flex justify-content-between align-items-center">
                        <p className="h5 m-0"><FormattedMessage id="total_packets" /></p>
                      </div>
                      <hr className="my-4"/>
                    <div className="row gutters-2">
                    <div className="chartwrapper">
                      <Line data={getChartDatafromType()} options={getChartOptionsfromType()} />
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div> */}
        </>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default observer(Dashboard);
