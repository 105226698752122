/*    
<summary>
   This functional component provides us a button with spinner that shows the loading.
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary> 
<returns>Returns JSX</returns>
*/
import React from 'react';
import Loader from '../Loader/Loader';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { formatMessage } from '../../translations/format-message';

const LoaderButton = (props: any) => {
  return (
    <React.Fragment>
      <Button
        data-testid={props.id ? props.id : undefined}
        onClick={props.onClick}
        disabled={props.disabled || props.isLoading}
        variant={props.variant}
        type={props.type}
        title={props.title? formatMessage(props.title) : undefined}
        className={`btn btn-blue ${props.className &&  props.className}`}>
        <FormattedMessage id={props.text} />
        {props.isLoading ? <Loader /> : null}
      </Button>
    </React.Fragment>
  );
}

export default LoaderButton


