/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined constant.
   This constant is used in unit data modules to provide default unit data.
   Developer: Aashish Singh, Created Date:19-Oct-2023
</summary>
*/

import moment from "moment";
import { MonthYearTuple } from "../models/response/IDeviceResponse";

export const emptyValue='NA';
export const defaultCountryCurrency = {id:2, symbol:"¥", placeholder:"Japanese Yen (¥)"};
export const infiniteDate = "0001/01/01 00:00:00"

export const getMonthName = (monthNumber:number) => {
  var mL = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
 
   return mL[monthNumber-1]
 }

  export const getShortMonthName = (monthNumber:number) => {
    var mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    return mS[monthNumber-1];
}

  export const getPastMonths = (currentMonth: number, currentYear: number, numberOfMonths: number): MonthYearTuple[] => {
    const pastMonths: MonthYearTuple[] = [];
    for (let i = 1; i <= numberOfMonths; i++) {
      const pastMonth = (currentMonth - i + 12) % 12 || 12;
      const pastYear = currentMonth - i <= 0 ? currentYear - 1 : currentYear;      
      pastMonths.push([pastYear, pastMonth]);
    }
    return pastMonths.reverse();
  };

  export const getPastMonthsIncCurrent = (currentMonth: number, currentYear: number, numberOfMonths: number): MonthYearTuple[] => {
    const pastMonths: MonthYearTuple[] = [];
    for (let i = 1; i < numberOfMonths; i++) {
      const pastMonth = (currentMonth - i + 12) % 12 || 12;
      const pastYear = currentMonth - i <= 0 ? currentYear - 1 : currentYear; 
      if(i == 1) { pastMonths.push([currentYear,currentMonth]) }     
      pastMonths.push([pastYear, pastMonth]);
    }
    return pastMonths.reverse();
};



  export const getDaysInMonth =(month: number, year: number): number => {
    // Month is 0-indexed in JavaScript Date object (0 for January, 1 for February, etc.)
    const date = new Date(year, month, 0);
    return date.getDate();
}
  export const dailyPacketCountGraphDefaultLabel = () => {
    let label = ["Day1","Day2","Day3","Day4","Day5","Day6","Day7","Day8","Day9","Day10","Day11","Day12","Day13","Day14","Day15","Day16","Day17","Day18","Day19","Day20","Day21","Day22","Day23","Day24","Day25","Day26","Day27","Day28","Day29","Day30","Day31"]
    return label;
  }

  /**
   * This function is used to get all the dates from month start to the current date of current month. 
   */
  export const getPastDays = () => {
    const currentMonthDays = [];
    const currentDate = new Date().getDate();
    for (let i = currentDate-1; i >= 0; i--) {
      const pastDate = moment(new Date()).subtract(i,'day').format("YYYY-MM-DD")    
      currentMonthDays.push(pastDate);
    }
    return currentMonthDays;
  };

 export const getFormattedDateWithName = (monthNumber:number, yearNumber:number) => {
   const date = new Date();
   date.setMonth(monthNumber - 1);
   date.setFullYear(yearNumber);
 
   return moment(date).format("MMM,YYYY");
 }

 export const defaultCountryCurrencyId = () => {
  return 2;
 }

 export const base64ToArrayBuffer = (base64:any) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer;
}