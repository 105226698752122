/*    
<summary>
  This functional component "DeviceFilterPopupHOC" provides props to the child component.
  1. State "setShowDeviceFilterPopup" that manages the show/hide of pop up
  2. Function "deviceFilterToggleHandler" to toggle the show/hide popup state "showConfirmPopup"
  Developer:Mohammad Saquib Khan, Created Date:18-Nov-2023
</summary>
<returns>Returns JSX</returns>
*/

import React, { useState } from "react";

const DeviceFilterPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showDeviceFilterPopup, setShowDeviceFilterPopup] =
      useState<boolean>(false);

    const deviceFilterToggleHandler = () => {
      setShowDeviceFilterPopup(!showDeviceFilterPopup);
    };

    return (
      <Component
        showDeviceFilterPopup={showDeviceFilterPopup}
        deviceFilterToggleHandler={deviceFilterToggleHandler}
        {...props}
      />
    );
  };

export default DeviceFilterPopupHOC;
