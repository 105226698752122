/*    
<summary>
  This functional component "AddTenantCharge" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:26-Oct-2023
</summary>
<returns>Returns JSX</returns>
*/

import "./add-tenant-charge.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import AddTenantChargeForm from "../forms/add-tenant-charge-form";
import toast from "react-hot-toast";
import { useStore } from "../../../contexts/StoreProvider";
import { IAddTenantCharge } from "../../../models/Form/IAddTenantCharge";
import { formatMessage } from "../../../translations/format-message";
import { cloneDeep } from "lodash";
import {initialState} from "../../../core/initial-state/add-tenant-charge-state";

interface IProps {
  modalClosed: () => void;
}

const AddTenantCharge = (props: IProps) => {
  const { tenantChargeStore, tenantStore } = useStore();
  const {
    resetAddTenantChargeState,
    addTenantChargeState,
    AddTenantChargesService,
    allCountryCurrencyOptions,
    GetAllCountryCurrencyService,
    countryCurrencyState
  } = tenantChargeStore;

  const {
    GetAllTenantsService,
    allTenantOptions
  } = tenantStore;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered 
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    GetAllTenantsService();
    GetAllCountryCurrencyService();
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "addUpdateTenantChargeSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (addTenantChargeState.success) {
        toast.success(formatMessage("added_success"));
      onModalClosed();
    }
  }, [addTenantChargeState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "tenantChargeDetailError" of the useEffect changed.
   */
  useEffect(() => {
    if (tenantStore.error) {
      toast.error(formatMessage(tenantStore.error));
    }
  }, [tenantStore.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "addUpdateTenantChargeError" of the useEffect changed.
   */
  useEffect(() => {
    if (addTenantChargeState.error) {
      toast.error(formatMessage(addTenantChargeState.error));
      resetAddTenantChargeState();
    }
  }, [addTenantChargeState.error]);

  /**
   * This function adds the tenantCharge when "Id < 0" and updates the Existing tenantCharge when Id > 0 by providing form values to the
   * correponding function 
   */
  const onSubmitForm = (values: IAddTenantCharge) => {
    let tempValues = cloneDeep(values);
    AddTenantChargesService(tempValues);
  };

  /**
   * This function closes the pop up modal and reset gettenantChargedetails, addUpdate tenantCharge observables (success, inprogress 
   * and error).
   */
  const onModalClosed = () => {
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <AddTenantChargeForm
        initialValues={initialState}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        tenantOptions={allTenantOptions}
        countryCurrencyOptions={allCountryCurrencyOptions}
        getOptionLoading={tenantStore.inProgress || countryCurrencyState.inProgress} 
        addTenantChargeLoading={addTenantChargeState.inProgress}
      />
    </React.Fragment>
  );
};

export default observer(AddTenantCharge);
