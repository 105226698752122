/*    
<summary>
   This functional linechart component mount the canvas js chart to the dashboard module. 
   Developer:Aashish Singh, Created Date:28-Sept-2023
</summary>
<returns>Returns JSX</returns>
*/

import React from "react";
import { injectIntl } from "react-intl";
import "./line-chart.css";
import CanvasJSReact from "../../assets/lib/canvasjs.react";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJS.addCultureInfo("jp", {
  saveJPGText: "画像保存（JPEG) ",
  savePNGText: "画像保存（PNG）",
  printText: "印刷",
  menuText:"オプション",
  resetText: "リセット",
  panText: "スクロール"
});

const LineChart = (props: any) => {
  return (
    <div className="chart-container">
      <div key={props.index}><CanvasJSChart options={props.options}
      /></div>
    </div>
  );
};

export default injectIntl(LineChart);