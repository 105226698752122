/*    
<summary>
   This is the Transactions Component which shows the Transactions Data.
   Developer: Aashish Singh, Created Date:26-Oct-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from "react";
import "./transaction.css";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/StoreProvider";
import IPageInfo, { IPageLimitInfo } from "../../models/ICommon";
import { formatMessage } from "../../translations/format-message";
import Grid from "../../shared-components/Grid/Grid";
import { observer } from "mobx-react";
import "react-sliding-side-panel/lib/index.css";
import AddPopupHOC from "../../shared-components/popup/add/add-popup-hoc";
import userSettingEnum from "../../constants/user-setting-enum";
import toast from "react-hot-toast";
import Pagination from "../../shared-components/Grid/Pagination";
import FormLoader from "../../shared-components/FormLoader/FormLoader";

const Transaction = (props: any) => {
  const { transactionStore, preferencesStore, authStore } = useStore();
  const {
    inProgress,
    transactionList,
    GetPaymentTransactionsService,
    allTransaction,
    reset,
    error,
  } = transactionStore;
  const { userSetting,  setUserSetting, islanguageChanged, updateLanguageChangedFlag } = preferencesStore;
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const allTransactionsList: Array<any> = allTransaction;
  const pageLimitOptions: Array<number> = [10, 50, 100, 1000];
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.transactionLimitConfig,
    isPageLimitChange: false,
  });

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(userSettingEnum.TransactionLimitConfig, parseInt(evt.target.value));
  };

  /**
   * This function calls the "getAllGroup" store funtion that get configuration list from API
   */
  const callGetAllTransactionService = () => {
    GetPaymentTransactionsService(pageInfo.currentPage, pageLimitInfo.pageLimit);
  };


  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    callGetAllTransactionService();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetAllTransactionService();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "allTransaction" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: transactionList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    setDisablePagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTransaction]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetAllTransactionService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "error" of the useEffect changed.
   */
  useEffect(() => {
    if (error) {
      toast.error(formatMessage(error));
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  
  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "islanguageChanged" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      callGetAllTransactionService();
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);

  /**
   * The column constant defines the column description for the tenantCharge grid (table)
   */
  /**
   * The column constant defines the column description for the tenantCharge grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      text: formatMessage("label_id"),
      hidden: true,
    },
    {
      dataField: "TenantName",
      text: formatMessage("tenant_name"),
      title: (cell: string, row: any) => {
        return cell ? (cell.length > 10 ? cell : undefined) : undefined;
      },
      classes: "p-2"
    },
    {
        dataField: "BillGenerationDate",
        text: formatMessage("bill_date"),
        title: (cell: string, row: any) => {
          return cell ? (cell.length > 10 ? cell : undefined) : undefined;
        },
    },
    {
      dataField: "PaymentRecieved",
      text: formatMessage("payment_recieved"),
      formatter: (cell: any, row:any) => <span>{row ? row.Currency.Symbol +" "+ row.PaymentRecieved : ""}</span>,
    },
    {
      dataField: "PaymentTimestamp",
      text: formatMessage("payment_date"),
    },
    {
      dataField: "Remarks",
      text: formatMessage("remarks"),
      title: (cell: string, row: any) => {
        return cell ? (cell.length > 10 ? cell : undefined) : undefined;
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div id="moveContent">
          <div className="container-fluid">
            <div className="row no-gutters  align-items-center">
              <div className="col-sm-6 col-12">
                <div className="filter-left-area">
                  <ul className="d-flex align-items-center">
                    <li>
                      <p className="h5 pl-5 mb-0">
                        <FormattedMessage id="transaction_title" />
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="add-list">
                  <ul className="d-flex align-items-center">
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table_items_list">
      {inProgress && <FormLoader loading={inProgress} />}
        {allTransactionsList.length > 0 ? (
          <div className="card mt-3">
            <div className="tableDesign row-eq-height tableDevices ">
              <div className="table-responsive" data-testid="table">
                {allTransactionsList && (
                  <Grid
                    data={allTransactionsList}
                    columns={columns}
                    keyField="Id"
                    loading={false}
                  />
                )}
                
              </div>
            </div>
            {allTransactionsList.length > 0 ? (
              <div className="row no-gutters p-2 tableFooter">
                <div className="col-sm-6 pt-1">
                  <FormattedMessage id="show" />
                  <select
                    data-testid="pageSelect"
                    className="pageLimit"
                    onChange={onPageLimitChanged}
                    value={pageLimitInfo.pageLimit}
                  >
                    {pageLimitOptions.map((op: any) => (
                      <option key={op} value={op}>
                        {op}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-6 d-flex justify-content-sm-end">
                  <Pagination
                    isRefreshPage={false}
                    totalPages={pageInfo.totalPages}
                    pageLimit={pageLimitInfo.pageLimit}
                    onPageChanged={onPageChanged}
                    disablePagination={disablePagination}
                    setIsRefreshPage={setIsRefreshPage}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="message text-center">
            <p className="noData text-muted text-center h4 mb-0">
              <FormattedMessage id="no_transaction" />
            </p>
            <p className="mb-3">{formatMessage("no_transaction_message")}</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default AddPopupHOC(observer(Transaction));
