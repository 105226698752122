/*    
<summary>
  This functional component "AddUser" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/

import "./add-edit-user.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import AddUserForm from "../forms/add-edit-user-form";
import toast from "react-hot-toast";
import { useStore } from "../../../contexts/StoreProvider";
import { IAddUser } from "../../../models/Form/IAddUser";
import { formatMessage } from "../../../translations/format-message";
import {IOption} from "../../../models/ICommon";
import { cloneDeep } from "lodash";

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddUser = (props: IProps) => {
  const { userStore } = useStore();
  const {
    GetUserByIdService,
    resetAddUpdateUserState,
    resetUserDetail,
    userState,
    userDetail,
    addUpdateUserState,
    AddUserService,
    UpdateUserService,
  } = userStore;
  const initialValues: IAddUser = userDetail;

  const userTypeOptions: IOption[] = [
    { id: -1, value: "please_select" },
    { id: 2, value: "General" },
    { id: 3, value: "ReadOnly" },
  ];

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered 
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    if (props.id > 0) {
      GetUserByIdService(props.id);
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "addUpdateUserSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateUserState.success) {
      if (props.id === -1)
        toast.success(formatMessage("added_success"));
      else toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [addUpdateUserState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "userDetailError" of the useEffect changed.
   */
  useEffect(() => {
    if (userState.error) {
      toast.error(formatMessage(userState.error));
    }
  }, [userState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while 
   * dependency "addUpdateUserError" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateUserState.error) {
      toast.error(formatMessage(addUpdateUserState.error));
      resetAddUpdateUserState();
    }
  }, [addUpdateUserState.error]);

  /**
   * This function adds the user when "Id < 0" and updates the Existing user when Id > 0 by providing form values to the
   * correponding function 
   */
  const onSubmitForm = (values: IAddUser) => {
    let tempValues = cloneDeep(values);
    tempValues.UserName = values.UserName.trim();
    if (props.id < 0) AddUserService(tempValues);
    else UpdateUserService(props.id, tempValues);
  };

  /**
   * This function closes the pop up modal and reset getuserdetails, addUpdate user observables (success, inprogress 
   * and error).
   */
  const onModalClosed = () => {
    resetUserDetail();
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <AddUserForm
        initialValues={initialValues}
        id={props.id}
        userTypeOptions={userTypeOptions}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        getUserLoading={userState.inProgress}
        addUpdateUserLoading={addUpdateUserState.inProgress}
      />
    </React.Fragment>
  );
};

export default observer(AddUser);
