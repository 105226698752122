/*    
<summary>
  This functional component "AddUpdateDeviceForm" provides form to add new user or update existing user.
  Developer:Mohammad Saquib Khan, Created Date: 29-March-2023, Updated By: Aashish Singh, Last Updated Date: 10-April-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Field, Form, Formik } from "formik";
import "./add-edit-device-form.css";
import { observer } from "mobx-react-lite";
import FormikFormInput from "../../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSelect from "../../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { Button } from "react-bootstrap";
import LoaderButton from "../../../../shared-components/Button/LoaderButton";
import IAddDevices from "../../../../models/Form/IAddUpdateDevice";
import { deviceValidateSchema, deviceValidateSchemaAwsIotCore, updateDeviceValidateSchemaAwsIotCore } from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import FormLoader from "../../../../shared-components/FormLoader/FormLoader";
import IAddDevice from "../../../../models/Form/IAddUpdateDevice";
import toast from "react-hot-toast";
import { formatMessage } from "../../../../translations/format-message";
import Label from "../../../../shared-components/Label/Label";
import { Tooltip } from "react-tooltip";
import { values } from "lodash";
import FormikFormFileInputCertificate from "../../../../shared-components/FormikFormHandlers/FormikFormFileInputCertificate";

interface IProps {
  Id: number;
  initialValues: IAddDevice;
  groupList: any;
  submitHandler: (data: any) => void;
  isLoading: boolean;
  modalClosed: () => void;
}

const AddUpdateDeviceForm = (props: IProps) => {
  const [tagsExtra, setExtraTags] = useState<Array<string>>([]);
  const [error, setError] = useState<string>("");
  const [tags, setTags] = useState<any>([]);
  const [awsIotCoreUsed, setAwsIotCoreUsed] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState("");
  const [ClientCertificateFileName, setClientCertificateFileName] =
  useState<any>(props.Id > 0 ? "Update File" : "Choose File");
  const [ClientCertificate, setClientCertificate] =
  useState<any>("");
  const [ClientKey, setClientKey] = useState<any>("");
  const [ClientKeyFileName, setClientKeyFileName] =
  useState<any>(props.Id > 0 ? "Update File" : "Choose File");

  const handleInputChange = (event: any) => {
    event.target.value = event.target.value.replace(/'/g, '');
    if(event.target.value?.length <= 10){
      setInputValue(event.target.value);
    }
    else{
      setError("tag_character_limit");
      clearErrorMessage(4000); 
    }
  };

  const handleFileReadClientCertificate = (event: any) => {
    const content = event.target.result;
    setClientCertificate(content);
    // or do whatever you need with the file content
  };

  const handleFileReadClientKey = (event: any) => {
    const content = event.target.result;
    setClientKey(content);
    // or do whatever you need with the file content
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Tab" || event.key === "Enter") {
      event.preventDefault();
      addTags();
    }
  };

  const addTags = () => {
    let tempTagValue = inputValue.trim();
    if (tempTagValue) {
      if (tags.includes(tempTagValue)) {
        setError("tag_exist");
        clearErrorMessage(3000); // Display error for 5 seconds
        return;
      }

      if (tags.length >= 5) {
        setError("tags_limit_reached");
        clearErrorMessage(3000); // Display error for 5 seconds
        return;
      }

      setTags([...tags, tempTagValue]);
      setInputValue("");
      clearErrorMessage(); // Clear error message if a valid tag is added
    }
  }

  const handleTagDelete = (tag: any) => {
    setTags(tags.filter((t: any) => t !== tag));
  };

  const clearErrorMessage = (timeout = 0) => {
    setTimeout(() => {
      setError("");
    }, timeout);
  };

  useEffect(() => {
    let tag: any = [];
    for (let i = 0; i < initialValues.Tags.length; i++) {
      tag.push(initialValues.Tags[i]);
    }
    setTags(tag);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Id > 0 && props.initialValues.Tags.length > 0]);

  /**
   * Area Block for Get functions/variables from store class
   * UpdatedBy:Mohammad Saquib Khan , Date:29-March-2023
   */

  const { initialValues, submitHandler, groupList, isLoading, modalClosed } =
    props;

  return (
    <>
      <div className="mx-auto">
        {isLoading && <FormLoader loading={isLoading} />}
        <Formik
          initialValues={initialValues}
          validationSchema={awsIotCoreUsed == false ? deviceValidateSchema : props.Id > 0 ? updateDeviceValidateSchemaAwsIotCore : deviceValidateSchemaAwsIotCore}
          enableReinitialize
          validateOnBlur={false}
          onSubmit={async (values: IAddDevices) => {
            if (tags.length > 5) {
              toast.error(formatMessage("tags_limit_reached"));
            } else if (tags.length >= 0) {
              values.Tags = tags;
              submitHandler({...values, ClientCertificate: props.Id > 0 && ClientCertificate == "" ? props.initialValues?.ClientCertificateData : ClientCertificate, ClientKey: props.Id > 0 && ClientKey == "" ? props.initialValues?.ClientKeyData : ClientKey});
            }
          }}
          render={({ values }) => (
              <Form id="formBody">
                <div className="row px-4  pt-4">
                  <div className="col-12">
                    <div className="form-group formSet">
                      <Label
                        className="label-style"
                        required={true}
                        label="device_name"
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="currentColor"
                        className="bi bi-info-circle ml-2"
                        viewBox="0 0 16 16"
                        data-tooltip-id="my-tooltip-device"
                        data-tooltip-html={formatMessage(
                          "device_name_validation"
                        )}
                        data-tooltip-variant="dark"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                      </svg>
                      <Tooltip id="my-tooltip-device" place="right" />
                      <Field
                        data-testid="DeviceName"
                        className="form-control"
                        name="Name"
                        validcharacter={["@", "#", "$", "-", "_", "."]}
                        placeholder="device_name"
                        type="text"
                        as={FormikFormInput}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group formSet">
                      <Label
                        className="label-style"
                        required={true}
                        label="iccid"
                      />

                      <Field
                        data-testid="ICCID"
                        className="form-control"
                        name="Iccid"
                        placeholder="iccid"
                        type="number"
                        as={FormikFormInput}
                        disabled={props.Id > 0}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group formSet">
                      <Label
                        className="label-style"
                        required={true}
                        label="group"
                      />
                      <Field
                        id="Groups"
                        className="form-control"
                        name="GroupId"
                        options={groupList}
                        as={FormikFormSelect}
                      />
                    </div>
                  </div>
                </div> 

                  {props.groupList.some((obj:any) => obj.id == values.GroupId && obj.protocol == 4) == true ?
                  <>
                    {setAwsIotCoreUsed(true)}
                    <div className="bg-light px-4 pb-2 pt-3">
                    <Label
                      className="text-dark fw-semibold"
                      required={false}
                      label="certificate"
                    />

{props.Id > 0 && awsIotCoreUsed == true? (
                          <div className="alert alert-warning mt-1">
                            <FormattedMessage id={"file_upload_message_device"} />
                          </div>
                        ) : (
                          ""
                        )}
                    
                    <div className="form-group formSet mt-2">
                      <Label
                        className="label-style"
                        required={true}
                        label="ccf"
                      />
                      <Field
                        className="form-control"
                        id="ckf_file"
                        name="ClientCertificate"
                        handleFileUpload={handleFileReadClientCertificate}
                        as={FormikFormFileInputCertificate}
                      />
                    </div>
                    <div className="form-group formSet">
                      <Label
                        className="label-style"
                        required={true}
                        label="ckf"
                      />
                      <Field
                        className="form-control"
                        id="ck_file"
                        name="ClientKey"
                        handleFileUpload={handleFileReadClientKey}
                        as={FormikFormFileInputCertificate}
                      />
                    </div>
                    </div> 
                  </>: 
                  
                  <>
                    {setAwsIotCoreUsed(false)}
                  </>
                  }
                <div className="row px-4  pb-4 pt-2">
                  <div className="col-12 mt-1">
                    <div className="form-group formSet">
                      <label className="label-style">
                        <FormattedMessage id="tags" />
                        <small className="text-muted"> (<FormattedMessage id="tags_max" />)</small>
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          value={inputValue}
                          className="form-control"
                          placeholder={formatMessage("tags_enter")}
                          onChange={(e) => handleInputChange(e)}
                          onKeyDown={handleKeyDown}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-secondary" type="button" onClick={() => addTags()}><i className="fa fa-plus"></i></button>
                        </div>
                      </div>
                      
                      <div>
                        {tags.map((tag: any, index: any) => (
                          <span className="bgTag primary tag mr-2" key={index}>
                            {tag}
                            <button
                              type="button"
                              className="close"
                              onClick={() => handleTagDelete(tag)}
                            >
                              &times;
                            </button>
                          </span>
                        ))}
                      </div>
                      {error != "" ? (
                        <span className="text-danger errorMessage">
                          <FormattedMessage id={error} />
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="modal-footer border-0 pt-0">
                  <LoaderButton
                    type="submit"
                    id="SaveDevice"
                    isLoading={isLoading}
                    text="submit"
                  />
                  <Button
                    data-testid="Cancel"
                    variant="secondary"
                    onClick={modalClosed}
                    disabled={isLoading}
                    type="button"
                    className=""
                  >
                    <FormattedMessage id="close" />
                  </Button>
                </div>
              </Form>
            )}
        ></Formik>
      </div>
    </>
  );
};

export default observer(AddUpdateDeviceForm);
