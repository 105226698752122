/*    
<summary>
   This file is used to provide initial values or state to the Configuration Module observables.
   Developer: Aashish Singh, Created Date: 07-April-2023
</summary>
*/

import { IConfigurationListVM } from "../../models/response/IConfigurationResponse";

export const initialState: IConfigurationListVM = {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage: 1,
        PageSize: 10,
    },
    ConfigurationProtocols: []
}