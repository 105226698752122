/*    
<summary>
   This file is used to provide initial values or state to the Bar Chart Module.
   Developer: Mohammad Saquib Khan, Created Date: 18-Jan-2024
</summary>
*/

import { ScriptableContext } from "chart.js";
import { formatMessage } from "../../translations/format-message";

export const initialState: any = {
	options: {
		animation: false,
		responsive: true,
		scales: {
			y: {
				beginAtZero: true,
				suggestedMin: 0,
            	suggestedMax: 10,
				// ticks:{
				// 	stepSize: 1,
				// }
			},
		},
		  plugins: {
			legend: {
			  display: false,
			},
			filler: {
			  propagate: false,
			},
		  },
	},
	data: {
		labels: ["Day1","Day2","Day3","Day4","Day5","Day6","Day7","Day8","Day9","Day10","Day11","Day12","Day13","Day14","Day15","Day16","Day17","Day18","Day19","Day20","Day21","Day22","Day23","Day24","Day25","Day26","Day27","Day28","Day29","Day30","Day31"],
		datasets: [
		  {
			label: formatMessage("data_usage"),
			data: [],
			fill: "start",
			strokeColor: "#ff6c23",
			pointColor: "#fff",
			pointStrokeColor: "#ff6c23",
			pointHighlightFill: "#fff",
			pointHighlightStroke: "#ff6c23",
			borderColor: "rgb(255, 108, 55)",
			backgroundColor: (context: ScriptableContext<"line">) => {
			  const ctx = context.chart.ctx;
			  const gradient = ctx.createLinearGradient(0, 0, 0, 200);
			  gradient.addColorStop(0, "rgba(255, 108, 55,0.6)");
			  gradient.addColorStop(1, "rgba(255, 108, 55,0.03)");
			  return gradient;
			},
		  },
		],
	},
  };
