/*    
<summary>
   This file is used to provide initial values or state to the User Module observables.
    Developer:Aashish Singh, Created Date:29-August-2023
</summary>
*/

import { IUserListVM } from "../../models/response/IUserResponse";

export const initialState: IUserListVM = {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage: 1,
        PageSize: 10,
    },
    Users: []
}