/*    
<summary>
   This file is used to provide initial values or state to the tenant chagre Data observables.
   Developer:Ashish Singh, Created Date: 27-Oct-2023
</summary>
*/

import { IAddTenantCharge } from "../../models/Form/IAddTenantCharge";

export const initialState: IAddTenantCharge = {
    TenantId: -1,
    RegistrationCharges: '', 
    PacketCharges: '',
    TenancyCharges: '',
    Taxes: '',
    CountryCurrencyId: ''
}