/*    
<summary>
  This functional component "AddUpdateConfiguration" defines the props like initial values & functions for the child form component.
  Developer:Mohammad Saquib Khan, Created Date: 20 April 2023
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import "./group-stats.css";
import { useStore } from "../../../contexts/StoreProvider";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  Tooltip,
  Legend,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  ScriptableContext,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { FormattedMessage } from "react-intl";
import ConfirmPopupHOC from "../../../shared-components/popup/confirm/confirm-popup-hoc";
import ConfirmPopup from "../../../shared-components/popup/confirm/confirm-popup";
import toast from "react-hot-toast";
import { formatMessage } from "../../../translations/format-message";
import { IGroupList } from "../../../models/response/IGroupResponse";
import nodevicesvg from "../../../assets/Images/svg/pie-chart.svg";
import RoutesConstants from "../../../shared-components/Routes/routes.constants";
import { useHistory } from "react-router-dom";
import userTypeEnum from "../../../constants/user-type-enum";
import protocolEnum from "../../../constants/protocol-enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";
interface IProps {
  selectedData: IGroupList;
  showConfirmUnblockPopup: () => void;
  closePanelHandler: () => void;
  viewDevicesHandler: () => void;
}

const GroupStats = (props: IProps) => {
  const history = useHistory();
  const { groupStore, authStore } = useStore();
  const { getUserType } = authStore;
  const {
    groupInfoId,
    GetGroupInfoByIdService,
    unblockGroupState,
    updateGroupIMEILockState,
    resetUpdateGroupIMEILockState,
    UpdateGroupIMEILockStatusService,
  } = groupStore;
  const [confirmLockPanel, setConfirmLockPanel] = useState<boolean>(false);
  const [imeiLockStatus, setImeiLockStatus] = useState<boolean>(false);

  /**
   * This function calls the "GetGroupByIdService" store funtion that get group data from API
   */
  const callGetGroupsById = () => {
    GetGroupInfoByIdService(props?.selectedData.Id);
  };

  useEffect(() => {
    if (props?.selectedData.Id > 0) {
      callGetGroupsById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedData.Id]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "unblockGroupState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (unblockGroupState.success) {
      callGetGroupsById();
    }
  }, [unblockGroupState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "updateGroupIMEILockState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (updateGroupIMEILockState.success) {
      toast.success(formatMessage("updated_success"));
      callGetGroupsById();
      imeiLockModalCloseHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateGroupIMEILockState.success]);

  /**
   * This fiunction saves the imei_lock state and open the confirmation popup.
   * @param lockStatus boolean status of imei_lock
   */
  const lockClickHandler = (lockStatus: boolean) => {
    setImeiLockStatus(lockStatus);
    setConfirmLockPanel(true);
  };

  /**
   * This function actually update the imei_lock of device by calling api.
   */
  const lockHandler = () => {
    UpdateGroupIMEILockStatusService(props.selectedData.Id, imeiLockStatus);
  };

  /**
   * This function resets the imei_lockState, imei_lockUpdate observables state & close the confirm pop up modal.
   */
  const imeiLockModalCloseHandler = () => {
    setImeiLockStatus(false);
    setConfirmLockPanel(false);
  };

  const configHandler = () => {
    history.push({
      pathname: RoutesConstants.Configurations,
      state: {
        configId: groupInfoId.ConfigurationProtocol?.Id,
        returnPath: RoutesConstants.Group,
        from: RoutesConstants.Group,
      },
    });
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    ArcElement,
    Tooltip,
    Legend
  );
  const data = {
    labels: [
      "Active Devices (" + groupInfoId.ActiveDevices + ")",
      "InActive Devices (" + groupInfoId.InActiveDevices + ")",
    ],
    datasets: [
      {
        label: "Devices",
        data: [groupInfoId.ActiveDevices, groupInfoId.InActiveDevices],
        backgroundColor: ["#f46703", "#ffe6bf"],
        borderColor: ["#f46703", "#ffe6bf"],
        borderWidth: 3,
      },
    ],
  };
  const option2 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: { yLabel: any }) {
          return tooltipItem.yLabel;
        },
      },
    },
  };

  return (
    <React.Fragment>
      {confirmLockPanel && (
        <ConfirmPopup
          className="imei-lock-modal delete_popup"
          title={
            imeiLockStatus
              ? "imei_lock_dailog_title"
              : "imei_unlock_dailog_title"
          }
          modalSubmit={lockHandler}
          modalClosed={imeiLockModalCloseHandler}
          message={
            imeiLockStatus
              ? "group_imei_lock_confirm_message"
              : "group_imei_unlock_confirm_message"
          }
          isLoading={updateGroupIMEILockState.inProgress}
        />
      )}
      <div className="row bb p-2 pl-4">
        <div className="col-lg-11">
          <h2 className={`fw-bold mb-0 mt-2 ${(groupInfoId.Name && groupInfoId.Name.length >= 10) ? "smallFont": ""}`}>
            <span
              className="text-dark"
              title={formatMessage(groupInfoId.IsLocked ? "lock" : "un_lock")}
            >
              <FontAwesomeIcon
                className="mr-2 largeData"
                icon={groupInfoId.IsLocked ? faLock : faUnlock}
              />
            </span>
            {props?.selectedData.Name}
            {props?.selectedData.IsDefault && (
              <span className="badge badge-success p-1 ml-1">
                <FormattedMessage id="default" />
              </span>
            )}
          </h2>
        </div>
        <div className="col-lg-1">
          <svg
            onClick={props.closePanelHandler}
            role="button"
            xlinkTitle="close"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            className="bi bi-x float-right"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </div>
      </div>
      <div className="group_detailsWrapper  pb-3">
        <div className="p-3">
          {groupInfoId.TotalDevices > 0 ? (
            <div className="row align-items-center">
              <div className="col-6">
                <Doughnut data={data} options={option2} />
              </div>
              <div className="col-6">
                <FormattedMessage id="active_device" />
                <h2>
                  {groupInfoId?.ActiveDevices} / {groupInfoId?.TotalDevices}
                </h2>
              </div>
            </div>
          ) : (
            <div className="row align-items-center">
              <div className="col-6">
                <img src={nodevicesvg} alt=" " />
              </div>
              <div className="col-6">
                <FormattedMessage id="no_device_available" />
                <h2>0/0</h2>
              </div>
            </div>
          )}
          {groupInfoId.Description && (
            <div className="row pt-2">
              <div className="col-12">
                <label className="text-muted bold">
                  <FormattedMessage id={"desc"} />:{" "}
                </label>
              </div>
              <div className="col-12">
                <p className="mw-100">{groupInfoId.Description}</p>
              </div>
            </div>
          )}
          {groupInfoId.IsBlocked && (
            <>
              <div className=" row pt-2">
                <div className="col-12">
                  <label className="text-muted bold">
                    <FormattedMessage id={"block_timestamp"} />:{" "}
                  </label>
                </div>
                <div className="col-12">
                  <p className="mw-100">{groupInfoId.BlockedTimestamp}</p>
                </div>
              </div>
              {getUserType !== userTypeEnum.ReadOnly && (
                <div className=" row pt-2">
                  <div className="col-12">
                    <label className="text-muted bold">
                      <FormattedMessage id={"is_blocked"} />:{" "}
                    </label>
                  </div>
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      title={formatMessage("un_block")}
                      onClick={() => {
                        props.showConfirmUnblockPopup();
                      }}
                    >
                      <FormattedMessage id="un_block" />
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="px-3">
          <div className="parent_card">
            <label className="text-muted bold">
              <FormattedMessage id="config_name" />:{" "}
            </label>
            <br />
            <a href="javascript:;" onClick={configHandler}>
              {groupInfoId.ConfigurationProtocol?.Name}
            </a>
          </div>
          <div className="childCard_wrap">
            {groupInfoId?.ConfigurationProtocol &&
              groupInfoId.ConfigurationProtocol?.HttpsParameters && (
                <div className="child_card">
                  <label className="text-muted bold">
                    <FormattedMessage id={"host"} />:{" "}
                  </label>
                  <p className="mw-100 text-break">
                    {groupInfoId.ConfigurationProtocol.HttpsParameters?.Host}
                  </p>
                </div>
              )}
            {groupInfoId.ConfigurationProtocol && (
              <div className="child_card">
                <label className="text-muted bold">
                  <FormattedMessage id="protocol" />:{" "}
                </label>
                <p className="mw-100">
                  {`${
                    protocolEnum[
                      groupInfoId?.ConfigurationProtocol?.SourceProtocol
                    ]
                  } `}{" "}
                  <i className="fa fa-arrow-right text-muted"></i>{" "}
                  
                  {` ${
                    groupInfoId?.ConfigurationProtocol?.DestinationProtocol == protocolEnum.AWSIoT ? formatMessage("aws_connector") :
                    protocolEnum[
                      groupInfoId?.ConfigurationProtocol?.DestinationProtocol
                    ]
                  } `}
                </p>
              </div>
            )}
          </div>
        </div>
        {groupInfoId.TotalDevices > 0 && (
          <button
            type="button"
            className="btn btn-secondary mx-auto d-table mt-3"
            onClick={() => props.viewDevicesHandler()}
          >
            <FormattedMessage id={"view_devices"} />
          </button>
        )}
      </div>
    </React.Fragment>
  );
};

export default ConfirmPopupHOC(observer(GroupStats));
