/*    
<summary>
  This functional component "AddTenantChargeForm" provides form to add new user or update existing user.
  Developer:Aashish Singh, Created Date:26-Oct-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";
import { Form, Formik, Field } from "formik";
import "./add-tenant-charge-form.css";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import { IAddTenantCharge } from "../../../models/Form/IAddTenantCharge";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import Label from "../../../shared-components/Label/Label";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { ICountryCurrencyOption, IOption } from "../../../models/ICommon";
import { AddTenantChargeValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { formatMessage } from "../../../translations/format-message";
import FormikFormInputForRegexCheck from "../../../shared-components/FormikFormHandlers/FormikFormInputForRegexCheck";
import { defaultCountryCurrency } from "../../../constants/common-constants";

interface IProps {
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  getOptionLoading: boolean;
  addTenantChargeLoading: boolean;
  initialValues: IAddTenantCharge;
  tenantOptions: IOption[];
  countryCurrencyOptions: ICountryCurrencyOption[];
}

const AddTenantChargeForm = (props: IProps) => {
  const [selectedCurrencySymbol, SetSelectedCurrencySymbol] = useState<any>(JSON.stringify(defaultCountryCurrency));
  const [selectedCurrencyOption, SetSelectedCurrencyOption] = useState<any>(defaultCountryCurrency);
  return (
    <>
      <Modal className="add-user-modal correction-modal fancy-modal" show={true} centered onHide={() => {}}>
        <Modal.Header>
          <Modal.Title>
              <FormattedMessage id="add_tenant_charge_form" />
            <button
              onClick={props.onModalClosed}
              disabled={props.addTenantChargeLoading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          validationSchema={AddTenantChargeValidateSchema}
          validateOnBlur={false}
          onSubmit={async (values: IAddTenantCharge) => {
            let tempValues: IAddTenantCharge = {
              TenantId: Number(values.TenantId),
              RegistrationCharges: Number(values.RegistrationCharges),
              PacketCharges: Number(values.PacketCharges),
              TenancyCharges: Number(values.TenancyCharges),
              Taxes: Number(values.Taxes),
              CountryCurrencyId: selectedCurrencyOption.id
          }
            props.submitHandler({...tempValues});
          }}
        >
          <Form className="user-form">
            {props.getOptionLoading ? (
              <FormLoader loading={props.getOptionLoading} />
            ) : null}
            <Modal.Body>
              <div className="form-group mb-1 row">
                <Label
                  className="label-style col-sm-6 col-form-label"
                  label="currency"
                />
                <div className="col-sm-6">
                  <select value={selectedCurrencySymbol}
                    className="form-control"
                    onChange={(event)=>{
                      let selectedData = JSON.parse(event.target.value)
                      SetSelectedCurrencySymbol(event.target.value);
                      SetSelectedCurrencyOption(selectedData)
                    }}>
                  {props.countryCurrencyOptions.map((data: any, i: number) => (
                    <option key={data.id} value={data.data} disabled={data.disabled}>
                      {data.placeholder}
                    </option>
                  ))}
                  </select>
                  {/* <Field
                    name="TenantId"
                    as={FormikFormSelect}
                    options={currencyOptions}
                  /> */}
                </div>
              </div>
              <div className="form-group mb-1 row">
                <Label
                  className="label-style col-sm-6 col-form-label"
                  label="tenant_id"
                  required={true}
                />
                <div className="col-sm-6">
                  <Field
                    name="TenantId"
                    as={FormikFormSelect}
                    options={props.tenantOptions}
                  />
                </div>
              </div>
              <div className="form-group mb-1 row">
                <Label
                  className="label-style col-sm-6 col-form-label"
                  required={true}
                  label={`${formatMessage("registration_charges")} (${selectedCurrencyOption.symbol})`}
                  formatLabel={true}
                />
                <div className="col-sm-6">
                  <Field
                    data-testid="registrationCharges"
                    className="form-control"
                    name="RegistrationCharges"
                    type="text"
                    placeholder="registration_charges"
                    regex={/^(?:\d+(\.\d*)?|)$/}
                    as={FormikFormInputForRegexCheck}
                  />
                </div>
              </div>
              <div className="form-group mb-1 row">
                <Label
                  className="label-style col-sm-6 col-form-label"
                  required={true}
                  formatLabel={true}
                  label={`${formatMessage("packet_charges")} (${selectedCurrencyOption.symbol})`}
                />
                <div className="col-sm-6">
                  <Field
                    data-testid="packetCharges"
                    className="form-control"
                    name="PacketCharges"
                    type="text"
                    placeholder="packet_charges"
                    regex={/^(?:\d+(\.\d*)?|)$/}
                    as={FormikFormInputForRegexCheck}
                  />
                </div>
              </div>
              <div className="form-group mb-1 row">
                <Label
                  className="label-style col-sm-6 col-form-label"
                  required={true}
                  formatLabel={true}
                  label={`${formatMessage("tenancy_charges")} (${selectedCurrencyOption.symbol})`}
                />
                <div className="col-sm-6">
                  <Field
                    data-testid="tenancyCharges"
                    className="form-control"
                    name="TenancyCharges"
                    type="text"
                    placeholder="tenancy_charges"
                    regex={/^(?:\d+(\.\d*)?|)$/}
                    as={FormikFormInputForRegexCheck}
                  />
                </div>
              </div>
              <div className="form-group mb-1 row">
                <Label
                  className="label-style col-sm-6 col-form-label"
                  required={true}
                  label="taxes_yen"
                />
                <div className="col-sm-6">
                  <Field
                    data-testid="taxes"
                    className="form-control"
                    name="Taxes"
                    type="text"
                    placeholder="taxes"
                    regex={/^(?:\d+(\.\d*)?|)$/}
                    as={FormikFormInputForRegexCheck}
                  />
                </div>
              </div>
            </Modal.Body>
            <div className="modal-footer pt-0 border-0">
              <div className="form-group row tab-action">
                <div className="col-12">
                  <LoaderButton
                    type="submit"
                    isLoading={props.addTenantChargeLoading}
                    text="button_save"
                  />
                  <Button
                    variant="secondary"
                    onClick={props.onModalClosed}
                    disabled={props.addTenantChargeLoading}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default AddTenantChargeForm;
