/*    
<summary>
  This functional component "EnableDisableSimPopupHOC" provides props to the child component.
  1. State "setShowEnableDisableSimPopup" that manages the show/hide of pop up
  2. Function "deviceFilterToggleHandler" to toggle the show/hide popup state "showConfirmPopup"
  Developer:Aashish Singh, Created Date:07-Feb-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, { useState } from "react";

const EnableDisableSimPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showEnableDisableSimPopup, setShowEnableDisableSimPopup] =
      useState<boolean>(false);

    const enableDisableSimToggleHandler = () => {
      setShowEnableDisableSimPopup(!showEnableDisableSimPopup);
    };

    return (
      <Component
        showEnableDisableSimPopup={showEnableDisableSimPopup}
        enableDisableSimToggleHandler={enableDisableSimToggleHandler}
        {...props}
      />
    );
  };

export default EnableDisableSimPopupHOC;
