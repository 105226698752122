/*    
<summary>
   This is the Billings Component which shows the Biiling Data.
   Developer: Aashish Singh, Created Date:19-Oct-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React, { SyntheticEvent, useEffect, useState } from "react";
import "./billing.css";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/StoreProvider";
import IPageInfo, { IPageLimitInfo } from "../../models/ICommon";
import { formatMessage } from "../../translations/format-message";
import Grid from "../../shared-components/Grid/Grid";
import { observer } from "mobx-react";
import "react-sliding-side-panel/lib/index.css";
import {
  ActionFormatter,
} from "../../shared-components/Grid/GridFormatter";
import {
  faMoneyCheck,
} from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import Pagination from "../../shared-components/Grid/Pagination"
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import userSettingEnum from "../../constants/user-setting-enum";
import SlidingPanel from "react-sliding-side-panel";
import BillingDetails from "./billing-details";
import { IBillingList } from "../../models/response/IBillingResponse";
import _ from "lodash";
import AddPopupHOC from "../../shared-components/popup/add/add-popup-hoc";
import AddPayment from "./components/add-payment";
import userTypeEnum from "../../constants/user-type-enum";
import { emptyValue } from "../../constants/common-constants";
import FilterPopupHOC from "../../shared-components/popup/filter/filter-popup-hoc";


const Billing = (props: any) => {
  const { billingStore, preferencesStore, authStore } = useStore();
  const {
    inProgress,
    billingList,
    GetAllTenantsBillService,
    allBilling,
    reset,
    error,
    addPaymentState,
    resetAddPaymentState,
  } = billingStore;
  const { getUserType } = authStore;
  const { userSetting, setUserSetting, islanguageChanged, updateLanguageChangedFlag } = preferencesStore;
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [selectedId, setSelectedId] = useState<number>(0);
  const [openPanel, setOpenPanel] = useState(false);
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const allBillingsList: Array<IBillingList> = allBilling;
  const pageLimitOptions: Array<number> = [10, 50, 100, 1000];
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.billingLimitConfig,
    isPageLimitChange: false,
  });
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(userSettingEnum.BillingLimitConfig, parseInt(evt.target.value));
  };

  /**
   * This function calls the "GetAllTenantsBillService" store funtion with default filter Details that get Billing list from API
   */
  const callGetAllBillingService = () => {
      GetAllTenantsBillService(pageInfo.currentPage, pageLimitInfo.pageLimit, {
        TenantId: -1,
        Year: 0,
        Month: 0
      });
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };
  
  /**
   * This function calls the "GetAllTenantsBillService" store funtion with applied filter Details that get Billing list from API
   */
  // const callGetAllBillingWithFilterService = (filterDetails: FilterDetails) => {
  //   GetAllTenantsBillService(pageInfo.currentPage, pageLimitInfo.pageLimit, filterDetails);
  // };

  const closePanelhandler = () => {
    setSelectedId(0);
    setOpenPanel(false);
  };

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    callGetAllBillingService();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetAllBillingService();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "GroupList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: billingList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    setDisablePagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBilling]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetAllBillingService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addPaymentState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addPaymentState.success) {
      resetAddPaymentState();
      callGetAllBillingService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPaymentState.success]);


  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "error" of the useEffect changed.
   */
  useEffect(() => {
    if (error) {
      toast.error(formatMessage(error));
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      callGetAllBillingService();
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);
  
  function rowClassFormat(row: any, rowIdx: number) {
    let className: string = "";
    if (selectedId && row.Id === selectedId) {
      className = "bg-row SelectedRow";
    }
    return className;
  }

  const rowEvents = (e: SyntheticEvent, row: any, rowIndex: number) => {
    setSelectedId(row.Id);
    setOpenPanel(true);
  };

  const openAddPaymentHandler = (
    row: { Id: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    closePanelhandler();
    event.stopPropagation();
    setSelectedRow(row);
    props.addToggleHandler();
  };

  /**
   * The column constant defines the column description for the Billing grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      text: formatMessage("label_id"),
      hidden: true,
    },
    {
      dataField: "Tenant.TenantName",
      text: formatMessage("tenant_name"),
      title: (cell: string) => {
        return cell ? (cell.length > 10 ? cell : undefined) : undefined;
      },
    },
    {
      dataField: "LastBillGenerationDate",
      text: formatMessage("bill_date"),
      title: (cell: string, row: any) => {
        return cell ? (cell.length > 10 ? cell : undefined) : undefined;
      },
    },
    //   {
    //   dataField: "BillStateType",
    //   text: formatMessage("bill_state_type"),
    // },
    {
      dataField: "Currency",
      formatter: (row: any) => {
        return(<span>{row ? (row.CurrencyName +" (" + row.Symbol +")" ): emptyValue}</span>)},
      text: formatMessage("currency"),
    },
    {
      dataField: "TotalOutstandingAmount",
      text: formatMessage("pending_amount"),
    },
    {
      dataField: "isPaid",
      formatter: ActionFormatter([
        {
          dataField: "isPaid",
          handler:openAddPaymentHandler,
          icon: faMoneyCheck,
          isDisable: true,
          title: "payment",
          varient: "danger",
        },
      ]),
      text: formatMessage("actions"),
      hidden: getUserType == userTypeEnum.ReadOnly ? true : false,
    },
  ];

  return (
    <React.Fragment>
      {props.showAddPopup && (
        <AddPayment
          selectedData={selectedRow}
          modalClosed={props.addToggleHandler}
        />
      )}
      <div className="icon-nav navbar-fixed-top device-nav-bar BillingTopNav">
        <div id="moveContent">
          <div className="container-fluid">
            <div className="row no-gutters  align-items-center">
              <div className="col-sm-6 col-12">
                <div className="filter-left-area">
                  <ul className="d-flex align-items-center">
                    <li>
                      <p className="h5 pl-5 mb-0">
                        <FormattedMessage id="billing_title" />
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="add-list">
                  <ul className="d-flex align-items-center">
                  {/* {(filter.isFilterActive) ? (
                      <li>
                        <button
                          data-testid="FilterButton"
                          className="btn btn-light btn-sm mr-1"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={resetFilter}
                          title={formatMessage("reset_filter")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            width="20px"
                            height="20px"
                            viewBox="0 0 122.88 110.668"
                            enable-background="new 0 0 122.88 110.668"
                          >
                            <g>
                              <path
                                fill="currentColor"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M91.124,15.645c12.928,0,23.406,10.479,23.406,23.406 c0,12.927-10.479,23.406-23.406,23.406c-12.927,0-23.406-10.479-23.406-23.406C67.718,26.125,78.197,15.645,91.124,15.645 L91.124,15.645z M2.756,0h117.322c1.548,0,2.802,1.254,2.802,2.802c0,0.848-0.368,1.622-0.996,2.139l-10.667,13.556 c-1.405-1.375-2.95-2.607-4.614-3.672l6.628-9.22H9.43l37.975,46.171c0.59,0.516,0.958,1.254,0.958,2.102v49.148l21.056-9.623 V57.896c1.651,1.9,3.548,3.582,5.642,4.996v32.133c0,1.105-0.627,2.064-1.586,2.506l-26.476,12.758 c-1.327,0.773-3.023,0.332-3.798-1.033c-0.258-0.441-0.368-0.92-0.368-1.4V55.02L0.803,4.756c-1.07-1.106-1.07-2.839,0-3.945 C1.355,0.258,2.056,0,2.756,0L2.756,0z M96.93,28.282c1.328-1.349,3.489-1.355,4.825-0.013c1.335,1.342,1.341,3.524,0.013,4.872 l-5.829,5.914l5.836,5.919c1.317,1.338,1.299,3.506-0.04,4.843c-1.34,1.336-3.493,1.333-4.81-0.006l-5.797-5.878l-5.807,5.889 c-1.329,1.349-3.489,1.355-4.826,0.013c-1.335-1.342-1.341-3.523-0.013-4.872l5.83-5.913l-5.836-5.919 c-1.317-1.338-1.3-3.507,0.04-4.843c1.339-1.336,3.492-1.333,4.81,0.006l5.796,5.878L96.93,28.282L96.93,28.282z"
                              />
                            </g>
                          </svg>
                        </button>
                      </li>
                    ) : (
                      ""
                    )}
                      <li data-testid="Filter">
                      <div className="dropdown">
                        <button
                          data-testid="FilterButton"
                          className="btn btn-light btn-sm mr-1"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={() => {
                            GetAllTenantsService();
                          }}
                          title={formatMessage("filter")}
                        >
                          <svg
                            className="svg-icon"
                            width="20px"
                            height="20px"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M859.02 234.524l0.808-0.756 0.749-0.813c27.047-29.356 33.876-70.34 17.823-106.957-15.942-36.366-50.416-58.957-89.968-58.957H163.604c-38.83 0-73.043 22.012-89.29 57.444-16.361 35.683-10.632 76.301 14.949 106.004l0.97 1.126 280.311 266.85 2.032 312.074c0.107 16.502 13.517 29.805 29.995 29.805l0.2-0.001c16.568-0.107 29.912-13.626 29.804-30.194l-2.198-337.564-296.478-282.241c-9.526-11.758-11.426-26.933-5.044-40.851 6.446-14.059 19.437-22.452 34.75-22.452h624.828c15.6 0 28.69 8.616 35.017 23.047 6.31 14.391 3.924 29.831-6.354 41.497l-304.13 284.832 1.302 458.63c0.047 16.54 13.469 29.916 29.998 29.915h0.087c16.568-0.047 29.962-13.517 29.915-30.085L573.04 502.36l285.98-267.836z"
                              fill="currentColor"
                            />
                            <path
                              d="M657.265 595.287c0 16.498 13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.498 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997H687.262c-16.498 0-29.997 13.499-29.997 29.997z m273.894 138.882H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z m0 168.878H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                        <ul className="dropdown-menu filter-menu">
                          <li className="dropright pb-2 border-bottom mb-1 d-flex justify-content-between align-items-center">
                            <h3
                              data-testid="GroupFilter"
                              className="m-0 filterHeading"
                            >
                              {tenantStore.inProgress ? <PageLoader /> : null}
                              <FormattedMessage id={"filter"} />
                            </h3>
                            {filter.isFilterActive == true ? (
                              <button
                                data-testid="resetFilter"
                                className="btn btn-warning btn-sm mx-2"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={resetFilter}
                              >
                                <FormattedMessage id="reset_filter" />
                              </button>
                            ) : (
                              ""
                            )}
                          </li>
                          <li>
                            <BillingFilterForm
                              initialValues={_.omit(filter, [
                                "isFilterActive",
                              ])}
                              isFilterActive={filter.isFilterActive}
                              tenantOptions={allTenantOptions}
                              submitHandler={filterSubmitHandler}
                              modalClosed = {props.filterToggleHandler} />
                          </li>
                        </ul>
                      </div>
                    </li> */}
                    {/* {
                      filter.isFilterActive && 
                      <li>
                      <button
                            data-testid="FilterButton"
                            className="btn btn-light btn-sm mr-1"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={resetFilter}
                            title={formatMessage("reset_filter")}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              id="Layer_1"
                              x="0px"
                              y="0px"
                              width="20px"
                              height="20px"
                              viewBox="0 0 122.88 110.668"
                              enable-background="new 0 0 122.88 110.668"
                            >
                              <g>
                                <path
                                  fill="currentColor"
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M91.124,15.645c12.928,0,23.406,10.479,23.406,23.406 c0,12.927-10.479,23.406-23.406,23.406c-12.927,0-23.406-10.479-23.406-23.406C67.718,26.125,78.197,15.645,91.124,15.645 L91.124,15.645z M2.756,0h117.322c1.548,0,2.802,1.254,2.802,2.802c0,0.848-0.368,1.622-0.996,2.139l-10.667,13.556 c-1.405-1.375-2.95-2.607-4.614-3.672l6.628-9.22H9.43l37.975,46.171c0.59,0.516,0.958,1.254,0.958,2.102v49.148l21.056-9.623 V57.896c1.651,1.9,3.548,3.582,5.642,4.996v32.133c0,1.105-0.627,2.064-1.586,2.506l-26.476,12.758 c-1.327,0.773-3.023,0.332-3.798-1.033c-0.258-0.441-0.368-0.92-0.368-1.4V55.02L0.803,4.756c-1.07-1.106-1.07-2.839,0-3.945 C1.355,0.258,2.056,0,2.756,0L2.756,0z M96.93,28.282c1.328-1.349,3.489-1.355,4.825-0.013c1.335,1.342,1.341,3.524,0.013,4.872 l-5.829,5.914l5.836,5.919c1.317,1.338,1.299,3.506-0.04,4.843c-1.34,1.336-3.493,1.333-4.81-0.006l-5.797-5.878l-5.807,5.889 c-1.329,1.349-3.489,1.355-4.826,0.013c-1.335-1.342-1.341-3.523-0.013-4.872l5.83-5.913l-5.836-5.919 c-1.317-1.338-1.3-3.507,0.04-4.843c1.339-1.336,3.492-1.333,4.81,0.006l5.796,5.878L96.93,28.282L96.93,28.282z"
                                />
                              </g>
                            </svg>
                          </button>
                      </li>        
                      
                    } */}
                    <li>
                    {/* <button
                          data-testid="FilterButton"
                          className="btn btn-light btn-sm mr-1"
                          type="button"
                          onClick={filterPopupHandler}
                          title={formatMessage("filter")}
                        >
                          <svg
                            className="svg-icon"
                            width="20px"
                            height="20px"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M859.02 234.524l0.808-0.756 0.749-0.813c27.047-29.356 33.876-70.34 17.823-106.957-15.942-36.366-50.416-58.957-89.968-58.957H163.604c-38.83 0-73.043 22.012-89.29 57.444-16.361 35.683-10.632 76.301 14.949 106.004l0.97 1.126 280.311 266.85 2.032 312.074c0.107 16.502 13.517 29.805 29.995 29.805l0.2-0.001c16.568-0.107 29.912-13.626 29.804-30.194l-2.198-337.564-296.478-282.241c-9.526-11.758-11.426-26.933-5.044-40.851 6.446-14.059 19.437-22.452 34.75-22.452h624.828c15.6 0 28.69 8.616 35.017 23.047 6.31 14.391 3.924 29.831-6.354 41.497l-304.13 284.832 1.302 458.63c0.047 16.54 13.469 29.916 29.998 29.915h0.087c16.568-0.047 29.962-13.517 29.915-30.085L573.04 502.36l285.98-267.836z"
                              fill="currentColor"
                            />
                            <path
                              d="M657.265 595.287c0 16.498 13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.498 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997H687.262c-16.498 0-29.997 13.499-29.997 29.997z m273.894 138.882H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z m0 168.878H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z"
                              fill="currentColor"
                            />
                          </svg>
                        </button> */}
                    </li>          
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table_items_list">
      {inProgress && <FormLoader loading={inProgress} />}
        {allBillingsList.length > 0 ? (
         <div className={`card mt-3 changingPanel ${openPanel ? "panelOpen": ""}`}>
         <div className="tableDesign row-eq-height tableDevices ">
              <div className="table-responsive" data-testid="table">
                {allBillingsList && (
                  <Grid
                    data={allBillingsList}
                    columns={columns}
                    keyField="Id"
                    loading={inProgress}
                    rowEvents={{ onClick: rowEvents }}
                    rowClasses={rowClassFormat}
                  />
                )}
                
              </div>
            </div>
            {allBillingsList.length > 0 ? (
              <div className="row no-gutters p-2 tableFooter">
                <div className="col-sm-6 pt-1">
                  <FormattedMessage id="show" />
                  <select
                    data-testid="pageSelect"
                    className="pageLimit"
                    onChange={onPageLimitChanged}
                    value={pageLimitInfo.pageLimit}
                  >
                    {pageLimitOptions.map((op: any) => (
                      <option key={op} value={op}>
                        {op}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-6 d-flex justify-content-sm-end">
                  <Pagination
                    isRefreshPage={pageInfo.isRefreshPage}
                    totalPages={pageInfo.totalPages}
                    pageLimit={pageLimitInfo.pageLimit}
                    onPageChanged={onPageChanged}
                    disablePagination={disablePagination}
                    setIsRefreshPage={setIsRefreshPage}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="message text-center">
            
            <p className="noData text-muted text-center h4 mb-0">
              <FormattedMessage id="no_bill" />
            </p>
            <p className="mb-3">{formatMessage("no_bill_message")}</p>
          </div>
        )}
      </div>
      <SlidingPanel
        type={"right"}
        isOpen={openPanel}
        noBackdrop={false}
        size={100}
      >
        <div className="bg_slide panel">
          <div className="row bb p-2 pl-4">
            <div className="col-lg-11">
              <h2 className="fw-bold mb-0 mt-2">
                <FormattedMessage id={"billing_details"} />
              </h2>
            </div>
            <div className="col-lg-1">
              <svg
                onClick={closePanelhandler}
                role="button"
                xlinkTitle="close"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x float-right"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </div>
          </div>
          <BillingDetails id={selectedId} />
        </div>
      </SlidingPanel>
    </React.Fragment>
  );
};

export default FilterPopupHOC(AddPopupHOC(observer(Billing)));
