/*    
<summary>
   This class component is all about Managing user data functionality.
   Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import base64 from 'base-64';
import { initialState } from '../initial-state/add-edit-user-state';
import { initialState as GetAllUserState } from '../initial-state/get-all-user-state';
import { action, computed, makeObservable, observable } from 'mobx';
import { AuthStore } from './auth-store';
import { IUserState } from '../../models/state/IUserState';
import { ICommonState } from '../../models/state/ICommonState';
import IApiResponse, { IApiSuccessResponse } from '../../models/response/IApiResponse';
import { IUserListVM, IUserVM, IUserList } from '../../models/response/IUserResponse';
import { IAddUser } from '../../models/Form/IAddUser';
import { IObservableInitialState } from '../../models/ICommon';
import URLConstants from '../../constants/url.constants';
import userTypeEnum from '../../constants/user-type-enum';
import * as baseService from '../service/base-service';
import { formatMessage } from '../../translations/format-message';
import toast from 'react-hot-toast';
import { IUserOTPRM } from '../../models/response/IAuthResponse';
import { IUpdateLoggedInUserPassword, IUpdatePassword } from '../../models/Form/IChangePassword';

const authStore = new AuthStore();

export class UserStore implements IUserState, ICommonState {
    inProgress = false;
    error = '';
    userList: IUserListVM = GetAllUserState

    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }

    addUpdateUserState ={...this.initialStateValue }
    deleteUserState ={...this.initialStateValue }

    activeInactiveState ={...this.initialStateValue }

    user: IUserVM | undefined = undefined;
    userState ={...this.initialStateValue }
    
    changePasswordState ={...this.initialStateValue }
    isUserExistsState = {...this.initialStateValue}

    isUserExists:boolean = false;

    isOTPVerifiedState = {...this.initialStateValue}
    isOTPVerified:boolean | undefined = false;
    oneTimePassword: number | undefined = 0;

    isPasswordUpdatedState = {...this.initialStateValue}

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            userList: observable,
            user: observable,
            addUpdateUserState: observable,
            deleteUserState: observable,
            activeInactiveState: observable,
            changePasswordState: observable,
            userState: observable,
            isUserExists: observable,
            isUserExistsState:observable,
            isOTPVerifiedState:observable,
            isOTPVerified: observable,
            oneTimePassword: observable,
            isPasswordUpdatedState: observable,
            GetUserListService: action,
            AddUserService: action,
            UpdateUserService: action,
            UpdateUserStatusService: action,
            DeleteUserService: action,
            GetUserByIdService: action,
            UpdateLoggedInUserPasswordService: action,
            UpdatePasswordService: action,
            reset: action,
            resetStore: action,
            resetUserDetail: action,
            resetActiveInactiveState: action,
            resetAddUpdateUserState: action,
            resetChangePassword: action,
            VerifyOTPPasswordResetService:action,
            VerifyUserPasswordResetService:action,
            UpdateUserPasswordByOTPService:action,
            allUser: computed,
            userDetail: computed,
        });
    }

    /**
     * This function is used to Get All Users List data with pagination information by calling an API.
     * @param currentPage : Current Page Number
     * @param pagerSize : Page Size
     * @returns 
     */
    GetUserListService = (currentPage: number, pagerSize: number) => {
        this.inProgress = true;
        const url = URLConstants.GetUserList + "?PageNo=" + currentPage + "&PageSize=" + pagerSize;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IUserListVM>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.userList = response.data.Data;
                }
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

   /**
    * This function is used to map userList to allUser List suitable for Grid Component.
    * @returns allUsersList
    */
    get allUser(): IUserList[] {
        if (this.userList?.Users && this.userList.Users?.length > 0)
            return this.userList.Users.map((user) => {
                let isDelete = true;
                let isEdit = true;
                let isChangePassword = true;
                return {
                    Id: user.Id,
                    UserType: user.UserType,
                    Email: user.Email,
                    UserName: user.UserName,
                    isActive: user.IsActive,
                    isDelete: isDelete,
                    isEdit: isEdit,
                    isChangePassword: isChangePassword,
                    CreatedBy: user.CreatedBy,
                    UpdatedBy: user.UpdatedBy,
                }
            })
        return [];
    }

   /**
    * This function is used to Get User Details by Id by calling an API.
    * @param id : The User Identifier
    * @returns 
    */
    GetUserByIdService = (id: number) => {
        this.userState.inProgress = true;
        let url = `${URLConstants.User}/${id}`;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IUserVM>>) => {
                if (response.data.Error) {
                    this.userState.error = response.data.Message;
                }
                else {
                    let data = response.data.Data;
                    this.user = data;
                }
            })
            .catch((err: string) => {
                this.userState.error = err;
            }).finally(action(() => { this.userState.inProgress = false; }));
    }
    
   /**
    * This function provides initail values to the Add User Form. 
    * @returns User Details
    */
   get userDetail() {
        if (this.user) {
            return {
                UserType: Object.keys(userTypeEnum).indexOf(this.user.UserType),
                Email: this.user.Email,
                IsActive: this.user.IsActive,
                UserName: this.user.UserName,
            }
        }
        return initialState;
    }
    
    /*
    This function is used to reset all user observables to their initial values.  
    */
    resetUserDetail = () => {
        this.user = undefined;
        this.userState = {...this.initialStateValue}
    }

   /**
    * This function is used to Add New User by calling an API.
    * @param data : User Data
    * @returns 
    */
    AddUserService = (data: IAddUser) => {
        this.addUpdateUserState.inProgress = true;
        let url = URLConstants.User;
        let userData : any = {
            UserType: Number(data.UserType),
            Email: data.Email.trim(),
            UserName: data.UserName.trim(),
            IsActive: data.IsActive,
            Password: base64.encode(data.Password!),
            ConfirmPassword: base64.encode(data.Password!),
        }
        return baseService.postRequest(url, userData)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addUpdateUserState.error = response.data.Message;
                }
                else this.addUpdateUserState.success = true;
            })
            .catch((err: string) => {
                this.addUpdateUserState.error = err;
            })
            .finally(action(() => { this.addUpdateUserState.inProgress = false; }));
    }

    /**
     * This function is used to Updating Existing User Details by calling an API.
     * @param id : The User Identifier
     * @param data 
     * @returns 
     */
    UpdateUserService = (id: number, data: IAddUser) => {
        this.addUpdateUserState.inProgress = true;
        let url = URLConstants.User;
        let userData: any = {
                Id: id,
                UserType: Number(data.UserType),
                Email: data.Email.trim(),
                UserName: data.UserName.trim(),
                IsActive: data.IsActive
            };
        return baseService.putRequest(url, userData)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addUpdateUserState.error = response.data.Message;
                }
                else this.addUpdateUserState.success = true;
            })
            .catch((err: string) => {
                this.addUpdateUserState.error = err;
            })
            .finally(action(() => { this.addUpdateUserState.inProgress = false; }));

    }
    
    /*
    This function is used to reset all AddUser observables to their initial values.  
    */
    resetAddUpdateUserState = () => {
        this.addUpdateUserState.inProgress = false;
        this.addUpdateUserState.success = false;
        this.addUpdateUserState.error = '';
    }

   /**
    * This function is used to Delete User by calling an API.
    * @param id : The User Identifier
    * @returns 
    */
    DeleteUserService = (id: number) => {
        this.deleteUserState.inProgress = true;
        let url = `${URLConstants.User}/${id}`;
        return baseService.deleteRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.deleteUserState.error = response.data.Message;
                    // toast.error(formatMessage(response.data.Message));
                }
                else this.deleteUserState.success = true;
            })
            .catch((err: string) => {
                this.deleteUserState.error = err;
            }).finally(action(() => { this.deleteUserState.inProgress = false; }));
    }

    /**
    * This function is used to Updating Existing User state (i.e. active/inactive) Details by calling an API.
    * @param id : The User Identifier
    * @param state: User state
    * @returns 
    */
    UpdateUserStatusService = (id: number, state: boolean) => {
        this.activeInactiveState.inProgress = true;
        let url = URLConstants.UserStatus;
        let userData: any = {
                Id: id,
                IsActive: state
        };
        return baseService.putRequest(url, userData)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.activeInactiveState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.activeInactiveState.success = true;
            })
            .catch((err: string) => {
                this.activeInactiveState.error = err;
            })
            .finally(action(() => { this.activeInactiveState.inProgress = false; }));

    }

    /*
    This function is used to reset all activeInactiveState observables to their initial values.  
    */
    resetActiveInactiveState = () => {
        this.activeInactiveState = {...this.initialStateValue}
    }

   /**
    * This function is used to Change Logged in User Password by calling an API & sending new Password details. 
    * @param data : Password data
    * @returns 
    */
    UpdateLoggedInUserPasswordService = ( data: IUpdateLoggedInUserPassword) => {
        this.changePasswordState.inProgress = true;
        let url = URLConstants.UpdateUserPassword;
        return baseService.putRequest(url, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.changePasswordState.error = response.data.Message;
                }
                else this.changePasswordState.success = true;
            })
            .catch((err: any) => {
                this.changePasswordState.error = err;
            }).finally(action(() => { this.changePasswordState.inProgress = false; }));
    }
    
   /**
    * This function is used by Tenant to update its user password calling an APi & sending the password details
    * @param data : New Password Details
    * @returns 
    */
    UpdatePasswordService = (data: IUpdatePassword) => {
        this.changePasswordState.inProgress = true;
        let url: string = URLConstants.UpdatePassword;
        return baseService.putRequest(url, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.changePasswordState.error = response.data.Message;
                }
                else this.changePasswordState.success = true;
            })
            .catch((err: any) => {
                this.changePasswordState.error = err;
            }).finally(action(() => { this.changePasswordState.inProgress = false; }));
    }

    /*
    This function is used to reset all changePassword observables to their initial values.  
    */
    resetChangePassword = () => {
        this.changePasswordState = {...this.initialStateValue};
    }

   /**
    * This function is used to check if the user already Exists or not by calling an API.
    * @param email : Login ID
    * @returns 
    */
    VerifyUserPasswordResetService = (email: string) => {
        this.isUserExistsState.inProgress = true;
        let data = { Email:email }
        let url = URLConstants.UserExists;
        return baseService.postRequest(url, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    toast.error(formatMessage(response.data.Message));
                    this.isUserExistsState.error = response.data.Message;
                }
                else {
                    this.isUserExists = response.data.Data;
                    this.isUserExistsState.success = true;
                }
            })
            .catch((err: string) => {
                this.isUserExistsState.error = err;
            })
            .finally(action(() => { this.isUserExistsState.inProgress = false; }));
    }

    /*
    This function is used to reset all UserCheck observables to their initial values.  
    */
    resetIsUserCheck = () => {
        this.isUserExistsState = {...this.initialStateValue};
    }

   /**
    * This function is used to verify the received OTP to reset Password by calling an API & sending email & otp.
    * @param email : User Email / Login ID
    * @param otp : OTP received on Email
    * @returns 
    */
    VerifyOTPPasswordResetService = (email: string, otp:number) => {
        this.isOTPVerifiedState.inProgress = true;
        let data = { Email:email, OneTimePassword: otp };
        let url = URLConstants.VerifyOTP;
        return baseService.postRequest(url, data)
            .then((response: IApiResponse<IApiSuccessResponse<IUserOTPRM>>) => {
                if (response.data.Error) {
                    this.isOTPVerifiedState.error = response.data.Message;
                }
                else {
                    this.isOTPVerified = response.data?.Data.IsOTPVerified;
                    this.oneTimePassword = response.data?.Data.OTP;
                    this.isOTPVerifiedState.success = true;
                }
            })
            .catch((err: string) => {
                this.isOTPVerifiedState.error = err;
            })
            .finally(action(() => { this.isOTPVerifiedState.inProgress = false; }));
    }
    
    /*
    This function is used to reset isOTPVerifiedState observable to their initial values.  
    */
    resetIsOTOVerified = () => {
        this.isOTPVerifiedState = {...this.initialStateValue};
    }

   /**
    * This function reset existing password with the new password by calling an API. 
    * @param data : password reset details
    * @returns 
    */
    UpdateUserPasswordByOTPService = (data:any) => {
        this.isPasswordUpdatedState.inProgress = true;
        let url = URLConstants.PasswordReset;
        return baseService.putRequest(url, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    toast.error(formatMessage(response.data.Message));
                    this.isPasswordUpdatedState.error = response.data.Message;
                }
                else {
                    this.isPasswordUpdatedState.success = true;
                }
            })
            .catch((err: string) => {
                this.isPasswordUpdatedState.error = err;
            })
            .finally(action(() => { this.isPasswordUpdatedState.inProgress = false; }));
    }
    
    /*
    This function is used to reset all observables to their initial values.  
    */
    reset = () => {
        this.error = '';
        this.inProgress = false;
        this.addUpdateUserState.success = false;
        this.addUpdateUserState.error = '';
        this.deleteUserState = {...this.initialStateValue}
        this.addUpdateUserState.inProgress = false;
        this.userState = {...this.initialStateValue}
        this.changePasswordState = {...this.initialStateValue}
        this.isUserExistsState = {...this.initialStateValue}
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.userList = GetAllUserState

        this.addUpdateUserState ={...this.initialStateValue }
        this.deleteUserState ={...this.initialStateValue }

        this.activeInactiveState ={...this.initialStateValue }

        this.user = undefined;
        this.userState ={...this.initialStateValue }
        
        this.changePasswordState ={...this.initialStateValue }
        this.isUserExistsState = {...this.initialStateValue}
        this.isOTPVerifiedState = {...this.initialStateValue}
        this.isPasswordUpdatedState = {...this.initialStateValue}
        this.isUserExists = false;
        this.isOTPVerified = false;
        this.oneTimePassword = 0;
    }

}

export default new UserStore();
