/*    
<summary>
   This file is used to provide initial values or state to the AddDeviceForm.
   Developer:Ashish Singh, Created Date: 03-April-2023
</summary>
*/
import { IGroupStats } from "../../models/response/IGroupResponse";

export const initialState: IGroupStats = {
    Id: 0,
    Name: '',
    Description: '',
    ConfigurationProtocol: {
        Id: 0,
        Name: "",
        Description: "",
        Groups: [],
        SourceProtocol: -1,
        DestinationProtocol: -1,
        IsStoreInDb: true
    },
    TotalDevices: 0,
    ActiveDevices: 0,
    InActiveDevices: 0,
    IsBlocked: false,
    IsLocked: false,
    IsDefault: false,
}
