import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../contexts/StoreProvider";
import DeviceFilterForm from "./forms/device-filter-form";
import { IOption } from "../../../models/ICommon";
import { DeviceFilter, IDataList } from "../../../models/response/IDeviceResponse";
import { Modal } from "react-bootstrap";

interface IProps {
  initialValues: DeviceFilter;
  submitHandler: (values: DeviceFilter) => void;
  modalClosed: () => void;
  groupOptions: IOption[];
  isFilterActive: boolean;
  allDevicesName:IDataList[];
  allDevicesIccid:IDataList[];
  allDevicesTags:IDataList[];
}

const DeviceFilterState = (props: any) => {
  const { groupStore } = useStore();
  const { GetAllGroupService, allAvailableGroupsFilter } = groupStore;

  useEffect(() => {
    GetAllGroupService();
  }, []);

  const onModalClosed = () => {
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <Modal
        className="correction-modal"
        show={true}
        centered={false}
        size="sm"
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="filter" />
          </Modal.Title>
          <button
            onClick={onModalClosed}
            data-testid="Close"
            className="Crossicons"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <div className="p-0">
          <DeviceFilterForm
            initialValues={props.initialValues}
            isFilterActive={props.isFilterActive}
            groupOptions={allAvailableGroupsFilter}
            submitHandler={props.submitHandler}
            modalClosed={props.modalClosed}
            allDevicesName={props.allDevicesName}
            allDevicesIccid={props.allDevicesIccid}
            allDevicesTags={props.allDevicesTags}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default observer(DeviceFilterState);
