/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   This enum is used to differentiate user types in the overall application.
   Developer: Aashish Singh, Created Date: 29-August-2023 
</summary>
*/
enum userTypeEnum{
    SuperAdministrator= "SuperAdministrator",
    TenantAdministrator = "TenantAdministrator",
    General = "General",
    ReadOnly= "ReadOnly"
}

export default userTypeEnum