import { observer } from "mobx-react";
import React from "react";
import { useStore } from "../../../contexts/StoreProvider";
import IAddDeviceBulk from "../../../models/Form/IAddDeviceInBulk";
import Lottie from "react-lottie";
import monozlogo from "../../../assets/Images/monoZ_Black.png";
import monoZlogo from "../../../assets/Images/Lottie/monoZLink.json";
import ForgetPasswordAuthForm from "../form/forget-password-auth-form";
import { FormattedMessage } from "react-intl";

const ForgetPassword = (props: any) => {
  const { deviceStore, groupStore } = useStore();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: monoZlogo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  /* Returning JSX */
  return (
    <React.Fragment>
      <div className="bg-img1 text-left background">
        <div className="login_wrapper">
          <img src={monozlogo} alt="mono_z_Logo" className="LightSmallLogo" />

          <Lottie options={defaultOptions} height={230} width={790} />
        </div>
        <ForgetPasswordAuthForm />
      </div>
    </React.Fragment>
  );
};

export default observer(ForgetPassword);
