/*    
<summary>
   This class component is all about Managing groups.
   Developer: Aashish Singh, Created Date: 10-April-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { action, computed, makeObservable, observable } from "mobx";
import { ICommonState } from "../../models/state/ICommonState";
import * as baseService from '../service/base-service';
import URLConstants from "../../constants/url.constants";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { IGroupListVM, IGroupList, IGroupStats, IGroupVM } from "../../models/response/IGroupResponse";
import { IGroupState } from "../../models/state/IGroupState";
import toast, { Toaster } from "react-hot-toast";
import { initialState as allGroupsInitialState } from "../initial-state/get-all-groups-state";
import { initialState as addGroupInitialState } from "../initial-state/add-group-state";
import { initialState as groupStatsState } from "../initial-state/get-group-stats-state";
import { formatMessage } from "../../translations/format-message";
import IAddGroup, { IUpdateGroup } from "../../models/Form/IAddGroup";
import { IAwsIoTCoreGroupOption, IOption } from "../../models/ICommon";
import config from "../../helpers/config-helper";
import moment from "moment";
import { emptyValue } from "../../constants/common-constants";

const appConfig = config();
const dateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT;

export class GroupStore implements IGroupState, ICommonState {
    inProgress = false;
    error = '';
    GroupList: IGroupListVM = allGroupsInitialState;
    allGroups: Array<IGroupVM> = [];
    
    initialStateValue = {
        success: false,
        error: '',
        inProgress: false
    }

    allBlockedGroups: Array<IGroupVM> = [];
    allBlockedGroupState = {...this.initialStateValue}

    selectedGroupId = 1;
    addUpdateGroupstate = { ...this.initialStateValue }
    deleteGroupState = { ...this.initialStateValue }
    groupDetailState = { ...this.initialStateValue }
    groupStatsState = { ...this.initialStateValue }
    groupDetail: IGroupVM | undefined = undefined;
    groupStats: any = undefined;
    IsAssociatedState = {...this.initialStateValue}
    IsAssociated : boolean | undefined = undefined;

    unblockGroupState ={...this.initialStateValue }

    updateDefaultGroupState = {...this.initialStateValue}
    updateGroupIMEILockState = {...this.initialStateValue}
    defaultGroup = -1;

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            GroupList: observable,
            groupDetail: observable,
            groupStats: observable,
            groupDetailState: observable,
            addUpdateGroupstate: observable,
            deleteGroupState: observable,
            groupStatsState: observable,
            IsAssociatedState: observable,
            IsAssociated: observable,
            allGroups: observable,
            allBlockedGroups: observable,
            allBlockedGroupState: observable,
            unblockGroupState: observable,
            updateDefaultGroupState: observable,
            defaultGroup: observable,
            updateGroupIMEILockState: observable,
            AddGroupService: action,
            UpdateGroupService: action,
            updateDefaultGroup: action,
            GetAllGroupService: action,
            GetBlockedGroupsService: action,
            GetGroupInfoByIdService: action,
            GetGroupListService: action,
            GetGroupByIdService: action,
            UnblockGroupService: action,
            UpdateGroupIMEILockStatusService: action,
            GetGroupAssociatedInfoService: action,
            DeleteGroupService:action,
            reset: action,
            resetStore: action,
            resetAddUpdateGroup: action,
            resetGetgroupDetail: action,
            resetIsAssociatedState: action,
            resetUnblockGroupState: action,
            resetBlockedGroupState: action,
            resetUpdateDefaultGroupState: action,
            resetUpdateGroupIMEILockState: action,
            groupInfoId: computed,
            groupIdDetail: computed,
            allGroupList: computed,
            allBlockedGroupList: computed,
            allAvailableGroups: computed,
            allAvailableGroupsAwsIotCore: computed
        });
    }

    /**
     * This function is used to Get Group list data from API by paging details.
     * @returns
     */
    GetGroupListService = (currentPage: number, pagerSize: number) => {
        this.inProgress = true;
        const url = URLConstants.GetAllGroupsPaging + "?PageNo=" + currentPage + "&PageSize=" + pagerSize;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IGroupListVM>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else
                    this.GroupList = response.data.Data;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false; }));
    }

    /**
     * This function provides all Group option values used in select group in Device Module.
     * @returns Group List Oprtions
     */
    get allGroupList(): IGroupList[] {
        if (this.GroupList && this.GroupList?.Groups.length > 0)
            return this.GroupList?.Groups.map((group) => {
                return {
                    Id: group?.Id,
                    Name: group?.Name,
                    Description: group?.Description,
                    ConfigurationProtocol: group?.ConfigurationProtocol,
                    IsBlocked: group?.IsBlocked,
                    IsDefault: group?.IsDefault,
                    IsLocked: group?.IsLocked,
                    GroupEvent: group?.GroupEvent,
                    BlockedTimestamp: group?.GroupEvent ? moment(group?.GroupEvent.EventTimestamp).format(dateTimeFormat) : emptyValue,
                    isDelete: true,
                    isEdit: true,
                    isData: true,
                }
            })
        return [];
    }

    /**
     * This function is used to Get All Group data from API.
     * @returns
     */
    GetAllGroupService = () => {
        this.inProgress = true;
        const url = URLConstants.GetAllGroups;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<Array<IGroupVM>>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else
                {
                    this.allGroups = response.data.Data;
                    let defaultGroup = this.allGroups.filter(item => item.IsDefault == true);
                    this.defaultGroup = defaultGroup.length > 0 ? defaultGroup[0].Id : -1;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false; }));
    }

    /**
    * This function is used to map available group List for Add Device Component.
    * @returns Initial Group Details
    */
    get allAvailableGroups(): IOption[] {
        const groupOptions: IOption[] = [{
            id: -1,
            value: "please_select",
            disabled: false
        }];
        if (this.allGroups && this.allGroups?.length > 0)
            this.allGroups.map((group) => {
                groupOptions.push({
                    id: group.Id,
                    value: group.Name + (group.IsDefault ? " (" + formatMessage("default") + ")": ""),
                    disabled: group.IsBlocked,
                    protocol: group?.ConfigurationProtocol?.DestinationProtocol
                })
            })
        return groupOptions;
    }

    /**
    * This function is used to map available group List for Add Device Component with AwsIoTCore Configuration.
    * @returns Initial Group Details
    */
        get allAvailableGroupsAwsIotCore(): IAwsIoTCoreGroupOption[] {
            const groupOptions: IAwsIoTCoreGroupOption[] = [{
                id: -1,
                value: "please_select",
                disabled: false,
                protocol: 0
            }];
            if (this.allGroups && this.allGroups?.length > 0)
                this.allGroups.map((group) => {
                    groupOptions.push({
                        id: group.Id,
                        value: group.Name + (group.IsDefault ? " (" + formatMessage("default") + ")": ""),
                        disabled: group.IsBlocked,
                        protocol: group?.ConfigurationProtocol?.DestinationProtocol
                    })
                })
            return groupOptions;
        }

    /**
    * This function is used to map available group List for Filters Device Component.
    * @returns Initial Group Details
    */
    get allAvailableGroupsFilter(): IOption[] {
        const groupOptions: IOption[] = [{
            id: 0,
            value: "All",
            disabled: false
        }];
        if (this.allGroups && this.allGroups?.length > 0)
            this.allGroups.map((group) => {
                groupOptions.push({
                    id: group.Id,
                    value: group.Name + (group.IsDefault ? " (" + formatMessage("default") + ")": ""),
                    disabled: group.IsBlocked
                })
            })

        return groupOptions;
    }

    /**
     * This function is used to Get All Group data from API.
     * @returns
     */
    GetBlockedGroupsService = () => {
        this.allBlockedGroupState.inProgress = true;
        const url = URLConstants.GetAllBlockedGroups;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<Array<IGroupVM>>>) => {
                if (response.data.Error) {
                    this.allBlockedGroupState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else
                    this.allBlockedGroups = response.data.Data;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.allBlockedGroupState.inProgress = false; }));
    }

    /**
     * This function provides all blocked Group list.
     * @returns Blocked Group List
     */
    get allBlockedGroupList(): IGroupList[] {
        if (this.allBlockedGroups && this.allBlockedGroups?.length > 0)
            return this.allBlockedGroups.map((group) => {
                return {
                    Id: group?.Id,
                    Name: group?.Name,
                    Description: group?.Description,
                    ConfigurationProtocol: group?.ConfigurationProtocol,
                    IsBlocked: group?.IsBlocked,
                    IsDefault: group?.IsDefault,
                    IsLocked: group?.IsLocked,
                    GroupEvent: group?.GroupEvent,
                    BlockedTimestamp: group?.GroupEvent ? moment(group?.GroupEvent.EventTimestamp).format(dateTimeFormat) : emptyValue,
                    isDelete: true,
                    isEdit: true,
                    isData: true,
                }
            })
        return [];
    }

    /**
     * This function is used to reset allBlockedGroupState observables to their initial values.
     * @returns
     */
    resetBlockedGroupState = () => {
        this.allBlockedGroupState = { ...this.initialStateValue };
    }

    /**
    * This function is used to Get Group Data Details by Id from API.
    * @param id : Group Identifier
    * @returns
    */
    GetGroupByIdService = (id: number) => {
        this.groupDetailState.inProgress = true;
        const url = URLConstants.GetGroupById + "/" + id;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IGroupVM>>) => {
                if (response.data.Error) {
                    this.groupDetailState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.groupDetail = response.data.Data;
                    this.groupDetailState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.groupDetailState.inProgress = false }));
    }

    /**
    * This function provides initail values to the Group By Id Component.
    * @returns Initial Group By Id Details
    */
    get groupIdDetail(): IAddGroup {
        if (this.groupDetail)
            return {
                Name: this.groupDetail?.Name,
                Description: this.groupDetail?.Description,
                ConfigurationProtocolId: this.groupDetail?.ConfigurationProtocol?.Id,
                ConfigurationProtocol: this.groupDetail?.ConfigurationProtocol,
                IsDefault: this.groupDetail?.IsDefault,
                IsLocked: this.groupDetail?.IsLocked,
            };
        return addGroupInitialState;
    }

    /**
    * This function is used to reset getGroupDetail observables to their initial values.
    * @returns
    */
    resetGetgroupDetail = () => {
        this.groupDetail = undefined;
        this.groupDetailState = { ...this.initialStateValue }
    }

    /**
    * This function is used to Get Group Stats by Id from API.
    * @param id : Group Identifier
    * @returns
    */
    GetGroupInfoByIdService = (id: number) => {
        this.groupStatsState.inProgress = true;
        const url = URLConstants.GetGroupInfo + "/" + id;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IGroupStats>>) => {
                if (response.data.Error) {
                    this.groupStatsState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.groupStats = response.data.Data;
                    this.groupStatsState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.groupStatsState.inProgress = false }));
    }

    /**
    * This function provides initail values to the Group stats Component.
    * @returns Initial Group Stats Details
    */
    get groupInfoId(): IGroupStats {
        if (this.groupStats)
            return {
                Id: this?.groupStats?.Id,
                Name: this?.groupStats?.Name,
                Description: this?.groupStats?.Description,
                ConfigurationProtocol: this?.groupStats?.ConfigurationProtocol,
                TotalDevices: this?.groupStats?.TotalDevices,
                ActiveDevices: this?.groupStats?.ActiveDevices,
                InActiveDevices: this?.groupStats?.InActiveDevices,
                IsBlocked: this?.groupStats?.IsBlocked,
                IsDefault: this?.groupStats?.IsDefault,
                IsLocked: this?.groupStats?.IsLocked,
                GroupEvent: this?.groupStats?.GroupEvent,
                BlockedTimestamp: this?.groupStats?.GroupEvent ? moment(this?.groupStats?.GroupEvent.EventTimestamp).format(dateTimeFormat) : emptyValue,
            }
        return groupStatsState;
    }

    /**
     * This function is used to Get group info info wheather device is associated or not from API.
     * @param id : Group Identifier
     * @returns
     */
    GetGroupAssociatedInfoService = (id: number) => {
        this.IsAssociatedState.inProgress = true;
        const url = URLConstants.GetGroupDeviceAssociatedInfo + "/" + id;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.IsAssociatedState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.IsAssociated = response.data.Data;
                    this.IsAssociatedState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.IsAssociatedState.inProgress = false }));
    }

    /**
     * This function is used to reset IsAssociatedState observables to their initial values.
     * @returns
     */
    resetIsAssociatedState = () => {
        this.IsAssociatedState = { ...this.initialStateValue }
        this.IsAssociated = undefined;
    }

    /**
    * This function is used to Add New Group. 
    * @param Group : Group Details
    * @returns
    */
    AddGroupService = (group: IAddGroup) => {
        this.addUpdateGroupstate.inProgress = true;
        return baseService.postRequest(URLConstants.AddGroup, group)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addUpdateGroupstate.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.addUpdateGroupstate.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.addUpdateGroupstate.inProgress = false; }));
    }

    /**
     * This function is used to update existing Group details. 
     * @param id : Group identifier
     * @param group : Group Details
     * @returns
     */
    UpdateGroupService = (id: number, group: IAddGroup) => {
        let data: IUpdateGroup = {
            Id: id,
            ...group
        }
        this.addUpdateGroupstate.inProgress = true;
        return baseService.putRequest(URLConstants.UpdateGroup, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addUpdateGroupstate.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.addUpdateGroupstate.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.addUpdateGroupstate.inProgress = false; }));
    }
    
    /**
    * This function is used to reset addUpdateGroup observables to their initial values.
    * @returns
    */
    resetAddUpdateGroup = () => {
        this.addUpdateGroupstate = { ...this.initialStateValue }
    }

    /**
     * This function is used to update default Group. 
     * @param id : Group identifier
     * @returns
     */
    updateDefaultGroup = (groupId: number) => {
        this.updateDefaultGroupState.inProgress = true;
        let data = {
            Id : groupId
        }
        let url = URLConstants.UpdateDefaultGroup;
        return baseService.putRequest(url, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.updateDefaultGroupState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else{
                    toast.success(formatMessage(response.data.Message));
                    this.updateDefaultGroupState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.updateDefaultGroupState.inProgress = false; }));
    }

    /**
     * This function is used to reset updateDefaultGroupState observables to their initial values.
     * @returns
     */
    resetUpdateDefaultGroupState = () => {
        this.updateDefaultGroupState = { ...this.initialStateValue };
    }

    /**
    * This function is used to delete existing group. 
    * @param id : Group identifier
    * @returns
    */
    DeleteGroupService = (id: number) => {
        this.deleteGroupState.inProgress = true;
        const url = URLConstants.DeleteGroup + "/" + id;
        return baseService.deleteRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.deleteGroupState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.deleteGroupState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.deleteGroupState.inProgress = false; }));
    }

    /*
    This function is used to unblock Existing Group.  
    */

    UnblockGroupService = (id: number) => {
        this.unblockGroupState.inProgress = true;
        let url = URLConstants.GroupUnblock;
        let data: any = {
                Id: id
        };
        return baseService.putRequest(url, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.unblockGroupState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.unblockGroupState.success = true;
            })
            .catch((err: string) => {
                this.unblockGroupState.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.unblockGroupState.inProgress = false; }));

    }

    /**
     * This function is used to reset unblockGroupState observables to their initial values.
     * @returns
     */
    resetUnblockGroupState = () => {
        this.unblockGroupState = { ...this.initialStateValue };
    }

    /*
    This function is used to update Sim IMEI Lock status by ICCID from API.  
    */

    UpdateGroupIMEILockStatusService = (id: number, imei_lock:boolean) => {
        this.updateGroupIMEILockState.inProgress = true;
        let url = URLConstants.UpdateGroupIMEILockStatus;
        let request = {
            Id:id,
            IsLocked: imei_lock
        }
        return baseService.putRequest(url, request)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.updateGroupIMEILockState.error = response.data.Message;
                }
                else {
                    let data = response.data.Data;
                    this.updateGroupIMEILockState.success = true;
                }
            })
            .catch((err: string) => {
                this.updateGroupIMEILockState.error = err;
            }).finally(action(() => { this.updateGroupIMEILockState.inProgress = false; }));
    }

    /**
     * This function is used to reset updateGroupIMEILockState observables to their initial values.
     * @returns
     */
    resetUpdateGroupIMEILockState = () => {
        this.updateGroupIMEILockState = {...this.initialStateValue};
    }

    /**
     * This function is used to reset observables to their initial values.
     * @returns
     */
    reset = () => {
        this.error = '';
        this.inProgress = false;
        this.deleteGroupState = {
            ...this.initialStateValue
        };
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.GroupList = allGroupsInitialState;
        this.allGroups = [];
        this.selectedGroupId = 1;
        this.addUpdateGroupstate = { ...this.initialStateValue }
        this.deleteGroupState = { ...this.initialStateValue }
        this.groupDetailState = { ...this.initialStateValue }
        this.groupStatsState = { ...this.initialStateValue }
        this.groupDetail = undefined;
        this.groupStats = undefined;
        this.IsAssociatedState = {...this.initialStateValue}
        this.IsAssociated = undefined;
        this.unblockGroupState = {...this.initialStateValue}
    }
}


export default new GroupStore();