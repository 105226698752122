/*    
<summary>
   This class component is all about Tenant Management System.
   Developer: Mohammad Saquib Khan, Created Date: 17-August-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import { action, computed, makeObservable, observable } from 'mobx';
import { ICommonState } from '../../models/state/ICommonState';
import { ITenantState } from '../../models/state/ITenantState';
import IAddTenant, { IUpdateTenant } from '../../models/Form/IAddUpdateTenant';
import toast from 'react-hot-toast';
import * as baseService from '../service/base-service';
import { formatMessage } from '../../translations/format-message';
import IApiResponse, { IApiSuccessResponse } from '../../models/response/IApiResponse';
import URLConstants from '../../constants/url.constants';
import { ITenantListVM, ITenantVM, ITenantCSV, ITenantList } from '../../models/response/ITenantResponse';
import { initialState as addTenantInitialState } from "../initial-state/add-tenant-state";
import { initialState as allTenantInitialState } from "../initial-state/get-all-tenant-state";
import base64 from 'base-64';
import { IOption } from '../../models/ICommon';
import { defaultCountryCurrencyId } from '../../constants/common-constants';
import moment from 'moment';
import config from '../../helpers/config-helper';

const appConfig = config();
const dateFormat =  appConfig.REACT_APP_DATE_FORMAT;
const fileNameDateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT_FILENAME;

export class TenantStore implements ITenantState, ICommonState {
    inProgress = false;
    error = '';

    initialStateValue = {
        success: false,
        error: '',
        inProgress: false
    }

    tenantDataList: ITenantListVM = allTenantInitialState;
    allTenants: ITenantVM[] = [];

    tenant: any = undefined;
    addUpdateTenantState = { ...this.initialStateValue };
    addTrialTenantState = { ...this.initialStateValue };
    deleteTenantState = { ...this.initialStateValue };
    tenantState = { ...this.initialStateValue };
    changePasswordState ={...this.initialStateValue };

    activeInactiveState ={...this.initialStateValue }

    dashboardTenantDetail: any = undefined;

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,

            tenantDataList: observable,
            allTenants: observable,
            tenant: observable,
            dashboardTenantDetail: observable,
            addUpdateTenantState: observable,
            deleteTenantState: observable,
            tenantState: observable,
            changePasswordState: observable,
            activeInactiveState: observable,
            addTrialTenantState:observable,

            GetTenantListService: action,
            GetAllTenantsService: action,
            AddTenantService: action,
            AddTrialTenant: action,
            UpdateTenantService: action,
            DeleteTenantService: action,
            resetChangePassword:action,
            UpdatePasswordService: action,
            UpdateTenantStatusService: action,
            exportAllTenantsService:action,

            reset: action,
            resetStore: action,
            resetAddUpdateTenant: action,
            resetGetTenantDetail: action,
            resetActiveInactiveState: action,
            GetTenantService: action,
            GetDashboardTenantByIdService: action,
            resetAddTrialTenantState:action,

            allTenantlist: computed,
            allTenantOptions: computed,
            dashboardTenantInfo:computed,
            tenantDetails:computed,
        });
    }

   /**
    * This function is used to get tenants list with pagination by calling API.
    * @param pageNumber : Page Number
    * @param pageSize : Page Size
    * @returns 
    */
    GetTenantListService = (pageNumber: number, pageSize: number) => {
        this.inProgress = true;
        const url = URLConstants.Tenant + "/" + "List/" + "?PageNo=" + pageNumber + "&PageSize=" + pageSize;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<ITenantListVM>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.tenantDataList = response.data.Data;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false }));
    }

    /**
    * This function is used to map deviceDataList to allTenantslist suitable for Grid Component.
    * @returns Initial Tenant Details
    */
    get allTenantlist(): ITenantList[] {
        if (this.tenantDataList && this.tenantDataList?.Tenants.length > 0)
            return this.tenantDataList?.Tenants.map((tenant) => {
                return {
                    Id: tenant.Id,
                    UserId: tenant.UserId,
                    TenantName: tenant.TenantName,
                    Email: tenant.Email,
                    OrganizationId: tenant.OrganizationId,
                    OrganizationName: tenant.OrganizationName,
                    ContactPhoneNumber: tenant.ContactPhoneNumber,
                    ContactEmail: tenant.ContactEmail,
                    ContactPersonName: tenant.ContactPersonName,
                    Address: tenant.Address,
                    MaxUserAllow: tenant.MaxUserAllow,
                    MaxDeviceAllow: tenant.MaxDeviceAllow,
                    IsActive: tenant.IsActive,
                    IsDataPakcetsStoreInDB: tenant.IsDataPakcetsStoreInDB,
                    ApiKey: tenant?.ApiKey,
                    ApiKeySecretValue: tenant?.ApiKeySecretValue,
                    IsTrialTenant: tenant.IsTrialTenant,
                    CreatedBy: tenant.CreatedBy,
                    UpdatedBy: tenant.UpdatedBy,
                    isDelete: true,
                    isEdit: true,
                    isData: true,
                }
            })
        return [];
    }

   /**
    * This function is used to get All Tenants without pagination by calling an API.
    * @returns 
    */
    GetAllTenantsService = () => {
        this.inProgress = true;
        const url = URLConstants.Tenant + "/" + "All";
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<ITenantVM[]>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.allTenants = response.data.Data;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false }));
    }

    /**
    * This function is used to map allTenants to allTenantOptions suitable for FormikFormSelect Component.
    * @returns Tenant Options List containing values & id
    */
    get allTenantOptions(): IOption[] {
        const options: IOption[] = [{
            id: -1,
            value: "please_select",
        }];
        if (this.allTenants && this.allTenants.length > 0)
            this.allTenants.map((tenant) => {
                options.push({
                    id: tenant.Id,
                    value: tenant.TenantName,
                })
            })

        return options;
    }

    /**
     * This function is used to Get Tenant Details by calling an API.
     * @param tenantId : Tenant Identifier
     * @param userId : User Identifier
     * @returns
     */
    GetTenantService = (tenantId: number, userId: number) => {
        this.tenantState.inProgress = true;
        const url = URLConstants.Tenant + "?TenantId=" + tenantId + "&UserId=" + userId;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<ITenantVM>>) => {
                if (response.data.Error) {
                    this.tenantState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.tenant = response.data.Data;
                    this.tenantState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.tenantState.inProgress = false }));
    }

    /**
    * This function provides initail values to the Add Update Device Form.
    * @returns Initial Device Details
    */
    get tenantDetails(): IAddTenant {
        if (this.tenant)
            return {
                TenantName: this.tenant.TenantName,
                Email: this.tenant.Email,
                Password: this.tenant.Password,
                OrganizationName: this.tenant.OrganizationName,
                OrganizationId: this.tenant.OrganizationId,
                ContactPhoneNumber: this.tenant.ContactPhoneNumber,
                ContactEmail: this.tenant.ContactEmail,
                ContactPersonName: this.tenant.ContactPersonName,
                Address: this.tenant.Address,
                MaxUserAllow: this.tenant.MaxUserAllow,
                MaxDeviceAllow: this.tenant.MaxDeviceAllow,
                MaxMessageLimit: this.tenant.MaxMessageLimit,
                IsActive: this.tenant.IsActive,
                IsDataPakcetsStoreInDB: this.tenant.IsDataPakcetsStoreInDB,
                SendThresholdAlert: this.tenant.SendThresholdAlert,
                UserId: this.tenant.UserId,
                ApiKey: this.tenant.ApiKey,
                ApiKeySecretValue: this.tenant.ApiKeySecretValue,
            };
        return addTenantInitialState;
    }

    /**
    * This function is used to reset getDeviceDetail observables to their initial values.
    * @returns
    */
    resetGetTenantDetail = () => {
        this.tenant = undefined;
        this.tenantState = { ...this.initialStateValue }
    }

    /**
     * This function is used to Get tenant Details by Id from API.
     * @param tenantId : Tenant Identifier
     * @param userId : User Identifier
     * @returns
     */
    GetDashboardTenantByIdService = (tenantId: number, userId: number) => {
        this.tenantState.inProgress = true;
        const url = URLConstants.Tenant + "?TenantId=" + tenantId + "&UserId=" + userId;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<ITenantVM>>) => {
                if (response.data.Error) {
                    this.tenantState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    let data = response.data.Data;
                    this.dashboardTenantDetail = data
                    localStorage.setItem("IsTrialTenant", (data.IsTrialTenant!).toString());
                    this.tenantState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.tenantState.inProgress = false }));
    }

    /**
  * This function provides values to the dashboard.
  * @returns Initial Tenant Details
  */
    get dashboardTenantInfo(): IAddTenant {
        if (this.dashboardTenantDetail)
            return {
                TenantName: this.dashboardTenantDetail.TenantName,
                Email: this.dashboardTenantDetail.Email,
                Password: this.dashboardTenantDetail.Password,
                OrganizationName: this.dashboardTenantDetail.OrganizationName,
                OrganizationId: this.dashboardTenantDetail.OrganizationId,
                ContactPhoneNumber: this.dashboardTenantDetail.ContactPhoneNumber,
                ContactEmail: this.dashboardTenantDetail.ContactEmail,
                ContactPersonName: this.dashboardTenantDetail.ContactPersonName,
                Address: this.dashboardTenantDetail.Address,
                MaxUserAllow: this.dashboardTenantDetail.MaxUserAllow,
                MaxDeviceAllow: this.dashboardTenantDetail.MaxDeviceAllow,
                MaxMessageLimit: this.dashboardTenantDetail.MaxMessageLimit,
                IsActive: this.dashboardTenantDetail.IsActive,
                IsDataPakcetsStoreInDB: this.dashboardTenantDetail.IsDataPakcetsStoreInDB,
                SendThresholdAlert: this.dashboardTenantDetail.SendThresholdAlert,
                UserId: this.dashboardTenantDetail.UserId,
                ApiKey: this.dashboardTenantDetail.ApiKey,
                ApiKeySecretValue: this.dashboardTenantDetail.ApiKeySecretValue,
                IsTrialTenant:this.dashboardTenantDetail.IsTrialTenant,
                CreatedDate: this.dashboardTenantDetail.CreatedDate
            };
        return addTenantInitialState;
    }


    /**
    * This function is used to Add New Tenant by calling an API & sending the required tenant details. 
    * @param tenant : Tenant Details
    * @returns
    */
    AddTenantService = (tenant: IAddTenant) => {
        var obj = {
            TenantName: tenant.TenantName,
            Password: base64.encode(tenant.Password),
            Email: tenant.Email,
            OrganizationId: tenant.OrganizationId,
            OrganizationName: tenant.OrganizationName,
            ContactPersonName: tenant.ContactPersonName,
            ContactEmail: tenant.ContactEmail,
            ContactPhoneNumber: tenant.ContactPhoneNumber,
            Address: tenant.Address,
            MaxUserAllow: tenant.MaxUserAllow,
            MaxDeviceAllow: tenant.MaxDeviceAllow,
            IsActive: tenant.IsActive,
            MaxMessageLimit: tenant.MaxMessageLimit,
            SendThresholdAlert: tenant.SendThresholdAlert,
            IsDataPakcetsStoreInDB: tenant.IsDataPakcetsStoreInDB,
            TenantCharge: {
                TenantId: Number(tenant.TenantCharge?.TenantId),
                RegistrationCharges: Number(tenant.TenantCharge?.RegistrationCharges),
                PacketCharges: Number(tenant.TenantCharge?.PacketCharges),
                TenancyCharges: Number(tenant.TenantCharge?.TenancyCharges),
                Taxes: Number(tenant.TenantCharge?.Taxes),
                CountryCurrencyId: tenant.TenantCharge?.CountryCurrencyId,
            }
        }
        this.addUpdateTenantState.inProgress = true;
        return baseService.postRequest(URLConstants.Tenant, obj)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addUpdateTenantState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.addUpdateTenantState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.addUpdateTenantState.inProgress = false; }));
    }

    /**
     * This function is used to reset addUpdateDevice observables to their initial values.
     * @returns
     */
    resetAddUpdateTenant = () => {
        this.addUpdateTenantState = { ...this.initialStateValue }
    }

    /**
    * This function is used to Add New Trial Tenant by calling an API & sending trail tenant details. 
    * @param tenant : Trial Tenant Details
    * @returns
    */
    AddTrialTenant = (tenant: IAddTenant) => {
        var obj = {
            TenantName: tenant.TenantName,
            Password: base64.encode(tenant.Password),
            Email: tenant.Email,
            OrganizationId: tenant.OrganizationId,
            OrganizationName: tenant.OrganizationName,
            ContactPersonName: tenant.ContactPersonName,
            ContactEmail: tenant.ContactEmail,
            ContactPhoneNumber: tenant.ContactPhoneNumber,
            Address: tenant.Address,
            MaxUserAllow: tenant.MaxUserAllow,
            MaxDeviceAllow: tenant.MaxDeviceAllow,
            MaxMessageLimit: tenant.MaxMessageLimit,
            SendThresholdAlert: tenant.SendThresholdAlert,
            IsActive: tenant.IsActive,
            IsDataPakcetsStoreInDB: tenant.IsDataPakcetsStoreInDB,
            TenantCharge: {
                TenantId: Number(tenant.TenantCharge?.TenantId),
                RegistrationCharges: Number(tenant.TenantCharge?.RegistrationCharges),
                PacketCharges: Number(tenant.TenantCharge?.PacketCharges),
                TenancyCharges: Number(tenant.TenantCharge?.TenancyCharges),
                Taxes: Number(tenant.TenantCharge?.Taxes),
                CountryCurrencyId: defaultCountryCurrencyId,
            }
        }
        this.addTrialTenantState.inProgress = true;
        return baseService.postRequest(URLConstants.TrialTenant, obj)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addTrialTenantState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.addTrialTenantState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.addTrialTenantState.inProgress = false; }));
    }

    /**
     * This function is used to reset addTrialTenantState observables to their initial values.
     * @returns
     */
    resetAddTrialTenantState = () => {
        this.addTrialTenantState = {... this.initialStateValue}
    }

    /**
    * This function is used to update existing tenant by calling an API & sending updated tenant details. 
    * @param id : Tenant identifier
    * @param tenant : Tenant Details
    * @returns
    */
    UpdateTenantService = (id: number, tenant: any) => {
        let data: IUpdateTenant = {
            Id: id,
            ...tenant
        }
        this.addUpdateTenantState.inProgress = true;
        return baseService.putRequest(URLConstants.Tenant, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addUpdateTenantState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.addUpdateTenantState.success = true;
            })
            .catch((err: string) => {
                if (err.includes(":")) {
                    let errorMess = err.split(":");
                    toast.error(errorMess[0] + " : " + formatMessage(errorMess[1]));
                } else { toast.error(formatMessage(err)); }
            })
            .finally(action(() => { this.addUpdateTenantState.inProgress = false; }));
    }

    /**
    * This function is used to delete existing tenant by calling an API. 
    * @param id : Tenant identifier
    * @returns
    */
    DeleteTenantService = (id: number) => {
        this.deleteTenantState.inProgress = true;
        const url = URLConstants.Tenant + "/" + id;
        return baseService.deleteRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.deleteTenantState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.deleteTenantState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.deleteTenantState.inProgress = false; }));
    }

    /**
     * This function is used to Change Tenant Password by SuperAdmin calling an API.
     * @param data : New Password Details
     * @returns 
     */
    UpdatePasswordService = (data: any) => {
        this.changePasswordState.inProgress = true;
        let url: string = URLConstants.UpdateTenantPassword;
        return baseService.putRequest(url, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.changePasswordState.error = response.data.Message;
                    // toast.error(formatMessage(response.data.Message));
                }
                else this.changePasswordState.success = true;
            })
            .catch((err: any) => {
                this.changePasswordState.error = err;
            }).finally(action(() => { this.changePasswordState.inProgress = false; }));
    }

    /*
    This function is used to reset all changePassword observables to their initial values.  
    */
    resetChangePassword = () => {
        this.changePasswordState = {...this.initialStateValue};
    }

    /*
      
    */
   /**
    * This function is used to Updating Existing tenant state (i.e. active/inactive) Details by calling an API.
    * @param id : The Tenant Identifier
    * @param state : State (true/false for active/inactive) 
    * @returns 
    */
    UpdateTenantStatusService = (id: number, state: boolean) => {
        this.activeInactiveState.inProgress = true;
        let requestData: any = {
            Id: id,
            IsActive: state
        };
        let url = URLConstants.UpdateTenantStatus;
        return baseService.putRequest(url, requestData)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.activeInactiveState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.activeInactiveState.success = true;
            })
            .catch((err: string) => {
                this.activeInactiveState.error = err;
            })
            .finally(action(() => { this.activeInactiveState.inProgress = false; }));

    }

     /*
    This function is used to reset all activeInactiveState observables to their initial values.  
    */
    resetActiveInactiveState = () => {
        this.activeInactiveState = {...this.initialStateValue}
    }

   /**
    * This function is used to get All Tenants list without pagination from API.
    * @returns 
    */
    exportAllTenantsService = () => {
        this.inProgress = true;
        const url = URLConstants.Tenant + "/" + "All";
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<ITenantVM[]>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.downloadTenantCSV(response.data.Data);
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false }));
    }

    /**
     * This function is used to convert tenant list data to the corresponding CSV format
     * @param data : Tenant List data
     * @returns csvData suitable for creating blob object
     */
    convertToCSV = (data: any[]): string => {
        const header = Object.keys(data[0]).join(",");
        const rows = data.map((obj) => Object.values(obj).join(","));
        return `${header}\n${rows.join("\n")}`;
    };
    
    /**
     * This function downloads the CSV by dynamically making an <a> tag. 
     * @param data : Tenant List data
     */
    downloadTenantCSV = (data: any[]) => {
        const currentDate = new Date().toLocaleString();
        const csvData = this.convertToCSV(this.exportTenantCSV(data));
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = moment(currentDate).format(fileNameDateTimeFormat) + " Tenants.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    /**
     * This function is used to convert the tenantDetailsList to the required data suitable for convertToCSV function.
     * @param tenantDataList : Tenant List
     * @returns CSV data suitable for convertToCSV function
     */
    exportTenantCSV(tenantDataList:any): ITenantCSV[] {
        if (tenantDataList && tenantDataList?.length > 0)
            return tenantDataList?.map((tenant:any) => {
                return {
                    TenantName: tenant.TenantName,
                    Email: tenant.Email,
                    OrganizationId: tenant.OrganizationId,
                    OrganizationName: tenant.OrganizationName,
                    ContactPhoneNumber: tenant.ContactPhoneNumber,
                    ContactEmail: tenant.ContactEmail,
                    ContactPersonName: tenant.ContactPersonName,
                    MaxUserAllow: tenant.MaxUserAllow,
                    MaxDeviceAllow: tenant.MaxDeviceAllow,
                    IsActive: tenant.IsActive,
                    IsTrialTenant:tenant.IsTrialTenant,
                    CreatedDate: moment(tenant.CreatedDate).format(dateFormat),
                }
            })
        return [];
    }

    /**
     * This function is used to reset observables to their initial values.
     * @returns
     */
    reset = () => {
        this.error = '';
        this.inProgress = false;
        this.deleteTenantState = {
            ...this.initialStateValue
        };
        this.changePasswordState = {...this.initialStateValue}
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.tenantDataList = allTenantInitialState;

        this.tenant = undefined;
        this.addUpdateTenantState = { ...this.initialStateValue };
        this.addTrialTenantState = {...this.initialStateValue}
        this.deleteTenantState = { ...this.initialStateValue };
        this.tenantState = { ...this.initialStateValue };
        this.changePasswordState ={...this.initialStateValue };

        this.activeInactiveState ={...this.initialStateValue };
        this.dashboardTenantDetail = undefined;
    }
}

export default new TenantStore();
