import "./forget-password-auth-form.css";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useStore } from "../../../contexts/StoreProvider";
import { Field, Form, Formik } from "formik";
import Label from "../../../shared-components/Label/Label";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import {
  UserChangePasswordValidateSchema,
  forgetPasswordAuthSchema,
  forgetPasswordOTPSchema,
} from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import FormikFormOTPInput from "../../../shared-components/FormikFormHandlers/FormikFormOTPInput";
import { formatMessage } from "../../../translations/format-message";
import toast from "react-hot-toast";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import base64 from "base-64";
import RoutesConstants from "../../../shared-components/Routes/routes.constants";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const ForgetPasswordAuthForm = (props: any) => {
  const history = useHistory();
  const { userStore } = useStore();
  const {
    VerifyUserPasswordResetService,
    isUserExists,
    isOTPVerifiedState,
    isOTPVerified,
    VerifyOTPPasswordResetService,
    oneTimePassword,
    UpdateUserPasswordByOTPService,
    isPasswordUpdatedState,
    isUserExistsState,
    resetIsUserCheck,
    resetIsOTOVerified,
    resetStore,
  } = userStore;
  const [showHidePasswordNew, setShowHidePasswordNew] = useState(false);
  const [showHidePasswordConfirm, setShowHidePasswordConfirm] = useState(false);
  const [email, setEmail] = useState("");
  const [d1, setD1] = useState("");
  const [d2, setD2] = useState("");
  const [d3, setD3] = useState("");
  const [d4, setD4] = useState("");

  const initialValues = {
    Email: "",
  };

  const initialValuesOTP = {
    Email: isUserExists == false ? "" : email,
  };

  const initialValuesNewPass = {
    Digit1: isOTPVerified == true ? d1 : "",
    Digit2: isOTPVerified == true ? d2 : "",
    Digit3: isOTPVerified == true ? d3 : "",
    Digit4: isOTPVerified == true ? d4 : "",
    NewPassword: "",
    ConfirmPassword: "",
  };

  const submitEmailHandler = (values: any) => {
    VerifyUserPasswordResetService(values.Email);
  };

  const submitOTPHandler = (values: any) => {
    let temp = { ...values };
    var otp = temp.Digit1 + temp.Digit2 + temp.Digit3 + temp.Digit4;
    setD1(temp.Digit1);
    setD2(temp.Digit2);
    setD3(temp.Digit3);
    setD4(temp.Digit4);
    VerifyOTPPasswordResetService(email, otp);
  };

  const submitNewPass = (values: any) => {
    let temp = { ...values };
    let data = {
      Email: email,
      OneTimePassword: oneTimePassword,
      NewPassword: base64.encode(temp.ConfirmPassword),
    };
    UpdateUserPasswordByOTPService(data);
  };

  useEffect(() => {
    if (isPasswordUpdatedState.error) {
      toast.error(formatMessage("password_update_failed"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPasswordUpdatedState.error]);

  useEffect(() => {
    if (isPasswordUpdatedState.success) {
      toast.success(formatMessage("password_update_success"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPasswordUpdatedState.success]);

  useEffect(() => {
    if (isUserExistsState.success) {
      resetIsUserCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserExistsState.success]);

  useEffect(() => {
    if (isUserExistsState.error) {
      toast.error(formatMessage("email_not_found"));
      resetIsUserCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserExistsState.error]);  

  useEffect(() => {
    if (isOTPVerifiedState.error) {
      toast.error(formatMessage("invalid_otp"));
      resetIsOTOVerified();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOTPVerifiedState.error]);

  useEffect(() => {
    if (isPasswordUpdatedState.success) {
      resetStore();
      history.push(RoutesConstants.Login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPasswordUpdatedState.success]);
  
  const handleInput = (event:any, nextInput:any) => {
    const inputLength = event.target.value.length;

    if (inputLength === 1 && nextInput) {
      document.getElementById(nextInput)?.focus();
    }
  }

  /* Returning JSX */
  return (
    <React.Fragment>
      {isUserExistsState.inProgress && (
        <FormLoader loading={isUserExistsState.inProgress} />
      )}

      {isOTPVerifiedState.inProgress && (
        <FormLoader loading={isOTPVerifiedState.inProgress} />
      )}

      {isPasswordUpdatedState.inProgress && (
        <FormLoader loading={isPasswordUpdatedState.inProgress} />
      )}
      {isUserExists == false ? (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={forgetPasswordAuthSchema}
            validateOnBlur={false}
            onSubmit={async (values) => {
              setEmail(values.Email);
              submitEmailHandler(values);
            }}
          >
            <Form className="login">
              <div className="display d-flex justify-content-between align-items-center">
                <h1 className="forgetPassHeading login_heading">
                  <FormattedMessage id={"reset_password_heading"} />
                </h1>
              </div>

              <div className="form-group mb-1 mt-3 row">
                <Label
                  className="label-style col-sm-12 col-form-label"
                  required={true}
                  label="user_label_login_id"
                />
                <div className="col-sm-12">
                  <Field
                    data-testid="testEmail"
                    className="form-control"
                    name="Email"
                    type="text"
                    disabled={isUserExists}
                    invalidcharacter={[" "]}
                    validcharacter={[
                      "-",
                      "_",
                      "[",
                      "]",
                      "(",
                      ")",
                      ":",
                      "#",
                      "@",
                      ".",
                    ]}
                    placeholder="user_label_login_id"
                    as={FormikFormInput}
                  />
                </div>
              </div>

              <LoaderButton
                type="submit"
                id="LoginButton"
                className="btn btn-primary btn-user"
                isLoading={props.isLoading}
                text="verify"
              />
              <p
                className="mt-3 back_to_login"
                onClick={() => {
                  resetStore();
                  history.push(RoutesConstants.Login);
                }}
              >
                <FormattedMessage id={"back_to_login"} />
              </p>
            </Form>
          </Formik>
        </>
      ) : (
        ""
      )}

      {isUserExists && isUserExists == true && isOTPVerified == false ? (
        <Formik
          initialValues={initialValuesOTP}
          validationSchema={forgetPasswordOTPSchema}
          validateOnBlur={false}
          onSubmit={async (values) => {
            submitOTPHandler(values);
          }}
        >
          <Form className="login">
            <div className="display d-flex justify-content-between align-items-center">
              <h1 className="forgetPassHeading login_heading">
                <FormattedMessage id={"reset_password_heading"} />
              </h1>
            </div>
            <div className="form-group mb-1 mt-3 row">
              <Label
                className="label-style col-sm-12 col-form-label"
                required={true}
                label="user_label_login_id"
              />
              <div className="col-sm-12 d-flex">
                <Field
                  data-testid="testEmail"
                  className="form-control"
                  name="Email"
                  type="text"
                  disabled={isUserExists}
                  invalidcharacter={[" "]}
                  validcharacter={[
                    "-",
                    "_",
                    "[",
                    "]",
                    "(",
                    ")",
                    ":",
                    "#",
                    "@",
                    ".",
                  ]}
                  placeholder="user_label_login_id"
                  as={FormikFormInput}
                />
                {isUserExists && isUserExists == true ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="#00D85C"
                    className="bi bi-check-circle-fill mx-2 my-1 mt-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="form-group mb-1 row">
              <Label
                className="label-style col-sm-12 col-form-label"
                required={true}
                label="otp"
              />
              <div className="col-sm-12 d-flex">
                <Field
                  testid="input1"
                  className="form-control otp mx-1 fw-bold"
                  name="Digit1"
                  type="number"
                  onInput={(e:any) => handleInput(e, 'input2')}
                  as={FormikFormOTPInput}
                />
                <Field
                  testid="input2"
                  className="form-control otp mx-1 fw-bold"
                  name="Digit2"
                  type="number"
                  onInput={(e:any) => handleInput(e, 'input3')}
                  as={FormikFormOTPInput}
                />
                <Field
                  testid="input3"
                  className="form-control otp mx-1 fw-bold"
                  name="Digit3"
                  type="number"
                  onInput={(e:any) => handleInput(e, 'input4')}
                  as={FormikFormOTPInput}
                />
                <Field
                  testid="input4"
                  className="form-control otp mx-1 fw-bold"
                  name="Digit4"
                  type="number"
                  onInput={(e:any) => handleInput(e, null)}
                  as={FormikFormOTPInput}
                />
              </div>
            </div>
            <LoaderButton
              type="submit"
              id="LoginButton"
              className="btn btn-primary btn-user"
              isLoading={props.isLoading}
              text="verify_otp"
            />
            <p
              className="mt-3 back_to_login"
              onClick={() => {
                resetStore();
                history.push(RoutesConstants.Login);
              }}
            >
              <FormattedMessage id={"back_to_login"} />
            </p>
          </Form>
        </Formik>
      ) : (
        ""
      )}

      {isOTPVerified &&
      isOTPVerified == true &&
      isPasswordUpdatedState.success == false ? (
        <Formik
          initialValues={initialValuesNewPass}
          validationSchema={UserChangePasswordValidateSchema}
          validateOnBlur={false}
          onSubmit={async (values) => {
            submitNewPass(values);
          }}
        >
          <Form className="login">
            <div className="display d-flex justify-content-between align-items-center">
              <h1 className="forgetPassHeading login_heading">
                <FormattedMessage id={"reset_password_heading"} />
              </h1>
            </div>
            <div className="form-group mt-2 row">
              <Label
                className="label-style col-sm-12 col-form-label"
                required={true}
                label="otp"
              />
              <div className="col-sm-12 d-flex">
                <Field
                  data-testid="digit1"
                  className="form-control otp mx-1 fw-bold"
                  name="Digit1"
                  type="number"
                  disabled="true"
                  as={FormikFormOTPInput}
                />
                <Field
                  data-testid="digit2"
                  className="form-control otp mx-1 fw-bold"
                  name="Digit2"
                  type="number"
                  disabled="true"
                  as={FormikFormOTPInput}
                />
                <Field
                  data-testid="digit3"
                  className="form-control otp mx-1 fw-bold"
                  name="Digit3"
                  type="number"
                  disabled="true"
                  as={FormikFormOTPInput}
                />
                <Field
                  data-testid="digit4"
                  className="form-control otp mx-1 fw-bold"
                  name="Digit4"
                  type="number"
                  disabled="true"
                  as={FormikFormOTPInput}
                />
                {isOTPVerified && isOTPVerified == true ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="#00D85C"
                    className="bi bi-check-circle-fill mx-2 my-1 mt-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="form-group row mt-1">
              <Label
                className="label-style col-sm-12 col-form-label"
                required={true}
                label="user_label_password"
              />

              <div className="col-sm-12">
                <Field
                  data-testid="testPassword"
                  className="form-control"
                  name="NewPassword"
                  type={showHidePasswordNew ? "text" : "password"}
                  placeholder="user_label_password"
                  invalidcharacter={[" "]}
                  validcharacter={[
                    "~",
                    "`",
                    "!",
                    "@",
                    "#",
                    "$",
                    "%",
                    "^",
                    "&",
                    "*",
                    "(",
                    ")",
                    "_",
                    "-",
                    "+",
                    "=",
                    "{",
                    "[",
                    "}",
                    "]",
                    "|",
                    "\\",
                    ":",
                    ";",
                    "'",
                    '"',
                    "<",
                    ",",
                    ">",
                    ".",
                    "?",
                    "/",
                  ]}
                  as={FormikFormInput}
                />
                {showHidePasswordNew ? (
                  <svg
                    type="button"
                    onClick={() => {
                      setShowHidePasswordNew(!showHidePasswordNew);
                    }}
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="showHidePassForget css-i6dzq1"
                  >
                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                    <circle cx="12" cy="12" r="3"></circle>
                  </svg>
                ) : (
                  <svg
                    type="button"
                    onClick={() => {
                      setShowHidePasswordNew(!showHidePasswordNew);
                    }}
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="showHidePassForget css-i6dzq1"
                  >
                    <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                    <line x1="1" y1="1" x2="23" y2="23"></line>
                  </svg>
                )}
              </div>
            </div>
            <div className="form-group row">
              <Label
                className="label-style col-sm-12 col-form-label pr-0"
                required={true}
                label="label_confirm_password"
              />
              <div className="col-sm-12">
                <Field
                  data-testid="testPassword"
                  className="form-control"
                  name="ConfirmPassword"
                  type={showHidePasswordConfirm ? "text" : "password"}
                  placeholder="label_confirm_password"
                  invalidcharacter={[" "]}
                  validcharacter={[
                    "~",
                    "`",
                    "!",
                    "@",
                    "#",
                    "$",
                    "%",
                    "^",
                    "&",
                    "*",
                    "(",
                    ")",
                    "_",
                    "-",
                    "+",
                    "=",
                    "{",
                    "[",
                    "}",
                    "]",
                    "|",
                    "\\",
                    ":",
                    ";",
                    "'",
                    '"',
                    "<",
                    ",",
                    ">",
                    ".",
                    "?",
                    "/",
                  ]}
                  as={FormikFormInput}
                />
                {showHidePasswordConfirm ? (
                  <svg
                    type="button"
                    onClick={() => {
                      setShowHidePasswordConfirm(!showHidePasswordConfirm);
                    }}
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="showHidePassForget css-i6dzq1"
                  >
                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                    <circle cx="12" cy="12" r="3"></circle>
                  </svg>
                ) : (
                  <svg
                    type="button"
                    onClick={() => {
                      setShowHidePasswordConfirm(!showHidePasswordConfirm);
                    }}
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="showHidePassForget css-i6dzq1"
                  >
                    <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                    <line x1="1" y1="1" x2="23" y2="23"></line>
                  </svg>
                )}
              </div>
            </div>
            <LoaderButton
              type="submit"
              id="LoginButton"
              className="btn btn-primary btn-user"
              isLoading={props.isLoading}
              text="submit"
            />
          </Form>
        </Formik>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default observer(ForgetPasswordAuthForm);
