/*    
<summary>
   This file is used to provide initial values or state to the Line Chart Module.
   Developer: Mohammad Saquib Khan, Created Date: 18-Jan-2024
</summary>
*/

import { ScriptableContext } from "chart.js";
import { formatMessage } from "../../translations/format-message";

export const initialState: any = {
	options: {
        scales: {
          y: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: 10,
          },
        },
        animation: false,
        responsive: true,
        elements: {
          line: {
            tension: 0.35,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          filler: {
            propagate: false,
          },
        },
    },
	data: {
        labels: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
        datasets: [

          {
            label: formatMessage("data_usage"),
            data: [0,0,0,0,0,0,0,0,0,0,0,0],
            fill: "start",
            strokeColor: "#00a04d",
            pointColor: "#fff",
            pointStrokeColor: "#00a04d",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "#00a04d",
            borderColor: "rgb(0, 160, 77)",
            backgroundColor: (context: ScriptableContext<"line">) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, "rgba(0, 160, 77,0.6)");
              gradient.addColorStop(1, "rgba(0, 160, 77,0.03)");
              return gradient;
            },
          },


        ],
	},
  };
