/*    
<summary>
   This class component is all about Managing 1NCE data.
   Developer: Aashish Singh, Created Date: 11-Sept-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import { action, computed, makeObservable, observable } from 'mobx';
import URLConstants from '../../constants/url.constants';
import * as baseService from '../service/base-service';
import { ICommonState } from '../../models/state/ICommonState';
import IApiResponse, { IApiSuccessResponse } from '../../models/response/IApiResponse';
import { IObservableInitialState } from '../../models/ICommon';
import moment from 'moment';
import { IOneNceState } from '../../models/state/IOneNceState';
import toast from 'react-hot-toast';
import { formatMessage } from '../../translations/format-message';
import config from '../../helpers/config-helper';
import { getPastDays, getPastMonths, getShortMonthName } from '../../constants/common-constants';

interface ErrorPresent
{flag: boolean, message:string}

const appConfig = config();
const dateFormat = appConfig.REACT_APP_DATE_FORMAT;

export class OneNceStore implements IOneNceState, ICommonState {
    inProgress = false;
    error = '';
    oneNcetoken = '';
    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }
    oneNceDeviceSimDetail: any = undefined;
    oneNceDeviceSimDetailState ={...this.initialStateValue }
    oneNceDeviceSimStatus: any = undefined;
    oneNceDeviceSimStatusState ={...this.initialStateValue }
    oneNceDeviceSimDataQuota: any = undefined;
    oneNceDeviceSimDataQuotaState ={...this.initialStateValue }
    updateIMEILockState = {...this.initialStateValue}
    oneNceDeviceResetSimState = {...this.initialStateValue}
    oneNceEnableSimState = {...this.initialStateValue}
    
    oneNceDeviceSimUsageState ={...this.initialStateValue }
    oneNceDeviceSimUsageDaily: any = undefined;
    oneNceDeviceSimUsageMonthly: any = undefined;
    
    oneNceDevicePacketsCountDaily: any = undefined;
    oneNceDevicePacketsCountMonthly: any = undefined;

    oneNceDeviceDLMessageCountDaily: any = undefined;
    oneNceDeviceDLMessageCountMonthly: any = undefined;
    
    deviceChartDetails = {
        rangeType: 0,
        chartType: 0
    }

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            oneNcetoken: observable,
            oneNceDeviceSimDetail: observable,
            oneNceDeviceSimDetailState: observable,
            oneNceDeviceSimUsageDaily: observable,
            oneNceDeviceSimUsageMonthly: observable,
            oneNceDevicePacketsCountDaily: observable,
            oneNceDevicePacketsCountMonthly: observable,
            oneNceDeviceDLMessageCountDaily: observable,
            oneNceDeviceDLMessageCountMonthly: observable,
            oneNceDeviceSimUsageState: observable,
            oneNceDeviceSimStatus: observable,
            oneNceDeviceSimStatusState: observable,
            oneNceDeviceSimDataQuota: observable,
            oneNceDeviceSimDataQuotaState: observable,
            updateIMEILockState: observable,
            oneNceDeviceResetSimState: observable,
            oneNceEnableSimState: observable,
            deviceChartDetails: observable,
            getOneNceDeviceSimDetailByICCIDService: action,
            getOneNceDeviceDataQuotaByICCIDService: action,
            GetDailySimUsagebByICCIDService: action,
            GetMonthlySimUsageByICCIDService: action,
            GetDeviceDailyPacketsCountByICCIDService: action,
            GetDeviceMonthlyPacketsCountByICCIDService: action,
            GetDeviceDLMessageCountByICCIDService: action,
            GetDeviceMonthlyDLMessageCountByICCIDService: action,
            getOneNceSimStatusByICCIDService: action,
            updateSimIMEILockByICCIDService: action,
            getOneNceTokenService: action,
            ResetSimConnectivityService: action,
            EnableDisableSimService: action,
            setDeviceChartDetails: action,
            reset: action,
            resetStore: action,
            resetOneNceDeviceSimDetailState: action,
            resetOneNceDeviceSimUsageState: action,
            resetOneNceDeviceSimStatusState: action,
            resetOneNceDeviceSimDataQuotaState: action,
            resetUpdateIMEILockState: action,
            resetOneNceResetSimState: action,
            resetOneNceEnableSimState: action,
            resetOneNceErrorState: action,
            chartSimUsageDaily: computed,
            chartSimUsageMonthly: computed,
            chartDevicePacketsCountDaily: computed,
            chartDevicePacketsCountMonthly: computed,
            chartDeviceDLMessageCountDaily: computed,
            chartDeviceDLMessageCountMonthly: computed,
            isErrorPresent: computed,
            simDataLoading: computed,
        });
    }

   /**
    * This function is used to Get Device sim Details by ICCID from API.
    * @param iccid : Device Identifier
    * @returns 
    */
    getOneNceDeviceSimDetailByICCIDService = (iccid: string) => {
        this.oneNceDeviceSimDetailState.inProgress = true;
        let url = URLConstants.OneNceDeviceSim + "/" + iccid;
        let requestTime = moment().format("ddd MMM DD YYYY HH:mm:ss");
        return baseService.postRequest(url, {Token: localStorage.getItem('1NCEtoken')})
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                if (response.data.Error) {
                    this.oneNceDeviceSimDetailState.error = response.data.Message;
                    this.oneNceDeviceSimDetail = undefined;
                }
                else {
                    let successTime = moment();
                    console.log("Api Path Url: {"+url+"}, <RequestTime:"+requestTime+"> , <SuccessResponseTime: "+moment().format("ddd MMM DD YYYY HH:mm:ss")+">, <TotalTimeTaken: "+ moment.utc((moment.duration(successTime.diff(requestTime))).asMilliseconds()).format("HH[h] mm[m] ss[s]"));
                    let data = response.data.Data;
                    this.oneNceDeviceSimDetail = data;
                    this.oneNceDeviceSimDetailState.success = true;
                }
            })
            .catch((err: string) => {
                let errorTime = moment();
                console.log("Api Path Url: {"+url+"}, <RequestTime:"+requestTime+"> , <ErrorResponseTime: "+moment().format("ddd MMM DD YYYY HH:mm:ss")+">, , <TotalTimeTaken: "+ moment.utc((moment.duration(errorTime.diff(requestTime))).asMilliseconds()).format("HH[h] mm[m] ss[s]"));
                this.oneNceDeviceSimDetailState.error = err;
                this.oneNceDeviceSimDetail = undefined;
            }).finally(action(() => { this.oneNceDeviceSimDetailState.inProgress = false; }));
    }

    /*
    This function is used to reset all oneNceDeviceSimDetail observables to their initial values.  
    */
    resetOneNceDeviceSimDetailState = () => {
        this.oneNceDeviceSimDetail = undefined;
        this.oneNceDeviceSimDetailState = {...this.initialStateValue};
    }

   /**
    * This function is used to Get Device data quota by ICCID from API.
    * @param iccid Device Identifier
    * @returns 
    */
    getOneNceDeviceDataQuotaByICCIDService = (iccid: string) => {
        this.oneNceDeviceSimDataQuotaState.inProgress = true;
        let url = URLConstants.OneNceDeviceSim + "/"+ iccid + "/DataQuota";
        let requestTime = moment().format("ddd MMM DD YYYY HH:mm:ss");
        return baseService.postRequest(url, { Token: localStorage.getItem('1NCEtoken')})
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                if (response.data.Error) {
                    this.oneNceDeviceSimDataQuotaState.error = response.data.Message;
                    this.oneNceDeviceSimDataQuota = undefined;
                }
                else {
                    let successTime = moment();
                    console.log("Api Path Url: {"+url+"}, <RequestTime:"+requestTime+"> , <SuccessResponseTime: "+moment().format("ddd MMM DD YYYY HH:mm:ss")+">, <TotalTimeTaken: "+ moment.utc((moment.duration(successTime.diff(requestTime))).asMilliseconds()).format("HH[h] mm[m] ss[s]"));
                    let data = response.data.Data;
                    this.oneNceDeviceSimDataQuota = data;
                    this.oneNceDeviceSimDataQuotaState.success = true;
                }
            })
            .catch((err: string) => {
                let errorTime = moment();
                console.log("Api Path Url: {"+url+"}, <RequestTime:"+requestTime+"> , <ErrorResponseTime: "+moment().format("ddd MMM DD YYYY HH:mm:ss")+">, , <TotalTimeTaken: "+ moment.utc((moment.duration(errorTime.diff(requestTime))).asMilliseconds()).format("HH[h] mm[m] ss[s]"));
                this.oneNceDeviceSimDataQuotaState.error = err;
                this.oneNceDeviceSimDataQuota = undefined;
            }).finally(action(() => { this.oneNceDeviceSimDataQuotaState.inProgress = false; }));
    }

    /*
    This function is used to reset all oneNceDeviceSimDataQuota observables to their initial values.  
    */
    resetOneNceDeviceSimDataQuotaState = () => {
        this.oneNceDeviceSimDataQuota = undefined;
        this.oneNceDeviceSimDataQuotaState = {...this.initialStateValue};
    }

   /**
    * This function is used to Get Daily Sim Usage by ICCID from API. 
    * @param iccid : Device Identifier
    * @param noOfDays : Total number of Days
    * @returns 
    */
    GetDailySimUsagebByICCIDService = (iccid: string, noOfDays:number) => {
        this.oneNceDeviceSimUsageState.inProgress = true;
        let url = URLConstants.OneNceSimUsage + "/" + iccid + "/Daily";
        let requestTime = moment().format("ddd MMM DD YYYY HH:mm:ss");
        let request = {
            Token: localStorage.getItem('1NCEtoken'),
            StartDate: moment(new Date()).subtract(noOfDays, "days").format(dateFormat),
            EndDate: moment(new Date()).format(dateFormat),
        }
        return baseService.postRequest(url, request)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                if (response.data.Error) {
                    this.oneNceDeviceSimUsageState.error = response.data.Message;
                    this.oneNceDeviceSimUsageDaily = undefined;
                }
                else {
                    let successTime = moment();
                    console.log("Api Path Url: {"+url+"}, <RequestTime:"+requestTime+"> , <SuccessResponseTime: "+moment().format("ddd MMM DD YYYY HH:mm:ss")+">, <TotalTimeTaken: "+ moment.utc((moment.duration(successTime.diff(requestTime))).asMilliseconds()).format("HH[h] mm[m] ss[s]"));
                    let data = response.data.Data;
                    this.oneNceDeviceSimUsageDaily = data;
                    this.oneNceDeviceSimUsageState.success = true;
                }
            })
            .catch((err: string) => {
                let errorTime = moment();
                console.log("Api Path Url: {"+url+"}, <RequestTime:"+requestTime+"> , <ErrorResponseTime: "+moment().format("ddd MMM DD YYYY HH:mm:ss")+">, , <TotalTimeTaken: "+ moment.utc((moment.duration(errorTime.diff(requestTime))).asMilliseconds()).format("HH[h] mm[m] ss[s]"));
                this.oneNceDeviceSimUsageState.error = err;
                this.oneNceDeviceSimUsageDaily = undefined;
            }).finally(action(() => { this.oneNceDeviceSimUsageState.inProgress = false; }));
    }

    /**
    * This function provides coumputed oneNceDeviceSimUsageDaily values suitable for plotting line chart. 
    * @returns chart points & totalVolumeDetails.
    */
    get chartSimUsageDaily(): {chartPoints:any, totalVolumeDetails:any} {
        let chartPoints:any=[
            {KPIName:"volume", dataPoints:[]},
            {KPIName:"volume_tx_chart", dataPoints:[]},
            {KPIName:"volume_rx_chart", dataPoints:[]},
        ]
        let totalVolumeDetails = {
            volume:"",
            volume_tx:"",
            volume_rx:"",
            maxValue:0,
        }
        let volumeData = {
            chartPoints,
            totalVolumeDetails
        }
        if (this.oneNceDeviceSimUsageDaily?.stats && this.oneNceDeviceSimUsageDaily.stats?.length > 0)
            this.oneNceDeviceSimUsageDaily.stats.map((stat:any,index:number) => {
                if(index != this.oneNceDeviceSimUsageDaily.stats?.length-1)
                {
                    chartPoints[0].dataPoints.push({x:new Date(stat.date), y:parseFloat(stat.data.volume)});
                    chartPoints[1].dataPoints.push({x:new Date(stat.date), y:parseFloat(stat.data.volume_tx)});
                    chartPoints[2].dataPoints.push({x:new Date(stat.date), y:parseFloat(stat.data.volume_rx)});    
                }
                else{
                    totalVolumeDetails.volume_tx = Number(stat.data.volume_tx).toFixed(6);
                    totalVolumeDetails.volume_rx = Number(stat.data.volume_rx).toFixed(6);
                    totalVolumeDetails.volume = Number(stat.data.volume).toFixed(6);
                    totalVolumeDetails.maxValue = parseFloat(stat.data.volume);
                }
            });
        return volumeData;
    }

   /**
    * This function is used to Get Monthly Sim Usage by ICCID from API.  
    * @param iccid : Device Identifier
    * @returns 
    */
    GetMonthlySimUsageByICCIDService = (iccid: string) => {
        this.oneNceDeviceSimUsageState.inProgress = true;
        let url = URLConstants.DeviceSimUsage + "/" + iccid + "/Monthly";
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                if (response.data.Error) {
                    this.oneNceDeviceSimUsageState.error = response.data.Message;
                    this.oneNceDeviceSimUsageMonthly = undefined;
                }
                else {
                    let data = response.data.Data;
                    this.oneNceDeviceSimUsageMonthly = data;
                    this.oneNceDeviceSimUsageState.success = true;
                }
            })
            .catch((err: string) => {
                this.oneNceDeviceSimUsageState.error = err;
                this.oneNceDeviceSimUsageMonthly = undefined;
            }).finally(action(() => { this.oneNceDeviceSimUsageState.inProgress = false; }));
    }

    /**
    * This function provides coumputed oneNceDeviceSimUsageMonthly values suitable for plotting line chart. 
    * @returns chart points & totalVolumeDetails.
    */
    get chartSimUsageMonthly(): {chartPoints:any, totalVolumeDetails:any} {
        let chartPoints:any=[
            {KPIName:"volume", dataPoints:[]},
            {KPIName:"volume_tx_chart", dataPoints:[]},
            {KPIName:"volume_rx_chart", dataPoints:[]},
        ]
        let totalVolumeDetails = {
            volume:"",
            volume_tx:"",
            volume_rx:"",
            maxValue:0
        }
        let volumeData = {
            chartPoints,
            totalVolumeDetails
        }
        if (this.oneNceDeviceSimUsageMonthly)
        {
            const currentDate = new Date();
            const pastMonths = getPastMonths(currentDate.getMonth() + 1, currentDate.getFullYear(), 12);
            let tempVolume = 0, tempVolumeTx=0, tempVolumeRx=0;
                for (let i = 0; i< pastMonths?.length; i++) {
                    if(pastMonths[i].length > 0) {
                        let month = pastMonths[i][1]; let year = pastMonths[i][0];
                        const stat = this.oneNceDeviceSimUsageMonthly.find((item:any) => item.Month == month && item.Year == year);
                        let monthLabel = getShortMonthName(pastMonths[i][1])+"'"+pastMonths[i][0].toString().slice(2,4);
                        if(stat)
                        {
                            chartPoints[0].dataPoints.push({label: monthLabel, y:parseFloat(stat.Volume)});
                            chartPoints[1].dataPoints.push({label: monthLabel, y:parseFloat(stat.VolumeTx)});
                            chartPoints[2].dataPoints.push({label: monthLabel, y:parseFloat(stat.VolumeRx)});
                            tempVolume += parseFloat(stat.Volume);
                            tempVolumeTx += parseFloat(stat.VolumeTx);
                            tempVolumeRx += parseFloat(stat.VolumeRx);
                        }
                        else{
                            chartPoints[0].dataPoints.push({label: monthLabel, y:0});
                            chartPoints[1].dataPoints.push({label: monthLabel, y:0});
                            chartPoints[2].dataPoints.push({label: monthLabel, y:0});
                        }
                    }
                }
                totalVolumeDetails.volume = Number(tempVolume).toFixed(6);
                totalVolumeDetails.volume_tx = Number(tempVolumeTx).toFixed(6);
                totalVolumeDetails.volume_rx = Number(tempVolumeRx).toFixed(6);
                totalVolumeDetails.maxValue = tempVolume;
        }
        return volumeData;
    }

   /**
    * This function is used to Get Daily Data Packets Count by ICCID from API.
    * @param iccid : Device Identifier
    * @returns 
    */
    GetDeviceDailyPacketsCountByICCIDService = (iccid: string) => {
        this.oneNceDeviceSimUsageState.inProgress = true;
        let url = URLConstants.Device + "/" + iccid + "/Daily/Packets/Count";
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                if (response.data.Error) {
                    this.oneNceDeviceSimUsageState.error = response.data.Message;
                    this.oneNceDevicePacketsCountDaily = undefined;
                }
                else {
                    let data = response.data.Data;
                    this.oneNceDevicePacketsCountDaily = data;
                    this.oneNceDeviceSimUsageState.success = true;
                }
            })
            .catch((err: string) => {
                this.oneNceDeviceSimUsageState.error = err;
                this.oneNceDevicePacketsCountDaily = undefined;
            }).finally(action(() => { this.oneNceDeviceSimUsageState.inProgress = false; }));
    }

    /**
    * This function provides coumputed oneNceDevicePacketsCountDaily values suitable for plotting line chart. 
    * @returns chart points.
    */
    get chartDevicePacketsCountDaily(): {chartPoints:any, maxValue: number} {
        let chartPoints:any=[
            {KPIName:"data_usage", dataPoints:[]},
        ]
        let maxValue: number = 0;
        if (this.oneNceDevicePacketsCountDaily){
            this.oneNceDevicePacketsCountDaily.forEach((packets:any) => {
                maxValue = packets.PacketCount > maxValue ? packets.PacketCount : maxValue;
                chartPoints[0].dataPoints.push(
                {
                    x: new Date(packets.Year, packets.Month-1, packets.Day),
                    y: packets.PacketCount
                });
            });
        }
        return {chartPoints, maxValue };
    }

   /**
    * This function is used to Get Monthly Data Packets Count by ICCID from API.
    * @param iccid : Device Identifier
    * @returns 
    */
    GetDeviceMonthlyPacketsCountByICCIDService = (iccid: string) => {
        this.oneNceDeviceSimUsageState.inProgress = true;
        let url = URLConstants.Device + "/" + iccid + "/Monthly/Packets/Count";
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                if (response.data.Error) {
                    this.oneNceDeviceSimUsageState.error = response.data.Message;
                    this.oneNceDevicePacketsCountMonthly = undefined;
                }
                else {
                    let data = response.data.Data;
                    this.oneNceDevicePacketsCountMonthly = data;
                    this.oneNceDeviceSimUsageState.success = true;
                }
            })
            .catch((err: string) => {
                this.oneNceDeviceSimUsageState.error = err;
                this.oneNceDevicePacketsCountMonthly = undefined;
            }).finally(action(() => { this.oneNceDeviceSimUsageState.inProgress = false; }));
    }

    /**
    * This function provides coumputed oneNceDevicePacketsCountMonthly values suitable for plotting line chart. 
    * @returns chart points.
    */
    get chartDevicePacketsCountMonthly(): {chartPoints:any, maxValue: number} {
        let chartPoints:any=[
            {KPIName:"data_usage", dataPoints:[]},
        ]
        
        let maxValue: number = 0;
        if (this.oneNceDevicePacketsCountMonthly)
        {
            const currentDate = new Date();
            const pastMonths = getPastMonths(currentDate.getMonth() + 1, currentDate.getFullYear(), 12);
                for (let i = 0; i< pastMonths?.length; i++) {
                    if(pastMonths[i].length > 0) {
                        let month = pastMonths[i][1]; let year = pastMonths[i][0];
                        const stat = this.oneNceDevicePacketsCountMonthly.find((item:any) => item.Month == month && item.Year == year);
                        let monthLabel = getShortMonthName(pastMonths[i][1])+"'"+pastMonths[i][0].toString().slice(2,4);
                        if(stat)
                        {
                            
                            maxValue = parseFloat(stat.TotalPacketCount) > maxValue ? parseFloat(stat.TotalPacketCount) : maxValue;
                            chartPoints[0].dataPoints.push({label: monthLabel, y:parseFloat(stat.TotalPacketCount)});
                        }
                        else{
                            chartPoints[0].dataPoints.push({label: monthLabel, y:0});
                        }
                    }
                }
        }
        return {chartPoints, maxValue};
    }

    /**
    * This function is used to Get Daily DL Message Count by ICCID from API.
    * @param iccid : Device Identifier
    * @returns 
    */
    GetDeviceDLMessageCountByICCIDService = (iccid: string) => {
        this.oneNceDeviceSimUsageState.inProgress = true;
        let url = URLConstants.Device + "/" + iccid + "/Daily/Published/Messages/Count";
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                if (response.data.Error) {
                    this.oneNceDeviceSimUsageState.error = response.data.Message;
                    this.oneNceDeviceDLMessageCountDaily = undefined;
                }
                else {
                    let data = response.data.Data;
                    this.oneNceDeviceDLMessageCountDaily = data;
                    this.oneNceDeviceSimUsageState.success = true;
                }
            })
            .catch((err: string) => {
                this.oneNceDeviceSimUsageState.error = err;
                this.oneNceDeviceDLMessageCountDaily = undefined;
            }).finally(action(() => { this.oneNceDeviceSimUsageState.inProgress = false; }));
    }

    /**
    * This function provides coumputed oneNceDeviceDLMessageCountDaily values suitable for plotting line chart. 
    * @returns chart points.
    */
    get chartDeviceDLMessageCountDaily(): {chartPoints:any, maxValue: number} {
        let chartPoints:any=[
            {KPIName:"data_usage", dataPoints:[]},
        ]
        let maxValue: number = 0;
        if (this.oneNceDeviceDLMessageCountDaily){
            this.oneNceDeviceDLMessageCountDaily.forEach((packets:any) => {
                maxValue = parseFloat(packets.MsgCount) > maxValue ? parseFloat(packets.MsgCount) : maxValue;
                chartPoints[0].dataPoints.push(
                {
                    x: new Date(packets.Year, packets.Month-1, packets.Day),
                    y: packets.MsgCount
                });
            });
        }
        return {chartPoints, maxValue};
    }    

    /**
    * This function is used to Get Monthly DL Message Count by ICCID from API.
    * @param iccid : Device Identifier
    * @returns 
    */
    GetDeviceMonthlyDLMessageCountByICCIDService = (iccid: string) => {
        this.oneNceDeviceSimUsageState.inProgress = true;
        let url = URLConstants.Device + "/" + iccid + "/Monthly/Published/Message/Count";
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                if (response.data.Error) {
                    this.oneNceDeviceSimUsageState.error = response.data.Message;
                    this.oneNceDeviceDLMessageCountMonthly = undefined;
                }
                else {
                    let data = response.data.Data;
                    this.oneNceDeviceDLMessageCountMonthly = data;
                    this.oneNceDeviceSimUsageState.success = true;
                }
            })
            .catch((err: string) => {
                this.oneNceDeviceSimUsageState.error = err;
                this.oneNceDeviceDLMessageCountMonthly = undefined;
            }).finally(action(() => { this.oneNceDeviceSimUsageState.inProgress = false; }));
    }

    /**
    * This function provides coumputed oneNceDeviceDLMessageCountMonthly values suitable for plotting line chart. 
    * @returns chart points.
    */
    get chartDeviceDLMessageCountMonthly(): {chartPoints:any, maxValue: number} {
        let chartPoints:any=[
            {KPIName:"data_usage", dataPoints:[]},
        ]
        let maxValue: number = 0;
        if (this.oneNceDeviceDLMessageCountMonthly)
        {
            const currentDate = new Date();
            const pastMonths = getPastMonths(currentDate.getMonth() + 1, currentDate.getFullYear(), 12);
                for (let i = 0; i< pastMonths?.length; i++) {
                    if(pastMonths[i].length > 0) {
                        let month = pastMonths[i][1]; let year = pastMonths[i][0];
                        const stat = this.oneNceDeviceDLMessageCountMonthly.find((item:any) => item.Month == month && item.Year == year);
                        let monthLabel = getShortMonthName(pastMonths[i][1])+"'"+pastMonths[i][0].toString().slice(2,4);
                        if(stat)
                        {
                            maxValue = parseFloat(stat.TotalMsgCount) > maxValue ? parseFloat(stat.TotalMsgCount) : maxValue;
                            chartPoints[0].dataPoints.push({label: monthLabel, y:parseFloat(stat.TotalMsgCount)});
                        }
                        else{
                            chartPoints[0].dataPoints.push({label: monthLabel, y:0});
                        }
                    }
                }
        }
        return {chartPoints, maxValue};
    }

    /*
    This function is used to reset all oneNceDeviceSimUsageDaily observables to their initial values.  
    */
    resetOneNceDeviceSimUsageState = () => {
        this.oneNceDeviceSimUsageDaily = undefined;
        this.oneNceDeviceSimUsageMonthly = undefined;
        this.oneNceDevicePacketsCountDaily = undefined;
        this.oneNceDevicePacketsCountMonthly = undefined;
        this.oneNceDeviceDLMessageCountDaily = undefined;
        this.oneNceDeviceDLMessageCountMonthly = undefined;
        this.oneNceDeviceSimUsageState = {...this.initialStateValue};
    }

    /**
     * This function is used to Get Sim Status by ICCID from API.
     * @param iccid : Device Identifier
     * @returns 
     */
    getOneNceSimStatusByICCIDService = (iccid: string) => {
        this.oneNceDeviceSimStatusState.inProgress = true;
        let url = URLConstants.OneNceSimStatus +"/" + iccid;
        let requestTime = moment().format("ddd MMM DD YYYY HH:mm:ss");
        return baseService.postRequest(url, {Token: localStorage.getItem('1NCEtoken')})
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                if (response.data.Error) {
                    this.oneNceDeviceSimStatusState.error = response.data.Message;
                    this.oneNceDeviceSimStatus = undefined;
                }
                else {
                    let successTime = moment();
                    console.log("Api Path Url: {"+url+"}, <RequestTime:"+requestTime+"> , <SuccessResponseTime: "+moment().format("ddd MMM DD YYYY HH:mm:ss")+">, <TotalTimeTaken: "+ moment.utc((moment.duration(successTime.diff(requestTime))).asMilliseconds()).format("HH[h] mm[m] ss[s]"));
                    let data = response.data.Data;
                    this.oneNceDeviceSimStatus = data;
                    this.oneNceDeviceSimStatusState.success = true;
                }
            })
            .catch((err: string) => {
                let errorTime = moment();
                console.log("Api Path Url: {"+url+"}, <RequestTime:"+requestTime+"> , <ErrorResponseTime: "+moment().format("ddd MMM DD YYYY HH:mm:ss")+">, , <TotalTimeTaken: "+ moment.utc((moment.duration(errorTime.diff(requestTime))).asMilliseconds()).format("HH[h] mm[m] ss[s]"));
                this.oneNceDeviceSimStatusState.error = err;
                this.oneNceDeviceSimStatus = undefined;
            }).finally(action(() => { this.oneNceDeviceSimStatusState.inProgress = false; }));
    }

    /*
    This function is used to reset all oneNceDeviceSimStatus observables to their initial values.  
    */
    resetOneNceDeviceSimStatusState = () => {
        this.oneNceDeviceSimStatus = undefined;
        this.oneNceDeviceSimStatusState = {...this.initialStateValue};
    }

    /**
     * This function is used to get the Ince token by calling an API.
     * @returns 
     */
    getOneNceTokenService = () => {
        this.inProgress = true;
        this.error = '';
        return baseService.getRequest(URLConstants.OneNceAccessToken)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                let data = response.data.Data;
                this.oneNcetoken = data.access_token;
                localStorage.setItem('1NCEtoken', data.access_token);
            })
            .catch((err: string) => {
                this.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => {
                this.inProgress = false;
            }));
    }

    /**
     * This function return the 1nce token from local storage.
     */
    get getOneNceToken() {
        return localStorage.getItem('1NCEtoken');
    }

    /**
     * This function is used to update Sim IMEI Lock status by ICCID from API.
     * @param iccid : Device Identifier
     * @param imei_lock : Lock Status to be updated
     * @returns 
     */
    updateSimIMEILockByICCIDService = (iccid: string, imei_lock:boolean) => {
        this.updateIMEILockState.inProgress = true;
        let url = URLConstants.OneNceIMEILock +"/" + iccid;;
        let request = {
            Token: localStorage.getItem('1NCEtoken'),
            IMEILock: imei_lock
        }
        return baseService.postRequest(url, request)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                if (response.data.Error) {
                    this.updateIMEILockState.error = response.data.Message;
                }
                else {
                    let data = response.data.Data;
                    this.updateIMEILockState.success = true;
                }
            })
            .catch((err: string) => {
                this.updateIMEILockState.error = err;
            }).finally(action(() => { this.updateIMEILockState.inProgress = false; }));
    }

    /*
    This function is used to reset all updateIMEILockState observables to their initial values.  
    */
    resetUpdateIMEILockState = () => {
        this.updateIMEILockState = {...this.initialStateValue};
    }

    
    /**
     * This function Resets the sim connectivity by calling an API & passing ICCID.
     * @param iccid : Device Identifier
     * @returns 
     */
    ResetSimConnectivityService = (iccid: string) => {
        this.oneNceDeviceResetSimState.inProgress = true;
        let url = URLConstants.OneNceSimReset +"/" + iccid;
        return baseService.putRequest(url, {Token: localStorage.getItem('1NCEtoken')})
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                if (response.data.Error) {
                    this.oneNceDeviceResetSimState.error = response.data.Message;
                }
                else {
                    this.oneNceDeviceResetSimState.success = true;
                }
            })
            .catch((err: string) => {
                this.oneNceDeviceResetSimState.error = err;
            }).finally(action(() => { this.oneNceDeviceResetSimState.inProgress = false; }));
    }

    /*
    This function is used to reset all oneNceDeviceSimStatus observables to their initial values.  
    */
    resetOneNceResetSimState = () => {
        this.oneNceDeviceResetSimState = {...this.initialStateValue};
    }

    /**
     * This function Enable/disables the sim by calling an API & passing ICCID , enable status.
     * @param iccid : Device Identifier
     * @param enable : Enable/disables state
     * @returns 
     */
    EnableDisableSimService = (iccid: string, enable :boolean) => {
        this.oneNceEnableSimState.inProgress = true;
        let url = URLConstants.OneNceSimEnable +"/" + iccid;
        return baseService.putRequest(url, {Enable: enable, Token: localStorage.getItem('1NCEtoken')})
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                if (response.data.Error) {
                    this.oneNceEnableSimState.error = response.data.Message;
                }
                else {
                    this.oneNceEnableSimState.success = true;
                }
            })
            .catch((err: string) => {
                this.oneNceEnableSimState.error = err;
            }).finally(action(() => { this.oneNceEnableSimState.inProgress = false; }));
    }

    /*
    This function is used to reset all oneNceDeviceSimStatus observables to their initial values.  
    */
    resetOneNceEnableSimState = () => {
        this.oneNceEnableSimState = {...this.initialStateValue};
    }

    /*
    This function is used to reset all observables to their initial values.  
    */
    reset = () => {
        this.error = '';
        this.inProgress = false;
        this.resetOneNceDeviceSimDetailState();
        this.resetOneNceDeviceSimUsageState();
        this.resetOneNceDeviceSimDataQuotaState();
        this.resetOneNceDeviceSimStatusState();
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.oneNcetoken = '';
        this.oneNceDeviceSimDetail = undefined;
        this.oneNceDeviceSimDetailState ={...this.initialStateValue }
        this.oneNceDeviceSimUsageDaily = undefined;
        this.oneNceDeviceSimUsageMonthly = undefined;
        this.oneNceDevicePacketsCountDaily = undefined;
        this.oneNceDevicePacketsCountMonthly = undefined;
        this.oneNceDeviceDLMessageCountDaily = undefined;
        this.oneNceDeviceDLMessageCountMonthly = undefined;
        this.oneNceDeviceSimUsageState ={...this.initialStateValue }
        this.oneNceDeviceSimStatus = undefined;
        this.oneNceDeviceSimStatusState ={...this.initialStateValue }
        this.oneNceDeviceSimDataQuota = undefined;
        this.oneNceDeviceSimDataQuotaState ={...this.initialStateValue }
        this.updateIMEILockState = {...this.initialStateValue}
        this.deviceChartDetails = {
            rangeType: 0,
            chartType: 0
        };
        this.oneNceEnableSimState ={...this.initialStateValue };
        this.oneNceDeviceResetSimState ={...this.initialStateValue };
    }
    
    /*
    This function is used to reset all oneNce store observables to their initial values.  
    */
    resetOneNceErrorState = () => {
        if(this.oneNceDeviceSimDetailState.error)
            this.resetOneNceDeviceSimDetailState();
        if(this.oneNceDeviceSimUsageState.error)
            this.resetOneNceDeviceSimUsageState();
        if(this.oneNceDeviceSimDataQuotaState.error)
            this.resetOneNceDeviceSimDataQuotaState();
        if(this.oneNceDeviceSimStatusState.error)
            this.resetOneNceDeviceSimStatusState();
    }

    /*
    This function is used to set device detail chart info.  
    */
    setDeviceChartDetails = (chartDetails:any) => {
        this.deviceChartDetails = {...chartDetails};
    }

    /**
     * This function provides the flag that we use to show loader on device detail page.
     */
    get simDataLoading(): boolean{
        if(this.oneNceDeviceSimDetailState.inProgress || this.oneNceDeviceSimDataQuotaState.inProgress || this.oneNceDeviceSimStatusState.inProgress || 
            this.oneNceDeviceSimUsageState.inProgress)
            return true;
        else
            return false
    }

    /**
     * This function returns flag whether an error is present or not.
     */
    get isErrorPresent(): ErrorPresent {
        return {flag : (this.oneNceDeviceSimDataQuotaState.error || this.oneNceDeviceSimDetailState.error || this.oneNceDeviceSimStatusState.error || this.oneNceDeviceSimUsageState.error) ? true : false, message:this.oneNceDeviceSimStatusState.error};
    }

}

export default new OneNceStore();
