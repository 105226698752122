/*    
<summary>
   This functional common component provides protected routing.
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import Layout from '../Layout/Layout';
import AuthProvider from '../../contexts/AuthProvider';

const LayoutRoute: FC<any> = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      <Layout>
        <AuthProvider>
          <Component {...matchProps} />
        </AuthProvider>
      </Layout>
    )} />
  )
};

export default LayoutRoute;  