/*    
<summary>
   This Interface maps unit Dashboard data observables to ChartOptions.
   Developer:Aashish Singh, Created Date: 28-Sept-2023
</summary>
*/
export type Options = {
    title: any;
    axisX: any,
    axisY: any,
    toolTip?: any,
    data: DataObj[],
    height: number,
    zoomEnabled: boolean,
    interactivityEnabled: boolean,
    animationEnabled: boolean,
    exportEnabled: boolean,
    culture: string
}

export type Map<T> = {
    [id: number]: T;
}

export type DataPoint = { x: any, y?: any };

export type DataObj = {
    type: string;
    id?: string;
    showInLegend?: boolean;
    name: string;
    color?:string;
    xValueFormatString?: string;
    yValueFormatString?: string;
    dataPoints: Array<DataPoint>
}

/**
 * This interface maps the DashboardData properties.
 */
export type lineChartOptions = {
    title: {
        text: string;
        fontSize: number;
        fontFamily: string;
    };
    axisX?: any,
    axisY?: any,
    toolTip?: any,
    data: DataObj[],
    height: number,
    //width: number,
    zoomEnabled?: boolean,
    interactivityEnabled?: boolean,
    animationEnabled?: boolean,
    exportEnabled?: boolean,
    exportFileName?: string,
    culture?: string
    theme?: string
    backgroundColor?: string
}

/**This constant defines the default options for a chart component */
export const volumeChartConfig: lineChartOptions = {
    title: {
        text: "",
        fontSize: 16,
        fontFamily: "Nunito, Sans Serif",
    },
    toolTip: {
        shared: true,
    },
    axisX: {
        title: "",
        valueFormatString: "MM-DD",
        // interval: 1,
        intervalType: "day",
        labelFontSize: 13,
        labelFontFamily: "Nunito, Sans Serif",
        labelFontColor: "#9daabe",
        titleFontSize: 13,
        titleFontFamily: "Nunito, Sans Serif",
        lineThickness: 2,
        lineColor: "#e2e8f0",
        tickColor: "#e2e8f0",
        tickThickness: 1
    },
    axisY: {
        title: "Data",
        titleFontColor: "#9daabe",
        valueFormatString: "#0.## MB",
        labelFontSize: 13,
        interval: undefined,
        labelFontFamily: "sans-serif",
        labelFontColor: "#9daabe",
        titleFontSize: 13,
        titleFontFamily: "sans-serif",
        gridColor: "#e2e8f0",
        gridThickness: 1,
        lineThickness: 2,
        lineColor: "#e2e8f0",
        tickColor: "#e2e8f0",
        tickThickness: 1
    },
    height: 230,
    data: [
        {
            xValueFormatString: "YYYY-MM-DD",
            type: "line",
            name: "0",
            color: "#ef403c",
            dataPoints: [
                ]
        },
        {
            xValueFormatString: "YYYY-MM-DD",
            type: "line",
            name: "1",
            color: "#00b746",
            dataPoints: [
                ]
        },
        {
            xValueFormatString: "YYYY-MM-DD",
            type: "line",
            name: "2",
            color: "#ffba1a",
            dataPoints: [
                ]
        }
    ],
    zoomEnabled: true,
    interactivityEnabled: true,
    animationEnabled: true,
    exportEnabled: false,
    culture: "en"
};
export const packetsCountChartConfig: lineChartOptions = {
    title: {
        text: "",
        fontSize: 16,
        fontFamily: "Nunito, Sans Serif",
    },
    toolTip: {
        shared: true,
    },
    axisX: {
        title: "",
        valueFormatString: "MM-DD",
        // interval: 1,
        intervalType: "day",
        labelFontSize: 13,
        labelFontFamily: "Nunito, Sans Serif",
        labelFontColor: "#9daabe",
        titleFontSize: 13,
        titleFontFamily: "Nunito, Sans Serif",
        lineThickness: 2,
        lineColor: "#e2e8f0",
        tickColor: "#e2e8f0",
        tickThickness: 1
    },
    axisY: {
        title: "Data",
        titleFontColor: "#9daabe",
        valueFormatString: "#",
        labelFontSize: 13,
        interval: undefined,
        labelFontFamily: "sans-serif",
        labelFontColor: "#9daabe",
        titleFontSize: 13,
        titleFontFamily: "sans-serif",
        gridColor: "#e2e8f0",
        gridThickness: 1,
        lineThickness: 2,
        lineColor: "#e2e8f0",
        tickColor: "#e2e8f0",
        tickThickness: 1
    },
    height: 230,
    data: [
        {
            xValueFormatString: "YYYY-MM-DD",
            type: "line",
            name: "0",
            color: "#ffba1a",
            dataPoints: [
                ]
        },
    ],
    zoomEnabled: true,
    interactivityEnabled: true,
    animationEnabled: true,
    exportEnabled: false,
    culture: "en"
};
