/*    
<summary>
   This is the Users Component which shows the Users Data.
   Developer: Aashish Singh, Created Date:29-August-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from "react";
import "./user.css";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/StoreProvider";
import IPageInfo, { IPageLimitInfo } from "../../models/ICommon";
import { formatMessage } from "../../translations/format-message";
import LoaderButton from "../../shared-components/Button/LoaderButton";
import Grid from "../../shared-components/Grid/Grid";
import { observer } from "mobx-react";
import "react-sliding-side-panel/lib/index.css";
import {
  ActionFormatter,
  ActiveInactiveButtonFormatter,
} from "../../shared-components/Grid/GridFormatter";
import {
  faKey,
  faPencilAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import DeletePopup from "../../shared-components/popup/delete/delete-popup";
import AddPopupHOC from "../../shared-components/popup/add/add-popup-hoc";
import DeletePopupHOC from "../../shared-components/popup/delete/delete-popup-hoc";
import ChangePasswordPopupHOC from "../../shared-components/popup/change-password/change-password-popup-hoc";
import { IUserList } from "../../models/response/IUserResponse";
import userSettingEnum from "../../constants/user-setting-enum";
import toast from "react-hot-toast";
import Pagination from "../../shared-components/Grid/Pagination";
import ChangePassword from "./components/change-password";
import AddUser from "./components/add-edit-user";
import userTypeEnum from "../../constants/user-type-enum";
import ConfirmPopupHOC from "../../shared-components/popup/confirm/confirm-popup-hoc";
import ConfirmPopup from "../../shared-components/popup/confirm/confirm-popup";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import no_user from "../../assets/Images/svg/no-user.svg";

const User = (props: any) => {
  const { userStore, preferencesStore, authStore } = useStore();
  const {
    inProgress,
    userList,
    GetUserListService,
    resetAddUpdateUserState,
    allUser,
    reset,
    DeleteUserService,
    deleteUserState,
    addUpdateUserState,
    error,
    UpdateUserStatusService,
    activeInactiveState,
    resetActiveInactiveState,
  } = userStore;
  const { getUserType } = authStore;
  const { userSetting, language, setUserSetting } = preferencesStore;
  let lang: string;
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const allUsersList: Array<IUserList> = allUser;
  const pageLimitOptions: Array<number> = [10, 50, 100, 1000];
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.userLimitConfig,
    isPageLimitChange: false,
  });
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(userSettingEnum.UserLimitConfig, parseInt(evt.target.value));
  };

  /**
   * This function calls the "getAllGroup" store funtion that get configuration list from API
   */
  const callGetUserListService = () => {
    GetUserListService(pageInfo.currentPage, pageLimitInfo.pageLimit);
  };

  /**
   * This function shows the add group pop up from where we can add new group and update existing group prootcol. we use id to identify
   * that we are adding or updating group.
   * "Id == -1" : we are adding new group
   * "Id != -1" : means we are updating existing group
   */
  const openAddUserHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    props.addToggleHandler();
  };

  /**
   * This function will update the shows the  confirm popup
   */
  const activeInactivePopupHandler = (
    row: IUserList,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedRow({ ...row, isActive: e.target.checked });
    props.confirmPopupToggleHandler();
  };

  /**
   * This function will update the Activate / DeActivate the Stability of the User
   */
  const activeInactiveHandler = () => {
    UpdateUserStatusService(selectedRow.Id, selectedRow.isActive);
  };

  /**
   * This function shows the delete user confirmation popup component and sets the seleted user Id
   */
  const deleteClickHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    props.deletePopupToggleHandler();
  };

  /**
   * This function deletes the user by providing Id to the Api
   */
  const deleteHandler = () => {
    DeleteUserService(selectedId);
  };

  /**
   * This function shows the change password popup component and sets the seleted user Id
   */
  const changePasswordClickHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    props.changePasswordToggleHandler();
  };

  /**
   * This function provides manual refresh functionality 
   * This function is used when we give refresh functionality to user.
   */
  const refreshClickHandler = () => {
    callGetUserListService();
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetUserListService();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "GroupList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: userList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    setDisablePagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUser]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetUserListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateGroupstate.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateUserState.success) {
      resetAddUpdateUserState();
      callGetUserListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateUserState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "error" of the useEffect changed.
   */
  useEffect(() => {
    if (error) {
      toast.error(formatMessage(error));
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "activeInactiveState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (activeInactiveState.success) {
      toast.success(formatMessage("updated_success"));
      resetActiveInactiveState();
      props.confirmPopupToggleHandler();
      callGetUserListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInactiveState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "activeInactiveState.error" of the useEffect changed.
   */
  useEffect(() => {
    if (activeInactiveState.error) {
      toast.error(formatMessage(activeInactiveState.error));
      resetActiveInactiveState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInactiveState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteUserSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteUserState.success) {
      toast.success(formatMessage("deleted_success"));
      reset();
      props.deletePopupToggleHandler();
      callGetUserListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUserState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteUserError" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteUserState.error) {
      toast.error(formatMessage(deleteUserState.error));
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUserState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (language !== lang) {
      callGetUserListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  /**
   * The column constant defines the column description for the user grid (table)
   */
  /**
   * The column constant defines the column description for the user grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      text: formatMessage("label_id"),
      hidden: true,
    },
    {
      dataField: "UserName",
      text: formatMessage("user_label_user_name"),
      title: (cell: string, row: any) => {
        return (cell.length > 10 ? cell : undefined);
      },
    },
    {
      dataField: "Email",
      text: formatMessage("user_label_login_id"),
      title: (cell: string, row: any) => {
        return (cell.length > 10 ? cell : undefined);
      },
    },
    {
      dataField: "UserType",
      text: formatMessage("user_table_col_user_type"),
      formatter: (cell: any) => <span>{formatMessage(cell)}</span>,
    },
    {
      dataField: "isActive",
      text: formatMessage("is_active"),
      formatter: ActiveInactiveButtonFormatter(activeInactivePopupHandler),
      hidden: getUserType == userTypeEnum.ReadOnly ? true : false,
    },
    {
      dataField: "",
      formatter: ActionFormatter([
        {
          dataField: "isEdit",
          handler: openAddUserHandler,
          icon: faPencilAlt,
          isDisable: true,
          title: "edit",
          varient: "primary",
        },
        {
          dataField: "isChangePassword",
          handler: changePasswordClickHandler,
          icon: faKey,
          isDisable: true,
          title: "change_password",
          varient: "secondary",
        },
        {
          dataField: "isDelete",
          handler: deleteClickHandler,
          icon: faTrashAlt,
          isDisable: true,
          title: "delete",
          varient: "danger",
        },
      ]),
      text: formatMessage("actions"),
      hidden: getUserType == userTypeEnum.ReadOnly ? true : false,
    },
  ];

  return (
    <React.Fragment>
      {props.showAddPopup && (
        <AddUser id={selectedId} modalClosed={props.addToggleHandler} />
      )}
      {props.showDeletePopup && (
        <DeletePopup
          title="delete_User"
          modalSubmit={deleteHandler}
          modalClosed={props.deletePopupToggleHandler}
          message="message_delete_user_confirm"
          isLoading={deleteUserState.inProgress}
        />
      )}
      {props.showConfirmPopup && (
        <ConfirmPopup
          title="account_status_title"
          modalSubmit={activeInactiveHandler}
          modalClosed={props.confirmPopupToggleHandler}
          message="active_inactive_account_status"
          isLoading={activeInactiveState.inProgress}
        />
      )}
      {props.showChangePasswordPopup && (
        <ChangePassword
          id={selectedId}
          modalClosed={props.changePasswordToggleHandler}
        />
      )}
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div id="moveContent">
          <div className="container-fluid">
            <div className="row no-gutters  align-items-center">
              <div className="col-sm-6 col-12">
                <div className="filter-left-area">
                  <ul className="d-flex align-items-center">
                    <li>
                      <p className="h5 pl-5 mb-0">
                        <FormattedMessage id="user_title" />
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="add-list">
                  <ul className="d-flex align-items-center">
                    {/* <li className="mx-2" title={formatMessage("refresh")}>
                      <LoaderIconButton
                        id="RefreshUsers"
                        onClick={() => refreshClickHandler()}
                        svg={
                          <svg
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-arrow-repeat m-0"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                            <path
                              fillRule="evenodd"
                              d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                            />
                          </svg>
                        }
                        className="btn-sm"
                        variant="light"
                      />
                    </li> */}
                    { getUserType !== userTypeEnum.ReadOnly &&
                      <li title={formatMessage("user_title_add_user")}>
                        <LoaderButton
                          id="AddUsers"
                          text="+_add_user"
                          onClick={() => openAddUserHandler({ Id: -1 })}
                        />
                      </li>
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table_items_list">
      {inProgress && <FormLoader loading={inProgress} />}
        {allUsersList.length > 0 ? (
          <div className="card mt-3">
            <div className="tableDesign row-eq-height tableDevices ">
              <div className="table-responsive" data-testid="table">
                {allUsersList && (
                  <Grid
                    data={allUsersList}
                    columns={columns}
                    keyField="Id"
                    loading={false}
                  />
                )}
                
              </div>
            </div>
            {allUsersList.length > 0 ? (
              <div className="row no-gutters p-2 tableFooter">
                <div className="col-sm-6 pt-1">
                  <FormattedMessage id="show" />
                  <select
                    data-testid="pageSelect"
                    className="pageLimit"
                    onChange={onPageLimitChanged}
                    value={pageLimitInfo.pageLimit}
                  >
                    {pageLimitOptions.map((op: any) => (
                      <option key={op} value={op}>
                        {op}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-6 d-flex justify-content-sm-end">
                  <Pagination
                    isRefreshPage={false}
                    totalPages={pageInfo.totalPages}
                    pageLimit={pageLimitInfo.pageLimit}
                    onPageChanged={onPageChanged}
                    disablePagination={disablePagination}
                    setIsRefreshPage={setIsRefreshPage}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="message text-center">
            <img src={no_user} alt="" className="no_message" />
            <p className="noData text-muted text-center h4 mb-0">
              <FormattedMessage id="no_user" />
            </p>
            <p className="mb-3">{formatMessage("no_user_message")}</p>
            <LoaderButton
              text="+_add_user"
              onClick={() => openAddUserHandler({ Id: -1 })}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ConfirmPopupHOC(
  ChangePasswordPopupHOC(AddPopupHOC(DeletePopupHOC(observer(User))))
);
