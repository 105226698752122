/*    
<summary>
  This functional component "AllGroupsPopup" defines the props like initial values & functions for the child form component.
  Developer:Mohammad Saquib Khan, Created Date: 15 September 2023,
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../contexts/StoreProvider";
import ChooseGroupForm from "./forms/group/choose-group-form";
import DownlinkForm from "./forms/downlink-form";
import { number } from "yup";
import { faPray } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import { formatMessage } from "../../../translations/format-message";

interface IProps {
  iccid: string;
  modalClosed: () => void;
}

const Downlink = (props: IProps) => {
  const { deviceStore, groupStore, tenantStore, authStore } = useStore();
  const { addDeviceCSV, addBulkDeviceState, resetAddBulkDeviceState, resetAddDeviceCSV, publishMessageState, PublishMessageForDevice, resetPublishMessageState } =
    deviceStore;
  const { GetTenantService, tenantDetails } = tenantStore;
  const { getTenantId, getUserId } = authStore;

  const onSubmitForm = (values: any) => {
    let fullTopic = "/"+tenantDetails?.OrganizationId+"/"+props?.iccid+"/sub"+values.Topic;
    let qos = Number(values.QOS);
    let format = Number(values.Format);
    let obj = {
      ICCID : props.iccid,
      Topic : fullTopic,
      Message: values.Message,
      QoS: qos,
      Format: format
    }
    PublishMessageForDevice(obj,props.iccid);
  };

  const onModalClosed = () => {
    resetPublishMessageState();
    props.modalClosed();
  };

    /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addBulkDeviceState.success" of the useEffect changed.
   */
    useEffect(() => {
      if (publishMessageState.success) {
        // onModalClosed();
        resetPublishMessageState();
        toast.success(formatMessage('MessagePublished'));
      }
    }, [publishMessageState.success]);
  
      /**
     * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
     * dependency "addBulkDeviceState.error" of the useEffect changed.
     */
      useEffect(() => {
        if (publishMessageState.error) {
          // resetPublishMessageState();
        }
      }, [publishMessageState.error]);

  useEffect(() => {
    GetTenantService(getTenantId,getUserId);
  }, []);

  return (
    <React.Fragment>
      <Modal
        className="correction-modal fancy-modal"
        show={true}
        centered={false}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="publish_message" />
          </Modal.Title>
          <button
            onClick={onModalClosed}
            data-testid="Close"
            className="Crossicons"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <div className="p-0">
          <DownlinkForm
            iccid={props.iccid}
            orgId={tenantDetails?.OrganizationId}
            initialValues={{ Topic: "", Format: "0", QOS: 0, Message: "" }}
            submitHandler={onSubmitForm}
            isLoading={publishMessageState.inProgress}
            modalClosed={onModalClosed}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default observer(Downlink);
