/*    
<summary>
  This functional component shows the billing details.
  Developer:Aashish Singh, Created Date: 09-Oct-2023
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/StoreProvider";

interface IProps {
  id: number;
}
const BilligDetails = (props: IProps) => {
  const { billingStore } = useStore();
  const { billDetail, GetTenantBillChargesDetailsService } = billingStore;

  /**
   * This function calls the "getBillingByIdService" store funtion that get Billing data from API
   */
  const callGetBillingById = () => {
    GetTenantBillChargesDetailsService(props.id)
  };

  useEffect(() => {
    if(props.id > 0)
    callGetBillingById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  return (
    <React.Fragment>
      <div className="p-3">
          {
            billDetail && 
            <>
              
              <div className="receipt">
                
                <header className="header">
                  <div className="header__top">
                    <div className="header__meta">
                      <div className="row mb-2">
                        <div className="col-6">
                          <p>
                            <small className="text-muted"><FormattedMessage id="currency" /></small><br/>
                            {billDetail.Currency ? (billDetail.Currency.CurrencyName +" (" + billDetail.Currency.Symbol +")" ): ""}
                          </p>
                          {/* <p>
                            <small className="text-muted"><FormattedMessage id="bill_state_type" /></small><br/>
                            {billDetail.BillStateType}
                          </p> */}
                        </div>
                        <div className="col-6 text-right">
                          <p><FormattedMessage id="bill_date" /><br/>{billDetail.BillGenerationDate}</p>
                          {/* <small className="text-muted"> */}
                            {/* <FormattedMessage id="bill_year" />/ <FormattedMessage id="bill_month" />  */}
                            {/* {new Date(billDetail?.BillGenerationDate).getFullYear()} / { getMonthName((new Date(billDetail?.BillGenerationDate).getMonth())+1)} */}
                          {/* </small> */}
                        </div>
                      </div>
                      
                      <div className="row mt-4 mb-3">
                        <div className="col-7">
                          <FormattedMessage id="prev_pending" />
                        </div>
                        <div className="col-5 text-right">
                          {billDetail.PendingOutstandingAmount}
                        </div>
                      </div>
                    </div>
                  </div>
                </header>
                
                <section className="cart">
                    <ul className="">
                      <li className="list__item">
                        <span className="list__name"><FormattedMessage id="registration_charges" /></span>
                        <span className="list__price">{billDetail.RegistrationCharges}</span>
                      </li>
                      <li className="list__item">
                        <span className="list__name"><FormattedMessage id="packet_charges" /></span>
                        <span className="list__price">{(billDetail.PacketCharges)}</span>
                      </li>
                      <li className="list__item">
                        <span className="list__name"><FormattedMessage id="tenancy_charges" /></span>
                        <span className="list__price">{billDetail.TenancyCharges}</span>
                      </li>

                      <li className="list__item">
                        <span className="list__name"><FormattedMessage id="taxes" /></span>
                        <span className="list__price">{(billDetail.Taxes)}</span>
                      </li>
                    </ul>	
                    <hr className="cart__hr" />
                    <footer className="cart__total">
                      <h3 className="cart__total-label"><FormattedMessage id="total_amount" /></h3>
                      <span className="cart__total-price">{billDetail.TotalOutstandingAmount}</span>				
                    </footer>
                </section>
              </div>
            </>
          }
    </div>
    </React.Fragment>
  );
};

export default observer(BilligDetails);
