/*    
<summary>
  This functional component "UnblockGroupPopupHOC" provides props to the child component.
  1. State "showUnblockDevicePopup" that manages the show/hide of pop up
  2. Function "unblockDeviceToggleHandler" to toggle the show/hide popup state "showUnblockDevicePopup"
  Developer:Aashish Singh, Created Date:07-March-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";

const UnblockGroupPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showUnblockGroupPopup, setShowUnblockGroupPopup] =
      useState<boolean>(false);

    const unblockGroupToggleHandler = () => {
      setShowUnblockGroupPopup(!showUnblockGroupPopup);
    };

    return (
      <Component
        showUnblockGroupPopup={showUnblockGroupPopup}
        unblockGroupToggleHandler={unblockGroupToggleHandler}
        {...props}
      />
    );
  };

export default UnblockGroupPopupHOC;
