/*    
<summary>
  This functional component "AddPaymentForm" provides form to add new payment.
  Developer:Aashish Singh, Created Date:27-Oct-2023
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import { Form, Formik, Field } from "formik";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import Label from "../../../shared-components/Label/Label";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import { IBillingList } from "../../../models/response/IBillingResponse";
import { addPaymentValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import FormikFormInputForRegexCheck from "../../../shared-components/FormikFormHandlers/FormikFormInputForRegexCheck";
import IAddPayment from "../../../models/Form/IPayment";

interface IProps {
  initialValues: IAddPayment;
  submitHandler: (data: any, currencyId: number) => void;
  onModalClosed: () => void;
  addPaymentLoading: boolean;
  selectedData: IBillingList;
}

const AddPaymentForm = (props: IProps) => {
  return (
    <>
      <Modal className="add-user-modal correction-modal fancy-modal" show={true} centered onHide={() => {}}>
        <Modal.Header>
          <Modal.Title>
              <FormattedMessage id="add_payment" />
            <button
              onClick={props.onModalClosed}
              disabled={props.addPaymentLoading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          validationSchema={addPaymentValidateSchema(Number(props.selectedData?.TotalOutstandingAmount))}
          validateOnBlur={false}
          onSubmit={async (values) => {
            let tempValues: IAddPayment = {...values};
            tempValues.PaymentRecieved = Number(tempValues.PaymentRecieved);
            props.submitHandler(tempValues, props.selectedData.Currency.Id);
          }}
          render={({ values }) => (
          <Form className="payment-form">
            <Modal.Body>
            <div className="row mb-2">
              </div>
                <div className="row pt-2 mb-2">  
                <div className="col-sm-5">
                  <label className="text-muted bold"><FormattedMessage id={"pending_amount"} /> ({props.selectedData.Currency.Symbol}): </label>
                </div>
                <div className="col-sm-7">
                  <p className="mw-100">{props.selectedData.TotalOutstandingAmount}</p>
                </div>          
              </div>
            <div className="form-group mb-1 row">
                <Label
                  className="label-style col-sm-5 col-form-label"
                  required={true}
                  label="payment_recieved"
                />
                <div className="col-sm-7">
                  <Field
                    data-testid="testPaymentRecieved"
                    className="form-control"
                    name="PaymentRecieved"
                    type="text"
                    placeholder="payment_recieved"
                    regex={/^(?:\d+(\.\d*)?|)$/}
                    as={FormikFormInputForRegexCheck}
                  />
                </div>
              </div>
              <div className="form-group mb-1 row">
                <Label
                  className="label-style col-sm-5 col-form-label"
                  label="balance_pending"
                />
                <div className="col-sm-7">
                  <input 
                    type="number"
                    disabled={true}
                    className="form-control"
                    value={Number(Number(props.selectedData.TotalOutstandingAmount) - Number(values.PaymentRecieved)).toFixed(2)}
                    />
                </div>
              </div>
              <div className="form-group mb-1 row">
                <Label
                  className="label-style col-sm-5 col-form-label"
                  label="remarks"
                />
                <div className="col-sm-7">
                    <Field
                        id="remarks"
                        name="Remarks"
                        placeholder="enter_text"
                        type="text"
                        className="form-control"
                        rows={5}
                        style={{ resize: "none" }}
                        as={FormikFormTextArea}
                    />
                </div>
              </div>
            </Modal.Body>
            <div className="modal-footer pt-0 border-0">
              <div className="form-group row tab-action">
                <div className="col-12">
                  <LoaderButton
                    type="submit"
                    isLoading={props.addPaymentLoading}
                    text="button_save"
                  />
                  <Button
                    variant="secondary"
                    onClick={props.onModalClosed}
                    disabled={props.addPaymentLoading}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </div>
          </Form>
          )}
       ></Formik>
      </Modal>
    </>
  );
};

export default AddPaymentForm;
