/*    
<summary>
  This functional component "AddPopupHOC" provides props to the child component.
  1. State "showAddPopup" that manages the show/hide of pop up
  2. Function "addToggleHandler" to toggle the show/hide popup state "showAddPopup"
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";

const AddPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showAddPopup, setShowAddPopup] = useState<boolean>(false);

    const addToggleHandler = () => {
      setShowAddPopup(!showAddPopup);
    };

    return (
      <Component
        showAddPopup={showAddPopup}
        addToggleHandler={addToggleHandler}
        {...props}
      />
    );
  };

export default AddPopupHOC;
