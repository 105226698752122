/*    
<summary>
  This functional component "ConfirmPopupHOC" provides props to the child component.
  1. State "showConfirmPopup" that manages the show/hide of pop up
  2. Function "confirmPopupToggleHandler" to toggle the show/hide popup state "showConfirmPopup"
  Developer:Aashish Singh, Created Date:04-Sept-2023
</summary>
<returns>Returns JSX</returns>
*/

import React, {useState } from 'react';

const ConfirmPopupHOC = (Component:any) => 
function Comp(props:any){
    const [showConfirmPopup,setShowConfirmPopup] = useState<boolean>(false);

    const ConfirmToggleHandler = () => {
        setShowConfirmPopup(!showConfirmPopup);
    }
	
	return <Component showConfirmPopup={showConfirmPopup} confirmPopupToggleHandler={ConfirmToggleHandler} {...props}/>;
};

export default ConfirmPopupHOC;   