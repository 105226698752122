/*    
<summary>
  This functional component "UserProfilePopUp" defines the props like initial values and functions for the child form 
  component.
  Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import UserProfile from "./components/UserProfile/UserProfile";
interface IProps {
  userName: string;
  userType: string;
  modalClosed: () => void;
}
const UserProfilePopUp: React.FC<IProps> = (props) => {
  const onModalClosed = () => {
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <UserProfile
        userName={props.userName}
        userType={props.userType}
        onModalClosed={onModalClosed}
      />
    </React.Fragment>
  );
};

export default UserProfilePopUp;
