/*    
<summary>
   This common functional component provides footer that contains company details.
   Developer:Aashish Singh, Created Date:10-Mar-2023
</summary> 
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from "react";
import "./Footer.css";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import config from "../../helpers/config-helper";
import { useStore } from "../../contexts/StoreProvider";

const Footer: React.FC = () => {
  const { preferencesStore } = useStore();


  return (
    <footer
      className="footer">
        <div className="copyright2020">
          {" "}
          © <span id="copy-year">{new Date().getFullYear()} </span>
          <FormattedMessage id="footer" />{" "}
        </div>
    </footer>
  );
};

export default Footer;
