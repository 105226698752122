/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   This enum is used to differentiate protocols in the overall application.
   Developer: Aashish Singh, Created Date: 20-Oct-2023 
</summary>
*/
enum billStateType {
    Pending = 0,
    Partial = 1,
    Paid = 2
}

export default billStateType;