/*    
<summary>
   This is the TenantCharges Component which shows the TenantCharges Data.
   Developer: Aashish Singh, Created Date:26-Oct-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from "react";
import "./tenant-charge.css";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/StoreProvider";
import IPageInfo, { IPageLimitInfo } from "../../models/ICommon";
import { formatMessage } from "../../translations/format-message";
import LoaderButton from "../../shared-components/Button/LoaderButton";
import Grid from "../../shared-components/Grid/Grid";
import { observer } from "mobx-react";
import "react-sliding-side-panel/lib/index.css";
import AddPopupHOC from "../../shared-components/popup/add/add-popup-hoc";
import userSettingEnum from "../../constants/user-setting-enum";
import toast from "react-hot-toast";
import Pagination from "../../shared-components/Grid/Pagination";
import AddTenantCharge from "./components/add-tenant-charge";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import userTypeEnum from "../../constants/user-type-enum";
import { emptyValue } from "../../constants/common-constants";

const TenantCharge = (props: any) => {
  const { tenantChargeStore, preferencesStore, authStore } = useStore();
  const {
    inProgress,
    tenantChargeList,
    GetTenantChargesListService,
    allTenantCharge,
    reset,
    error,
    addTenantChargeState,
    resetAddTenantChargeState
  } = tenantChargeStore;
  const { getUserType } = authStore;
  const { userSetting,  setUserSetting, islanguageChanged, updateLanguageChangedFlag } = preferencesStore;
  let lang: string;
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const allTenantChargesList: Array<any> = allTenantCharge;
  const pageLimitOptions: Array<number> = [10, 50, 100, 1000];
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.tenantChargeLimitConfig,
    isPageLimitChange: false,
  });
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(userSettingEnum.TenantChargeLimitConfig, parseInt(evt.target.value));
  };

  /**
   * This function calls the "getAllGroup" store funtion that get configuration list from API
   */
  const callGetAllTenantChargeService = () => {
    GetTenantChargesListService(pageInfo.currentPage, pageLimitInfo.pageLimit);
  };

  /**
   * This function shows the add group pop up from where we can add new user
   */
  const openAddTenantChargeHandler = () => {
    props.addToggleHandler();
  };

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    callGetAllTenantChargeService();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetAllTenantChargeService();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "allTenantCharge" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: tenantChargeList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    setDisablePagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTenantCharge]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetAllTenantChargeService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addTenantChargeState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addTenantChargeState.success) {
      resetAddTenantChargeState();
      callGetAllTenantChargeService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTenantChargeState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "error" of the useEffect changed.
   */
  useEffect(() => {
    if (error) {
      toast.error(formatMessage(error));
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  
  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "islanguageChanged" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      callGetAllTenantChargeService();
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);

  /**
   * The column constant defines the column description for the tenantCharge grid (table)
   */
  /**
   * The column constant defines the column description for the tenantCharge grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      text: formatMessage("label_id"),
      hidden: true,
    },
    {
      dataField: "Tenant.TenantName",
      text: formatMessage("tenant_name"),
      title: (cell: string, row: any) => {
        return cell ? (cell.length > 10 ? cell : undefined) : undefined;
      },
      classes: "p-2"
    },
    {
      dataField: "UpdatedDate",
      text: formatMessage("updation_date"),
      title: (cell: string, row: any) => {
        return cell ? (cell.length > 10 ? cell : undefined) : undefined;
      },
    },
    {
      dataField: "Currency",
      formatter: (row: any) => <span>{row ? (row.CurrencyName +" (" + row.Symbol +")" ): emptyValue}</span>,
      text: formatMessage("currency"),
    },
    {
      dataField: "RegistrationCharges",
      text: formatMessage("registration_charges"),
    },
    {
      dataField: "PacketCharges",
      text: formatMessage("packet_charges"),
    },
    {
      dataField: "TenancyCharges",
      text: formatMessage("tenancy_charges"),
    },
    {
      dataField: "Taxes",
      text: formatMessage("taxes"),
    }
  ];

  return (
    <React.Fragment>
      {props.showAddPopup && (
        <AddTenantCharge modalClosed={props.addToggleHandler} />
      )}
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div id="moveContent">
          <div className="container-fluid">
            <div className="row no-gutters  align-items-center">
              <div className="col-sm-6 col-12">
                <div className="filter-left-area">
                  <ul className="d-flex align-items-center">
                    <li>
                      <p className="h5 pl-5 mb-0">
                        <FormattedMessage id="tenant_charge_title" />
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="add-list">
                  <ul className="d-flex align-items-center">
                    { getUserType === userTypeEnum.SuperAdministrator &&
                      <li title={formatMessage("add_tenant_charge_form")}>
                        <LoaderButton
                          id="AddTenantCharges"
                          text="+_add_tenant_charge"
                          onClick={() => openAddTenantChargeHandler()}
                        />
                      </li>
                      }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table_items_list">
      {inProgress && <FormLoader loading={inProgress} />}
        {allTenantChargesList.length > 0 ? (
          <div className="card mt-3">
            <div className="tableDesign row-eq-height tableDevices ">
              <div className="table-responsive" data-testid="table">
                {allTenantChargesList && (
                  <Grid
                    data={allTenantChargesList}
                    columns={columns}
                    keyField="Id"
                    loading={false}
                  />
                )}
                
              </div>
            </div>
            {allTenantChargesList.length > 0 ? (
              <div className="row no-gutters p-2 tableFooter">
                <div className="col-sm-6 pt-1">
                  <FormattedMessage id="show" />
                  <select
                    data-testid="pageSelect"
                    className="pageLimit"
                    onChange={onPageLimitChanged}
                    value={pageLimitInfo.pageLimit}
                  >
                    {pageLimitOptions.map((op: any) => (
                      <option key={op} value={op}>
                        {op}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-6 d-flex justify-content-sm-end">
                  <Pagination
                    isRefreshPage={false}
                    totalPages={pageInfo.totalPages}
                    pageLimit={pageLimitInfo.pageLimit}
                    onPageChanged={onPageChanged}
                    disablePagination={disablePagination}
                    setIsRefreshPage={setIsRefreshPage}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="message text-center">
            <p className="noData text-muted text-center h4 mb-0">
              <FormattedMessage id="no_tenant_charge" />
            </p>
            <p className="mb-3">{formatMessage("no_tenant_charge_message")}</p>
            {
               getUserType !== userTypeEnum.ReadOnly &&
              <LoaderButton
                text="+_add_tenant_charge"
                onClick={() => openAddTenantChargeHandler()}
              />
            }
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default AddPopupHOC(observer(TenantCharge));
