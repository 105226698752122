/*    
<summary>
  This functional component "AddUpdateDeviceForm" provides form to add new user or update existing user.
  Developer:Mohammad Saquib Khan, Created Date: 29-March-2023, Updated By: Aashish Singh, Last Updated Date: 10-April-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react";
import { Button } from "react-bootstrap";
import LoaderButton from "../../../../../shared-components/Button/LoaderButton";
import FormLoader from "../../../../../shared-components/FormLoader/FormLoader";
import FormikFormSelect from "../../../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { changeGroupValidateSchema, deviceValidateSchema } from "../../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../../../shared-components/Label/Label";
import { formatMessage } from "../../../../../translations/format-message";

interface IProps {
  initialValues: any;
  groupList: any;
  submitHandler: (data: any) => void;
  isLoading: boolean;
  modalClosed: () => void;
}

const SelectGroupForm = (props: IProps) => {
  /**
   * Area Block for Get functions/variables from store class
   * UpdatedBy:Mohammad Saquib Khan , Date:15-September-2023
   */

  const { initialValues, submitHandler, groupList, isLoading, modalClosed } =
    props;

  /**End */

  return (
    <>
      <div className="mx-auto">
        {isLoading && <FormLoader loading={isLoading} />}
        <Formik
          initialValues={initialValues}
          validationSchema={changeGroupValidateSchema}
          enableReinitialize
          validateOnBlur={false}
          onSubmit={async (values: any) => {
            submitHandler(values);
          }}
          render={({ values }) => {
            return (
              <Form id="formBody">
                <div className="row p-4">
                  <div className="col-12">
                    <div className="form-group formSet">
                      <Label
                        className="label-style"
                        required={true}
                        label="available_group"
                      />
                      <Field
                        id="Groups"
                        className="form-control"
                        name="GroupId"
                        options={groupList}
                        as={FormikFormSelect}
                      />
                    </div>
                  </div>
                </div>

                <div className="modal-footer border-0 pt-0">
                  <LoaderButton
                    type="submit"
                    id="SaveDevice"
                    isLoading={isLoading}
                    text="submit"
                  />
                  <Button
                    data-testid="Cancel"
                    variant="secondary"
                    onClick={modalClosed}
                    disabled={isLoading}
                    className=""
                  >
                    <FormattedMessage id="close" />
                  </Button>
                </div>
              </Form>
            );
          }}
        ></Formik>
      </div>
    </>
  );
};

export default observer(SelectGroupForm);
