/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   This enum is used to differentiate protocols in the overall application.
   Developer: Mohammad Saquib Khan, Created Date: 11-January-2024 
</summary>
*/
enum authenticationEnum {
    NoAuthorization=0,
    BasicAuthorization=1,
    APIKey=2,
    BearerToken=3,
    AWSSignature=4
}

export default authenticationEnum;