/*    
<summary>
   This is the Dashboard Component which shows the monitoring activites of Device Flow.
   Developer: Mohammad Saquib Khan, Created Date:17-April-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React, { SyntheticEvent, useEffect, useState } from "react";
import "./configuration.css";
import { FormattedMessage } from "react-intl";
import Pagination from "../../shared-components/Grid/Pagination";
import Grid from "../../shared-components/Grid/Grid";
import IPageInfo, { IPageLimitInfo } from "../../models/ICommon";
import { useStore } from "../../contexts/StoreProvider";
import { formatMessage } from "../../translations/format-message";
import AddConfiguration from "./add-edit-configuration/add-edit-configuration";
import { observer } from "mobx-react";
import {
  ActionFormatter,
  ConfigurationGroupsFormatterDetailView,
  TableConfigurationGroupsFormatter,
} from "../../shared-components/Grid/GridFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import protocolEnum from "../../constants/protocol-enum";
import ConfirmPopup from "../../shared-components/confirmationPopup/confirm-popup";
import toast, { Toaster } from "react-hot-toast";
import DeletePopupHOC from "../../shared-components/confirmationPopup/hoc/confirmation-popup-hoc";
import AddConfigurationPopupHOC from "./hoc/add-edit-configuration-popup-hoc";
import InfoPopupHOC from "../../shared-components/infoPopup/hoc/info-popup-hoc";
import InfoPopup from "../../shared-components/infoPopup/info-popup";
import userTypeEnum from "../../constants/user-type-enum";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import SlidingPanel from "react-sliding-side-panel";
import ConfigurationDetails from "./configuration-details";
import no_config from "../../assets/Images/svg/no-configuration.svg";
import userSettingEnum from "../../constants/user-setting-enum";
import webhookIcon from "../../assets/Images/webhook.svg";
import authenticationEnum from "../../constants/authentication-enum";
import RoutesConstants from "../../shared-components/Routes/routes.constants";
import awsIcon from "../../assets/Images/image2019-8-27_16-51-53.png";
import azureIcon from "../../assets/Images/53_iot-hub.f08d8d0436.png";

const Configuration = (props: any) => {
  const { preferencesStore, configurationStore, authStore, tenantStore } = useStore();
  const { userSetting, language, setUserSetting } = preferencesStore;
  const {
    GetConfigurationListService,
    allConfigurationslist,
    configurationList,
    IsAssociatedState,
    IsAssociated,
    addUpdateConfigurationstate,
    GetConfigurationAssociatedInfoService,
    DeleteConfigurationService,
    deleteConfigurationState,
    reset,
    resetIsAssociatedState,
    inProgress,
    resetGetConfigurationDetail,
    setSelectedAssinedGroupId,
  } = configurationStore;
  const { getUserType, getTenantId, getUserId } = authStore;
  const { GetTenantService, tenantDetails } = tenantStore;
  let lang: string;
  const [openPanel, setOpenPanel] = useState(false);
  const [tableView, setTableView] = useState<boolean>(
    localStorage.getItem("tableView") == "false" ? false : true
  );
  const { setUpdateTableView } = preferencesStore;

  const tableViewToggleHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAssinedGroupId(0);
    let tableView =
      localStorage.getItem("tableView") == "false" ? "true" : "false";
    setUpdateTableView(tableView);
    if (tableView == "false") {
      setTableView(false);
    } else {
      setTableView(true);
    }
  };
  // const allConfigurationslist: Array<IConfigurationList> = allConfigurationslist;
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [selectedAction, setSelectedAction] = useState<number>(0);
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const [isFilterActive, setFilterActive] = useState<boolean>(false);
  const pageLimitOptions: Array<number> = [10, 50, 100, 1000];
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.configLimitConfig,
    isPageLimitChange: false,
  });
  
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });
  const configId = props.location.state ? props.location.state.configId : -1;
  const [configurationId, setConfigurationId] = useState<number>(configId);
  
  /**
   * This function calls the "getAllConfiguration" store funtion that get configuration list from API
   */
  const callGetAllConfigurations = () => {
    GetConfigurationListService(pageInfo.currentPage, pageLimitInfo.pageLimit, configurationId > 0 ? configurationId : -1);
  };

  useEffect(() => {
    if(configurationId > 0){
      setFilterActive(true);
    }
      callGetAllConfigurations();
  }, [configurationId]);

  const resetFilter = () => {
    setConfigurationId(-1);
    setFilterActive(false);
  }

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(
      userSettingEnum.ConfigLimitConfig,
      parseInt(evt.target.value)
    );
  };

  useEffect(() => {
    let tableView =
      localStorage.getItem("tableView") == "false" ? "false" : "true";
    setUpdateTableView(tableView);
    if (tableView == "false") {
      setTableView(false);
    } else {
      setTableView(true);
    }
    return () => {
      setSelectedAssinedGroupId(0);
    };
  }, []);

  /**
   * This function shows the add configuration pop up from where we can add new configuration and update existing configuration prootcol. we use id to identify
   * that we are adding or updating configuration.
   * "Id == -1" : we are adding new configuration
   * "Id != -1" : means we are updating existing configuration
   */
  const openAddConfigurationHandler = (
    row: {
      Id: number;
      ProtocolId: number;
    },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    closePanelhandler();
    setSelectedAction(row.ProtocolId);
    setSelectedId(row.Id);
    resetGetConfigurationDetail();
    props.addConfigurationToggleHandler();
  };

  /**
   * This function shows the add configuration pop up from where we can add new configuration and update existing configuration prootcol. we use id to identify
   * that we are adding or updating configuration.
   * "Id == -1" : we are adding new configuration
   * "Id != -1" : means we are updating existing configuration
   */
  const openAddConfigurationHandlerTable = (
    row: {
      Id: number;
      DestinationProtocol: number;
    },
    event: any
  ) => {
    event.stopPropagation();
    // setSelectedAssinedGroupId(0);
    closePanelhandler();
    setSelectedAction(row.DestinationProtocol);
    setSelectedId(row.Id);
    resetGetConfigurationDetail();
    props.addConfigurationToggleHandler();
  };

  const deleteHandler = () => {
    DeleteConfigurationService(selectedId);
  };

  const infoPopupCloseHandler = () => {
    // setSelectedAssinedGroupId(0);
    props.infoToggleHandler();
  };

  /**
   * This function shows the configuration protocol data popup component and sets the seleted configuration Id
   */
  const dataHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    // props.deletePopupToggleHandler();
  };

  /**
   * This function shows the delete configuration confirmation popup component and sets the seleted configuration Id
   */
  const deleteClickHandler = (row: { Id: number }, event: any) => {
    event.stopPropagation();
    // setSelectedAssinedGroupId(0);
    closePanelhandler();
    GetConfigurationAssociatedInfoService(row.Id);
    setSelectedId(row.Id);

    // props.confirmToggleHandler();
  };

  const closePanelhandler = () => {
    setSelectedRow({});
    // setSelectedAssinedGroupId(0);
    setOpenPanel(false);
    resetGetConfigurationDetail();
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetAllConfigurations();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "configurationList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: configurationList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurationList]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetAllConfigurations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateConfigurationstate.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateConfigurationstate.success) {
      callGetAllConfigurations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateConfigurationstate.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "IsAssociatedState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (IsAssociatedState.success) {
      if (IsAssociated) props.infoToggleHandler();
      else props.confirmToggleHandler();
      resetIsAssociatedState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IsAssociatedState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteConfigurationState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteConfigurationState.success) {
      toast.success(formatMessage("deleted_success"));
      reset();
      props.confirmToggleHandler();
      callGetAllConfigurations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteConfigurationState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "deleteConfigurationState.error" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteConfigurationState.error) {
      toast.error(formatMessage(deleteConfigurationState.error));
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteConfigurationState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (language !== lang) {
      callGetAllConfigurations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  function rowClassFormat(row: any, rowIdx: number) {
    let className: string = "";
    if (selectedRow?.Id && row.Id === selectedRow.Id) {
      className = "bg-row SelectedRow";
    }
    return className;
  }

  const rowEvents = (e: SyntheticEvent, row: any, rowIndex: number) => {
    // setSelectedAssinedGroupId(0);
    setSelectedRow(row);
    setOpenPanel(true);
  };

  /**
   * The column constant defines the column description for the user grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      text: formatMessage("id"),
      hidden: true,
    },
    {
      dataField: "Name",
      text: formatMessage("config_name"),
      title: (cell: string, row: any) => {
        return cell.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Description",
      text: formatMessage("desc"),
      title: (cell: string, row: any) => {
        return cell.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Groups",
      text: formatMessage("group"),
      formatter: TableConfigurationGroupsFormatter(setSelectedAssinedGroupId),
      classes: "overflow-visible assignGroupWrap",
    },
    {
      dataField: "Protocol",
      text: formatMessage("configuration_type"),
      formatter: (cell: any, row: any) => {
        return (
          <span>
            {row.DestinationProtocol == protocolEnum.HTTPS ? <FormattedMessage id="webhook" /> : row.DestinationProtocol == protocolEnum.AWSIoT ? <FormattedMessage id="aws_connector" /> : getProtocol(row.DestinationProtocol)}
          </span>
        );
      },
    },
    // {
    //   dataField: "SourceProtocol",
    //   formatter: (row: any) => <span>{getProtocol(row)}</span>,
    //   text: formatMessage("source_protocol"),
    // },
    // {
    //   dataField: "DestinationProtocol",
    //   formatter: (row: any) => <span>{getProtocol(row)}</span>,
    //   text: formatMessage("destination_protocol"),
    // },
    {
      dataField: "UserId",
      text: formatMessage("user_id"),
      hidden: true,
    },
    {
      dataField: "IsStoreInDb",
      text: formatMessage("store_in_DB"),
      classes: "storeDBCheck",
      hidden: true,
    },
    {
      dataField: "",
      formatter: ActionFormatter([
        {
          dataField: "isEdit",
          handler: openAddConfigurationHandlerTable,
          icon: faPencilAlt,
          isDisable: true,
          title: "edit",
          varient: "primary",
        },
        {
          dataField: "isDelete",
          handler: deleteClickHandler,
          icon: faTrashAlt,
          isDisable: true,
          title: "delete",
          varient: "danger",
        },
      ]),
      text: formatMessage("actions"),
      hidden: getUserType == userTypeEnum.ReadOnly ? true : false,
    },
    // {
    //   dataField: "isEdit, isDelete, isData",
    //   formatter: TableConfigurationFormatter(
    //     openAddConfigurationHandlerTable,
    //     faPencilAlt,
    //     deleteClickHandler,
    //     faTrashAlt
    //   ),
    //   text: formatMessage("actions"),
    //   hidden: getUserType == userTypeEnum.ReadOnly ? true : false,
    // },
  ];

  const getProtocol = (type: any) => {
    return protocolEnum[type];
  };

  const documentationHandler =(div_id:string) =>{
    props.history.push({
      pathname: RoutesConstants.SingleView,
      state: {
        ...props.location.state,
        div_id,
      },
    });
  }

  return (
    <React.Fragment>
      {props.showAddConfigurationPopup && (
        <AddConfiguration
          id={selectedId}
          actionId={selectedAction}
          modalClosed={props.addConfigurationToggleHandler}
        />
      )}
      {props.showConfirmPopup && (
        <ConfirmPopup
          title="delete_configuration"
          modalSubmit={deleteHandler}
          modalClosed={props.confirmToggleHandler}
          message="delete_configuration_confirm"
          dialogClassName="delete_popup"
          category="delete"
          isLoading={deleteConfigurationState.inProgress}
        />
      )}
      {props.showInfoPopup && (
        <InfoPopup
          title="alert"
          modalClosed={infoPopupCloseHandler}
          message="config_delete_warn_confirm"
          dialogClassName="alert_popup"
          isLoading={IsAssociatedState.inProgress}
        />
      )}
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div id="moveContent">
          {inProgress && <FormLoader loading={inProgress} />}
          <div className="container-fluid">
            <div className="row no-gutters align-items-center">
              <div className="col-sm-6 col-12">
                <div className="filter-left-area">
                  <ul className="d-flex align-items-center">
                    <li>
                      <p className="h5 pl-5 mb-0">
                        <FormattedMessage id="protocol_config" />
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="add-list configuration-list">
                  <ul className="d-flex">
                  {isFilterActive == true ? (
                      <li>
                        <button
                          data-testid="FilterButton"
                          className="btn btn-light btn-sm mr-1"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={resetFilter}
                          title={formatMessage("reset_filter")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            width="20px"
                            height="20px"
                            viewBox="0 0 122.88 110.668"
                            enable-background="new 0 0 122.88 110.668"
                          >
                            <g>
                              <path
                                fill="currentColor"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M91.124,15.645c12.928,0,23.406,10.479,23.406,23.406 c0,12.927-10.479,23.406-23.406,23.406c-12.927,0-23.406-10.479-23.406-23.406C67.718,26.125,78.197,15.645,91.124,15.645 L91.124,15.645z M2.756,0h117.322c1.548,0,2.802,1.254,2.802,2.802c0,0.848-0.368,1.622-0.996,2.139l-10.667,13.556 c-1.405-1.375-2.95-2.607-4.614-3.672l6.628-9.22H9.43l37.975,46.171c0.59,0.516,0.958,1.254,0.958,2.102v49.148l21.056-9.623 V57.896c1.651,1.9,3.548,3.582,5.642,4.996v32.133c0,1.105-0.627,2.064-1.586,2.506l-26.476,12.758 c-1.327,0.773-3.023,0.332-3.798-1.033c-0.258-0.441-0.368-0.92-0.368-1.4V55.02L0.803,4.756c-1.07-1.106-1.07-2.839,0-3.945 C1.355,0.258,2.056,0,2.756,0L2.756,0z M96.93,28.282c1.328-1.349,3.489-1.355,4.825-0.013c1.335,1.342,1.341,3.524,0.013,4.872 l-5.829,5.914l5.836,5.919c1.317,1.338,1.299,3.506-0.04,4.843c-1.34,1.336-3.493,1.333-4.81-0.006l-5.797-5.878l-5.807,5.889 c-1.329,1.349-3.489,1.355-4.826,0.013c-1.335-1.342-1.341-3.523-0.013-4.872l5.83-5.913l-5.836-5.919 c-1.317-1.338-1.3-3.507,0.04-4.843c1.339-1.336,3.492-1.333,4.81,0.006l5.796,5.878L96.93,28.282L96.93,28.282z"
                              />
                            </g>
                          </svg>
                        </button>
                      </li>
                    ) : (
                      ""
                    )}
                    {allConfigurationslist.length > 0 && (
                      <li>
                        <div className="form-check tableAccordian-switch p-0 m-0">
                          <input
                            className="ml-1 form-check-input"
                            type="checkbox"
                            role="switch"
                            data-testid="SwitchConfigurationView"
                            checked={tableView}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              closePanelhandler();
                              tableViewToggleHandler(e);
                            }}
                            id="flexSwitchCheckDefault"
                          />
                          <label htmlFor="flexSwitchCheckDefault">
                            <svg
                              width="17"
                              height="17"
                              version="1.1"
                              viewBox="0 0 32 32"
                            >
                              <path
                                fill="currentColor"
                                d="M-0.4-0.4v32.7h32.7V-0.4H-0.4z M2.6,2.6h7.4v7.4H2.6V2.6z M13,2.6H19v7.4H13V2.6z M21.9,2.6h7.4v7.4h-7.4V2.6z M2.6,13h7.4
  V19H2.6V13z M13,13H19V19H13V13z M21.9,13h7.4V19h-7.4V13z M2.6,21.9h7.4v7.4H2.6V21.9z M13,21.9H19v7.4H13V21.9z M21.9,21.9h7.4
  v7.4h-7.4V21.9z"
                              />
                            </svg>

                            <svg
                              width="17"
                              height="17"
                              version="1.1"
                              viewBox="0 0 17 17"
                            >
                              <path
                                d="M4 0v9h13v-9h-13zM16 8h-11v-7h11v7zM0 3h3v-3h-3v3zM1 1h1v1h-1v-1zM4 13h13v-3h-13v3zM5 11h11v1h-11v-1zM0 13h3v-3h-3v3zM1 11h1v1h-1v-1zM4 17h13v-3h-13v3zM5 15h11v1h-11v-1zM0 17h3v-3h-3v3zM1 15h1v1h-1v-1z"
                                fill="currentColor"
                              />
                            </svg>
                          </label>
                        </div>
                      </li>
                    )}

                    {getUserType == userTypeEnum.ReadOnly ? (
                      ""
                    ) : (
                      <li>
                        <div className="dropdown">
                          <button
                            data-testid="AddConfigurationButton"
                            className="btn btn-primary dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            + <FormattedMessage id="add_configuration" />
                          </button>
                          <ul className="dropdown-menu pt-0">
                            <li>
                              <h6 className="dropdown-header pb-0">
                                <FormattedMessage id="destination_protocol" />
                              </h6>
                              <div className="dropdown-divider"></div>
                            </li>
                            <li data-testid="AddHTTPSConfiguration">
                              <a
                                className="dropdown-item"
                                onClick={(event: any) =>
                                  openAddConfigurationHandler(
                                    {
                                      Id: -1,
                                      ProtocolId: 2,
                                    },
                                    event
                                  )
                                }
                              >
                                <img src={webhookIcon} alt="" />{" "}
                                <FormattedMessage id="webhook" />
                              </a>
                            </li>
                            <li className="pr-2">
                              <a
                                  className="dropdown-item"
                                  onClick={(event: any) =>
                                    openAddConfigurationHandler(
                                      {
                                        Id: -1,
                                        ProtocolId: 4,
                                      },
                                      event
                                    )
                                  }
                                >
                                <img src={awsIcon} alt="" />{" "}
                                <FormattedMessage id="aws_connector" />
                              </a>
                            </li>
                            {/* <li className="pr-2">
                              <a
                                  className="dropdown-item"
                                  onClick={(event: any) =>
                                    openAddConfigurationHandler(
                                      {
                                        Id: -1,
                                        ProtocolId: 3,
                                      },
                                      event
                                    )
                                  }
                                >
                                <img src={awsIcon} alt="" />{" "}
                                <FormattedMessage id="aws_connector_mqtt" />
                              </a>
                            </li> */}
                            <li className="disabled-li pr-2">
                              <a className="dropdown-item">
                                <img src={azureIcon} alt="" />{" "}
                                <FormattedMessage id="azure_connector" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto">
        {tableView == true ? (
          <div className="table_items_list">
            {allConfigurationslist.length > 0 ? (
              <div
                className={`card mt-3 changingPanel ${
                  openPanel ? "panelOpen" : ""
                }`}
              >
                <div className="tableDesign row-eq-height tableDevices ">
                  <div
                    className="table-responsive"
                    data-testid="ConfigurationListTable"
                  >
                    {allConfigurationslist && (
                      <Grid
                        data={allConfigurationslist}
                        columns={columns}
                        keyField="Id"
                        loading={false}
                        rowClasses={rowClassFormat}
                        rowEvents={{ onClick: rowEvents }}
                      />
                    )}
                  </div>
                </div>
                {allConfigurationslist.length > 0 && (
                  <div className="row no-gutters p-2 tableFooter">
                    <div className="col-sm-6 pt-1">
                      <FormattedMessage id="show" />
                      <select
                        data-testid="pageSelect"
                        className="pageLimit"
                        onChange={onPageLimitChanged}
                        value={pageLimitInfo.pageLimit}
                      >
                        {pageLimitOptions.map((op: any) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </select>
                      {/* <FormattedMessage id="config_per_page" /> */}
                    </div>
                    <div className="col-sm-6 d-flex justify-content-sm-end">
                      <Pagination
                        isRefreshPage={false}
                        totalPages={pageInfo.totalPages}
                        pageLimit={pageLimitInfo.pageLimit}
                        onPageChanged={onPageChanged}
                        disablePagination={disablePagination}
                        setIsRefreshPage={setIsRefreshPage}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="message text-center configuration-list">
                <img src={no_config} alt="" className="no_message" />
                <p className="noData text-muted h4 mb-0">
                  <FormattedMessage id="no_configs" />
                </p>
                <p className="mb-3">
                  <FormattedMessage id={"no_configs_detail"} />
                </p>
                {getUserType !== userTypeEnum.ReadOnly && (
                  <div className="dropdown">
                          <button
                            data-testid="AddConfigurationButton"
                            className="btn btn-primary dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            + <FormattedMessage id="add_configuration" />
                          </button>
                          <ul className="dropdown-menu pt-0">
                            <li>
                              <h6 className="dropdown-header pb-0">
                                <FormattedMessage id="destination_protocol" />
                              </h6>
                              <div className="dropdown-divider"></div>
                            </li>
                            <li data-testid="AddHTTPSConfiguration">
                              <a
                                className="dropdown-item"
                                onClick={(event: any) =>
                                  openAddConfigurationHandler(
                                    {
                                      Id: -1,
                                      ProtocolId: 2,
                                    },
                                    event
                                  )
                                }
                              >
                                <img src={webhookIcon} alt="" />{" "}
                                <FormattedMessage id="webhook" />
                              </a>
                            </li>
                            {/* disabled-li */}
                            <li className="pr-2">
                              <a
                                  className="dropdown-item"
                                  onClick={(event: any) =>
                                    openAddConfigurationHandler(
                                      {
                                        Id: -1,
                                        ProtocolId: 4,
                                      },
                                      event
                                    )
                                  }
                                >
                                <img src={awsIcon} alt="" />{" "}
                                <FormattedMessage id="aws_connector" />
                              </a>
                            </li>
                            <li className="disabled-li pr-2">
                              <a className="dropdown-item">
                                <img src={azureIcon} alt="" />{" "}
                                <FormattedMessage id="azure_connector" />
                              </a>
                            </li>
                          </ul>
                        </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="accordian_items_list pt-3">
            <div className="tableDesign row-eq-height2 tableDevices ">
              {allConfigurationslist.map((configuration: any, index: any) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      className="mt-1 accordion"
                      id={"accordion" + configuration.Id}
                    >
                      <div className="accordion-item">
                        <div className="accordion-header position-relative">
                          {getUserType == userTypeEnum.ReadOnly ? (
                            ""
                          ) : (
                            <>
                              <FontAwesomeIcon
                                icon={faPencilAlt}
                                data-testid={
                                  "EditConfigurationId" + configuration.Id
                                }
                                onClick={(event: any) =>
                                  openAddConfigurationHandler(
                                    {
                                      Id: configuration.Id,
                                      ProtocolId:
                                        configuration.DestinationProtocol,
                                    },
                                    event
                                  )
                                }
                                className="css-i6dzq1 editIcon text-primary"
                              />
                              <FontAwesomeIcon
                                data-testid={
                                  "DeleteConfigurationId" + configuration.Id
                                }
                                icon={faTrashAlt}
                                onClick={(event: any) =>
                                  deleteClickHandler(
                                    { Id: configuration.Id },
                                    event
                                  )
                                }
                                className="css-i6dzq1 deleteIcon text-danger"
                              />
                            </>
                          )}

                          <button
                            className="fs14 bg-monoz-light accordion-button collapsed"
                            type="button"
                            data-testid={
                              "OpenCloseConfiguration" + configuration.Id
                            }
                            data-bs-toggle="collapse"
                            data-bs-target={"#" + "collapse" + configuration.Id}
                            aria-expanded="false"
                            aria-controls={"collapse" + configuration.Id}
                          >
                            {index + 1 + ": " + configuration.Name}

                            <div>
                              <h4 className="badge bg_source  m-0 p-1 ml-2">
                                {protocolEnum[configuration.SourceProtocol]}
                              </h4>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#AAB7B8"
                                className="bi bi-arrow-right mx-1"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                />
                              </svg>
                              <h4 className="badge bg_destination m-0 p-1">
                                {
                                  protocolEnum[
                                    configuration.DestinationProtocol
                                  ]
                                }
                              </h4>
                            </div>
                          </button>
                        </div>
                        <div
                          id={"collapse" + configuration.Id}
                          className="accordion-collapse collapse"
                          data-bs-parent={"#" + "accordion" + configuration.Id}
                        >
                          <div className="accordion-body">
                            <div className="p-3">
                              <div className="row">
                                {/* <div className="col-6">
                                  <label className="text-muted bold">
                                    <FormattedMessage id={"config_name"} />:{" "}
                                  </label>
                                  <p className="mw-100">{configuration.Name}</p>
                                </div>
                                <div className="col-6">
                                  <label className="text-muted bold">
                                    <FormattedMessage id={"protocol"} />:{" "}
                                  </label>
                                  <p className="mw-100">
                                    <span>
                                      {`${protocolEnum[configuration.SourceProtocol]} `}{" "}
                                      <i className="fa fa-arrow-right text-muted"></i>{" "}
                                      {` ${protocolEnum[configuration.DestinationProtocol]} `}
                                    </span>
                                  </p>
                                </div> */}
                                  <div className="col-12">
                                    { (configuration?.HttpsParameters && configuration.HttpsParameters.Host) &&
                                      <>
                                        <label className="text-muted bold">
                                          <FormattedMessage id={"destination_url"} />
                                        </label>
                                        <p className="mb-3 text-break">
                                          {configuration.HttpsParameters?.Host}
                                        </p>
                                      </>
                                    }
                                    {
                                      configuration?.Description &&
                                      <>
                                        <label className="text-muted bold">
                                          <FormattedMessage id={"desc"} />:{" "}
                                        </label>
                                        <p className="mr-3 text-break">
                                          {configuration.Description}
                                        </p>
                                      </>
                                    }
                                  </div>
                                {
                                  (configuration.DestinationProtocol === protocolEnum.HTTPS) &&
                                  <div className="pt-2 col-lg-3 col-md-6 col-12">
                                    
                                    <label className="text-muted pt-2 bold">
                                      <FormattedMessage
                                        id={"authentication_type"}
                                      />
                                    </label>
                                    <p className="mr-3 text-break">
                                      {formatMessage(
                                        authenticationEnum[
                                          configuration.HttpsParameters
                                            .AuthorizationType
                                        ]
                                      )}
                                    </p>
                                  </div>
                                }

                                <div className="pt-2 col-lg-3 col-md-6 col-12">
                                  <label className="text-muted bold">
                                    <FormattedMessage id={"headers"} />
                                  </label>
                                  {/* <p> */}
                                  {configuration?.HttpsParameters &&
                                  configuration?.HttpsParameters?.CustomHeaders
                                    .length > 0 ? (
                                    configuration?.HttpsParameters?.CustomHeaders?.map(
                                      (headers: any) => {
                                        return (
                                          <>
                                            <p className="header-text">
                                             <span className="text-code">{headers.Key}</span> : <span className="text-code">{headers.Value}</span>
                                            </p>
                                          </>
                                        );
                                      }
                                    )
                                  ) : (
                                    <p>
                                      <FormattedMessage id={"no_headers"} />
                                    </p>
                                  )}
                                  {/* </p> */}
                                </div>
                                <div className="pt-2 col-lg-3 col-md-6 col-12">
                                  <label className="text-muted bold d-flex">
                                    <FormattedMessage id={"body"} />
                                    <svg
                                      onClick={() => documentationHandler("doc_config_help")}
                                      fill="currentColor"
                                      id="Layer_1"
                                      viewBox="0 0 100 100"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="ml-2"
                                      data-name="Layer 1"
                                      strokeWidth="3px"
                                      height="20px"
                                      width="20px"
                                    >
                                      <path d="m50 99.68a49.68 49.68 0 1 1 49.5-49.68 49.64 49.64 0 0 1 -49.5 49.68zm0-94.36a44.68 44.68 0 1 0 44.5 44.68 44.63 44.63 0 0 0 -44.5-44.68z" />
                                      <path d="m50 61.87a2.5 2.5 0 0 1 -2.5-2.5v-4.67a5.13 5.13 0 0 1 4.32-5 10.51 10.51 0 0 0 8.74-10.55 10.77 10.77 0 0 0 -10.4-10.39 10.55 10.55 0 0 0 -10.7 10.56 2.5 2.5 0 0 1 -5 0 15.55 15.55 0 0 1 15.77-15.6 15.67 15.67 0 0 1 15.31 15.28 15.45 15.45 0 0 1 -12.88 15.58c-.09 0-.17.07-.17.12v4.67a2.5 2.5 0 0 1 -2.49 2.5z" />
                                      <path d="m50 74.28a2.49 2.49 0 0 1 -2.5-2.5v-2.07a2.5 2.5 0 0 1 5 0v2.07a2.49 2.49 0 0 1 -2.5 2.5z" />
                                    </svg>
                                  </label>
                                  <p className="">
                                    {configuration?.HttpsParameters
                                      ?.IsSendICCID == true ? (
                                      <FormattedMessage id={"iccid"} />
                                    ) : (
                                      <FormattedMessage id={"topic"} />
                                    )}
                                  </p>
                                </div>
                                <div className="pt-2 col-lg-3 col-md-6 col-12">
                                  <label className="text-muted bold">
                                    <FormattedMessage id={"used_in_groups"} />
                                  </label>
                                  <div>
                                    {configuration?.Groups?.length > 0 ? (
                                      <React.Fragment>
                                      <ul className="d-flex flex-wrap ">
                                      {configuration?.Groups?.map((group: any) => {
                                        return (
                                          <React.Fragment key={group}> 
                                              <li
                                                className="badge badge-warning mr-1 mb-2"
                                                aria-disabled={true}
                                              >
                                                {group.Name}
                                              </li>
                                          </React.Fragment>
                                        );
                                      })}
                                      </ul>
                                    </React.Fragment> ) : (
                                      <FormattedMessage
                                        id={"no_groups_assigned"}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
              {allConfigurationslist.length == 0 && (
                <div className="message text-center">
                  <img src={no_config} alt="" className="no_message" />
                  <p className="noData text-muted h4 mb-0">
                    <FormattedMessage id="no_configs" />
                  </p>
                  <p className="mb-3">
                    <FormattedMessage id="no_configuration" />
                  </p>

                  {getUserType == userTypeEnum.ReadOnly ? (
                    ""
                  ) : (
                    <div className="dropdown">
                      <button
                        className="btn btn-primary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        + <FormattedMessage id="add_configuration" />
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={(event: any) =>
                              openAddConfigurationHandler(
                                {
                                  Id: -1,
                                  ProtocolId: 2,
                                },
                                event
                              )
                            }
                          >
                            HTTPS
                          </a>
                        </li>
                        <li className="disabled-li">
                          <a
                            className="dropdown-item"
                            // onClick={() =>
                            //   openAddConfigurationHandler({
                            //     Id: -1,
                            //     ProtocolId: 3,
                            //   })
                            // }
                          >
                            MQTTS (Currently Unsupported)
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </div>
            {allConfigurationslist.length > 0 && (
              <div className="row no-gutters p-2 tableFooter">
                <div className="col-sm-6 pt-1">
                  <FormattedMessage id="show" />
                  <select
                    data-testid="pageSelect"
                    className="pageLimit"
                    onChange={onPageLimitChanged}
                    value={pageLimitInfo.pageLimit}
                  >
                    {pageLimitOptions.map((op: any) => (
                      <option key={op} value={op}>
                        {op}
                      </option>
                    ))}
                  </select>
                  {/* <FormattedMessage id="config_per_page" /> */}
                </div>
                <div className="col-sm-6 d-flex justify-content-sm-end">
                  <Pagination
                    isRefreshPage={false}
                    totalPages={pageInfo.totalPages}
                    pageLimit={pageLimitInfo.pageLimit}
                    onPageChanged={onPageChanged}
                    disablePagination={disablePagination}
                    setIsRefreshPage={setIsRefreshPage}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <SlidingPanel
        type={"right"}
        isOpen={openPanel}
        noBackdrop={false}
        size={100}
      >
        <div className="bg_slide panel">
          <div className="row bb p-2 pl-4 no-gutters align-items-center">
            <div className="col-lg-11">
              <h2 className="fw-bold mb-0 mt-1">
                {selectedRow.Name}
                {/* <FormattedMessage id={"config_details"} /> */}
              </h2>
            </div>
            <div className="col-lg-1">
              <svg
                onClick={closePanelhandler}
                role="button"
                xlinkTitle="close"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x float-right"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </div>
          </div>
          <div className="group_detailsWrapper  pb-3">
            <ConfigurationDetails selectedData={selectedRow} />
          </div>
        </div>
      </SlidingPanel>
    </React.Fragment>
  );
};

export default InfoPopupHOC(
  DeletePopupHOC(AddConfigurationPopupHOC(observer(Configuration)))
);
