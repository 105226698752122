/*    
<summary>
   This functional common component provides us custom input box that allows values based on regex & has error handler that shows validation error. 
   Developer:Aashish Singh, Created Date:30-Oct-2023
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import { injectIntl } from "react-intl";
import FormikFormErrorHandler from "./FormikFormErrorHandler";
import { useField } from "formik";
import FormikRelayInfoFormErrorHandler from "./FormikFormRelayErrorHandler";

interface IProps {
  children: React.ReactNode;
  className: string;
  errclassname: string | undefined;
  "data-testid"?: string;
  intl: any; //IntlConfig;
  name: string;
  onBlur: () => void;
  onChange: () => void;
  placeholder: string;
  type: string;
  value: string;
  toLowerCase: boolean;
  disabled: boolean;
  readOnly: boolean;
  spaceAllowed: boolean;
  validcharacter?: Array<string>;
  invalidcharacter?: Array<string>;
  regex:RegExp;
}

const FormikFormInputForRegexCheck = (props: IProps) => {
  const [field, meta, helpers] = useField(props);
  const errorClass = `${meta.error && meta.touched ? "error" : ""}`;
  const _placeholder = props.placeholder
    ? props.intl.formatMessage({ id: props.placeholder })
    : props.placeholder;
  
  return (
    <>
      <input
        {...field}
        {...props}
        placeholder={_placeholder}
        className={props.className + " " + errorClass}
        disabled={props.disabled}
        type={props.type}
        readOnly={props.readOnly}
        onChange={(event) => {
          let value = props.toLowerCase ? event.target.value.toLowerCase() : event.target.value;
          if(props.regex.test(value))
            helpers.setValue(value);
          else{
            event?.preventDefault()
          }
        }}
      />
      {props.errclassname != undefined ||
      props.className == "relay_max_character_error" ? (
        <FormikRelayInfoFormErrorHandler intl={props.intl} meta={meta} />
      ) : (
        <FormikFormErrorHandler intl={props.intl} meta={meta} />
      )}
    </>
  );
};

export default injectIntl(FormikFormInputForRegexCheck);
