/*    
<summary>
  This functional component "AddDevicePopupHOC" provides props to the child component.
  1. State "showAddDevicePopup" that manages the show/hide of pop up
  2. Function "addDeviceToggleHandler" to toggle the show/hide popup state "showAddDevicePopup"
  Developer:Aashish Singh, Created Date:29-March-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";

const AddConfigurationPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showAddConfigurationPopup, setShowAddConfigurationPopup] =
      useState<boolean>(false);

    const addConfigurationToggleHandler = () => {
      setShowAddConfigurationPopup(!showAddConfigurationPopup);
    };

    return (
      <Component
        showAddConfigurationPopup={showAddConfigurationPopup}
        addConfigurationToggleHandler={addConfigurationToggleHandler}
        {...props}
      />
    );
  };

export default AddConfigurationPopupHOC;
