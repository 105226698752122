/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   Developer: Aashish Singh, Created Date: 22-Nov-2023 
</summary>
*/
enum userActionEnum{
    None,
    Fetch,
    Add,
    Update,
    Delete,
    Move,
    GroupBlock,
    GroupUnblock,
    Login,
    Logout,
    IMEILock,
    IMEIUnlock,
    UpdateDefaultGroup,
    PasswordReset,
    PasswordChanged,
    ActivateUser,
    DeactivateUser,
    Enable,
    Disable,
    SIMReset,
    AddBulk,
    AddBulkByCSV,
    DeleteBulk,
    AutoRegistrationServiceBulk,
    ActionEngineService,
    SIMRecharge,
    SIMExtend,
    PublishMessage   
}

export default userActionEnum;