/*    
<summary>
  This functional component "DefaultPopupHOC" provides props to the child component.
  1. State "showDefaultPopup" that manages the show/hide of pop up
  2. Function "DefaultPopupToggleHandler" to toggle the show/hide popup state "showDefaultPopup"
  Developer:Aashish Singh, Created Date:11-Dec-2023
</summary>
<returns>Returns JSX</returns>
*/

import React, {useState } from 'react';

const DefaultPopupHOC = (Component:any) => 
function Comp(props:any){
    const [showDefaultPopup,setShowDefaultPopup] = useState<boolean>(false);

    const DefaultToggleHandler = () => {
        setShowDefaultPopup(!showDefaultPopup);
    }
	
	return <Component showDefaultPopup={showDefaultPopup} defaultPopupToggleHandler={DefaultToggleHandler} {...props}/>;
};

export default DefaultPopupHOC;   