/*    
<summary>
  This functional component "ConfigurationSettingForm" provides form to add new configuration settingForm or 
  update configuration setting of IoT device simulator.
  Developer:Mohammmad Saquib Khan, Created Date: 29-Mar-2023, Last Updated By: Aashish Singh, Updated Date: 27-Sept-2023
</summary>
<returns>Returns JSX</returns>
*/
import { Formik, Form, Field, useFormikContext } from "formik";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import "./AddBulkForm.css";
import LoaderButton from "../../../../shared-components/Button/LoaderButton";
import FormikFormInput from "../../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSelect from "../../../../shared-components/FormikFormHandlers/FormikFormSelect";
import Grid from "../../../../shared-components/Grid/Grid";
import { formatMessage } from "../../../../translations/format-message";
import IAddDeviceBulk from "../../../../models/Form/IAddDeviceInBulk";
import FormLoader from "../../../../shared-components/FormLoader/FormLoader";
import { bulkDeviceSchema } from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import FormikFormCheckBox from "../../../../shared-components/FormikFormHandlers/FormikFormCheckBox";
import toast, { Toaster } from "react-hot-toast";
import RoutesConstants from "../../../../shared-components/Routes/routes.constants";
import { NavLink } from "react-router-dom";
import Label from "../../../../shared-components/Label/Label";
import { Tooltip } from "react-tooltip";
import { useStore } from "../../../../contexts/StoreProvider";

interface IProps {
  submitHandler: (data: any) => void;
  initialValues: IAddDeviceBulk;
  isAdding: boolean;
  groupList: any;
}

const AddBulkForm = (props: any) => {
  const { deviceStore, preferencesStore } = useStore();
  const { getAddBulkCount } = preferencesStore;
  const { addBulkDeviceState, resetAddBulkDeviceState } = deviceStore;
  const [startIccid, setStartIccid] = useState(BigInt(0));
  const [endIccid, setEndIccid] = useState(BigInt(0));
  const [deviceName, setDeviceName] = useState<string>("");
  const [devices, setDevices] = useState<any>([]);
  const [loaders, setLoaders] = useState<boolean>(false);
  const [errorStartIccid, setErrorStartIccid] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [tags, setTags] = useState<any>([]);
  const [inputValue, setInputValue] = useState("");
  let resetPresForm = () => {}; // This function dtores the resetForm function
  /**
   * Area Block for Get functions/variables from store class
   * UpdatedBy:Mohammad Saquib Khan ,Date:10-April-2023
   */

  const { submitHandler, groupList, isAdding } = props;

  const startIccidHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartIccid(BigInt(e.target.value));
  };
  const endIccidHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndIccid(BigInt(e.target.value));
  };
  const deviceNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDeviceName(e.target.value);
  };

  const handleInputChange = (event: any) => {
    event.target.value = event.target.value.replace(/'/g, '');
    if(event.target.value?.length <= 10){
      setInputValue(event.target.value);
    }
    else{
      setError("tag_character_limit");
      clearErrorMessage(4000); 
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Tab" || event.key === "Enter") {
      event.preventDefault();
      addTags();
    }
  };

  const addTags = () => {
    let tempTagValue = inputValue.trim();
    if (tempTagValue) {
      if (tags.includes(tempTagValue)) {
        setError("tag_exist");
        clearErrorMessage(3000); // Display error for 5 seconds
        return;
      }

      if (tags.length >= 5) {
        setError("tags_limit_reached");
        clearErrorMessage(3000); // Display error for 5 seconds
        return;
      }

      setTags([...tags, tempTagValue]);
      setInputValue("");
      clearErrorMessage(); // Clear error message if a valid tag is added
    }
  };

  const handleTagDelete = (tag: any) => {
    setTags(tags.filter((t: any) => t !== tag));
  };

  const clearErrorMessage = (timeout = 0) => {
    setTimeout(() => {
      setError("");
    }, timeout);
  };

  const generateIccidDevices = () => {
    if(startIccid != BigInt(0) && endIccid != BigInt(0) ){
    let temp_arr: any = [];
    let totalDevices = endIccid - startIccid;
    if(totalDevices <= 10000){
      setLoaders(true);
      setTimeout(() => {
        for (let i = startIccid; i <= endIccid; i++) {
          var obj = {
            Name: deviceName.trim() + " " + i,
            Iccid: i.toString(),
            Tags: tags.length > 0 ? tags : [],
          };
          temp_arr.push(obj);
        }
        setDevices(temp_arr);
        setLoaders(false);
      }, 1000);
    }
    else{
      toast.error(formatMessage("MaxBulkDeviceCountExceed"));
      setDevices([])
    }
  }
  };

  const columns: any = [
    {
      dataField: "Name",
      text: formatMessage("device_name"),
    },
    {
      dataField: "Iccid",
      text: formatMessage("iccid"),
    },
  ];

  /**
   * This functional component stores the reset form function in the resetPresentForm variable
   */
  const ResettingForm = () => {
    const { resetForm } = useFormikContext();
    resetPresForm = resetForm; // Store the value of resetForm in this variable
    return null;
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addBulkDeviceState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addBulkDeviceState.success) {
      toast.success(formatMessage("added_success"));
      setDevices([]);
      setTags([]);
      resetPresForm();
      resetAddBulkDeviceState();
    }
  }, [addBulkDeviceState.success]);

  return (
    <>
      {<FormLoader loading={loaders} />}
      <div className="mx-auto">
        <Formik
          initialValues={props.initialValues}
          validationSchema={bulkDeviceSchema}
          enableReinitialize
          validateOnBlur={false}
          onSubmit={async (values: any) => {
            delete values.api_type;
            values.GroupId = Number(values.GroupId);
            let data: IAddDeviceBulk = { ...values };
            data.AddDevices = devices;
            if (startIccid > endIccid) {
              setErrorStartIccid("iccid_validation");
            } else {
              setErrorStartIccid("");
            }

            if (errorStartIccid == "" && error == "") {
              if (devices.length == 0) {
                toast.error(formatMessage("no_device_generated"));
              } else {
                submitHandler(data);
              }
            }
          }}
          render={({ values }) => {
            return (
              <Form id="formBody" className="mt-3">
                {isAdding && (
                  <FormLoader
                    loading={getAddBulkCount > 0 ? false : isAdding}
                  />
                )}
                <div className="row">
                  <div className="col-lg-8 col-12">
                    <div className="card card-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group formSet">
                            <Label
                              className="label-style mb-0"
                              required={true}
                              label="group"
                            />
                            <Field
                              data-testid="BulkGroup"
                              className="form-control"
                              name="GroupId"
                              options={groupList}
                              as={FormikFormSelect}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group formSet">
                            <Label
                              className="label-style mb-0"
                              required={true}
                              label={formatMessage("device_name_prefix")}
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              fill="currentColor"
                              className="bi bi-info-circle ml-2"
                              viewBox="0 0 16 16"
                              data-tooltip-id="my-tooltip-bulk"
                              data-tooltip-html={formatMessage(
                                "bulk_device_name_validation"
                              )}
                              data-tooltip-variant="dark"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                            <Tooltip id="my-tooltip-bulk" place="right" />
                            <Field
                              data-testid="BulkDeviceName"
                              className="form-control"
                              name="Name"
                              validcharacter={["@", "#", "$", "-", "_", "."]}
                              placeholder="device_name_prefix"
                              type="text"
                              onKeyUp={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                deviceNameHandler(e);
                              }}
                              as={FormikFormInput}
                            />
                          </div>
                        </div>
                        <div className="col-12 mt-1">
                          <div className="form-group formSet">
                            <label className="label-style">
                              <FormattedMessage id="tags" />
                              <small className="text-muted">
                                {" "}
                                (<FormattedMessage id="tags_max" />)
                              </small>
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                value={inputValue}
                                className="form-control"
                                placeholder={formatMessage("tags_enter")}
                                onChange={(e) => handleInputChange(e)}
                                onKeyDown={handleKeyDown}
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-secondary"
                                  type="button"
                                  onClick={() => addTags()}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>

                            <div>
                              {tags.map((tag: any, index: any) => (
                                <span
                                  className="bgTag primary tag mr-2"
                                  key={index}
                                >
                                  {tag}
                                  <button
                                    type="button"
                                    className="close"
                                    onClick={() => handleTagDelete(tag)}
                                  >
                                    &times;
                                  </button>
                                </span>
                              ))}
                            </div>
                            {error != "" ? (
                              <span className="text-danger errorMessage">
                                <FormattedMessage id={error} />
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {/* <div className="col-12">
                          <div className="checkbox mb-3">
                            <label>
                              <Field
                                data-testid="BulkIsEnable"
                                className="form-control"
                                name="IsEnable"
                                role="switch"
                                type="checkbox"
                                id="inlineFormCheck"
                                as={FormikFormCheckBox}
                              />
                              <span className="cr">
                                <i className="cr-icon fas fa-check"></i>
                              </span>
                            </label>
                            <label className="h">
                              <FormattedMessage id={"is_enabled"} />
                            </label>
                          </div>
                        </div> */}
                      </div>
                      <div className="d-flex">
                        <ul
                          className="nav nav-tabs radio_tabs"
                          id="myTab"
                          role="tablist"
                        >
                          <li
                            data-testid="CustomAPITab"
                            className="nav-item"
                            role="presentation"
                          >
                            <input
                              type="radio"
                              value="1"
                              name="api_type"
                              id="custom-tab"
                              className="nav-link active"
                              data-bs-toggle="tab"
                              data-bs-target="#custom"
                              role="tab"
                              aria-controls="custom"
                              aria-selected="false"
                              data-testid="customAPI"
                            />
                            <label htmlFor="custom-tab">
                              <FormattedMessage id="custom_api" />
                            </label>
                          </li>
                          <li
                            data-testid="OperatorAPITab"
                            className="nav-item"
                            role="presentation"
                          >
                            <input
                              type="radio"
                              value="0"
                              name="api_type"
                              id="operator-tab"
                              className="nav-link"
                              disabled={true}
                              data-bs-toggle="tab"
                              data-bs-target="#operator"
                              role="tab"
                              aria-controls="operator"
                              aria-selected="true"
                              data-testid="OperatorAPI"
                            />
                            <label htmlFor="operator-tab">
                              <FormattedMessage id="operator_api" /> <br />
                              (Currently Unsupported)
                            </label>
                          </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="custom"
                            role="tabpanel"
                            aria-labelledby="custom-tab"
                          >
                            <div className="form-group">
                              <Label
                                className="label-style"
                                required={true}
                                label="start_iccid"
                              />
                              <Field
                                data-testid="StartICCIDBulk"
                                className="form-control"
                                name="StartICCID"
                                placeholder="start_iccid"
                                type="number"
                                onKeyUp={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  startIccidHandler(e);
                                }}
                                as={FormikFormInput}
                              />
                            </div>
                            <div className="form-group">
                              <Label
                                className="label-style"
                                required={true}
                                label="end_iccid"
                              />
                              <Field
                                data-testid="EndICCIDBulk"
                                className="form-control"
                                name="EndICCID"
                                placeholder="end_iccid"
                                type="number"
                                onKeyUp={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  endIccidHandler(e);
                                }}
                                as={FormikFormInput}
                              />
                            </div>
                            <div className="my-2">
                              <LoaderButton
                                id="GenerateBulkDevices"
                                onClick={generateIccidDevices}
                                variant="secondary"
                                disabled={
                                  deviceName.trim() == "" ? true : false
                                }
                                isLoading={false}
                                text={"generate"}
                              />
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="operator"
                            role="tabpanel"
                            aria-labelledby="operator-tab"
                          >
                            <div className="form-group">
                              <label className="label-style">
                                {formatMessage("account")}
                              </label>
                              <Field
                                data-testid="Account"
                                className="form-control"
                                name="account"
                                placeholder="account"
                                type="text"
                                as={FormikFormInput}
                              />
                            </div>
                            <div className="row">
                              <div className="form-group col-sm-6">
                                <label className="label-style">
                                  {formatMessage("user_id")}
                                </label>
                                <Field
                                  data-testid="UserIDBulk"
                                  className="form-control"
                                  name="user_id"
                                  placeholder="user_id"
                                  type="text"
                                  as={FormikFormInput}
                                />
                              </div>
                              <div className="form-group col-sm-6">
                                <label className="label-style">
                                  {formatMessage("password")}
                                </label>
                                <Field
                                  data-testid="PasswordBulk"
                                  className="form-control"
                                  name="password"
                                  placeholder="password"
                                  autoComplete="new-password"
                                  type="password"
                                  as={FormikFormInput}
                                />
                              </div>
                              <div className="col-12">
                                <div className="my-1">
                                  <LoaderButton
                                    id="FetchOperator"
                                    type="submit"
                                    variant="secondary"
                                    isLoading={false}
                                    text={"fetch"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer p-0 border-0 flex-column align-items-start justify-content-start bulkFormFooter">
                        <div className="form-group m-0  ">
                          <div className="px-0">
                            <LoaderButton
                              id="SaveBulkDevices"
                              type="submit"
                              variant="primary"
                              disabled={getAddBulkCount > 0 ? true : false}
                              text="button_save"
                            />

                            <NavLink
                              className="btn btn-secondary ml-2"
                              to={RoutesConstants.Device}
                            >
                              <FormattedMessage id="button_cancel" />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                      <ResettingForm />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card h-100">
                      <div className="tableDesign heightTable">
                        <div
                          className="table-responsive"
                          data-testid="BulkGeneratedDevices"
                        >
                          <Grid
                            data={devices}
                            columns={columns}
                            keyField="Name"
                          />
                        </div>
                        {devices?.length == 0 ? (
                          <div className=" p-3 no_data_grid text-center h5 text-muted">
                            <FormattedMessage id="no_data" />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        ></Formik>
      </div>
    </>
  );
};

export default observer(AddBulkForm);
