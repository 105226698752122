/*    
<summary>
   This class component is all about Managing transactions data functionality.
   Developer:Aashish Singh, Created Date:31-Oct-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import { initialState as GetAllTransactionState } from '../initial-state/get-all-transactions-state';
import { action, computed, makeObservable, observable } from 'mobx';
import { ICommonState } from '../../models/state/ICommonState';
import IApiResponse, { IApiSuccessResponse } from '../../models/response/IApiResponse';
import URLConstants from '../../constants/url.constants';
import * as baseService from '../service/base-service';
import { formatMessage } from '../../translations/format-message';
import toast from 'react-hot-toast';
import moment from 'moment';
import config from '../../helpers/config-helper';
import { IPaymentTransactionListVM, ITransactionDetailsVM, ITransactionsList } from '../../models/response/ITransactionResponse';
import { ITransactionState } from '../../models/state/ITransactionState';

const appConfig = config();
const dateTimeFormat =  appConfig.REACT_APP_DATE_TIME_FORMAT;

export class TransactionStore implements ITransactionState, ICommonState {
    inProgress = false;
    error = '';
    transactionList: IPaymentTransactionListVM = GetAllTransactionState

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            transactionList: observable,
            GetPaymentTransactionsService: action,
            reset: action,
            resetStore: action,
            allTransaction: computed,
        });
    }

    /*
    This function is used to Get All Transactions data from API.  
    */

    GetPaymentTransactionsService = (pageNumber: number, pageSize: number) => {
        this.inProgress = true;
        const url = URLConstants.Transactions + "/" + "?PageNo=" + pageNumber + "&PageSize=" + pageSize;;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IPaymentTransactionListVM>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.transactionList = response.data.Data;
                }
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

    /*
    This function is used to map transactionList to allTransaction List suitable for Grid Component.  
    */

    get allTransaction(): ITransactionsList[] {
        if (this.transactionList?.Transactions && this.transactionList.Transactions?.length > 0)
            return this.transactionList.Transactions.map((transaction:ITransactionDetailsVM) => {
                return {
                    Id: transaction.Id,
                    TenantName: transaction?.TenantName,
                    BillGenerationDate: transaction?.BillGenerationDate ? moment(transaction.BillGenerationDate).format(dateTimeFormat) : "",
                    PaymentRecieved: Number(transaction?.PaymentRecieved).toFixed(2),
                    Remarks: transaction?.Remarks,
                    PaymentTimestamp: moment(transaction?.PaymentTimestamp).format(dateTimeFormat),
                    Currency: transaction?.Currency,
                }
            })
        return [];
    }

    /*
    This function is used to reset all observables to their initial values.  
    */
    reset = () => {
        this.error = '';
        this.inProgress = false;
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.transactionList = GetAllTransactionState;
    }

}

export default new TransactionStore();
