/*    
<summary>
  This functional component "UserProfile" provides user setting functionality like changing language with the help "setting" tab props like initial values.
  Developer:Aashish Singh, Created Date: 08-Mar-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useRef } from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";
import { formatMessage } from "../../../../translations/format-message";
import Settings from "../../forms/LanguageSettings";
import "./UserProfile.css";
import { useStore } from "../../../../contexts/StoreProvider";
import { observer } from "mobx-react";
import FormLoader from "../../../../shared-components/FormLoader/FormLoader";
import ChangeLoggedInUserPassword from "../change-logged-in-user-password";
import userTypeEnum from "../../../../constants/user-type-enum";
import { FormattedMessage } from "react-intl";
import toast from "react-hot-toast";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface IProps {
  userName: string;
  userType: string;
  onModalClosed: () => void;
}

const UserProfile: React.FC<IProps> = (props) => {
  const { authStore, tenantStore } = useStore();
  const { getUserType, getUserId } = authStore;
  const { tenantDetails, tenantState} = tenantStore;

  const ControlledTabs = () => {

    return (
      <div className="user-profile-popup">
        <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
          <Tab eventKey={1} title={formatMessage("language")}>
            <Settings modalClosed={props.onModalClosed} />
          </Tab>
          <Tab eventKey={2} title={formatMessage("change_password")}>
            <ChangeLoggedInUserPassword
                    id={getUserId ? Number(getUserId) : -2}
                    modalClosed ={props.onModalClosed}
               />
          </Tab>
        </Tabs>
        </div>
    );
  };

  const handleCopyClick = (secretKey:any) => {
      const textField = document.createElement('textarea');
      textField.innerText = secretKey;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      toast.success(formatMessage("code_copied"));
  };

  return (
    <Modal
      className="add-profile-modal "
      show={true}
      centered
      onHide={props.onModalClosed}
    >
      <Modal.Header
      >
        <Modal.Title>
          {tenantState.inProgress ? (
              <FormLoader loading={tenantState.inProgress} />
            ) : null}
          {/* <h2 className="m-0 text-white">{getEmail ? getEmail : ""}</h2> */}
          <h3 className="m-0 text-white">{tenantDetails?.TenantName ? tenantDetails?.TenantName : ""}</h3>
          <div className="d-flex small justify-content-center">
            <p className="border-right text-white px-2">{formatMessage("role")}: {getUserType && formatMessage(getUserType)}</p>
            <p className="text-white px-2">{tenantDetails.OrganizationName}</p>
          </div>
          <div className="d-flex small justify-content-center">
            <p className="border-right text-white px-2"><FormattedMessage id="user_limit" /> : {tenantDetails?.MaxUserAllow}</p>
            <p className="text-white px-2"><FormattedMessage id="device_limit" /> : {tenantDetails?.MaxDeviceAllow}</p>
          </div>
            {/* {
              getUserType == userTypeEnum.TenantAdministrator &&
              <div className="d-flex small">
                <p className="border-right text-white px-2">{tenantDetails.ApiKey}</p>
                <p className="text-white px-2">{tenantDetails.ApiKeySecretValue}</p>
              </div>
            } */}
          <button
              onClick={props.onModalClosed}
              disabled={tenantState.inProgress}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          getUserType == userTypeEnum.TenantAdministrator &&
          <>
          <div className="row small">
            <div className="col-4">
              <label className="text-muted small"><FormattedMessage id="account_id_profile" /></label>
            </div>
            <div className="col-8">
            <p>{tenantDetails.OrganizationId}</p>
            </div>
          </div>
         <div className="row mb-3 small position-relative" >
            <div className="col-4">
              <label className="text-muted small"><FormattedMessage id="key" /></label>
              <p>{tenantDetails.ApiKey}</p>
            </div>
            <div className="col-8 position-relative">
              <div className="copy-code">
              <CopyToClipboard
                text={tenantDetails.ApiKeySecretValue!}
                onCopy={() => toast.success(formatMessage("code_copied"))}>
                {/* <button> */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#a6a6a6" className="bi bi-copy" viewBox="0 0 16 16">
                    <title>{formatMessage("copy_secret_key")}</title>
                    <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
                  </svg>
                {/* </button> */}
     </CopyToClipboard>
              </div>
              <label className="text-muted small"><FormattedMessage id="value" /></label>
              
              <div
              style={{ userSelect: 'none',}}
              contentEditable={false}
              suppressContentEditableWarning={true}
            >{tenantDetails?.ApiKeySecretValue}</div>
            </div>
          </div>
          </>
        }
        <ControlledTabs />
      </Modal.Body>
    </Modal>
  );
};

export default observer(UserProfile);
