/*    
<summary>
   This class component is all about Managing tenantCharge data functionality.
   Developer:Aashish Singh, Created Date:25-Oct-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import { initialState as GetAllTenantChargeState } from '../initial-state/get-all-tenant-charge-state';
import { action, computed, makeObservable, observable } from 'mobx';
import { ICommonState } from '../../models/state/ICommonState';
import IApiResponse, { IApiSuccessResponse } from '../../models/response/IApiResponse';
import { ICurrencyVM, IGetAllTenantCharge, ITenantChargesVM } from '../../models/response/ITenantChargeResponse';
import { IAddTenantCharge } from '../../models/Form/IAddTenantCharge';
import { ICountryCurrencyOption, IObservableInitialState } from '../../models/ICommon';
import URLConstants from '../../constants/url.constants';
import * as baseService from '../service/base-service';
import { formatMessage } from '../../translations/format-message';
import toast from 'react-hot-toast';
import moment from 'moment';
import config from '../../helpers/config-helper';
import { ITenantChargeState } from '../../models/state/ITenantChargeState';

const appConfig = config();
const dateTimeFormat =  appConfig.REACT_APP_DATE_TIME_FORMAT;

export class TenantChargeStore implements ITenantChargeState, ICommonState {
    inProgress = false;
    error = '';
    tenantChargeList: IGetAllTenantCharge = GetAllTenantChargeState;
    
    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }
    
    allCountryCurrencies: ICurrencyVM[] = [];
    countryCurrencyState:IObservableInitialState ={...this.initialStateValue}

    addTenantChargeState ={...this.initialStateValue }
    

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            tenantChargeList: observable,
            allCountryCurrencies: observable,
            countryCurrencyState: observable,
            addTenantChargeState: observable,
            GetTenantChargesListService: action,
            GetAllCountryCurrencyService: action,
            AddTenantChargesService: action,
            reset: action,
            resetStore: action,
            resetAddTenantChargeState: action,
            allTenantCharge: computed,
            allCountryCurrencyOptions: computed,
        });
    }

    /*
    This function is used to Get All TenantCharges data from API.  
    */
    GetTenantChargesListService = (pageNumber: number, pageSize: number) => {
        this.inProgress = true;
        const url = URLConstants.TenantCharges + "/" + "List/" + "?PageNo=" + pageNumber + "&PageSize=" + pageSize;;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IGetAllTenantCharge>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.tenantChargeList = response.data.Data;
                }
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

        /*
    This function is used to map tenantChargeList to allTenantCharge List suitable for Grid Component.  
    */
    get allTenantCharge(): ITenantChargesVM[] {
        if (this.tenantChargeList?.TenantCharges && this.tenantChargeList.TenantCharges?.length > 0)
            return this.tenantChargeList.TenantCharges.map((tenantCharge:ITenantChargesVM) => {
                return {
                    Id: tenantCharge.Id,
                    Tenant: tenantCharge.Tenant,
                    RegistrationCharges: Number(tenantCharge.RegistrationCharges).toFixed(2),
                    PacketCharges: Number(tenantCharge.PacketCharges).toFixed(2),
                    TenancyCharges: Number(tenantCharge.TenancyCharges).toFixed(2),
                    UpdatedDate: moment(tenantCharge.UpdatedDate).format(dateTimeFormat),
                    Taxes: Number(tenantCharge.Taxes).toFixed(2),
                    Currency: tenantCharge.Currency,
                    CreatedBy: tenantCharge.CreatedBy,
                    UpdatedByBy: tenantCharge.UpdatedBy,
                }
            })
        return [];
    }

    /*
    This function is used to Get All Country Currencies data from API.  
    */
    GetAllCountryCurrencyService = () => {
        this.countryCurrencyState.inProgress = true;
        const url = URLConstants.CurrencyList;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<ICurrencyVM[]>>) => {
                if (response.data.Error) {
                    this.countryCurrencyState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.allCountryCurrencies = response.data.Data;
                }
            })
            .catch((err: string) => {
                this.countryCurrencyState.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.countryCurrencyState.inProgress = false; }));
    }

    /*
    This function is used to map allCountryCurrencies to allCountryCurrencyOptions List suitable for Grid Component.  
    */
    get allCountryCurrencyOptions(): ICountryCurrencyOption[] {
        const options: any[] = [];
        if (this.allCountryCurrencies && this.allCountryCurrencies.length > 0)
            this.allCountryCurrencies.map((currencyDetails) => {
                let tempPlaceholder = currencyDetails.CurrencyName +" (" + currencyDetails.Symbol +")";
                options.push({
                    data: JSON.stringify({id:currencyDetails.Id, symbol:currencyDetails.Symbol, placeholder:tempPlaceholder}),
                    placeholder: tempPlaceholder,
                })
            })

        return options;
    }

    /*
    This function is used to Add New TenantCharge.  
    */
    AddTenantChargesService = (data: IAddTenantCharge) => {
        this.addTenantChargeState.inProgress = true;
        let url = URLConstants.TenantCharges;
        return baseService.postRequest(url, {...data})
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addTenantChargeState.error = response.data.Message;
                    // toast.error(formatMessage(response.data.Message));
                }
                else this.addTenantChargeState.success = true;
            })
            .catch((err: string) => {
                this.addTenantChargeState.error = err;
            })
            .finally(action(() => { this.addTenantChargeState.inProgress = false; }));
    }
    
    /*
    This function is used to reset all AddTenantCharge observables to their initial values.  
    */
    resetAddTenantChargeState = () => {
        this.addTenantChargeState.inProgress = false;
        this.addTenantChargeState.success = false;
        this.addTenantChargeState.error = '';
    }

    /*
    This function is used to reset all observables to their initial values.  
    */
    reset = () => {
        this.error = '';
        this.inProgress = false;
        this.addTenantChargeState = {...this.initialStateValue}
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.tenantChargeList = GetAllTenantChargeState

        this.addTenantChargeState ={...this.initialStateValue }
    }
}

export default new TenantChargeStore();
