/*    
<summary>
  This functional component "AddUpdateDeviceForm" provides form to add new user or update existing user.
  Developer:Mohammad Saquib Khan, Created Date: 29-March-2023, Updated By: Aashish Singh, Last Updated Date: 10-April-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Field, Form, Formik } from "formik";
import "./add-edit-aws-core-config-form.css";
import { observer } from "mobx-react-lite";

import FormikFormInput from "../../../../../shared-components/FormikFormHandlers/FormikFormInput";
import { Button } from "react-bootstrap";
import LoaderButton from "../../../../../shared-components/Button/LoaderButton";
import FormikFormCheckBox from "../../../../../shared-components/FormikFormHandlers/FormikFormCheckBox";
import FormikFormTextArea from "../../../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import { addAWSCoreValidateSchema, addMQTTSValidateSchema, updateAWSCoreValidateSchema } from "../../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import FormLoader from "../../../../../shared-components/FormLoader/FormLoader";
import toast, { Toaster } from "react-hot-toast";
import FormikFormSelect from "../../../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { SourceProtocol } from "../../../../../constants/options.constant";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import config from "../../../../../helpers/config-helper";
import { formatMessage } from "../../../../../translations/format-message";
import { useStore } from "../../../../../contexts/StoreProvider";
import FormikFormFileInput from "../../../../../shared-components/FormikFormHandlers/FormikFormFileInput";
import FormikFormFileInputCertificate from "../../../../../shared-components/FormikFormHandlers/FormikFormFileInputCertificate";

interface IProps {
  Id: number;
  IsDataPakcetsStoreInDB: boolean;
  isLoading: boolean;
  initialValues: any;
  submitHandler: (data: any) => void;
  modalClosed: () => void;
}

const appConfig = config();
const isStoreinDbSuccess = appConfig.IS_STORE_IN_DB_SUCCESS;
const isStoreinDbFailed = appConfig.IS_STORE_IN_DB_FAILED;

const AddAWSCoreConfigForm = (props: IProps) => {
  const { tenantStore } = useStore();
  const { tenantDetails, tenantState } = tenantStore;
  const topicInfo = formatMessage("topic_info").replace(
    "{0}",
    tenantDetails.OrganizationId
  );
  const eg = "e.g-Level1/{orgid}/{iccid}/Level4";
  const [useCertificate, setUseCertificate] = useState<boolean>(false);
  const [ServerCertificateFileName, setServerCertificateFileName] =
    useState<any>(props.Id > 0 ? "Update File" : "Choose File");
  const [ClientCertificateFileName, setClientCertificateFileName] =
    useState<any>(props.Id > 0 ? "Update File" : "Choose File");
  const [ClientKeyFileName, setClientKeyFileName] = useState<any>(
    props.Id > 0 ? "Update File" : "Choose File"
  );

  const [ServerCertificate, setServerCertificate] = useState<any>("");
  const [ClientCertificate, setClientCertificate] = useState<any>("");
  const [ClientKey, setClientKey] = useState<any>("");

  /**
   * Area Block for Get functions/variables from store class
   * CreatedBy:Mohammad Saquib Khan , Date:20-April-2023
   */

  const { initialValues, submitHandler, modalClosed, isLoading } = props;

  /**End */

  const handleFileUpload = (event: any) => {
    var files = event?.target?.result;
    setServerCertificate(files.toString());
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />
      <div className="mx-auto">
        {isLoading && <FormLoader loading={isLoading} />}
        <Formik
          initialValues={initialValues}
          validationSchema={props.Id > 0 ? updateAWSCoreValidateSchema : addAWSCoreValidateSchema}
          enableReinitialize
          validateOnBlur={false}
          onSubmit={async (values: any) => {
            submitHandler({
              ...values,
              AwsIoTCoreParameters: { ...values.AwsIoTCoreParameters, ServerCertificateData: props.Id > 0 && ServerCertificate == "" ? props.initialValues?.AwsIoTCoreParameters?.ServerCertificateData :ServerCertificate },
            });
          }}
          render={({ values }) => (
            <Form id="formBody">
              <div className="position-relative px-4 pt-3">
                <div className="row form-group justify-content-end align-items-center">
                  <div className=" col-md-4">
                    <label className="label-style fw-semibold">
                      <FormattedMessage id="source_protocol" />
                    </label>
                  </div>

                  <div className="formS et col-md-8">
                    <Field
                      data-testid="test_source_protocol"
                      className="form-control"
                      name="SourceProtocol"
                      placeholder="source_protocol"
                      type="text"
                      options={SourceProtocol}
                      as={FormikFormSelect}
                    />
                  </div>
                  <div className="col-sm-8 mt-2 formSet d-flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    fill="currentColor"
                    className="bi bi-info-circle mr-1"
                    viewBox="0 0 16 16"
                    data-tooltip-id="my-tooltip2"
                    data-tooltip-html={topicInfo}
                    data-tooltip-variant="dark"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                  </svg>
                  <label className="label-style text-dark fw-semibold">
                    <FormattedMessage id="topic_pattern" />
                  </label>
                  <p className="mx-1">{eg}</p>
                </div>
                <Tooltip id="my-tooltip2" place="top" />
                </div>
                
              </div>
              <div className="py-3 px-4 bg-light rounded-3">
                <h4 className="divider_heading">
                  <FormattedMessage id="destination_protocol" />
                  <span className="ml-2 badge badge-success">
                    <FormattedMessage id="aws_connector" />
                  </span>
                </h4>
                <div className="row">
                  <div className="form-group formSet col-sm-12">
                    <label className="label-style text-dark fw-semibold">
                      <FormattedMessage id="config_name" />
                    </label>
                    <Field
                      data-testid="test_devicename"
                      className="form-control"
                      name="Name"
                      validcharacter={["@", "#", "$", "-", "_", "."]}
                      placeholder="config_name"
                      type="text"
                      as={FormikFormInput}
                    />
                  </div>
                </div>
                <div className="form-group formSet">
                  <label className="label-style text-dark fw-semibold">
                    <FormattedMessage id="desc" />
                  </label>
                  <Field
                    data-testid="testjson"
                    name="Description"
                    type="text"
                    className="form-control"
                    placeholder="enter_text"
                    rows={3}
                    style={{ resize: "none" }}
                    as={FormikFormTextArea}
                  />

                  {(isStoreinDbSuccess || isStoreinDbFailed) && 
                    props.IsDataPakcetsStoreInDB == true ?
                  (
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <label className="h">
                          <FormattedMessage id="store_in_DB" />
                        </label>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          className="bi bi-info-circle ml-2"
                          viewBox="0 0 16 16"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-html={formatMessage("store_in_db_info")}
                          data-tooltip-variant="dark"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                        <Tooltip id="my-tooltip" place="right" />
                      </div>
                      {isStoreinDbSuccess && (
                        <div className="checkbox col-md-4">
                          <label>
                            <Field
                              data-testid="testRememberMe"
                              className="form-control"
                              name="IsSuccessPacketsStoreInDb"
                              type="checkbox"
                              id="inlineFormCheck"
                              as={FormikFormCheckBox}
                            />
                            <span className="cr">
                              <i className="cr-icon fas fa-check"></i>
                            </span>
                          </label>
                          <label className="h">
                            <FormattedMessage id="store_in_db_success" />
                          </label>
                        </div>
                      )}
                      {isStoreinDbFailed && (
                        <div className="checkbox col-md-4">
                          <label>
                            <Field
                              data-testid="testRememberMe"
                              className="form-control"
                              name="IsFailedPacketsStoreInDb"
                              type="checkbox"
                              id="inlineFormCheck"
                              as={FormikFormCheckBox}
                            />
                            <span className="cr">
                              <i className="cr-icon fas fa-check"></i>
                            </span>
                          </label>
                          <label className="h">
                            <FormattedMessage id="store_in_db_failed" />
                          </label>
                        </div>
                      )}
                    </div>
                  ) : ""}
                </div>

                <div className="position-relative">
                  <span className="fs14 text-dark fw-semibold">
                    <FormattedMessage id="parameters" />
                  </span>
                  <div className="form-group formSet">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <label className="input-group-text label-style">
                          <FormattedMessage id="host" />
                          <span className="text-danger ml-1"> *</span>
                          {/* <span> (mqtts://) </span> */}
                        </label>
                      </div>
                      <Field
                        data-testid="test_host"
                        className="form-control"
                        name="AwsIoTCoreParameters.Host"
                        placeholder="host"
                        type="text"
                        validcharacter={[
                          "-",
                          "_",
                          ":",
                          "#",
                          "@",
                          ".",
                          "/",
                          "?",
                          "=",
                          "%",
                        ]}
                        as={FormikFormInput}
                      />
                    </div>

                    <div className="input-group">
                      <div className="input-group-prepend">
                        <label className="label-style input-group-text">
                          <FormattedMessage id="user_id" />
                        </label>
                      </div>
                      <Field
                        data-testid="test_user_id"
                        className="form-control"
                        name="AwsIoTCoreParameters.UserId"
                        placeholder="user_id"
                        type="text"
                        as={FormikFormInput}
                      />
                    </div>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <label className="label-style input-group-text">
                          <FormattedMessage id="password" />
                        </label>
                      </div>
                      <Field
                        data-testid="test_password"
                        className="form-control"
                        name="AwsIoTCoreParameters.Password"
                        placeholder="password"
                        type="password"
                        autoComplete="new-password"
                        as={FormikFormInput}
                      />
                    </div>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <label className="label-style input-group-text">
                          <FormattedMessage id="ca_file" />
                        <span className="text-danger ml-1"> *</span>
                          <small>
                            <FormattedMessage id="server_certificate" />
                          </small>
                        </label>
                      </div>

                      <Field
                        className="form-control"
                        id="ckf_file"
                        name="AwsIoTCoreParameters.ServerCertificate"
                        handleFileUpload={handleFileUpload}
                        as={FormikFormFileInputCertificate}
                      />
                    </div>
                  </div>

                  {/* <div className="d-flex align-items-center">
                    <h4 className="m-0">
                      <FormattedMessage id="advanced_options" />
                    </h4>
                    <div className="p-0 form-switch ml-2">
                      <label className="switch">
                        <Field
                          data-testid="testRememberMe"
                          className="form-control"
                          name="AwsIoTCoreParameters.Advanced"
                          type="checkbox"
                          id="inlineFormCheck"
                          as={FormikFormCheckBox}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div> */}
                  {/* {values.AwsIoTCoreParameters.Advanced == true ? ( */}
                  <>
                    {/* <div className="d-flex align-items-center">
                        <h4 className="m-0">
                          <FormattedMessage id="use_certificates" />
                        </h4>
                        <div className="p-0 form-switch ml-2">
                          <label className="switch">
                            <Field
                              data-testid="testRememberMe"
                              className="form-control"
                              name="AwsIoTCoreParameters.UseCertificate"
                              type="checkbox"
                              id="inlineFormCheck"
                              as={FormikFormCheckBox}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div> */}
                    <div className="form-group formSet mt-3">
                      {/* {values.AwsIoTCoreParameters.UseCertificate == true ? ( */}

                      <React.Fragment>
                        {props.Id > 0 ? (
                          <div className="alert alert-warning">
                            <FormattedMessage id={"file_upload_message"} />
                          </div>
                        ) : (
                          ""
                        )}

                      </React.Fragment>
                      <div className="row justify-content-between">
                        <div className="col-sm-7 border-right">
                          <div className="row form-group btn-radioCheckbox">
                            <div className="col-12">
                              <label className="fs14 text-dark fw-semibold">
                                <FormattedMessage id="topic" />
                              </label>
                            </div>
                            <div className="col-6">
                              <Field
                                type="radio"
                                value="0"
                                name="AwsIoTCoreParameters.TopicType"
                                id="incoming-tab"
                                className="nav-link active"
                              />
                              <label htmlFor="incoming-tab">
                                <FormattedMessage id="same_as_incoming" />
                              </label>
                            </div>
                            <div className="col-6">
                              <Field
                                type="radio"
                                value="1"
                                name="AwsIoTCoreParameters.TopicType"
                                id="custom-tab"
                                className="nav-link active"
                              />
                              <label htmlFor="custom-tab">
                                <FormattedMessage id="custom" />

                                {values.AwsIoTCoreParameters.TopicType == "1" ? (
                                  <React.Fragment>
                                    <Field
                                      data-testid="test_custom"
                                      className="form-control"
                                      name="AwsIoTCoreParameters.CustomTopic"
                                      validcharacter={["/"]}
                                      placeholder="e.g-/topic1/iccid/topic2"
                                      type="text"
                                      as={FormikFormInput}
                                    />
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="row form-group btn-radioCheckbox">
                            <div className="col-12">
                              <label className="fs14 text-dark fw-semibold">
                                <FormattedMessage id="qos" />
                              </label>
                            </div>

                            <div className="col-6">
                              <Field
                                type="radio"
                                value="0"
                                name="AwsIoTCoreParameters.Qos"
                                id="zero-tab"
                                className="nav-link active"
                              />
                              <label htmlFor="zero-tab">
                                <FormattedMessage id="zero" />
                              </label>
                            </div>
                            <div className="col-6">
                              <Field
                                type="radio"
                                value="1"
                                name="AwsIoTCoreParameters.Qos"
                                id="one-tab"
                                className="nav-link active"
                              />
                              <label htmlFor="one-tab" className="d-flex">
                                <FormattedMessage id="one" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>

                  {/* ) : (
                    ""
                  )} */}
                </div>
              </div>

              <div className="modal-footer border-0">
                <LoaderButton
                  type="submit"
                  // isLoading={isLoading}
                  text="submit"
                />
                <Button
                  variant="secondary"
                  onClick={modalClosed}
                  disabled={false}
                  className=""
                >
                  Close
                </Button>
              </div>
            </Form>
          )}
        ></Formik>
      </div>
    </>
  );
};

export default observer(AddAWSCoreConfigForm);
