import IAddTenant from "../../models/Form/IAddUpdateTenant";
import { initialState as defaultTenantCharge } from "./add-tenant-charge-state";

export const initialState: IAddTenant = {
    TenantName: "",
    Password: "",
    Email: "",
    OrganizationId: "",
    OrganizationName: "",
    ContactPersonName: "",
    ContactEmail: "",
    ContactPhoneNumber: "",
    Address: "",
    MaxUserAllow: 1,
    MaxDeviceAllow: 4,
    MaxMessageLimit: 1440,
    IsActive: true,
    IsDataPakcetsStoreInDB: false,
    SendThresholdAlert: true,
    UserId:-1,
    TenantCharge:{...defaultTenantCharge}

}