/*    
<summary>
   This functional common component shows loader only for the duration of getting data from api. 
   Developer:Aashish Singh, Created Date:03-April-2023
</summary> 
<returns>Returns JSX</returns>
*/
import * as Yup from "yup";
const japaneseRegExp = /[\p{Script=Hiragana}\p{Script=Katakana}\p{Script=Han}]/u
export const LoginValidateSchema = Yup.object({
  email: Yup.string()
    .required("field_required"),
  password: Yup.string()
    .required("field_required"),
});

export const deviceValidateSchema = Yup.object({
  Name: Yup.string()
    .required("field_required")
    .max(40, 'char_device_name_limit_reached'),
  Iccid: Yup.string()
    .required("field_required")
    .max(30, 'iccid_length_required')
    .test('must_greater-than-0', 'iccid_reater_than_1', function (
      Iccid
    ) {
      if (Iccid)
        return Iccid ? (BigInt(Iccid) > 0 ? true : false) : false;
      else
        return true;
  }),
  GroupId: Yup.number()
    .required("field_required")
    .min(1, "field_required"),
  IsEnable: Yup.boolean()
    .required("field_required"),
});

export const deviceValidateSchemaAwsIotCore = Yup.object({
  Name: Yup.string()
    .required("field_required")
    .max(40, 'char_device_name_limit_reached'),
  Iccid: Yup.string()
    .required("field_required")
    .max(30, 'iccid_length_required')
    .test('must_greater-than-0', 'iccid_reater_than_1', function (
      Iccid
    ) {
      if (Iccid)
        return Iccid ? (BigInt(Iccid) > 0 ? true : false) : false;
      else
        return true;
  }),
  GroupId: Yup.number()
    .required("field_required")
    .min(1, "field_required"),
  IsEnable: Yup.boolean()
    .required("field_required"),
  ClientCertificate: Yup.mixed().nullable()
  .required('field_required')
  .test('csv', 'only_security_extensions', (value) => {
    let val;
    if(value){
      val = value?.split('.');
      return (val[1].toLowerCase() === "pem" || 
      val[1].toLowerCase() === "crt" || 
      val[1].toLowerCase() === "cer"||
      val[1].toLowerCase() === "p7b" ||
      val[1].toLowerCase() === "p7c" ||
      val[1].toLowerCase() === "p7s" ||
      val[1].toLowerCase() === "key" ||
      val[1].toLowerCase() === "keystore" ||
      val[1].toLowerCase() === "jks");
    }
    else return false
  }),
  ClientKey: Yup.mixed().nullable()
    .required('field_required')
      .test('csv', 'only_security_extensions', (value) => {
        let val;
        if(value){
          val = value?.split('.');
          return (val[1].toLowerCase() === "pem" || 
          val[1].toLowerCase() === "crt" || 
          val[1].toLowerCase() === "cer"||
          val[1].toLowerCase() === "p7b" ||
          val[1].toLowerCase() === "p7c" ||
          val[1].toLowerCase() === "p7s" ||
          val[1].toLowerCase() === "key" ||
          val[1].toLowerCase() === "keystore" ||
          val[1].toLowerCase() === "jks");
        }
        else return false}),
});

export const updateDeviceValidateSchemaAwsIotCore = Yup.object({
  Name: Yup.string()
    .required("field_required")
    .max(40, 'char_device_name_limit_reached'),
  Iccid: Yup.string()
    .required("field_required")
    .max(30, 'iccid_length_required')
    .test('must_greater-than-0', 'iccid_reater_than_1', function (
      Iccid
    ) {
      if (Iccid)
        return Iccid ? (BigInt(Iccid) > 0 ? true : false) : false;
      else
        return true;
  }),
  GroupId: Yup.number()
    .required("field_required")
    .min(1, "field_required"),
  IsEnable: Yup.boolean()
    .required("field_required"),
  ClientCertificate: Yup.mixed().nullable()
  .test('csv', 'only_security_extensions', (value) => {
    if (!value) {
      return true; // Skip validation for null or undefined values
    }
    let val = value.split('.');
    return (val[1].toLowerCase() === "pem" || 
      val[1].toLowerCase() === "crt" || 
      val[1].toLowerCase() === "cer"||
      val[1].toLowerCase() === "p7b" ||
      val[1].toLowerCase() === "p7c" ||
      val[1].toLowerCase() === "p7s" ||
      val[1].toLowerCase() === "key" ||
      val[1].toLowerCase() === "keystore" ||
      val[1].toLowerCase() === "jks");
  }),
  ClientKey: Yup.mixed().nullable()
  .test('csv', 'only_security_extensions', (value) => {
    if (!value) {
      return true; // Skip validation for null or undefined values
    }
    let val = value.split('.');
    return (val[1].toLowerCase() === "pem" || 
      val[1].toLowerCase() === "crt" || 
      val[1].toLowerCase() === "cer"||
      val[1].toLowerCase() === "p7b" ||
      val[1].toLowerCase() === "p7c" ||
      val[1].toLowerCase() === "p7s" ||
      val[1].toLowerCase() === "key" ||
      val[1].toLowerCase() === "keystore" ||
      val[1].toLowerCase() === "jks");
  }),
});


export const changeGroupValidateSchema = Yup.object({
  GroupId: Yup.number()
    .min(1, "field_required"),
});

export const billingStatsIccidValidateSchema = Yup.object({
  Iccid: Yup.string()
    .required("field_required")
    .max(30, 'iccid_length_required')
    .test('must_greater-than-0', 'iccid_reater_than_1', function (
      Iccid
    ) {
      if (Iccid)
        return Iccid ? (BigInt(Iccid) > 0 ? true : false) : false;
      else
        return true;
  }),
});

export const deviceFilterValidationSchema = Yup.object({
});

export const publishMessageValidationSchema = Yup.object({
  Topic: Yup.string()
    .max(6500, 'char_limit_reached')
    .test('startsWithSlash', 'topic_starts_with', (value) => {
      if (!value) {
        return true; // or false, depending on your requirements
      }
      let res = value.startsWith("/");
      return res;
    }),
  Format: Yup.string()
    .required("field_required"),
  Message: Yup.string()
    .required("field_required")
    .when('Format', {
      is: '0',
      then: Yup.string().test('isJson', 'json_validation', (value) => {
        if (!value) {
          return true; // or false, depending on your requirements for an empty string
        }
        let result  = value.trim().startsWith('{') ? true : false;

        try {
          JSON.parse(value);
          return true && result;
        } catch (error) {
          return false && result;
        }


      }),
    })
    .test(
      'maxFileSize',
      'message_validation',
      (value) => {
        if (!value) {
          return true;
        }
        const totalBytes = new Blob([value]).size;
        const totalMegabytes = totalBytes / (1024 * 1024);
        return totalMegabytes < 1;
      }
    )
});

export const selectGroupValidateSchema = Yup.object({
  GroupId: Yup.number()
    .min(1, "field_required"),
  CSVFile: Yup.mixed().nullable()
    .required('field_required')
    .test('csv', 'only_csv', (value) => {
      let val;
      if(value ){
        val = value.split('.');
        return val[1].toLowerCase() === "csv";
      }
      else return false
    }),
});

export const addTenantValidateSchema = Yup.object({
  TenantName: Yup.string()
    .required("field_required")
    .max(20, 'char_limit_reached'),
  Password: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    .matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/, "user_validation_password_matches")
    .required("field_required"),
  Email: Yup.string()
    .matches(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/, "invalid_login_id")
    .min(5, "lodin_id_length_required")
    .max(50, "lodin_id_length_required")
    .required("field_required"),
  OrganizationId: Yup.string()
    .required("field_required")
    .matches(/^[A-Za-z][A-Za-z0-9]*$/, "invalid_input")
    .max(8, 'char_limit_reached_org_id'),
  OrganizationName: Yup.string()
    .required("field_required")
    .max(20, 'char_limit_reached'),
  ContactPersonName: Yup.string()
    .required("field_required")
    .max(20, 'char_limit_reached'),
  MaxUserAllow: Yup.number()
    .required("field_required")
    .min(1, "user_limit_reached")
    .max(10, "user_limit_reached"),
  MaxDeviceAllow: Yup.number()
    .required("field_required")
    .min(1, "device_limit_reached")
    .max(100000, "device_limit_reached"),
  ContactEmail: Yup.string()
    .matches(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/, "invalid_email")
    .min(5, "lodin_id_length_required")
    .max(50, "lodin_id_length_required")
    .required("field_required"),
  TenantCharge: Yup.object({
    RegistrationCharges: Yup.number()
      .min(0,"charges_limit")
      .max(1000,"charges_limit")
      .required("field_required")
      .test(
        "is-decimal",
        "two_digits_allowed",
        (val: any) => {
          if (val != undefined) {
            return /^\d+(\.\d{0,2})?$/.test(val);
          }
          return true;
        }
      ),
    PacketCharges: Yup.number()
      .required("field_required")
      .min(0,"charges_limit")
      .max(1000,"charges_limit")
      .test(
        "is-decimal",
        "two_digits_allowed",
        (val: any) => {
          if (val != undefined) {
            return /^\d+(\.\d{0,2})?$/.test(val);
          }
          return true;
        }
      ),
    TenancyCharges: Yup.number()
      .required("field_required")
      .min(0,"charges_limit")
      .max(1000,"charges_limit")
      .test(
        "is-decimal",
        "two_digits_allowed",
        (val: any) => {
          if (val != undefined) {
            return /^\d+(\.\d{0,2})?$/.test(val);
          }
          return true;
        }
      ),
    Taxes: Yup.number()
      .required("field_required")
      .max(100,"invalid_taxes_range")
      .test(
        "is-decimal",
        "two_digits_allowed",
        (val: any) => {
          if (val != undefined) {
            return /^\d+(\.\d{0,2})?$/.test(val);
          }
          return true;
        }
      ),
  })
});

export const addDefaultTenantChargeValidateSchema = Yup.object({
  TenantCharge: Yup.object({
    RegistrationCharges: Yup.number()
      .min(0,"charges_limit")
      .max(1000,"charges_limit")
      .required("field_required")
      .test(
        "is-decimal",
        "two_digits_allowed",
        (val: any) => {
          if (val != undefined) {
            return /^\d+(\.\d{0,2})?$/.test(val);
          }
          return true;
        }
      ),
    PacketCharges: Yup.number()
      .required("field_required")
      .min(0,"charges_limit")
      .max(1000,"charges_limit")
      .test(
        "is-decimal",
        "two_digits_allowed",
        (val: any) => {
          if (val != undefined) {
            return /^\d+(\.\d{0,2})?$/.test(val);
          }
          return true;
        }
      ),
    TenancyCharges: Yup.number()
      .required("field_required")
      .min(0,"charges_limit")
      .max(1000,"charges_limit")
      .test(
        "is-decimal",
        "two_digits_allowed",
        (val: any) => {
          if (val != undefined) {
            return /^\d+(\.\d{0,2})?$/.test(val);
          }
          return true;
        }
      ),
    Taxes: Yup.number()
      .required("field_required")
      .max(100,"invalid_taxes_range")
      .test(
        "is-decimal",
        "two_digits_allowed",
        (val: any) => {
          if (val != undefined) {
            return /^\d+(\.\d{0,2})?$/.test(val);
          }
          return true;
        }
      ),
  })
});

export const addTenantDetailsValidateSchema = Yup.object({
  TenantName: Yup.string()
    .required("field_required")
    .max(20, 'char_limit_reached'),
  Password: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    .matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/, "user_validation_password_matches")
    .required("field_required"),
  Email: Yup.string()
    .matches(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/, "invalid_login_id")
    .min(5, "lodin_id_length_required")
    .max(50, "lodin_id_length_required")
    .required("field_required"),
  MaxUserAllow: Yup.number()
    .required("field_required")
    .min(1, "user_limit_reached")
    .max(10, "user_limit_reached"),
  MaxDeviceAllow: Yup.number()
    .required("field_required")
    .min(1, "device_limit_reached")
    .max(100000, "device_limit_reached"),
  MaxMessageLimit: Yup.number()
    .required("field_required")
    .min(0, "device_limit_reached")
    // .when('SendThresholdAlert', {
    //   is: true,
    //   then: Yup.number().min(1, "min_max_message_limit_reached"),
    // })
});

export const organizationDetailValidateSchema = Yup.object({
  OrganizationId: Yup.string()
    .required("field_required")
    .matches(/^[A-Za-z][A-Za-z0-9]*$/, "invalid_input")
    .max(8, 'char_limit_reached_org_id'),
  OrganizationName: Yup.string()
    .required("field_required")
    .max(20, 'char_limit_reached'),
  ContactPersonName: Yup.string()
    .required("field_required")
    .max(20, 'char_limit_reached'),
  ContactEmail: Yup.string()
    .matches(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/, "invalid_email")
    .min(5, "lodin_id_length_required")
    .max(50, "lodin_id_length_required")
    .required("field_required"),
});

export const addTrialTenantValidateSchema = Yup.object({
  TenantName: Yup.string()
    .required("field_required")
    .max(20, 'char_limit_reached'),
  Email: Yup.string()
    .matches(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/, "invalid_email")
    .min(5, "lodin_id_length_required")
    .max(50, "lodin_id_length_required")
    .required("field_required"),
  Password: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    .matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/, "user_validation_password_matches")
    .required("field_required"),
  OrganizationId: Yup.string()
    .required("field_required")
    .matches(/^[A-Za-z][A-Za-z0-9]*$/, "invalid_input")
    .max(8, 'char_limit_reached_org_id'),
  OrganizationName: Yup.string()
    .required("field_required")
    .max(20, 'char_limit_reached'),
  ContactPersonName: Yup.string()
    .required("field_required")
    .max(20, 'char_limit_reached'),
  ContactEmail: Yup.string()
    .matches(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/, "invalid_email")
    .min(5, "lodin_id_length_required")
    .max(50, "lodin_id_length_required")
    .required("field_required")
});

export const editTenantValidateSchema = Yup.object({
  TenantName: Yup.string()
    .required("field_required")
    .max(20, 'char_limit_reached'),
  Email: Yup.string()
    .matches(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/, "invalid_email")
    .min(5, "lodin_id_length_required")
    .max(50, "lodin_id_length_required")
    .required("field_required"),
  OrganizationId: Yup.string()
    .required("field_required")
    .matches(/^[A-Za-z][A-Za-z0-9]*$/, "invalid_input")
    .max(8, 'char_limit_reached_org_id'),
  OrganizationName: Yup.string()
    .required("field_required")
    .max(20, 'char_limit_reached'),
  ContactPersonName: Yup.string()
    .required("field_required")
    .max(20, 'char_limit_reached'),
  MaxUserAllow: Yup.number()
    .required("field_required")
    .min(1, "user_limit_reached")
    .max(10, "user_limit_reached"),
  MaxDeviceAllow: Yup.number()
    .required("field_required")
    .min(1, "device_limit_reached")
    .max(100000, "device_limit_reached"),
  ContactEmail: Yup.string()
    .matches(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/, "invalid_email")
    .min(5, "lodin_id_length_required")
    .max(50, "lodin_id_length_required")
    .required("field_required")
});

export const editTenantDetailsValidateSchema = Yup.object({
  TenantName: Yup.string()
    .required("field_required")
    .max(20, 'char_limit_reached'),
  Email: Yup.string()
    .matches(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/, "invalid_email")
    .min(5, "lodin_id_length_required")
    .max(50, "lodin_id_length_required")
    .required("field_required"),
  MaxUserAllow: Yup.number()
    .required("field_required")
    .min(1, "user_limit_reached")
    .max(10, "user_limit_reached"),
  MaxDeviceAllow: Yup.number()
    .required("field_required")
    .min(1, "device_limit_reached")
    .max(100000, "device_limit_reached"),
  MaxMessageLimit: Yup.number()
    .required("field_required")
    .min(0, "device_limit_reached")
    // .when('SendThresholdAlert', {
    //   is: true,
    //   then: Yup.number().min(1, "min_max_message_limit_reached"),
    // }),
});

export const addHTTPSValidateSchema = Yup.object({
  SourceProtocol: Yup.number()
    .required("field_required"),
  Name: Yup.string()
    .required("field_required")
    .max(20, 'char_limit_reached'),
  Description: Yup.string()
    .max(250, 'des_char_limit_reached'),
  DestinationProtocol: Yup.string()
    .required("field_required"),
  IsSuccessPacketsStoreInDb: Yup.boolean()
    .required("field_required"),
  IsFailedPacketsStoreInDb: Yup.boolean()
    .required("field_required"),
  HttpsParameters: Yup.object({
    Host: Yup.string()
      .required("field_required")
      .max(1024, 'host_url_char_limit_reached')
      .matches(
        /^(https:\/\/(www\.)?|http:\/\/(www\.)?)[A-Za-z0-9-_.:/]+$/,
        'invalid_url'
      ), 
    UserId: Yup.string()
      .test(
          'UserId',
          'field_required',
          (UserId, context) => {
            return (UserId == undefined && context.parent.AuthorizationType == 1) ? false : true;
          }
        )
      .test(
        'UserId',
        'user_id_limit_reached',
        (UserId, context) => {
          return (UserId != undefined && context.parent.AuthorizationType == 1 && UserId.length > 80) ? false : true;
        }
      )
      .test(
        'UserId',
        'user_id_limit_reached',
        (UserId, context) => {
          return (UserId != undefined && context.parent.AuthorizationType == 1 && UserId.length < 4) ? false : true;
        }
      ),
    Password: Yup.string()
      .test(
        'Password',
        'field_required',
        (Password, context) => {
          return (Password == undefined && context.parent.AuthorizationType == 1) ? false : true;
        }
      )
      .test(
        'Password',
        'min_basic_auth_password_length_required',
        (Password, context) => {
          return (Password != undefined && context.parent.AuthorizationType == 1 && Password.length > 80) ? false : true;
        }
      )
      .test(
        'Password',
        'min_basic_auth_password_length_required',
        (Password, context) => {
          return (Password != undefined && context.parent.AuthorizationType == 1 && Password.length < 4) ? false : true;
        }
      ),
    Key: Yup.string()
      .test(
        'Key',
        'field_required',
        (Key, context) => {
          return (Key == undefined && context.parent.AuthorizationType == 2) ? false : true;
        }
      )
      .test(
        'Key',
        'host_url_char_limit_reached',
        (Key, context) => {
          return (Key != undefined && context.parent.AuthorizationType == 2 && Key.length > 1024) ? false : true;
        }
      ),
    Value: Yup.string()
      .test(
        'Value',
        'field_required',
        (Value, context) => {
          return (Value == undefined && context.parent.AuthorizationType == 2) ? false : true;
        }
      )
      .test(
        'Value',
        'host_url_char_limit_reached',
        (Value, context) => {
          return (Value != undefined && context.parent.AuthorizationType == 2 && Value.length > 1024) ? false : true;
        }
      ),
    Token: Yup.string()
      .test(
        'Token',
        'field_required',
        (Token, context) => {
          return (Token == undefined && context.parent.AuthorizationType == 3) ? false : true;
        }
      )  
      .test(
        'Token',
        'host_url_char_limit_reached',
        (Token, context) => {
          return (Token != undefined && context.parent.AuthorizationType == 3 && Token.length > 1024) ? false : true;
        }
      ),
    },
  ),
  KeyCustom: Yup.string()
    .max(80, 'custom_key_header_limit_reached'),
  ValueCustom: Yup.string()
    .max(80, 'custom_key_header_limit_reached'),
});

export const addAWSCoreValidateSchema: any = Yup.object({
  SourceProtocol: Yup.number()
    .required("field_required"),
  Name: Yup.string()
    .required("field_required")
    .max(20, 'char_limit_reached'),
  Description: Yup.string()
    .max(250, 'des_char_limit_reached'),
  DestinationProtocol: Yup.string()
    .required("field_required"),
  // IsStoreInDb: Yup.boolean()
  //   .required("field_required"),
  AwsIoTCoreParameters: Yup.object({
    Host: Yup.string().nullable()
      .required("field_required"),
    ServerCertificate: Yup.mixed().nullable()
      .required('field_required')
      .test('csv', 'only_security_extensions', (value) => {
        let val;
        if(value){
          val = value?.split('.');
          return (val[1].toLowerCase() === "pem" || 
          val[1].toLowerCase() === "crt" || 
          val[1].toLowerCase() === "cer"||
          val[1].toLowerCase() === "p7b" ||
          val[1].toLowerCase() === "p7c" ||
          val[1].toLowerCase() === "p7s" ||
          val[1].toLowerCase() === "key" ||
          val[1].toLowerCase() === "keystore" ||
          val[1].toLowerCase() === "jks");
        }
        else return false
      }),
    CustomTopic: Yup.string()
      .test(
        'CustomTopic',
        'field_required',
        (value, context) => {
          return (value == undefined && context.parent.TopicType == "1") ? false : true;
        }
      ),
  }),
});

export const updateAWSCoreValidateSchema: any = Yup.object({
  SourceProtocol: Yup.number()
    .required("field_required"),
  Name: Yup.string()
    .required("field_required")
    .max(20, 'char_limit_reached'),
  Description: Yup.string()
    .max(250, 'des_char_limit_reached'),
  DestinationProtocol: Yup.string()
    .required("field_required"),
  // IsStoreInDb: Yup.boolean()
  //   .required("field_required"),
  AwsIoTCoreParameters: Yup.object({
    Host: Yup.string().nullable()
      .required("field_required"),
      ServerCertificate: Yup.mixed().nullable()
      .test('csv', 'only_security_extensions', (value) => {
        if (!value) {
          return true; // Skip validation for null or undefined values
        }
        let val = value.split('.');
        return (val[1].toLowerCase() === "pem" || 
          val[1].toLowerCase() === "crt" || 
          val[1].toLowerCase() === "cer"||
          val[1].toLowerCase() === "p7b" ||
          val[1].toLowerCase() === "p7c" ||
          val[1].toLowerCase() === "p7s" ||
          val[1].toLowerCase() === "key" ||
          val[1].toLowerCase() === "keystore" ||
          val[1].toLowerCase() === "jks");
      }),
    
    CustomTopic: Yup.string()
      .test(
        'CustomTopic',
        'field_required',
        (value, context) => {
          return (value == undefined && context.parent.TopicType == "1") ? false : true;
        }
      ),
  }),
});

export const addMQTTSValidateSchema: any = Yup.object({
  SourceProtocol: Yup.number()
    .required("field_required"),
  Name: Yup.string()
    .required("field_required")
    .max(20, 'char_limit_reached'),
  Description: Yup.string()
    .max(250, 'des_char_limit_reached'),
  DestinationProtocol: Yup.string()
    .required("field_required"),
  // IsStoreInDb: Yup.boolean()
  //   .required("field_required"),
  MqttsParameters: Yup.object({
    Host: Yup.string()
      .required("field_required"),
    CustomTopic: Yup.string()
      .test(
        'CustomTopic',
        'field_required',
        (value, context) => {
          return (value == undefined && context.parent.TopicType == "1") ? false : true;
        }
      )
  }),
});

export const bulkDeviceSchema = Yup.object({
  GroupId: Yup.number()
    .min(1, "field_required"),
  Name: Yup.string()
    .required("field_required")
    .max(9, "max_length_required"),
  IsEnable: Yup.boolean()
    .required("field_required"),
  StartICCID: Yup.string()
    .required("field_required")
    .max(30, 'iccid_length_required')
    .test('must_greater-than-0', 'iccid_reater_than_1', function (
      StartICCID
    ) {
      if (StartICCID)
        return StartICCID ? (BigInt(StartICCID) > 0 ? true : false) : false;
      else
        return true;
    }),
  EndICCID: Yup.string()
    .required("field_required")
    .max(30, 'iccid_length_required')
    .test('greater-than-start', 'iccid_validation', function (
      EndICCID
    ) {
      const { StartICCID } = this.parent;
      if (!StartICCID || !EndICCID) return true; // Allow empty values to be handled by required()
      return BigInt(EndICCID) > BigInt(StartICCID);
    })
    .test('must_greater-than-0', 'iccid_reater_than_1', function (
      EndICCID
    ) {
      if (EndICCID)
        return EndICCID ? (BigInt(EndICCID) > 0 ? true : false) : false;
      else
        return true;
    }),
})

export const forgetPasswordOTPSchema = Yup.object({
  Digit1: Yup.number(),
  Digit2: Yup.number(),
  Digit3: Yup.number(),
  Digit4: Yup.number()
})

export const forgetPasswordAuthSchema = Yup.object({
  Email: Yup.string()
  .matches(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/, "invalid_login_id")
  .min(5, "lodin_id_length_required")
  .max(50, "lodin_id_length_required")
  .required("field_required"),
})

export const groupValidateSchema = Yup.object({
  Name: Yup.string()
    .required("field_required")
    .max(20, 'char_limit_reached'),
  ConfigurationProtocolId: Yup.number()
    .required("field_required")
    .min(1, "field_required"),
  Description: Yup.string()
    .max(250, 'des_char_limit_reached'),
})

export const UserChangePasswordValidateSchema = Yup.object({
  NewPassword: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    .matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/, "user_validation_password_matches")
    .required("field_required"),
  ConfirmPassword: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    .matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/, "user_validation_password_matches")
    .oneOf([Yup.ref('NewPassword'), null], "validation_new_confirm_password_mismatch")
    .required("field_required"),
});

export const AddUserValidateSchema = Yup.object({
  UserName: Yup.string()
    .required("field_required")
    .min(5, "min_user_name_length_required")
    .max(25, "min_user_name_length_required"),
  Password: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    .matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/, "user_validation_password_matches")
    .required("field_required"),
  Email: Yup.string()
    .matches(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/, "invalid_login_id")
    .min(5, "lodin_id_length_required")
    .max(50, "lodin_id_length_required")
    .required("field_required"),
  UserType: Yup.number()
    .min(0, "field_required"),
})

export const EditUserValidateSchema = Yup.object({
  UserName: Yup.string()
    .required("field_required")
    .min(5, "min_user_name_length_required")
    .max(25, "min_user_name_length_required"),
  Email: Yup.string()
    .matches(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/, "invalid_login_id")
    .min(5, "lodin_id_length_required")
    .max(50, "lodin_id_length_required")
    .required("field_required"),
  UserType: Yup.number()
    .min(0, "field_required")
})

export const ChangeLoggedInUserPasswordValidateSchema = Yup.object({
  OldPassword: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    .matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/, "user_validation_password_matches")
    .required("field_required"),
  NewPassword: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    .matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/, "user_validation_password_matches")
    .required("field_required"),
});

export const billingFilterValidateSchema = Yup.object({
  TenantId: Yup.number(),
  Year: Yup.number(),
  Month: Yup.number()
})

export const AddTenantChargeValidateSchema = Yup.object({
  TenantId: Yup.number()
    .min(0,"field_required"),
  RegistrationCharges: Yup.number()
    .required("field_required")
    .min(0,"charges_limit")
    .max(1000,"charges_limit")
    .test(
      "is-decimal",
      "two_digits_allowed",
      (val: any) => {
        if (val != undefined) {
          return /^\d+(\.\d{0,2})?$/.test(val);
        }
        return true;
      }
    ),
  PacketCharges: Yup.number()
    .required("field_required")
    .min(0,"charges_limit")
    .max(1000,"charges_limit")
    .test(
      "is-decimal",
      "two_digits_allowed",
      (val: any) => {
        if (val != undefined) {
          return /^\d+(\.\d{0,2})?$/.test(val);
        }
        return true;
      }
    ),
  TenancyCharges: Yup.number()
    .required("field_required")
    .min(0,"charges_limit")
    .max(1000,"charges_limit")
    .test(
      "is-decimal",
      "two_digits_allowed",
      (val: any) => {
        if (val != undefined) {
          return /^\d+(\.\d{0,2})?$/.test(val);
        }
        return true;
      }
    ),
  Taxes: Yup.number()
    .required("field_required")
    .max(100,"invalid_taxes_range")
    .test(
      "is-decimal",
      "two_digits_allowed",
      (val: any) => {
        if (val != undefined) {
          return /^\d+(\.\d{0,2})?$/.test(val);
        }
        return true;
      }
    ),
})

export const addPaymentValidateSchema = (pendingAmount:number)=>{
  return(
    Yup.object({
      PaymentRecieved: Yup.number()
        .required('field_required')
        .positive("invalid_payment_range")
        .max(pendingAmount,"invalid_payment_range")
        .test(
          "is-decimal",
          "two_digits_allowed",
          (val: any) => {
            if (val != undefined) {
              return /^\d+(\.\d{0,2})?$/.test(val);
            }
            return true;
          }
        ),
      Remarks: Yup.string()
        .max(250, 'des_char_limit_reached'),
    })
  )
}

export const auditLogFormValidationSchema = () => {
  return (
    Yup.object({
      StartDate: Yup.date()
      .required("field_required"),
      EndDate:  Yup.date()
      .required("field_required")
      .min( Yup.ref('StartDate'), "end_date_must_be_greater")
    })
  )}