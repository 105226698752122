/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   This enum is used to differentiate protocols in the overall application.
   Developer: Aashish Singh, Created Date: 02-May-2024
</summary>
*/
enum ratTypeIdEnum {
    "3G"=1,
    "4G"=2,
    "HSPA+"=5,
    "LTE"=6,
    "NB-IoT"=8,
    "CAT-M"=9
}

export default ratTypeIdEnum;