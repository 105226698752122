/*    
<summary>
   This rootStore combines all Store into a single one.
   Developer: Aashish Singh, Created Date: 08-Mar-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns RootStore Type</returns>
<returns>rootStore</returns>
*/

import oneNceStore, { OneNceStore } from './oneNce-store';
import authStore, { AuthStore } from './auth-store';
import configurationStore, { ConfigurationStore } from './configuration-store';
import deviceStore, { DeviceStore } from './device-store';
import groupStore, { GroupStore } from './group-store';
import preferencesStore, { PreferencesStore } from './preferences-store';
import tenantStore, { TenantStore } from './tenant-store';
import userStore, { UserStore } from './user-store';
import billingStore, { BillingStore } from './billing-store';
import tenantChargeStore, { TenantChargeStore } from './tenant-charge-store';
import transactionStore, { TransactionStore } from './transaction-store';
import auditLogStore, { AuditLogStore } from './audit-log-store';
import pricingMenuStore, { PricingMenuStore } from './pricing-menu-store';

export type RootStore = {
    authStore: AuthStore;
    preferencesStore: PreferencesStore;
    deviceStore: DeviceStore;
    groupStore: GroupStore;
    tenantStore: TenantStore;
    configurationStore: ConfigurationStore;
    userStore: UserStore;
    oneNceStore: OneNceStore;
    billingStore: BillingStore;
    tenantChargeStore: TenantChargeStore;
    transactionStore: TransactionStore;
    auditLogStore: AuditLogStore;
    pricingMenuStore: PricingMenuStore;
}

const rootStore: RootStore = {
    authStore,
    preferencesStore,
    deviceStore,
    groupStore,
    tenantStore,
    configurationStore,
    userStore,
    oneNceStore,
    billingStore,
    tenantChargeStore,
    transactionStore,
    auditLogStore,
    pricingMenuStore
};

export default rootStore;