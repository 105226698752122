/*    
<summary>
  This functional component "LoginForm" provides form to login into application on the basis of the credentials provided.
  Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { FormattedMessage } from "react-intl";
import ILogin from "../../../models/ILogin";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import { LoginValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../shared-components/Label/Label";
import { useStore } from "../../../contexts/StoreProvider";
import { observer } from "mobx-react";
import RoutesConstants from "../../../shared-components/Routes/routes.constants";
import { useHistory } from "react-router-dom";
import config from "../../../helpers/config-helper";

interface IProps {
  loginHandler: (data: ILogin) => void;
  initialValues: ILogin;
  isLoading: boolean;
}

const appConfig = config();
const isTrialAccountActive = appConfig.IS_TRIAL_ACCOUNT_ACTIVE;

let LoginForm = (props: IProps) => {
  const history = useHistory();
  const { preferencesStore } = useStore();
  const [showHidePassword, setShowHidePassword] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<{
    language: string;
    isLanguageChanged: boolean;
  }>({ language: preferencesStore.language, isLanguageChanged: false });

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "selectedLanguage.isLanguageChanged" of the useEffect changed.
   */
  useEffect(() => {
    if (selectedLanguage.isLanguageChanged) {
      preferencesStore.changeLanguage(selectedLanguage.language);
      setSelectedLanguage({ ...selectedLanguage, isLanguageChanged: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage.isLanguageChanged]);

  return (
    <>
      <Formik
        initialValues={props.initialValues}
        validationSchema={LoginValidateSchema}
        validateOnBlur={false}
        onSubmit={async (values) => {
          props.loginHandler(values);
        }}
      >
        <Form className="login">
          <div className="display d-flex justify-content-between align-items-center">
            <h1 className="login_heading">monoZ:Link</h1>
            <select
              className="language_select"
              value={selectedLanguage.language}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                if (selectedLanguage.language != e.target.value)
                  setSelectedLanguage({
                    language: e.target.value,
                    isLanguageChanged: true,
                  });
              }}
            >
              <option key="en" value="en">
                EN
              </option>
              <option key="jp" value="jp">
                JP
              </option>
            </select>
          </div>
          <div className="loginBox">
            <div className="form-group">
              <Label
                required={true}
                label="user_label_login_id"
                htmlFor="email"
              />
              <Field
                data-testid="Username"
                className="form-control"
                name="email"
                type="text"
                invalidcharacter={[" "]}
                placeholder="user_label_login_id"
                validcharacter={[
                  "-",
                  "_",
                  "[",
                  "]",
                  "(",
                  ")",
                  ":",
                  "#",
                  "@",
                  ".",
                ]}
                as={FormikFormInput}
              />
            </div>
            <div className="showHideOuterDiv form-group">
              <Label
                required={true}
                label="user_label_password"
                htmlFor="password"
              />
              <Field
                data-testid="Password"
                className="form-control"
                name="password"
                type={showHidePassword ? "text" : "password"}
                invalidcharacter={[" "]}
                validcharacter={[
                  "~",
                  "`",
                  "!",
                  "@",
                  "#",
                  "$",
                  "%",
                  "^",
                  "&",
                  "*",
                  "(",
                  ")",
                  "_",
                  "-",
                  "+",
                  "=",
                  "{",
                  "[",
                  "}",
                  "]",
                  "|",
                  "\\",
                  ":",
                  ";",
                  "'",
                  '"',
                  "<",
                  ",",
                  ">",
                  ".",
                  "?",
                  "/",
                ]}
                placeholder="user_label_password"
                as={FormikFormInput}
              />

              {showHidePassword ? (
                <svg
                  type="button"
                  onClick={() => {
                    setShowHidePassword(!showHidePassword);
                  }}
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="showHidePass css-i6dzq1"
                >
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
              ) : (
                <svg
                  type="button"
                  onClick={() => {
                    setShowHidePassword(!showHidePassword);
                  }}
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="showHidePass css-i6dzq1"
                >
                  <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                  <line x1="1" y1="1" x2="23" y2="23"></line>
                </svg>
              )}
            </div>
            {/* <div className="checkbox">
              <label className="withlabel-checkbox1">
                <Field
                  data-testid="RememberMe"
                  className="form-control"
                  name="rememberMe"
                  type="checkbox"
                  id="inlineFormCheck"
                  as={FormikFormCheckBox}
                />
                <span className="cr">
                  <i className="cr-icon fas fa-check"></i>
                </span>
              </label>
              <h4 className="remember">
                <FormattedMessage id="remember_me" />
              </h4>
            </div> */}
          </div>
          <LoaderButton
            type="submit"
            id="LoginButton"
            className="btn btn-primary btn-user"
            isLoading={props.isLoading}
            text="login"
          />
          <div className="align-items-center justify-content-center flex-wrap d-flex">
            <div className="forgetPas">
              <p
                onClick={() => {
                  history.push(RoutesConstants.ForgetPassword);
                }}
              >
                {<FormattedMessage id="forget_pass" />}
              </p>
            </div>
            {isTrialAccountActive === true ? 
            <>
            <div className="mt-2 mx-4">
            |
            </div>
            <div className="forgetPas">
              <p
                onClick={() => {
                  history.push(RoutesConstants.CreateAccount);
                }}
              >
                {<FormattedMessage id="create_account" />}
              </p>
            </div>
            </>
            : ""}
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default observer(LoginForm);
