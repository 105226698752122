/*    
<summary>
   This functional common component provides us custom date form Field  and has error handler that shows validation error. 
   Developer:Aashish Singh, Created Date:24-Nov-2023
</summary>
<returns>Returns JSX</returns>
*/
import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import { useField } from 'formik';
import { formatMessage } from '../../translations/format-message';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function FormikDateFieldMUI(props:any) {
  const { disabled, min, max , label} = props;
  const [ field, meta, helpers ] = useField(props);
  const { touched, error } = meta;

  return (
    <>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <DatePicker
            renderInput={(params:any) => <TextField {...params} readOnly={true} />}
            value={field.value ? field.value:""}
            views={['year', 'month', 'day']}
            inputFormat="YYYY-MM-DD"
            disabled={props.disabled}
            disableFuture={true}
            minDate={min}
            className='datepicker-control'
            onChange={(newValue:any) => { 
              helpers.setValue(newValue);
            }}
          />
      </Stack>
    </LocalizationProvider>
    { 
        (error &&
        <span data-testid="testError" className="text-danger errorMessage">{formatMessage(error)}</span>)}
    </>
  );
}
