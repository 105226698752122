/*    
<summary>
  This functional component "PublishMessagePopupHOC" provides props to the child component.
  1. State "showPublishMessagePopup" that manages the show/hide of pop up
  2. Function "publishMessageToggleHandler" to toggle the show/hide popup state "showPublishMessagePopup"
  Developer:Mohammad Saquib Khan, Created Date:06-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";

const PublishMessagePopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showPublishMessagePopup, setShowPublishMessagePopup] =
      useState<boolean>(false);

    const publishMessageToggleHandler = () => {
      setShowPublishMessagePopup(!showPublishMessagePopup);
    };

    return (
      <Component
        showPublishMessagePopup={showPublishMessagePopup}
        publishMessageToggleHandler={publishMessageToggleHandler}
        {...props}
      />
    );
  };

export default PublishMessagePopupHOC;
