/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   Developer: Aashish Singh, Created Date: 22-Nov-2023 
</summary>
*/
enum moduleEnum{
    Device,
    Configuration,
    Group,
    Tenant,
    User,
    Account,
    SIM
}

export default moduleEnum;