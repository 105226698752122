/*    
<summary>
   This functional common component provides header to the module.
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React from 'react';
import './TopNavBar.css';

const TopNavBar = () => {
    return (
        <header className="main-header " id="header">
            <nav className="navbar navbar-static-top navbar-expand-lg">

            </nav>
        </header>
    )
}
export default TopNavBar;