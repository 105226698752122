/*    
<summary>
   This functional layout component provides our application layout (after login) that contain left menubar, 
   top nav bar and footer. 
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React from "react";
import TopNavBar from "../NavBar/TopNavBar";
import LeftMenuBar from "../NavBar/LeftNavBar";
import Footer from "../Footer/Footer";
import "./Layout.css";
import { useStore } from "../../contexts/StoreProvider";

const Layout: React.FC<any> = (props) => {
  const { authStore } = useStore();

  return (
    <>
      <div
        className="header-fixed sidebar-fixed sidebar-dark header-light"
        id="body"
      >
        
        <div className="wrapper">
          <LeftMenuBar
            email={authStore.getEmail}
            userType={authStore.getUserType}
          />
          <div className="wrapper device-wrapper">
            <TopNavBar />
            <div className="content-wrapper">
              <div id="moveContent">
                <div className="container-fluid">
                  <div className="">{props.children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
