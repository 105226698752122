/*    
<summary>
   This class component is all about Managing devices.
   Developer: Aashish Singh, Created Date: 03-April-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { action, computed, makeObservable, observable } from "mobx";
import { ICommonState } from "../../models/state/ICommonState";
import { IDeviceState } from "../../models/state/IDeviceState";
import * as baseService from '../service/base-service';
import URLConstants from "../../constants/url.constants";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { formatMessage } from "../../translations/format-message";
import toast, { Toaster } from 'react-hot-toast';
import IAddDevice, { IUpdateDevice } from "../../models/Form/IAddUpdateDevice";
import { IDashboardChartType, IDataList, IDevice, IDeviceCount, IDeviceList, IGetAllDevice, ITotalPacketCount, MonthYearTuple } from "../../models/response/IDeviceResponse";
import { initialState as addDeviceInitialState } from "../initial-state/add-device-state";
import { initialState as allDeviceInitialState } from "../initial-state/get-all-device-state";
import IAddDeviceBulk from "../../models/Form/IAddDeviceInBulk";
import moment from "moment";
import config from "../../helpers/config-helper";
import Papa from 'papaparse';
import JSZip from 'jszip';
import { getPastMonths, getPastMonthsIncCurrent, getShortMonthName } from "../../constants/common-constants";

const appConfig = config();
const dateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT;
const fileNameDateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT_FILENAME;

export class DeviceStore implements IDeviceState, ICommonState {
    inProgress = false;
    error = '';
    deviceDataList: IGetAllDevice = allDeviceInitialState;
    deviceDataListByFilter: IGetAllDevice = allDeviceInitialState;
    recentDevicesList: IGetAllDevice = allDeviceInitialState;

    initialStateValue = {
        success: false,
        error: '',
        inProgress: false
    }

    exportDevicesState = { ... this.initialStateValue }
    addUpdateDevicestate = { ...this.initialStateValue }
    updateActiveSwitchDevicestate = { ...this.initialStateValue }
    moveDevicesState = { ...this.initialStateValue }
    deleteDeviceState = { ...this.initialStateValue }
    deleteAllDeviceState = { ...this.initialStateValue }
    deleteSelectedDeviceState = { ...this.initialStateValue }
    deviceDetail: any = undefined;
    deviceCount: any = undefined;
    failedPacketCount: any = undefined;
    failedPacketCountsState = { ...this.initialStateValue };
    deviceDetailState = { ...this.initialStateValue }
    publishCount: any = undefined;
    publishCountState = {...this.initialStateValue}
    addBulkDeviceState = { ... this.initialStateValue }
    deleteFilteredDevicesState = { ... this.initialStateValue }
    addDeviceCSVState = { ... this.initialStateValue }
    totalPacketsCount: number = 0;
    totalPacketsCountState = { ...this.initialStateValue }
    recentDevicesState = { ...this.initialStateValue }
    allMonthTotalPacketCounts: any = undefined;
    allMonthTotalPacketCountsState = { ...this.initialStateValue };
    publishMessageState = {... this.initialStateValue};

    selectedTag = 0;

    selectedDashboardChartTypeDetails: IDashboardChartType = {chartType: "2", isTypeChanged: false};

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            deviceDataList: observable,
            addUpdateDevicestate: observable,
            updateActiveSwitchDevicestate: observable,
            deviceDataListByFilter: observable,
            failedPacketCount: observable,
            failedPacketCountsState: observable,
            deviceDetail: observable,
            deviceDetailState: observable,
            recentDevicesList: observable,
            deleteDeviceState: observable,
            publishMessageState:observable,
            deleteAllDeviceState: observable,
            deleteSelectedDeviceState: observable,
            moveDevicesState: observable,
            deviceCount: observable,
            publishCount: observable,
            totalPacketsCount: observable,
            totalPacketsCountState: observable,
            addBulkDeviceState: observable,
            exportDevicesState: observable,
            deleteFilteredDevicesState: observable,
            addDeviceCSVState: observable,
            recentDevicesState: observable,
            allMonthTotalPacketCounts: observable,
            allMonthTotalPacketCountsState: observable,
            publishCountState: observable,
            selectedTag: observable,
            selectedDashboardChartTypeDetails: observable,
            getAllDevices: action,
            addDevice: action,
            addDeviceInBulk: action,
            updateDevice: action,
            getRecentlyAddedDevices: action,
            getAllDevicesCount: action,
            updateDeviceActiveSwitch: action,
            getDeviceById: action,
            getTotalPacketsCountByICCIDService: action,
            deleteDevice: action,
            deleteAllDevice: action,
            deleteSelectedDevice: action,
            moveDevicesToGroups: action,
            getAllDevicesByTagsFilter: action,
            addDeviceCSV: action,
            downloadLog: action,
            generateLogContent: action,
            deleteFilteredDevice: action,
            PublishMessageForDevice:action,
            reset: action,
            resetStore: action,
            resetAddUpdateDevice: action,
            resetAddBulkDeviceState: action,
            resetGetDeviceDetail: action,
            resetMoveDevicesToGroups: action,
            resetUpdateDeviceActiveSwitch: action,
            resetExportCSVState: action,
            resetTotalPacketsCountState: action,
            resetPublishMessageState:action,
            exportDevices: action,
            setSelectedTagDeviceId: action,
            getAllPublishMessageCount: action,
            getAllFailedPacketCounts:action,
            setSelectedDashboardChartTypeDetails:action,
            resetChartDataStates:action,
            devieDetail: computed,
            deviceAllDetail: computed,
            allDeviceslist: computed,
            getRecentDevices: computed,
            allDeviceslistByFilter: computed,
            allTagslist: computed,
            allDevicesIccidList: computed,
            allDevicesNameList: computed,
            getAllMonthPublishMessageGraphData:computed,
            getAllMonthFailedPacketsGraphData: computed,
            getDeviceSummaryGraphData: computed
        });
    }

    /**
    * This function is used to get All devices list from API by group id.
    * @param data: An object that have data like groupId, DeviceName & tags
    */
    getAllDevices = (data: any) => {
        this.inProgress = true;
        const url = URLConstants.GetAllDevices;
        return baseService.postRequest(url, data)
            .then((response: IApiResponse<IApiSuccessResponse<IGetAllDevice>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.deviceDataList = response.data.Data;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false }));
    }


    /**
    * This function is used to get All devices list from API using Tags & Group Filters.
    * @param groupid : Group Identifier
    * @param pageNumber : Page Number
    * @param pageSize : Page Size / No of records in a page
    * @returns
    */
    getAllDevicesByTagsFilter = (filterData: any) => {
        this.inProgress = true;
        const url = URLConstants.GetAllDevicesByFilter;
        return baseService.postRequest(url, filterData)
            .then((response: IApiResponse<IApiSuccessResponse<IGetAllDevice>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.deviceDataListByFilter = response.data.Data;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false }));
    }


    /**
    * This function is used to get All devices Count from API.
    */
    getAllDevicesCount = (month:number,year:number) => {
        this.inProgress = true;
        const url = URLConstants.GetAllDevicesCount+"?Month="+month+"&Year="+year;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IDeviceCount>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.deviceCount = response.data.Data;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false }));
    }

    /**
    * This function is used to get All devices Publish Count from API.
    */
    getAllPublishMessageCount = () => {
        this.inProgress = true;
        const url = URLConstants.GetAllPublishMessageCount;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IDeviceCount>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.publishCount = response.data.Data;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false }));
    }

    /**
    * This function is used to get All devices Count from API.
    */
    getAllFailedPacketCounts = () => {
        this.failedPacketCountsState.inProgress = true;
        const url = URLConstants.GetAllFailedPacketCount;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IDeviceCount>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.failedPacketCount = response.data.Data;
                    this.failedPacketCountsState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                this.failedPacketCountsState.error = err;
            })
            .finally(action(() => { this.failedPacketCountsState.inProgress = false }));
    }

    /*
    This function is used to set device detail chart info.  
    */
    setSelectedDashboardChartTypeDetails = (chartTypeDetails:IDashboardChartType) => {
        this.selectedDashboardChartTypeDetails = {...chartTypeDetails};
    }

    /**
     * This function is used to Get Device Data Details by Id from API.
     * @param id : Device Identifier
     * @returns
     */
    getDeviceById = (id: number) => {
        this.deviceDetailState.inProgress = true;
        const url = URLConstants.Device + "/" + id;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IDevice>>) => {
                if (response.data.Error) {
                    this.deviceDetailState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.deviceDetail = response.data.Data;
                    this.deviceDetailState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.deviceDetailState.inProgress = false }));
    }

    /**
     * This function is used to Publish Message by Iccid from API.
     * @param iccid : Device Identifier
     * @returns
     */
        PublishMessageForDevice = (obj:any,iccid:string) => {
            this.publishMessageState.inProgress = true;
            const url = URLConstants.Device + "/" + iccid+"/Publish/Message";
            return baseService.postRequest(url,obj)
                .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                    if (response.data.Error) {
                        this.publishMessageState.error = response.data.Message;
                        toast.error(formatMessage(response.data.Message));
                    }
                    else {
                        this.deviceDetail = response.data.Data;
                        this.publishMessageState.success = true;
                    }
                })
                .catch((err: string) => {
                    toast.error(formatMessage(err));
                })
                .finally(action(() => { this.publishMessageState.inProgress = false }));
        }

    /**
     * This function is used to Get all Months Total Packet counts from API.
     * @param id : Device Identifier
     * @returns
     */
    getAllMonthsTotalPacketCounts = () => {
        this.allMonthTotalPacketCountsState.inProgress = true;
        const url = URLConstants.AllMonthPacketCount;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<Array<ITotalPacketCount>>>) => {
                if (response.data.Error) {
                    this.allMonthTotalPacketCountsState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.allMonthTotalPacketCounts = response.data.Data;
                    this.allMonthTotalPacketCountsState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.allMonthTotalPacketCountsState.inProgress = false }));
    }

    /**
     * This function is used to Get Recently added devices.
     * @param deviceCount : Total Devices Count
     * @returns
     */
    getRecentlyAddedDevices = (deviceCount: number) => {
        var obj = {
            DeviceCount: deviceCount
        };
        this.recentDevicesState.inProgress = true;
        const url = URLConstants.GetRecentDevices;
        return baseService.postRequest(url, obj)
            .then((response: IApiResponse<IApiSuccessResponse<IGetAllDevice>>) => {
                if (response.data.Error) {
                    this.recentDevicesState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.recentDevicesList = response.data.Data;
                    this.recentDevicesState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.recentDevicesState.inProgress = false }));
    }

    /**
     * This function is used to Add New Device. 
     * @param device : Device Details
     * @returns
     */
    addDevice = (device: IAddDevice) => {
        this.addUpdateDevicestate.inProgress = true;
        return baseService.postRequest(URLConstants.Device, device)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addUpdateDevicestate.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.addUpdateDevicestate.success = true;
            })
            .catch((err: string) => {
                if (err.includes(":")) {
                    let errorMess = err.split(":");
                    toast.error(errorMess[0] + " : " + formatMessage(errorMess[1]));
                } else { toast.error(formatMessage(err)); }
            })
            .finally(action(() => { this.addUpdateDevicestate.inProgress = false; }));
    }

    /**
     * This function is used to Move Devices to Groups. 
     * @param data : Device & Group Details
     * @returns
     */
    moveDevicesToGroups = (data: any) => {
        this.moveDevicesState.inProgress = true;
        return baseService.postRequest(URLConstants.UpdateMultipleDevicesGroup, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.moveDevicesState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.moveDevicesState.success = true;
                    toast.success(formatMessage(response.data.Message));
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.moveDevicesState.inProgress = false; }));
    }

    /**
     * This function is used to update existing device details. 
     * @param id : Device identifier
     * @param device : Device Details
     * @returns
     */
    updateDevice = (id: number, device: IAddDevice) => {
        let data: IUpdateDevice = {
            Id: id,
            ...device
        }
        this.addUpdateDevicestate.inProgress = true;
        return baseService.putRequest(URLConstants.Device, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addUpdateDevicestate.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.addUpdateDevicestate.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.addUpdateDevicestate.inProgress = false; }));
    }

    /**
    * This function is used to update existing device Active / InActive Details. 
    * @param id : Device identifier
    * @param device : Device Details
    * @returns
    */
    updateDeviceActiveSwitch = (id: number, device: IAddDevice) => {
        let data: IUpdateDevice = {
            Id: id,
            ...device
        }
        this.updateActiveSwitchDevicestate.inProgress = true;
        return baseService.putRequest(URLConstants.Device, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.updateActiveSwitchDevicestate.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.updateActiveSwitchDevicestate.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.updateActiveSwitchDevicestate.inProgress = false; }));
    }

    /**
     * This function is used to delete existing device. 
     * @param id : Device identifier
     * @returns
     */
    deleteDevice = (id: number) => {
        this.deleteDeviceState.inProgress = true;
        const url = URLConstants.Device + "/" + id;
        return baseService.deleteRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.deleteDeviceState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.deleteDeviceState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.deleteDeviceState.inProgress = false; }));
    }

    /**
     * This function is used to delete existing device. 
     * @param id : Group identifier
     * @returns
     */
    deleteAllDevice = (id: number) => {
        this.deleteAllDeviceState.inProgress = true;
        const url = URLConstants.DeleteAllDevice + "/" + id;
        return baseService.deleteRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.deleteAllDeviceState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.deleteAllDeviceState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.deleteAllDeviceState.inProgress = false; }));
    }

    /**
     * This function is used to delete existing device. 
     * @param id : Group identifier
     * @returns
     */
    deleteSelectedDevice = (obj: any) => {
        this.deleteSelectedDeviceState.inProgress = true;
        return baseService.postRequest(URLConstants.DeleteSelected, obj)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.deleteSelectedDeviceState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.deleteSelectedDeviceState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.deleteSelectedDeviceState.inProgress = false; }));
    }

    /**
 * This function is used to delete existing device. 
 * @param id : Group identifier
 * @returns
 */
    deleteFilteredDevice = (obj: any) => {
        this.deleteFilteredDevicesState.inProgress = true;
        return baseService.postRequest(URLConstants.DeleteFiltered, obj)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.deleteFilteredDevicesState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else this.deleteFilteredDevicesState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.deleteFilteredDevicesState.inProgress = false; }));
    }

    /**
    * This function is used to Add New Device using Bulk Device Service. 
    * @param devices : Bulk Device Details
    * @returns
    */
    addDeviceInBulk = (devices: IAddDeviceBulk) => {
        this.addBulkDeviceState.inProgress = true;
        return baseService.postRequest(URLConstants.AddBulkDevice, devices)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addBulkDeviceState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.addBulkDeviceState.success = true;
                    // toast.success(formatMessage(response.data.Message));
                }

            })
            .catch((err: string) => {
                if (err.includes(":")) {
                    let errorMess = err.split(":");
                    toast.error(formatMessage(errorMess[0]) + " : " + errorMess[1]);
                } else { toast.error(formatMessage(err)); }

            })
            .finally(action(() => { this.addBulkDeviceState.inProgress = false; }));
    }

    generateAndDownloadZip = (arrays: any) => {
        const currentDate = new Date().toLocaleString();
        const zip = new JSZip();
        arrays.forEach((arr: any, index: any) => {
            const csvData = Papa.unparse(arr);
            zip.file(`data${index + 1}.csv`, csvData);
        });

        zip.generateAsync({ type: 'blob' })
            .then((content: any) => {
                const zipFile = new Blob([content]);
                const link = document.createElement('a');
                link.href = URL.createObjectURL(zipFile);
                link.download = "Monoz_" + currentDate + '_CSV.zip';
                link.click();
            })
            .catch((error: any) => console.error('Error generating zip', error));


    };

    /**
    * This function is used to Export Devices in CSV. 
    * @param Filter : Group & Filter Details
    * @returns
    */
    exportDevices = (data: any) => {
        this.exportDevicesState.inProgress = true;
        return baseService.postRequest(URLConstants.ExportDevices, data)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.exportDevicesState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.exportDevicesState.success = true;
                    this.generateAndDownloadZip(response.data.Data);
                    toast.success(formatMessage(response.data.Message));
                }

            })
            .catch((err: string) => {
                if (err.includes(":")) {
                    let errorMess = err.split(":");
                    toast.error(formatMessage(errorMess[0]) + " : " + errorMess[1]);
                } else { toast.error(formatMessage(err)); }

            })
            .finally(action(() => { this.exportDevicesState.inProgress = false; }));
    }

    setSelectedTagDeviceId = (id:number) =>{
        this.selectedTag = id;
    }

    /**
    * This function is used to Add New Device using CSV Service. 
    * @param devices : Add Device CSV 
    * @returns
    */
    addDeviceCSV = (devices: IAddDeviceBulk) => {
        this.addDeviceCSVState.inProgress = true;
        return baseService.postRequest(URLConstants.AddBulkCSV, devices)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addDeviceCSVState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.downloadLog(response.data);
                    this.addDeviceCSVState.success = true;
                    toast.success(formatMessage(response.data.Message));
                }

            })
            .catch((err: string) => {
                this.addDeviceCSVState.error = err;
                if (err.includes(":")) {
                    let errorMess = err.split(":");
                    toast.error(formatMessage(errorMess[0]) + " : " + errorMess[1]);
                } else { toast.error(formatMessage(err)); }

            })
            .finally(action(() => { this.addDeviceCSVState.inProgress = false; }));
    }


    /**
    * This function is used to download Log. 
    * @param devices : Device CSV 
    * @returns
    */
    downloadLog = (data: any) => {
        const currentDate = new Date();
        const logContent = this.generateLogContent(data.Data);
        const blob = new Blob([logContent], { type: "text/plain" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = moment(currentDate).format(fileNameDateTimeFormat) + " log.txt";
        a.click();
        URL.revokeObjectURL(a.href);
    };


    generateLogContent = (data: any) => {
        const currentDate = new Date();
      
        const generateErrorList = (values: string[] | number, itemsPerLine: number = 5) => {
            if (!Array.isArray(values) || values.length === 0) {
              return 'None';
            }
        
            const formattedList = values.map((item, index) => {
              const separator = (index + 1) % itemsPerLine === 0 ? '\n' : ', ';
              return `${item}${separator}`;
            });
        
            return formattedList.join('').trim();
          };
      
        const logContent = `
Log generated on: ${currentDate}
Total Device Count (To be added): ${data.TotalDevices}
Device Added: ${data.FilteredDevices - data.FailedDueToSpace?.length}

**Errors**

Device Failed: ${data.TotalDevices - data.FilteredDevices + data.FailedDueToSpace?.length}
[Row No's] Device Name Validation Breached : ${data.NameValidation?.length > 0 ? data.NameValidation : 0}
[Row No's] Device Name Contains Invalid Character: ${data.NameHasInvalidChar > 0 ? data.NameHasInvalidChar : 0}          
[Row No's] Iccid Validation Breached : ${data.IccidValidation?.length > 0 ? data.IccidValidation : 0}          
[Row No's] Iccid is Alphanumeric : ${data.IccidAlphanumerics?.length > 0 ? data.IccidAlphanumerics : 0}      
[Row No's] Duplicate Data in CSV: ${data.DuplicateIndex?.length > 0 ? data.DuplicateIndex : 0}
[Row No's] Devices Failed due to Device Limit: ${data.FailedDueToSpace?.length > 0 ? data.FailedDueToSpace : 0}
[Cell No's] Missing Fields in CSV: ${data.MissingCellsIndex.length > 0 ?
    data.MissingCellsIndex.filter(
        (index: string) => !index.includes("C") && !index.includes("D")
    ) : 0}

**Device Names Unavailable (Count ${data.DeviceNameNotAvailable?.length}) ** :
${data?.DeviceNameNotAvailable?.length > 0 ? generateErrorList(data.DeviceNameNotAvailable) : 0}

**Device Iccid's Unavailable (Count: ${data.IccidNotAvailable?.length}) ** :
 ${data?.IccidNotAvailable?.length > 0 ? generateErrorList(data.IccidNotAvailable) : 0}
`;
      
        return logContent;
      };
      
    /**
     * This function is used to Get Device Data Details by Id from API.
     * @param id : Device Identifier
     * @returns
     */
    getTotalPacketsCountByICCIDService = (iccid: number) => {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        this.totalPacketsCountState.inProgress = true;
        const url = URLConstants.TotalPacketsCount + "/" + iccid + "?month=" + month + "&year=" + year;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<number>>) => {
                if (response.data.Error) {
                    this.totalPacketsCountState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.totalPacketsCount = response.data.Data;
                    this.totalPacketsCountState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.totalPacketsCountState.inProgress = false }));
    }



    /**
     * This function is used to reset observables to their initial values.
     * @returns
     */
    reset = () => {
        this.error = '';
        this.inProgress = false;
        this.deleteDeviceState = {
            ...this.initialStateValue
        };
        this.deleteAllDeviceState = {
            ...this.initialStateValue
        };
        this.deleteSelectedDeviceState = {
            ...this.initialStateValue
        };
        this.deleteFilteredDevicesState = {
            ...this.initialStateValue
        }
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.deviceDataList = allDeviceInitialState;
        this.deviceDataListByFilter = allDeviceInitialState;
        this.recentDevicesList = allDeviceInitialState;
        this.addUpdateDevicestate = { ...this.initialStateValue }
        this.updateActiveSwitchDevicestate = { ...this.initialStateValue }
        this.moveDevicesState = { ...this.initialStateValue }
        this.deleteDeviceState = { ...this.initialStateValue }
        this.deleteAllDeviceState = { ...this.initialStateValue }
        this.deleteSelectedDeviceState = { ...this.initialStateValue }
        this.deviceDetail = undefined;
        this.deviceCount = undefined;
        this.publishCount = undefined;
        this.deviceDetailState = { ...this.initialStateValue }
        this.addBulkDeviceState = { ... this.initialStateValue }
        this.exportDevicesState = { ... this.initialStateValue }
        this.addDeviceCSVState = { ... this.initialStateValue }
        this.totalPacketsCount = 0;
        this.totalPacketsCountState = { ...this.initialStateValue }
        this.allMonthTotalPacketCounts = undefined;
        this.allMonthTotalPacketCountsState = { ...this.initialStateValue }
        this.publishMessageState = {... this.initialStateValue}
        this.publishCountState = {...this.initialStateValue}
    }

    /**
     * This function is used to reset addUpdateDevice observables to their initial values.
     * @returns
     */
    resetAddUpdateDevice = () => {
        this.addUpdateDevicestate = { ...this.initialStateValue }
    }

    /**
     * This function is used to reset addBulkDeviceState observables to their initial values.
     */
    resetAddBulkDeviceState = () => {
        this.addBulkDeviceState = { ...this.initialStateValue }
    }


    /**
     * This function is used to reset addUpdateDevice observables to their initial values.
     * @returns
    */
    resetMoveDevicesToGroups = () => {
        this.moveDevicesState = { ...this.initialStateValue }
    }


    /**
     * This function is used to reset addUpdateDevice observables to their initial values.
     * @returns
     */
    resetUpdateDeviceActiveSwitch = () => {
        this.updateActiveSwitchDevicestate = { ...this.initialStateValue }
    }

    /**
     * This function is used to reset getDeviceDetail observables to their initial values.
     * @returns
     */
    resetGetDeviceDetail = () => {
        this.deviceDetail = undefined;
        this.deviceDetailState = { ...this.initialStateValue }
    }

    /**
     * This function is used to reset Export CSV observables to their initial values.
     * @returns
     */
    resetExportCSVState = () => {
        this.exportDevicesState = { ...this.initialStateValue }
    }


    /**
     * This function is used to reset Export CSV observables to their initial values.
     * @returns
     */
    resetDeleteFilteredDevices = () => {
        this.deleteFilteredDevicesState = { ...this.initialStateValue }
    }

    /**
     * This function is used to reset Export CSV observables to their initial values.
     * @returns
     */
    resetAddDeviceCSV = () => {
        this.addDeviceCSVState = { ...this.initialStateValue }
    }

    /**
    * This function is used to resetTotalPacketsCount observables to their initial values.
    * @returns
    */
    resetTotalPacketsCountState = () => {
        this.totalPacketsCount = 0;
        this.totalPacketsCountState = { ...this.initialStateValue }
    }

    /**
    * This function is used to resetTotalPacketsCount observables to their initial values.
    * @returns
    */
    resetAllMonthTotalPacketsCountState = () => {
        this.allMonthTotalPacketCountsState = { ...this.initialStateValue }
    }

    /**
    * This function is used to resetPublishMessage observables to their initial values.
    * @returns
    */
    resetPublishMessageState = () => {
        this.publishMessageState = { ...this.initialStateValue }
    }

    /**
    * This function is used to resetPublishMessage observables to their initial values.
    * @returns
    */
    resetPublishCount = () => {
        this.publishCount = undefined;
        this.publishCountState = { ...this.initialStateValue }
    }

    /**
      * This function provides initail values to the Add Update Device Form.
      * @returns Initial Device Details
      */
    get devieDetail(): IAddDevice {
        if (this.deviceDetail)
            return {
                GroupId: this.deviceDetail?.Group?.Id,
                Protocol: this.deviceDetail?.Group?.ConfigurationProtocol?.DestinationProtocol,
                Name: this.deviceDetail.Name,
                Iccid: this.deviceDetail.Iccid,
                IsEnable: this.deviceDetail.IsEnable,
                Tags: this.deviceDetail.Tags,
                FirstPacketTimestamp: this.deviceDetail.FirstPacketTimestamp,
                ClientCertificateData: this.deviceDetail.ClientCertificate,
                ClientKeyData: this.deviceDetail.ClientKey,
                ClientCertificate: "",
                ClientKey: "",
            };
        return addDeviceInitialState;
    }


    /**
     * This function is used to map deviceDataList to allDeviceslist suitable for Grid Component.
     * @returns Initial Device Details
     */
    get allDeviceslist(): IDeviceList[] {
        if (this.deviceDataList?.Devices && this.deviceDataList.Devices.length > 0)
            return this.deviceDataList?.Devices.map((device) => {
        let tagSelected = this.selectedTag == device.Id ? true : false;
                return {
                    Id: device?.Id,
                    Group: device?.Group,
                    Name: device?.Name,
                    Iccid: device?.Iccid,
                    SourceProtocol: device?.Group?.ConfigurationProtocol?.SourceProtocol,
                    DestinationProtocol: device?.Group?.ConfigurationProtocol?.DestinationProtocol,
                    IsEnable: device?.IsEnable,
                    IsActive: device?.IsActive == true,
                    FirstPacketTimestamp: device.FirstPacketTimestamp == null ? null : moment(device.FirstPacketTimestamp)?.format(dateTimeFormat),
                    Tags: device?.Tags,
                    isDelete: true,
                    isEdit: true,
                    isData: true,
                    isPublishMessage: true,
                    isTagSelected: tagSelected
                }
            })
            
        return [];
    }

    get allDevicesNameList(): IDataList[]{
        if (this.deviceDataList?.Devices && this.deviceDataList.Devices.length > 0)
            return this.deviceDataList?.Devices.map((device) => {
                return {
                    Id: device?.Id,
                    Value: device?.Name,
                }
            })
        return [];
    }

    get allDevicesIccidList(): IDataList[]{
        if (this.deviceDataList?.Devices && this.deviceDataList.Devices.length > 0)
            return this.deviceDataList?.Devices.map((device) => {
                return {
                    Id: device?.Id,
                    Value: device?.Iccid,
                }
            })
        return [];
    }

    /**
     * This function is used to map tag list for Grid Component.
     * @returns Initial Tags Details
    */
        get allTagslist(): IDataList[] {
            if (this.deviceDataList?.Devices && this.deviceDataList.Devices.length > 0){
                const uniqueTags = [...new Set(this.deviceDataList.Devices.flatMap(device => device.Tags))];
                if(uniqueTags.length > 0){
                  return uniqueTags.map((tags, index) => {
                        return{
                            Id: index,
                            Value: tags
                        }
                    })
                }
            }                
            return [];
        }

    /**
    * This function is used to map deviceDataList to allDeviceslist suitable for Grid Component.
    * @returns Initial Device Details
    */
    get allDeviceslistByFilter(): IDeviceList[] {
        if (this.deviceDataListByFilter?.Devices && this.deviceDataListByFilter.Devices.length > 0)
            return this.deviceDataListByFilter?.Devices.map((device) => {
                return {
                    Id: device?.Id,
                    Group: device?.Group,
                    Name: device?.Name,
                    Iccid: device?.Iccid,
                    SourceProtocol: device?.Group?.ConfigurationProtocol?.SourceProtocol,
                    DestinationProtocol: device?.Group?.ConfigurationProtocol?.DestinationProtocol,
                    IsActive: device?.IsActive,
                    IsEnable: device?.IsEnable,
                    FirstPacketTimestamp: device.FirstPacketTimestamp == null ? null : moment(device?.FirstPacketTimestamp)?.format(dateTimeFormat),
                    Tags: device?.Tags,
                    isDelete: true,
                    isEdit: true,
                    isData: true,
                    isPublishMessage: true
                }
            })
        return [];
    }

    /**
    * This function is used to map deviceDataList to allDeviceslist suitable for Grid Component.
    * @returns Initial Device Details
    */
    get getRecentDevices(): IDeviceList[] {
        if (this.recentDevicesList?.Devices && this.recentDevicesList.Devices.length > 0)
            return this.recentDevicesList?.Devices.map((device) => {
                return {
                    Id: device?.Id,
                    Group: device?.Group,
                    Name: device?.Name,
                    Iccid: device?.Iccid,
                    SourceProtocol: device?.Group?.ConfigurationProtocol?.SourceProtocol,
                    DestinationProtocol: device?.Group?.ConfigurationProtocol?.DestinationProtocol,
                    IsActive: device?.IsActive,
                    IsEnable: device?.IsEnable,
                    FirstPacketTimestamp: device?.FirstPacketTimestamp,
                    Tags: device?.Tags,
                }
            })
        return [];
    }

    /**
    * This function is used to map Active & Inactive Devices suitable for Grid Component.
    * @returns Active / Inactive Device Details Object
    */
    get activeInactiveDevices(): any {
        let count: number = 0;
        if (this.deviceDataList?.Devices && this.deviceDataList.Devices.length > 0)
            this.deviceDataList?.Devices.map((device) => {
                if (device.IsActive == true) {
                    count += 1
                }
            })

        let obj = {
            active: count,
            inactive: this.deviceDataList.Devices.length - count
        }
        return obj;
    }

    get RecentDevices(): any {
        let date = new Date();
        let s = moment(date).format(dateTimeFormat);
        let e = moment().subtract(7, "d").format(dateTimeFormat);
        let data: any[] = [];
        if (this.deviceDataList?.Devices && this.deviceDataList.Devices.length > 0)
            this.deviceDataList?.Devices.map((device) => {
                if (device.IsEnable == true) {
                    data.push({

                    })
                }
            })
        return [];
    }

    get deviceAllDetail(): any {
        if (this.deviceDetail)
            return {
                Id: this.deviceDetail.Id,
                Group: this.deviceDetail.Group,
                Name: this.deviceDetail.Name,
                Iccid: this.deviceDetail.Iccid,
                IsEnable: this.deviceDetail.IsEnable,
                Tags: this.deviceDetail.Tags,
            };
        return this.deviceDetail;
    }

    get getAllMonthPacketsGraphData(): any {
        let packetCounts:number[] = [];
        let labels:string[] = [];
        if (this.allMonthTotalPacketCounts) {
            const currentDate = new Date();
            const pastMonths = getPastMonthsIncCurrent(currentDate.getMonth() + 1, currentDate.getFullYear(), 12);
                for (let i = 0; i< pastMonths?.length; i++) {
                    if(pastMonths[i]?.length > 0) {
                        let month = pastMonths[i][1]; let year = pastMonths[i][0];
                        const data = this.allMonthTotalPacketCounts.find((item:any) => item.Month == month && item.Year == year);
                        labels.push(getShortMonthName(pastMonths[i][1])+" "+pastMonths[i][0]);
                        if(data)
                            packetCounts.push(data.TotalPacketCount);
                        else
                            packetCounts.push(0);
                    }
                }
            return { DataPackets: packetCounts, Labels: labels };
        }
        return {DataPackets: packetCounts, Labels: labels };
    }

    get getAllMonthFailedPacketsGraphData(): any {
        let packetCounts:number[] = [];
        let labels:string[] = [];
        if (this.failedPacketCount) {
            const currentDate = new Date();
            const pastMonths = getPastMonthsIncCurrent(currentDate.getMonth() + 1, currentDate.getFullYear(), 12);
                for (let i = 0; i< pastMonths?.length; i++) {
                    if(pastMonths[i]?.length > 0) {
                        let month = pastMonths[i][1]; let year = pastMonths[i][0];
                        const data = this.failedPacketCount.find((item:any) => item.Month == month && item.Year == year);
                        labels.push(getShortMonthName(pastMonths[i][1])+" "+pastMonths[i][0]);
                        if(data)
                            packetCounts.push(data.TotalPacketCount);
                        else
                            packetCounts.push(0);
                    }
                }
            return { DataPackets: packetCounts, Labels: labels };
        }
        return {DataPackets: packetCounts, Labels: labels };
    }

    get getAllMonthPublishMessageGraphData(): any {
        let packetCounts:number[] = [];
        let labels:string[] = [];
        if (this.publishCount) {
            const currentDate = new Date();
            const pastMonths = getPastMonthsIncCurrent(currentDate.getMonth() + 1, currentDate.getFullYear(), 12);
                for (let i = 0; i< pastMonths?.length; i++) {
                    if(pastMonths[i]?.length > 0) {
                        let month = pastMonths[i][1]; let year = pastMonths[i][0];
                        const data = this.publishCount.find((item:any) => item.Month == month && item.Year == year);
                        labels.push(getShortMonthName(pastMonths[i][1])+" "+pastMonths[i][0]);
                        if(data)
                            packetCounts.push(data.TotalMsgCount);
                        else
                            packetCounts.push(0);
                    }
                }
            return { DataPackets: packetCounts, Labels: labels };
        }
        return {DataPackets: packetCounts, Labels: labels };
    }
    
    get getDeviceSummaryGraphData():any {
        let labels:string[]= [];
        let deviceAdded:number[]= [];
        let deviceDeleted:number[]= [];
        let deviceActivated:number[]= [];
        let deviceInActive: number[] = [];
        if(this.deviceCount){
            for(var i=0;i<this.deviceCount?.DeviceSummary?.length;i++){
                labels.push(getShortMonthName(this.deviceCount?.DeviceSummary[i]?.Month)+" "+this.deviceCount?.DeviceSummary[i]?.Year);
                deviceAdded.push(this.deviceCount?.DeviceSummary[i]?.DeviceAdded);
                deviceDeleted.push(this.deviceCount?.DeviceSummary[i]?.DeviceDeleted);
                deviceActivated.push(this.deviceCount?.DeviceSummary[i]?.DeviceActivated);
                deviceInActive.push(this.deviceCount?.DeviceSummary[i]?.DeviceInActive);
            }
            return {
                Labels: labels,
                DeviceAdded: deviceAdded,
                DeviceDeleted: deviceDeleted,
                DeviceActivated: deviceActivated,
                DeviceInActive: deviceInActive
            };
        }
        return {
            Labels: labels,
            DeviceAdded: deviceAdded,
            DeviceDeleted: deviceDeleted,
            DeviceActivated: deviceActivated,
            DeviceInActive: deviceInActive
        };
    }

    resetChartDataStates = () =>{
        this.publishCount = undefined;
        this.publishCountState = {...this.initialStateValue}
        this.failedPacketCount = undefined;
        this.failedPacketCountsState = { ...this.initialStateValue };
        this.allMonthTotalPacketCounts = undefined;
        this.allMonthTotalPacketCountsState = { ...this.initialStateValue };
    }
}

export default new DeviceStore();