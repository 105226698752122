/*    
<summary>
  This functional component "ConfirmPopupHOC" provides props to the child component.
  1. State "showConfirmPopup" that manages the show/hide of pop up
  2. Function "ConfirmPopupToggleHandler" to toggle the show/hide popup state "showConfirmPopup"
  Developer:Aashish Singh, Created Date:28-Aug-2022
</summary>
<returns>Returns JSX</returns>
*/

import React, { useState } from "react";

const ActiveDeviceConfirmPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showActiveDeviceConfirmPopup, setShowActiveDeviceConfirmPopup] =
      useState<boolean>(false);

    const deviceActiveConfirmToggleHandler = () => {
      setShowActiveDeviceConfirmPopup(!showActiveDeviceConfirmPopup);
    };

    return (
      <Component
        showActiveDeviceConfirmPopup={showActiveDeviceConfirmPopup}
        deviceActiveConfirmToggleHandler={deviceActiveConfirmToggleHandler}
        {...props}
      />
    );
  };

export default ActiveDeviceConfirmPopupHOC;
