/*    
<summary>
  This functional component is used for proper authentication of user using the Login Credentials (Username & Password)
  Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import toast from "react-hot-toast";
import "./Login.css";
import { withRouter } from "react-router-dom";
import RoutesConstants from "../../shared-components/Routes/routes.constants";
import { formatMessage } from "../../translations/format-message";
import { observer } from "mobx-react";
import { useStore } from "../../contexts/StoreProvider";
import Footer from "../../shared-components/Footer/Footer";
import ILogin from "../../models/ILogin";
import { ICommonState } from "../../models/state/ICommonState";
import { useCookies } from "react-cookie";
import { errorMessage } from "../../constants/error.constants";
import LoginForm from "./forms/LoginForm";
import Lottie from "react-lottie";
import monoZlogo from "../../assets/Images/Lottie/monoZLink.json";
import monozlogo from "../../assets/Images/monoZ_Black.png";
import userTypeEnum from "../../constants/user-type-enum";

const css = `
body, html {
  overflow: visible;
  height: auto;
}
`;
const Login = (props: any) => {
  const { authStore, preferencesStore, tenantStore } = useStore();
  const { getVersion } =
    preferencesStore;
  const {
    addTrialTenantState,
    resetAddTrialTenantState,
  } = tenantStore;
  const { error, inProgress }: ICommonState = authStore;
  const { isAuthenticated, getToken, login, getUserType } = authStore;
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const emailFromCookies = cookies.user;
  const initialValues: ILogin = {
    email: emailFromCookies !== undefined ? emailFromCookies : "",
    password: "",
    rememberMe: emailFromCookies !== undefined ? true : false,
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: monoZlogo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const handleLogin = (values: ILogin) => {
    if (values.rememberMe === true) {
      setCookie("user", values.email);
    } else {
      if (cookies.user != "") {
        removeCookie("user");
      }
    }
    login(values);
  };

  useEffect(() => {
    if (isAuthenticated || getToken) {
      getVersion();
      if (getUserType === userTypeEnum.SuperAdministrator)
        props.history.push(RoutesConstants.Tenants);
      else props.history.push(RoutesConstants.Dashboard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (error) {
      if(error === errorMessage.UserAlreadyLogin){
        props.confirmLoginPopupToggleHandler()
      }
      else{
        toast.error(formatMessage(error));
        authStore.reset();
      }
    }
  }, [error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateDevicestate.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addTrialTenantState.success) {
      toast.success(formatMessage("account_created_success"));
      resetAddTrialTenantState();
    }
  }, [addTrialTenantState.success]);

  return (
    <>
      <div className="bg-img1 text-left background">
        <div className="login_wrapper">          
          <img src={monozlogo} alt="mono_z_Logo" className="LightSmallLogo" />
          <Lottie options={defaultOptions} height={230} width={790} />            
        </div>
        <LoginForm
          isLoading={inProgress}
          loginHandler={handleLogin}
          initialValues={initialValues}
        />
      </div>
      <Footer />
    </>
  );
};

export default withRouter(observer(Login));
