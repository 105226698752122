/*    
<summary>
   This file is used to provide initial values or state to the User Data observables.
   Developer:Ashish Singh, Created Date: 29-August-2023
</summary>
*/

import { IAddUser } from "../../models/Form/IAddUser";

export const initialState:IAddUser = {
    UserType: -1,
    Password: '', 
    Email: '',
    IsActive: true,
    UserName: ''
}