/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined constant.
   This constant is used in providing paths for API Route URL for every modules.
   Developer: Aashish Singh, Created Date: 08-Mar-2023
   UpdatedBy:Deepak Paliwal,Date: 23-March-2023
</summary>
*/
const URLConstants = {
    Authenticate: '/Account/Login',
    Logout: '/Account/Logout',
    LoginReset: '/Account/Login/Reset',
    RefreshToken: '/Account/Token',
    AddConfiguration: '/ConfigurationProtocol',
    UpdateConfiguration: '/ConfigurationProtocol',
    GetConfigurationById: '/ConfigurationProtocol',
    GetConfigurationGroupAssociatedInfo: '/ConfigurationProtocol/IsGroupAssociated',
    DeleteConfiguration: '/ConfigurationProtocol',
    AddBulkDevice: '/Device/Bulk',
    AddBulkCSV: '/Device/CSV',
    Device: '/Device',
    DeleteAllDevice: '/Device/Group',
    DeleteSelected: '/Device/Delete/Multiple',
    GetAllDevices: '/Device/List',
    ExportDevices: '/Device/Export',
    GetAllDevicesByFilter: '/Device/List/Tags/Filter',
    UpdateMultipleDevicesGroup: '/Device/Move/Multiple/Device',
    GetRecentDevices: '/Device/List/Recent',
    GetAllDevicesCount: '/Device/Summary/Count',
    GetAllPublishMessageCount: '/Device/All/Monthly/Published/Messages/Count',
    GetAllFailedPacketCount: 'Device/All/Monthly/FailedPacket/Count',
    TotalPacketsCount: '/Device/Total/Packets/Count',
    GetGroupById: '/Group',
    UpdateGroupIMEILockStatus: '/Group/Update/IMEILock/Status',
    GetGroupInfo: '/Group/GroupInfo',
    AddGroup: '/Group',
    UpdateGroup: '/Group',
    GetAllGroups: '/Group',
    UpdateDefaultGroup: '/Group/Update/Default',
    GetAllBlockedGroups: '/Group/Blocked',
    GetAllGroupsPaging: '/Group/List',
    DeleteGroup: '/Group',
    GroupUnblock: '/Group/Unblock',
    DeleteFiltered: '/Device/Delete/Filter',
    GetGroupDeviceAssociatedInfo: '/Group/IsDeviceAssociated',
    GetConfigurationList: '/ConfigurationProtocol/List',
    GetConfigurations: '/ConfigurationProtocol',
    Version: '/Version',
    GetUserList: '/User/List',
    User: '/User',
    UpdatePassword: '/Account/Update/User/Password',
    UserStatus: '/User/UserStatus',
    UpdateUserPassword: '/Account/Update/Password',
    Tenant: '/Tenant',
    UpdateTenantPassword: '/Account/Update/Tenant/Password',
    UpdateTenantStatus: '/Tenant/UpdateStatus',
    OneNceAccessToken: '/Device/OneNce/Token',
    OneNceDeviceSim: '/Device/OneNce/Sim',
    OneNceSimUsage: '/Device/OneNce/SimUsage',
    DeviceSimUsage: '/Device/SimUsage',
    OneNceSimStatus: '/Device/OneNce/SimStatus',
    OneNceSimReset: '/Device/OneNce/Sim/Reset',
    OneNceSimEnable: '/Device/OneNce/Sim/Enable',
    OneNceIMEILock: '/Device/OneNce/IMEILock',
    GetBillingList: '/Tenant/All/Bill/List',
    GetBillDetail: '/Tenant/Bill',
    PaymentRecieved: '/Billing/Payment/Received',
    Billing: '/Billing',
    TenantCharges: '/Tenant/Charges',
    CurrencyList: '/Tenant/Currency/List',
    GetTenantBill: '/Tenant',
    Transactions: '/Billing/Payment/Transactions/List',
    AllMonthPacketCount: '/Device/All/Monthly/Packet/Count',
    AuditLog: '/AuditLog/List',
    AuditLogDetail: '/AuditLog',
    UserExists:'/Account/User/Email/Verification',
    VerifyOTP:'/Account/User/OTP/Verification',
    PasswordReset: '/Account/User/Password/Reset',
    TrialTenant: '/Tenant/Trial',
    DumpLogs:'/DumpLog/List',
    GetPricingStats:'/Tenant/',
    BillingStatisticsCSV:'Billing/Statistics/CSV/Download/Tenant/'
}

export default URLConstants;