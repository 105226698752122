/*    
<summary>
   This functional common component provides protected routing if user is authenticated then it provides access to the route 
   otherwise redirect the user to login Form.
   Developer: Aashish Singh, Created Date: 08-Mar-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import RoutesConstants from '../shared-components/Routes/routes.constants';
import { useStore } from './StoreProvider';

interface IProps {
  children: React.ReactNode
}

const AuthProvider = (props: IProps) => {
  const { preferencesStore, authStore } = useStore();
  const { getToken, isAuthenticated } = authStore;

  useEffect(() => {
    if (getToken) {
      preferencesStore.UpdateLanguage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {(getToken || isAuthenticated) ? props.children : <Redirect to={RoutesConstants.Login} />}
    </React.Fragment>
  )
}

export default AuthProvider;