/*    
<summary>
   This class component is all about Managing common functionalities (i.e. language, version).
   Developer: Aashish Singh, Created Date: 08-Mar-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import { action, computed, makeObservable, observable } from "mobx";
import { ICommonState } from "../../models/state/ICommonState";
import { IPreferenceState } from "../../models/state/IPreferenceState";
import URLConstants from "../../constants/url.constants";
import * as baseService from '../service/base-service';
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import auditLog from "../../modules/audit-logs/audit-log";

export class PreferencesStore implements IPreferenceState, ICommonState {
    inProgress = false;
    error = '';
    lang = localStorage.getItem('language') !== null ? localStorage.getItem('language') : 'en';
    theme = localStorage.getItem('darkTheme') !== null ? localStorage.getItem('darkTheme') : 'false';
    tableView = localStorage.getItem('tableView') !== null ? localStorage.getItem('tableView') : 'false';
    language = this.lang!;
    version = '';
    actionEngineVersion = '';
    userSetting: any = {
        deviceLimitConfig: 100,
        configLimitConfig: 100,
        groupLimitConfig: 100,
        userLimitConfig: 100,
        tenantLimitConfig: 100,
        billingLimitConfig: 100,
        transactionLimitConfig: 100,
        tenantChargeLimitConfig: 100,
        auditLogLimitConfig: 100,
    };

    successToastTheme = {
        border: '1px solid #28B463',
        backgroundColor: '#EAFAF1',
    }

    activeGroupId: number = 0;
    filterActive: boolean = false;
    deviceNameFilter: string = "";
    tags: any[] = [];
    searchByIccid: string = "false";
    islanguageChanged = false;
    isThemeChanged = false;
    addBulkCount: number = 0;

    isSignUpActive: boolean = false;
    selectedLogs: string = "auditLog";

    constructor() {
        makeObservable(this, {
            language: observable,
            islanguageChanged: observable,
            isThemeChanged: observable,
            version: observable,
            userSetting: observable,
            selectedLogs:observable,
            theme: observable,
            actionEngineVersion:observable,
            activeGroupId: observable,
            filterActive: observable,
            deviceNameFilter: observable,
            addBulkCount:observable,
            isSignUpActive:observable,
            tags: observable,
            tableView: observable,
            searchByIccid:observable,
            getLanguage: computed,
            getTheme: computed,
            getSelectedLogs:computed,
            getAddBulkCount:computed,
            deviceName: computed,
            isFilterActive: computed,
            getSearchByIccid: computed,
            tableViewData: computed,
            tagsFilter: computed,
            getIsSignUpActive:computed,
            successToast: computed,
            changeLanguage: action,
            UpdateLanguage: action,
            setUpdateTheme: action,
            updateThemeChangedFlag: action,
            setUpdateSelectedLogs:action,
            setUpdateTableView: action,
            setUserSetting: action,
            setUpdateActiveGroup: action,
            setUpdateDeviceName: action,
            setUpdateFilterActive: action,
            setUpdateTags:action,
            setUpdateSearchByIccid:action,
            setUpdateBulkCount:action,
            resetStore:action,
            setUpdateIsSignupActive:action
            // setPageLimitConfig:action,
        })
    }

    get getLanguage() {
        return this.language;
    }

    get getTheme() {
        return this.theme;
    }

    get successToast() {
        return this.successToastTheme;
    }

    get tableViewData() {
        return this.tableView;
    }

    get deviceName() {
        return this.deviceNameFilter;
    }

    get isFilterActive() {
        return this.filterActive;
    }

    get tagsFilter() {
        return this.tags;
    }

    get getAddBulkCount() {
        return this.addBulkCount;
    }

    get getSearchByIccid() {
        return this.searchByIccid;
    }

    get getIsSignUpActive() {
        return this.isSignUpActive;
    }

    get getSelectedLogs() {
        return this.selectedLogs;
    }
    /*
    This function is used to Change Language.  
    */

    changeLanguage(language: string) {
        this.islanguageChanged = this.language === language ? false : true;
        this.language = language;
        localStorage.setItem('language', language);
    }

    /*
    This function is used to set Language Changed Flag.  
    */
    updateLanguageChangedFlag = () => {
        this.islanguageChanged = false;
    }

    /*
    This function Updates language with the language getting from local Storage.  
    */

    UpdateLanguage() {
        let language = localStorage.getItem('language');
        if (language)
            this.language = language;
    }

    setUpdateIsSignupActive = (value: boolean) => {
        this.isSignUpActive = value;
    }

    setUpdateSelectedLogs = (value: string) => {
        this.selectedLogs = value;
    }

    /*
This function is used to Get Version from API.  
*/

    getVersion = () => {
        this.inProgress = true;
        const url = URLConstants.Version;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                if (response.data.Error)
                    this.error = response.data.Message;
                else {
                    let data = response.data.Data;
                    localStorage.setItem('version', data);
                }
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }


    get getVersionFromStore() {
        return localStorage.getItem('version');
    }

    setUserSetting = (key: string, id: number) => {
        this.userSetting[key] = id;
    }

    setUpdateTheme = (theme: string) => {
        this.isThemeChanged = this.theme === theme ? false : true;
        this.theme = theme;
        localStorage.setItem('darkTheme', theme)
    }

    /*
    This function is used to set theme Changed Flag.  
    */
    updateThemeChangedFlag = () => {
        this.isThemeChanged = false;
    }

    setUpdateTableView = (tableView: string) => {
        this.tableView = tableView;
        localStorage.setItem('tableView', tableView)
    }

    setUpdateActiveGroup = (id: number) => {
        this.activeGroupId = id;
    }

    setUpdateDeviceName = (name: string) => {
        this.deviceNameFilter = name;
    }

    setUpdateFilterActive = (value: boolean) => {
        this.filterActive = value;
    }

    setUpdateTags = (value: any[]) => {
        this.tags = value;
    }

    setUpdateBulkCount = (value:any) =>{
        this.addBulkCount = value;
    }

    setUpdateSearchByIccid = (value:any) =>{
        this.searchByIccid = value;
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.theme = localStorage.getItem('darkTheme') !== null ? localStorage.getItem('darkTheme') : 'false';
        this.tableView = localStorage.getItem('tableView') !== null ? localStorage.getItem('tableView') : 'false';
        this.language = this.getLanguage;
        this.version = '';
        this.actionEngineVersion = '';
        this.userSetting = {
            deviceLimitConfig: 100,
            configLimitConfig: 100,
            groupLimitConfig: 100,
            userLimitConfig: 100,
            tenantLimitConfig: 100,
            billingLimitConfig: 100,
            transactionLimitConfig: 100,
            tenantChargeLimitConfig: 100,
            auditLogLimitConfig: 100,
        };
        this.activeGroupId = 0;
        this.filterActive = false;
        this.deviceNameFilter = "";
        this.tags = [];

        this.islanguageChanged = false;
        this.searchByIccid = "false";
        this.addBulkCount = 0;
        this.isSignUpActive = false;
        this.selectedLogs = "auditLog";
    }

    // setPageLimitConfig = (pageLimit: number) => {
    //     this.pageLimitConfig = pageLimit;
    // }
}

export default new PreferencesStore();