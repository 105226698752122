/*    
<summary>
   This functional common components provides us custom buttons for grid. 
   Developer:Aashish Singh, Created Date:25-Aug-2022
</summary> 
<returns>Returns JSX</returns>
*/
import React, { Dispatch, SetStateAction } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Grid.css";
import { FormattedMessage } from "react-intl";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { formatMessage } from "../../translations/format-message";
import { IActionFormatter } from "../../models/ICommon";
import isActiveImgSrc from "../../assets/Images/true.svg"
import isInactiveImgSrc from "../../assets/Images/false.svg"
import { IConfigurationList } from "../../models/response/IConfigurationResponse";
import moment from "moment";
import config from "../../helpers/config-helper";

const appConfig = config();
const dateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT;

export const ButtonFormatter =
  (handler: any, icon: any, isDisable: boolean, title: string) =>
  (cell: any, row: any) => {
    return (
      <button
        title={title}
        disabled={isDisable && !row.isDelete}
        onClick={() => handler(row, !row.isDelete)}
      >
        <FontAwesomeIcon className="colorWhite" icon={icon} />
      </button>
    );
  };

  export const IsActiveFirstPacketTimeFormatter =
  (title: string) =>
  (cell: any, row: any) => {
    return (
      <React.Fragment>
        {
          cell == null && row.IsActive == false ? 
          <img src={isInactiveImgSrc} className="buttonIcon is_active_btn"/>
          :
          cell
        }
      </React.Fragment>
    );
  };

export const ChartFormatter =
  (handler: any, src: any, isDisable: boolean, title: string) =>
  (cell: any, row: any) => {
    return (
      <button title={title} onClick={() => handler(row)}>
        <img className="gridChartIcons" src={src} alt="chart" />
      </button>
    );
  };

export const DeleteButtonFormatter =
  (handler: any, icon: any, isDisable: boolean, title: string) =>
  (cell: any, row: any) => {
    return (
      <button
        type="button"
        title={title}
        disabled={isDisable && !row.isDelete}
        onClick={() => handler(row, !row.isDelete)}
      >
        <FontAwesomeIcon icon={icon} />
      </button>
    );
  };

export const HeaderDeleteButtonFormatter =
  (handler: any, icon: any) => (cell: any, row: any) => {
    return (
      <button
        type="button"
        className="text-danger"
        onClick={() => handler(row, !row.isDelete)}
      >
        <FontAwesomeIcon icon={icon} />
      </button>
    );
  };

export const StopButtonFormatter =
  (handler: any, title?: string) => (cell: any, row: any) => {
    return (
      <>
        <button
          type="button"
          title={title}
          disabled={cell}
          onClick={() => handler(row, !row.isDelete)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill={!cell ? "red" : "#b5b5b5"}
            className="bi bi-stop-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3z" />
          </svg>
        </button>
      </>
    );
  };

export const AlertFormatter =
  (color: string, title: string) => (cell: any, row: any) => {
    return (
      <>
        {cell && row.isDeviceAllAlertsActive && (
          <button title={title} type="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill={color}
              className="bi bi-exclamation-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
            </svg>
          </button>
        )}
      </>
    );
  };

export const HealthCheckFormatter =
  (handler: any, title?: string) => (cell: any, row: any) => {
    return (
      <>
        {row.hasHealthCheckAlert && row.isDeviceAllAlertsActive && (
          <button title={title}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-exclamation-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
            </svg>
          </button>
        )}
      </>
    );
  };

export const HealthCheckFormatterAlertViewer =
  (handler: any, title?: string) => (cell: any, row: any) => {
    return (
      <>
        {cell && (
          <button title={title}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-exclamation-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
            </svg>
          </button>
        )}
      </>
    );
  };

export const EditFormatter =
  (handler: any, icon: any, isDisable: boolean, title: string) =>
  (cell: any, row: any) => {
    return (
      <button
        title={title}
        disabled={isDisable && !row.isEdit}
        onClick={() => handler(row, !row.isEdit)}
      >
        <FontAwesomeIcon icon={icon} />
      </button>
    );
  };
export const TableEditFormatter =
  (
    edithandler: any,
    editIcon: any,
    deletehandler: any,
    deleteIcon: any,
    handler: any,
    viewIcon: any
  ) =>
  (cell: any, row: any) => {
    return (
      <div className="btn-group">
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => edithandler(row, !row.isEdit)}
        >
          <FontAwesomeIcon icon={editIcon} />
        </button>
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => deletehandler(row, !row.isDelete)}
        >
          <FontAwesomeIcon icon={deleteIcon} />
        </button>
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={() => handler(row, !row.isData)}
        >
          <FontAwesomeIcon icon={viewIcon} />
        </button>
      </div>
    );
  };

export const TableGroupFormatter =
  (
    edithandler: any,
    editIcon: any,
    handler: any,
    viewIcon: any,
    deletehandler: any,
    deleteIcon: any
  ) =>
  (cell: any, row: any) => {
    return (
      <div className="btn-group">
        <button
          data-testid={"EditGroup"}
          className="btn btn-outline-primary btn-sm"
          onClick={(event) => edithandler(row, event)}
        >
          <FontAwesomeIcon icon={editIcon} />
        </button>
        <button
          data-testid={"ViewGroup"}
          className="btn btn-outline-secondary btn-sm"
          onClick={(event) => handler(row, event)}
        >
          <FontAwesomeIcon icon={viewIcon} />
        </button>
        <button
          data-testid={"DeleteGroup"}
          className="btn btn-outline-danger btn-sm"
          onClick={(event) => deletehandler(row, event)}
        >
          <FontAwesomeIcon icon={deleteIcon} />
        </button>
      </div>
    );
  };

export const DescriptionFormatter = () => (cell: any, row: any) => {
  if (row.Description != "") {
    return (
      <React.Fragment>
        <p
          data-tooltip-id="my-tooltip"
          data-tooltip-html={DynamicTooltipMultiline(row.Description)}
          data-tooltip-variant="dark"
        >
          {row.Description.substring(0, 50) + "...."}
        </p>

        <Tooltip id="my-tooltip" place="bottom" />
      </React.Fragment>
    );
  } else {
    return "";
  }
};

export const DynamicTooltipMultiline = (desc: string) => {
  let index = Math.ceil(desc.length / 2);
  if (desc.length > 0) {
    return (
      desc.substring(0, index) + "<br />" + desc.substring(index, desc.length)
    );
  }
  return desc;
};

export const TableConfigurationFormatter =
  (edithandler: any, editIcon: any, deletehandler: any, deleteIcon: any) =>
  (cell: any, row: any) => {
    return (
      <div className="btn-group">
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => edithandler(row, !row.isEdit)}
        >
          <FontAwesomeIcon icon={editIcon} />
        </button>
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => deletehandler(row, !row.isDelete)}
        >
          <FontAwesomeIcon icon={deleteIcon} />
        </button>
      </div>
    );
  };

export const TableDeviceFormatter =
  (edithandler: any, editIcon: any, handler: any, viewIcon: any) =>
  (cell: any, row: any) => {
    return (
      <div className="btn-group">
        <button
          data-testid="EditDevice"
          className="btn btn-outline-primary btn-sm"
          title={formatMessage("edit")}
          onClick={() => edithandler(row, !row.isEdit)}
        >
          <FontAwesomeIcon icon={editIcon} />
        </button>
        <button
          data-testid="DeleteDevice"
          className="btn btn-outline-secondary btn-sm"
          title={formatMessage("delete")}
          onClick={() => handler(row, !row.isData)}
        >
          <FontAwesomeIcon icon={viewIcon} />
        </button>
      </div>
    );
  };

export const TableTenantFormatter =
  (edithandler: any, editIcon: any, handler: any, viewIcon: any) =>
  (cell: any, row: any) => {
    return (
      <div className="btn-group">
        <button
          data-testid="EditDevice"
          className="btn btn-outline-primary btn-sm"
          title={formatMessage("edit")}
          onClick={() => edithandler(row, !row.isEdit)}
        >
          <FontAwesomeIcon icon={editIcon} />
        </button>
        <button
          data-testid="DeleteDevice"
          className="btn btn-outline-secondary btn-sm"
          title={formatMessage("delete")}
          onClick={() => handler(row, !row.isData)}
        >
          <FontAwesomeIcon icon={viewIcon} />
        </button>
      </div>
    );
  };

export const TableDeviceTagsFormatter = () => (cell: any, row: any) => {
  return (
    <div className="btn-group flex-wrap">
      {row.Tags.length > 0 ? (
        row.Tags?.map((tags: any) => {
          return (
            <React.Fragment key={tags}>
              <span className="bgTag primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="15"
                  fill="currentColor"
                  className="bi bi-tag-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                </svg>
                <span className="m-0">{tags}</span>
              </span>
            </React.Fragment>
          );
        })
      ) : (
        <React.Fragment>
          <span className="bgTag badge badge-warning p-1 mx-1 d-flex">
            <FormattedMessage id={"no_tag_applied"} />
          </span>
        </React.Fragment>
      )}
    </div>
  );
};

export const TableConfigurationGroupsFormatter =
  (setSelectedAssinedGroupId:(id:number)=>void) => (cell: any, row: any) => {
    return (
      <div className="dropright">
        {row.Groups.length > 0 ? (
          row.Groups.length > 1 ?
          <React.Fragment>
            <button
              type="button"
              id={row.Id}
              className="fs08 p-1 badge badge-warning dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={(event:any)=>{
                event.stopPropagation();
                setSelectedAssinedGroupId(event.target.id)
                }
              }
            >
              <FormattedMessage id={"used_in_groups"} />
            </button>
            <ul className={`dropdown-menu ${row.isAssignedSelected ? "show" : " "}`}>
                {row.Groups.map((group: any) => {
                  return (
                    <React.Fragment key={group}>
                      <li className="fs08 groups">
                        <a
                          className="p-1"
                          aria-disabled={true}
                        >
                          {group.Name}
                        </a>
                      </li>
                    </React.Fragment>
                  );
                })}
            </ul>
          </React.Fragment> :
          row.Groups.map((group: any) => {
            return (
              <React.Fragment key={group}>
                <span className="p-1 badge badge-warning">
                  {group.Name}
                </span>
              </React.Fragment>
            );
          })
        ) : (
          <React.Fragment>
            <span className="fs08  badge badge-light p-1 ">
              <FormattedMessage id={"unassigned"} />
            </span>
          </React.Fragment>
        )}
      </div>
    );
  };
export const ConfigurationGroupsFormatterDetailView =(row: any) => {
  return (
    <div>
      {row.Groups.length > 0 ? (
        <React.Fragment>
          <ul className="d-flex flex-wrap ">
          {row.Groups.map((group: any) => {
            return (
              <React.Fragment key={group}> 
                  <li
                    className="bg-gray rounded px-2 mr-1 mb-2"
                    aria-disabled={true}
                  >
                    {group.Name}
                  </li>
              </React.Fragment>
            );
          })}
          </ul>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <span className="fs08  badge badge-light p-1 ">
            <FormattedMessage id={"unassigned"} />
          </span>
        </React.Fragment>
      )}
    </div>
  );
};

  export const TableTagsFormatter =
  (setSelectedTagDeviceId:(id:number)=>void) => (cell: any, row: any) => {
    return (
      <div className="dropright">
        {row.Tags.length > 0 ? (

          row.Tags?.length > 1 ?          
          <React.Fragment>
            <button
              type="button"
              id={row.Id}
              className="fs08 p-1 badge badge-warning dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={(event:any)=>{
                event.stopPropagation();
                setSelectedTagDeviceId(event.target.id)
                }
              }
            >
              <FormattedMessage id={"tags"} />
            </button>
            <ul className={`dropdown-menu ${row.isTagSelected ? "show" : " "}`}>
              {row.Tags?.map((tag: any) => {
                return (
                  <React.Fragment key={tag}>
                    <li className="fs08 groups">
                      {/* <a
                        className="p-1"
                        aria-disabled={true}
                      >
                        {tag}
                      </a> */}
                      <span className="bgTag primary my-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="15"
                          fill="currentColor"
                          className="bi bi-tag-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                        <span className="m-0">{tag}</span>
                      </span>
                    </li>
                  </React.Fragment>
                );
              })}
            </ul>
          </React.Fragment>
          :
          row.Tags?.map((tag: any) => {
            return (
              <React.Fragment key={tag}>
              <span className="bgTag primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="15"
                  fill="currentColor"
                  className="bi bi-tag-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                </svg>
                <span className="m-0">{tag}</span>
              </span>
              </React.Fragment>
            );
          })
          
        ) : (
          <React.Fragment>
          <span className="bgTag badge badge-warning p-1 m-0">
            <FormattedMessage id={"no_tag_applied"} />
          </span>
        </React.Fragment>
        )}
      </div>
    );
  };

export const TableDeviceSwitchFormatter =
  (updateSwitchHandler: any) => (cell: any, row: any) => {
    return (
      <>
        <div className="form-check form-switch p-0">
          <input
            className="form-check-input ml-0"
            type="checkbox"
            title={row.IsEnable}
            role="switch"
            id="flexSwitchCheckChecked"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateSwitchHandler(row, e);
            }}
            onClick={event => event.stopPropagation()}
            checked={row.IsEnable == 1 ? true : false}
          />
        </div>
      </>
    );
  };

export const ChangePasswordFormatter =
  (handler: any, icon: any, isDisable: boolean, title: string) =>
  (cell: any, row: any) => {
    return (
      <button
        title={title}
        disabled={isDisable && !row.isChangePassword}
        onClick={() => handler(row, !row.isChangePassword)}
      >
        <FontAwesomeIcon icon={icon} />
      </button>
    );
  };

export const ActionFormatter =
  (actions: Array<IActionFormatter>) => (cell: any, row: any) => {
    return (
      <div className="btn-group">
        {actions.map((action: IActionFormatter, index: number) => {
          return (
            <button
              className={`btn btn-outline-primary btn-sm`}
              key={index}
              title={formatMessage(action.title)}
              type="button"
              disabled={action.isDisable && !row[action.dataField]}
              onClick={(event) => action.handler(row, event)}
            >
              <FontAwesomeIcon icon={action.icon} />
            </button>
          );
        })}
      </div>
    );
  };

  export const DeviceActionFormatter =
  (actions: Array<IActionFormatter>) => (cell: any, row: any) => {
    return (
      <div className="btn-group">
        {actions.map((action: IActionFormatter, index: number) => {
          return (
            <button
              className={`btn btn-outline-primary btn-sm`}
              key={index}
              data-testid={formatMessage(action.title)+"_"+row?.Iccid}
              title={formatMessage(action.title)}
              type="button"
              disabled={action.isDisable && !row[action.dataField]}
              onClick={(event) => action.handler(row, event)}
            >
              <FontAwesomeIcon icon={action.icon} />
            </button>
          );
        })}
      </div>
    );
  };

export const ActiveInactiveButtonFormatter = (handler: any) =>
  (cell: any, row: any) => {
    return (
      <div className="form-check form-switch p-0">
          <input
            className="form-check-input ml-0"
            type="checkbox"
            title={row.isActive}
            role="switch"
            id="flexSwitchCheckChecked"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handler(row, e);
            }}
            checked={row.isActive ? true : false}
          />
        </div>
    );
  };

export const TenantActiveInactiveButtonFormatter = (handler: any) =>
(cell: any, row: any) => {
  return (
    <div className="form-check form-switch p-0">
        <input
          className="form-check-input ml-0"
          type="checkbox"
          title={row.IsActive}
          role="switch"
          id="flexSwitchCheckChecked"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handler(row, e);
          }}
          checked={row.IsActive ? true : false}
        />
      </div>
  );
};

export const ShowHidePasswordButton = (props:{showHidePassword: boolean, setShowHidePassword:Dispatch<SetStateAction<boolean>>}) =>{
  const {showHidePassword, setShowHidePassword} = props;
  return(
    <>
    {showHidePassword ? (
        <svg
        type="button"
        onClick={() => {
          setShowHidePassword(!showHidePassword);
        }}
        viewBox="0 0 24 24"
        width="20"
        height="20"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="showHidePass css-i6dzq1 modalBox"
      >
        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
        <circle cx="12" cy="12" r="3"></circle>
      </svg>
      ) : (
        <svg
          type="button"
          onClick={() => {
            setShowHidePassword(!showHidePassword);
          }}
          viewBox="0 0 24 24"
          width="20"
          height="20"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="showHidePass css-i6dzq1 modalBox"
        >
          <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
          <line x1="1" y1="1" x2="23" y2="23"></line>
        </svg>
      )}
    </>
  )
};