/*    
<summary>
  This functional component "ChangePasswordForm" provides form to change the password of the selected user.
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";
import { Form, Formik, Field } from "formik";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import { IChangePassword } from "../../../../models/Form/IChangePassword";
import LoaderButton from "../../../../shared-components/Button/LoaderButton";
import FormikFormInput from "../../../../shared-components/FormikFormHandlers/FormikFormInput";
import { UserChangePasswordValidateSchema } from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../../shared-components/Label/Label";

interface IProps {
  id: number;
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  loading: boolean;
  initialValues: IChangePassword;
}

const ChangePasswordTenantForm = (props: IProps) => {
  const [showHidePassword, setShowHidePassword] = useState(false);
  return (
    <>
      <Modal
        className="changePasswordModal correction-modal fancy-modal"
        show={true}
        centered
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            <button
              onClick={props.onModalClosed}
              disabled={props.loading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
            <FormattedMessage id="change_password" />
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          validationSchema={UserChangePasswordValidateSchema}
          validateOnBlur={false}
          onSubmit={async (values) => {
            props.submitHandler(values);
          }}
        >
          <Form className="change_password-form">
            <Modal.Body>
              <div className="form-group row">
                <Label
                  className="label-style col-sm-4 col-form-label"
                  required={true}
                  label="user_label_password"
                />
                <div className="col-sm-8">
                  <Field
                    data-testid="testPassword"
                    className="form-control"
                    name="NewPassword"
                    type="password"
                    placeholder="user_label_password"
                    invalidcharacter={[" "]}
                    validcharacter={[
                      "~",
                      "`",
                      "!",
                      "@",
                      "#",
                      "$",
                      "%",
                      "^",
                      "&",
                      "*",
                      "(",
                      ")",
                      "_",
                      "-",
                      "+",
                      "=",
                      "{",
                      "[",
                      "}",
                      "]",
                      "|",
                      "\\",
                      ":",
                      ";",
                      "'",
                      '"',
                      "<",
                      ",",
                      ">",
                      ".",
                      "?",
                      "/",
                    ]}
                    as={FormikFormInput}
                  />
                </div>
              </div>
              <div className="form-group row">
                <Label
                  className="label-style col-sm-4 col-form-label pr-0"
                  required={true}
                  label="label_confirm_password"
                />
                <div className="col-sm-8">
                  <Field
                    data-testid="testPassword"
                    className="form-control"
                    name="ConfirmPassword"
                    type="password"
                    placeholder="label_confirm_password"
                    invalidcharacter={[" "]}
                    validcharacter={[
                      "~",
                      "`",
                      "!",
                      "@",
                      "#",
                      "$",
                      "%",
                      "^",
                      "&",
                      "*",
                      "(",
                      ")",
                      "_",
                      "-",
                      "+",
                      "=",
                      "{",
                      "[",
                      "}",
                      "]",
                      "|",
                      "\\",
                      ":",
                      ";",
                      "'",
                      '"',
                      "<",
                      ",",
                      ">",
                      ".",
                      "?",
                      "/",
                    ]}
                    as={FormikFormInput}
                  />
                </div>
              </div>
            </Modal.Body>
            <div className="modal-footer pt-0 border-0">
              <div className="form-group row tab-action">
                <div className="col-12">
                  <LoaderButton
                    type="submit"
                    isLoading={props.loading}
                    text="button_save"
                  />
                  <Button
                    variant="secondary"
                    disabled={props.loading}
                    onClick={props.onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default ChangePasswordTenantForm;
