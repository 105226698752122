/*    
<summary>
   This class component is all about Managing billing data functionality.
   Developer:Aashish Singh, Created Date:19-Oct-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import { action, computed, makeObservable, observable } from 'mobx';
import { initialState as GetAllBillState } from '../initial-state/get-all-billing-state';
import { ICommonState } from '../../models/state/ICommonState';
import IApiResponse, { IApiSuccessResponse } from '../../models/response/IApiResponse';
import { IObservableInitialState } from '../../models/ICommon';
import URLConstants from '../../constants/url.constants';
import * as baseService from '../service/base-service';
import { formatMessage } from '../../translations/format-message';
import toast from 'react-hot-toast';
import { IBillingById, IBillChargesDetailVm, IAllTenantBillStatusListVm, IBillingList, IBillStatusVm } from '../../models/response/IBillingResponse';
import config from '../../helpers/config-helper';
import moment from 'moment';
import billingStateType from '../../constants/billing-state-type-enum';
import { IBillingState } from '../../models/state/IBillingState';

const appConfig = config();
const dateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT;

export class BillingStore implements IBillingState, ICommonState {
    inProgress = false;
    error = '';
    billingList: IAllTenantBillStatusListVm = GetAllBillState;

    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }

    billingDetail: IBillChargesDetailVm | undefined = undefined;
    tenantBillDetail: IBillChargesDetailVm | undefined = undefined;
    tenantPendingDueAmountResponse: IBillStatusVm | undefined = undefined;

    billingDetailState = { ...this.initialStateValue }
    tenantBillDetailState = { ...this.initialStateValue }

    addPaymentState = { ...this.initialStateValue }

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            billingList: observable,
            billingDetail: observable,
            billingDetailState: observable,
            addPaymentState: observable,
            tenantBillDetail: observable,
            tenantBillDetailState: observable,
            tenantPendingDueAmountResponse: observable,
            GetAllTenantsBillService: action,
            GetTenantBillChargesDetailsService: action,
            GetTenantBillByTenantIdService: action,
            AddTenantBillPaymentReceviedService: action,
            reset: action,
            resetStore: action,
            allBilling: computed,
            getTenantPendingDueAmount: computed
        });
    }

    /*
    This function is the API Call that gets All Bills data from API.  
    */
    GetAllTenantsBillService = (pageNumber: number, pageSize: number, filterDetails: any) => {
        this.inProgress = true;
        const url = URLConstants.GetBillingList+ "?PageNo=" + pageNumber + "&PageSize=" + pageSize;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IAllTenantBillStatusListVm>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.billingList = response.data.Data;
                }
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

    /*
    This is the computed function used for getting all the bills. 
    */
    get allBilling(): IBillingList[] {
        if (this.billingList?.Bills && this.billingList?.Bills?.length > 0)
            return this.billingList?.Bills.map((billing:IBillStatusVm) => {
                let isPaid: boolean = true;
                return {
                    Id: billing.Id,
                    Tenant: billing?.Tenant,
                    LastBillGenerationDate: moment(billing?.LastBillGenerationDate).format(dateTimeFormat),
                    BillStateType:  billing.BillStateType ? billingStateType[billing.BillStateType].toString() : "",
                    TotalOutstandingAmount: Number(billing?.TotalOutstandingAmount).toFixed(2),
                    Currency: billing?.Currency,
                    isPaid: billing?.BillStateType == billingStateType.Paid ? false : isPaid,
                }
            })
        return [];
    }

        /*
    This function is the API Call that gets All Bills data from API.  
    */
    GetTenantBillChargesDetailsService = (id:number) => {
        this.inProgress = true;
        const url = URLConstants.GetBillDetail+ "/" +id+ "/Charges/Detail";
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IBillChargesDetailVm>>) => {
                if (response.data.Error) {
                    this.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.billingDetail = response.data.Data;
                }
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

    /*
    This is the computed function used for getting single bill detail by bill Id. 
    */
    get billDetail(): IBillingById | undefined{
        if (this.billingDetail)
            return {
                Id: this.billingDetail.Id,
                BillGenerationDate: moment(this.billingDetail?.BillGenerationDate).format(dateTimeFormat),
                BillStateType:  this.billingDetail?.BillStateType ? billingStateType[this.billingDetail.BillStateType].toString() : "",
                RegistrationCharges: Number(this.billingDetail?.RegistrationCharges).toFixed(2),
                PacketCharges: Number(this.billingDetail?.PacketCharges).toFixed(2),
                TenancyCharges: Number(this.billingDetail?.TenancyCharges).toFixed(2),
                Taxes: Number(this.billingDetail?.Taxes).toFixed(2),
                Currency: this.billingDetail?.Currency,
                CurrentBillAmount: Number(this.billingDetail?.CurrentBillAmount).toFixed(2),
                PendingOutstandingAmount: Number(this.billingDetail?.PendingOutstandingAmount).toFixed(2),
                TotalOutstandingAmount: Number(this.billingDetail?.TotalOutstandingAmount).toFixed(2),
            };
        return this.billingDetail;
    }

    /**
    * Summary
    * GetTenantBill:This function is responsible for to Get specific tenant's Pending Amount.
    * (UpdatedBy:Deepak Paliwal,Date:10-Jan-2024)
    */
    GetTenantBillByTenantIdService = (tenantId: number) => {
        this.tenantBillDetailState.inProgress = true;
        let url = `${URLConstants.GetTenantBill}/${tenantId}/Bill/Status`;
        return baseService.getRequest(url)
            .then((response: IApiResponse<IApiSuccessResponse<IBillStatusVm>>) => {
                if (response.data.Error) {
                    this.tenantBillDetailState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.tenantPendingDueAmountResponse = response.data.Data;
                    this.tenantBillDetailState.success = true;
                }
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.tenantBillDetailState.inProgress = false }));
    }

    /*
    This is the computed function used for getting pending due amount details from tenant bill. 
    */
    get getTenantPendingDueAmount(): IBillingList | undefined {
        if (this.tenantPendingDueAmountResponse)
            return {
                Id:this.tenantPendingDueAmountResponse.Id,
                Tenant: this.tenantPendingDueAmountResponse?.Tenant,
                LastBillGenerationDate: moment(this.tenantPendingDueAmountResponse?.LastBillGenerationDate)?.format(dateTimeFormat),
                BillStateType: this.tenantPendingDueAmountResponse.BillStateType ? billingStateType[this.tenantPendingDueAmountResponse.BillStateType]?.toString() : " ",
                TotalOutstandingAmount: Number(this.tenantPendingDueAmountResponse?.TotalOutstandingAmount)?.toFixed(2),
                Currency: this.tenantPendingDueAmountResponse?.Currency,
            }
        return this.tenantPendingDueAmountResponse;
    }

    /*
    This function is used to add received payemnt.  
    */
    AddTenantBillPaymentReceviedService = (data: any) => {
        this.addPaymentState.inProgress = true;
        const url = URLConstants.PaymentRecieved;
        return baseService.postRequest(url, { ...data })
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                if (response.data.Error) {
                    this.addPaymentState.error = response.data.Message;
                    toast.error(formatMessage(response.data.Message));
                }
                else {
                    this.addPaymentState.success = true;
                }
            })
            .catch((err: string) => {
                this.addPaymentState.error = err;
            })
            .finally(action(() => { this.addPaymentState.inProgress = false; }));
    }

    /*
    This function is used to reset addPaymentState observables to their initial values.  
    */
    resetAddPaymentState = () => {
        this.addPaymentState = { ...this.initialStateValue }
    }

    /*
    This function is used to reset all observables to their initial values.  
    */
    reset = () => {
        this.error = '';
        this.inProgress = false;
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.billingList = GetAllBillState;
        this.tenantBillDetail = undefined;
        this.tenantBillDetailState = { ...this.initialStateValue }
        this.addPaymentState = { ...this.initialStateValue }
    }

}

export default new BillingStore();
