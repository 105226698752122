/*    
<summary>
   This functional component provides routing functionality. 
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary> 
<returns>Returns JSX</returns>
*/
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import Login from "../../modules/auth/Login";
import RoutesConstants from "./routes.constants";
import LoginLayoutRoute from "./LoginLayoutRoute";
import LayoutRoute from "./LayoutRoute";
import { withRouter } from "react-router-dom";
import Device from "../../modules/device/Device";
import AddBulk from "../../modules/device/add-bulk/AddBulk";
import Dashboard from "../../modules/dashboard/Dashboard";
import Configuration from "../../modules/configurations/configuration";
import Group from "../../modules/groups/group";
import Billing from "../../modules/billing/billing";
import TenantCharge from "../../modules/tenant-charge/tenant-charge";
import tenants from "../../modules/tenants/tenants";
import User from "../../modules/user/user";
import AuditLog from "../../modules/audit-logs/audit-log";
import { useStore } from "../../contexts/StoreProvider";
import userTypeEnum from "../../constants/user-type-enum";
import { observer } from "mobx-react";
import Documentation from "../../modules/documentation/documentation";
import Transaction from "../../modules/transaction/transaction";
import SingleView from "../../modules/documentation/components/singleView/singleView";
import forgetPassword from "../../modules/forget-password/components/forget-password";
import PricingMenu from "../../modules/pricing-menu/pricing-menu";
import CreateAccount from "../../modules/create-account/create-account";


const Routes = (props: any) => {
  const {authStore} = useStore();
  const {userType, getUserType } = authStore;
  const showUserManagement = (userType === userTypeEnum.TenantAdministrator || getUserType === userTypeEnum.TenantAdministrator);
  if( getUserType === userTypeEnum.SuperAdministrator)
    return (
        <React.Fragment>
            <Switch>
                <LayoutRoute path={RoutesConstants.Tenants} component={tenants} />
                <LayoutRoute path={RoutesConstants.Bill} component={Billing} />
                <LayoutRoute path={RoutesConstants.TenantCharge} component={TenantCharge} />
                <LayoutRoute path={RoutesConstants.Documentation} component={Documentation} />
                <LayoutRoute path={RoutesConstants.Transaction} component={Transaction} />
                <LayoutRoute path={RoutesConstants.SingleView} component={SingleView} />
                <LayoutRoute path={RoutesConstants.PricingMenu} component={PricingMenu}/>
                <Redirect to={RoutesConstants.Tenants} />
            </Switch>
        </React.Fragment>
  );
  else
    return (
      <React.Fragment>
          <Switch>
              <LoginLayoutRoute path={RoutesConstants.Login} component={Login} />
              <LoginLayoutRoute path={RoutesConstants.ForgetPassword} component={forgetPassword} />
              <LoginLayoutRoute path={RoutesConstants.CreateAccount} component={CreateAccount} />
              <LayoutRoute path={RoutesConstants.Device} component={Device} />
              <LayoutRoute path={RoutesConstants.AddBulk} component={AddBulk} />
              <LayoutRoute path={RoutesConstants.Dashboard} component={Dashboard} />
              <LayoutRoute path={RoutesConstants.Group} component={Group} />
              <LayoutRoute path={RoutesConstants.Documentation} component={Documentation} />
              <LayoutRoute path={RoutesConstants.SingleView} component={SingleView} />
              {showUserManagement && <LayoutRoute path={RoutesConstants.User} component={User} />}
              {showUserManagement && <LayoutRoute path={RoutesConstants.AuditLog} component={AuditLog} />}
              <LayoutRoute path={RoutesConstants.Configurations} component={Configuration}/>
              <Redirect to={RoutesConstants.Dashboard} />
          </Switch>
      </React.Fragment>
  );
};

export default withRouter(observer(Routes));
