/*    
<summary>
   This file is used to provide initial values or state to the AddDeviceForm.
   Developer:Ashish Singh, Created Date: 03-April-2023
</summary>
*/
import IAddDevices from "../../models/Form/IAddUpdateDevice";

export const initialState: IAddDevices = {
    GroupId: -1,
    Name: '',
    Iccid: '',
    IsEnable: true,
    Tags: [],
    ClientCertificate: '',
    ClientKey: ''
  }