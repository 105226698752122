/*    
<summary>
  This functional component is used for Create new Account.
  Developer:Aashish Singh, Created Date:07-Feb-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useHistory, withRouter } from "react-router-dom";
import { formatMessage } from "../../translations/format-message";
import { observer } from "mobx-react";
import { useStore } from "../../contexts/StoreProvider";
import Footer from "../../shared-components/Footer/Footer";
import UserRegistrationForm from "../user/forms/user-registration-form";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import { initialState } from "../../core/initial-state/add-trial-tenant-state";
import RoutesConstants from "../../shared-components/Routes/routes.constants";

const CreateAccount = () => {
  const { tenantStore } = useStore();
  const {
    AddTrialTenant,
    addTrialTenantState,
    resetAddTrialTenantState,
  } = tenantStore;
  const history = useHistory();
  
  const submitTenant = (values: any) => {
    AddTrialTenant(values);
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateDevicestate.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addTrialTenantState.success) {
      history.push(RoutesConstants.Login);
    }
  }, [addTrialTenantState.success]);

  useEffect(() => {
    if (addTrialTenantState.error) {
      toast.success(formatMessage(addTrialTenantState.error));
      resetAddTrialTenantState();
    }
  }, [addTrialTenantState.error]);

  return (
    <>
      {addTrialTenantState.inProgress && (
        <FormLoader loading={addTrialTenantState.inProgress} />
      )}
      <div className="bg-img1 text-left background">
          <UserRegistrationForm
            initialValues={initialState}
            submitHandler={submitTenant}
        />
      </div>
      <Footer />
    </>
  );
};

export default withRouter(observer(CreateAccount));
