/*    
<summary>
   This file is used to provide initial values or state to the transactions Module observables.
    Developer:Aashish Singh, Created Date:22-Nov-2023
</summary>
*/

import { IGetAllAuditLogState } from "../../models/response/IAuditLogsResponse";

export const initialState: IGetAllAuditLogState = {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage: 1,
        PageSize: 10,
    },
    Logs: []
}