/*    
<summary>
  This functional component "AddUpdateGroup" defines the props like initial values & functions for the child form component.
  Developer:Mohammad Saquib Khan, Created Date: 24 April 2023
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../contexts/StoreProvider";
import toast from "react-hot-toast";
import { formatMessage } from "../../../translations/format-message";
import IAddGroup from "../../../models/Form/IAddGroup";
import AddEditGroupForm from "./forms/add-edit-group-form";
import { cloneDeep } from "lodash";
import protocolEnum from "../../../constants/protocol-enum";
import { IOption } from "../../../models/ICommon";

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddEditGroup = (props: IProps) => {
  const { groupStore, configurationStore } = useStore();
  const {
    AddGroupService,
    UpdateGroupService,
    addUpdateGroupstate,
    groupIdDetail,
    groupDetailState,
    GetGroupByIdService,
    resetAddUpdateGroup,
    resetGetgroupDetail,
    allGroupList,
  } = groupStore;
  const { GetAllConfigurationService, allconfigurations } = configurationStore;
  const [availableConfiguration, setAvailableConfigurations] = useState<any>(
    []
  );

  const onSubmitForm = (values: IAddGroup) => {
    let tempValues = cloneDeep(values);
    tempValues.Name = values.Name.trim();
    tempValues.Description = values.Description.trim();
    if (props.id < 0) {
      AddGroupService(tempValues);
    } else {
      if(values.ConfigurationProtocol?.DestinationProtocol==4){
        toast.error("Group's Destination Protocol is:AWS IoT Core so you can't update setting for now");
        return;
      }
      UpdateGroupService(props.id, tempValues);
    }
  };

  const onModalClosed = () => {
    setAvailableConfigurations([]);
    resetAddUpdateGroup();
    resetGetgroupDetail();
    props.modalClosed();
  };

  const getProtocol = (type: any) => {
    return protocolEnum[type];
  };
  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */

  useEffect(() => {
    if (props.id > 0) {
      const configOptions: IOption[] = [
        {
          id: -1,
          value: "please_select",
        },
      ];
      if (allconfigurations && allconfigurations?.length > 0)
        allconfigurations?.map((configuration: any) => {
          configOptions.push({
            id: configuration.Id,
            value:
              configuration.Name 
              // +
              // " (" +
              // (configuration.DestinationProtocol == protocolEnum.HTTPS
              //   ? formatMessage("webhook").toString()
              //   : getProtocol(configuration?.DestinationProtocol)) +
              // ")"
              ,
            disabled:
              groupIdDetail?.ConfigurationProtocol?.DestinationProtocol !=
              configuration?.DestinationProtocol
                ? true
                : false,
          });
        });
      setAvailableConfigurations(configOptions);
    }
  }, [
    allconfigurations.length > 0 && groupIdDetail.ConfigurationProtocolId > 0,
  ]);

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    if (props.id > 0) GetGroupByIdService(props.id);
    GetAllConfigurationService();
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateGroupstate.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateGroupstate.success) {
      if (props.id < 0) toast.success(formatMessage("added_success"));
      else toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [addUpdateGroupstate.success]);

  return (
    <React.Fragment>
      <Modal
        className="correction-modal fancy-modal"
        show={true}
        centered={false}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            {props.id < 0 ? (
              <FormattedMessage id="add_group" />
            ) : (
              <FormattedMessage id="update_group" />
            )}
          </Modal.Title>
          <button
            onClick={onModalClosed}
            data-testid={"Close"}
            className="Crossicons"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </Modal.Header>
        <div className="p-0">
          <AddEditGroupForm
            initialValues={
              allGroupList.length == 0
                ? { ...groupIdDetail, IsDefault: true }
                : groupIdDetail
            }
            allConfigurations={availableConfiguration}
            submitHandler={onSubmitForm}
            id={props.id}
            isLoading={
              props.id < 0
                ? addUpdateGroupstate.inProgress
                : groupDetailState.inProgress
            }
            modalClosed={onModalClosed}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default observer(AddEditGroup);
