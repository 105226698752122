/*    
<summary>
   This functional component provides us a button with spinner that shows the loading.
   Developer:Mohammad Saquib Khan, Created Date:08-Mar-2023
</summary> 
<returns>Returns JSX</returns>
*/
import React from "react";
import Loader from "../Loader/Loader";
import { Button } from "react-bootstrap";
import { formatMessage } from "../../translations/format-message";

const LoaderIconButton = (props: any) => {
  return (
    <React.Fragment>
      <Button
        data-testid={props.id ? props.id : undefined}
        onClick={props.onClick}
        disabled={props.disabled || props.isLoading}
        type={props.type}
        variant={props.variant}
        title={props.title ? formatMessage(props.title) : undefined}
        className={`btn btn-blue ${props.className && props.className}`}
      >
        {props.svg}
        {props.isLoading ? <Loader /> : null}
      </Button>
    </React.Fragment>
  );
};

export default LoaderIconButton;
