/*    
<summary>
  This functional component "Device Filter" renders the device details.
  Developer:Aashish Singh, Created Date: 05-Sept-2023
</summary>
<returns>Returns JSX</returns>
*/

import "./device-filter-form.css";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../contexts/StoreProvider";
import config from "../../../../helpers/config-helper";
import { IOption } from "../../../../models/ICommon";
import { Field, Form, Formik } from "formik";
import { deviceFilterValidationSchema } from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../../shared-components/Label/Label";
import FormikFormSelect from "../../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { FormattedMessage } from "react-intl";
import LoaderButton from "../../../../shared-components/Button/LoaderButton";
import {
  DeviceFilter,
  IDataList,
} from "../../../../models/response/IDeviceResponse";
import { formatMessage } from "../../../../translations/format-message";
import FormikFormInputFilter from "../../../../shared-components/FormikFormHandlers/FormikFormInputFilter";
const appConfig = config();
const dateTimeFormat = appConfig.REACT_APP_DATE_TIME_FORMAT;
const dateFormat = appConfig.REACT_APP_DATE_FORMAT;

interface IProps {
  initialValues: DeviceFilter;
  submitHandler: (values: DeviceFilter) => void;
  groupOptions: IOption[];
  isFilterActive: boolean;
  modalClosed: () => void;
  allDevicesName: IDataList[];
  allDevicesIccid: IDataList[];
  allDevicesTags: IDataList[];
}

const DeviceFilterForm = (props: any) => {
  const { deviceStore, oneNceStore, authStore, preferencesStore } = useStore();
  const [error, setError] = useState<string>("");
  const [tags, setTags] = useState<any>([]);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event: any) => {
    event.target.value = event.target.value.replace(/'/g, '');
    if (event.target.value?.length <= 10) {
      setInputValue(event.target.value);
    } else {
      setError("tag_character_limit");
      clearErrorMessage(4000);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Tab" || event.key === "Enter") {
      event.preventDefault();
      addTags();
    }
  };

  const addTags = () => {
    let tempTagValue = inputValue.trim();
    if (tempTagValue) {
      if (tags.includes(tempTagValue)) {
        setError("tag_exist");
        clearErrorMessage(3000); // Display error for 5 seconds
        return;
      }

      if (tags.length >= 5) {
        setError("tags_limit_reached");
        clearErrorMessage(3000); // Display error for 5 seconds
        return;
      }

      setTags([...tags, tempTagValue]);
      setInputValue("");
      clearErrorMessage(); // Clear error message if a valid tag is added
    }
  };

  const handleTagDelete = (tag: any) => {
    setTags(tags.filter((t: any) => t !== tag));
  };

  const clearErrorMessage = (timeout = 0) => {
    setTimeout(() => {
      setError("");
    }, timeout);
  };

  useEffect(() => {
    let tag: any = [];
    for (let i = 0; i < props.initialValues.Tags.length; i++) {
      tag.push(props.initialValues.Tags[i]);
    }
    setTags(tag);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Id > 0 && props.initialValues.Tags.length > 0]);

  return (
    <React.Fragment>
      {
        <>
          <Formik
            initialValues={props.initialValues}
            enableReinitialize
            validateOnBlur={false}
            validationSchema={deviceFilterValidationSchema}
            onSubmit={async (values: DeviceFilter) => {
              let tempValues: DeviceFilter = {
                DeviceName: values.DeviceName,
                GroupId: Number(values.GroupId),
                Tags: tags.length > 0 ? tags : [],
                SearchByIccid: values.SearchByIccid,
              };
              props.submitHandler({ ...tempValues });
            }}
            render={({ values }) => {
              return (
                <Form className="user-form p-3">
                  <div className="form-group formSet mb-1">
                    <Label
                      className="label-style col-form-label"
                      label="group"
                    />
                    <Field
                        name="GroupId"
                        as={FormikFormSelect}
                        options={props.groupOptions}
                      />
                  </div>
                  <div className="form-group formSet">
                        <label className="label-style">
                          <FormattedMessage id="tags" />
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            value={inputValue}
                            className="form-control"
                            placeholder={formatMessage("tags_enter")}
                            onChange={(e) => handleInputChange(e)}
                            onKeyDown={handleKeyDown}
                            // pattern="[a-zA-Z0-9]+"
                          />
                          <div className="input-group-append">
                          <button className="btn btn-secondary" type="button" onClick={() => addTags()}><i className="fa fa-plus"></i></button>
                        </div>
                          {/* <datalist id="tags">
                            {props.allDevicesTags?.map((item: any) => (
                              <option key={item?.Id} value={item.Value} />
                            ))}
                          </datalist> */}
                        </div>

                        <div>
                          {tags.map((tag: any, index: any) => (
                            <span
                              className="bgTag primary tag mr-2"
                              key={index}
                            >
                              {tag}
                              <button
                                type="button"
                                className="close"
                                onClick={() => handleTagDelete(tag)}
                              >
                                &times;
                              </button>
                            </span>
                          ))}
                        </div>
                        {error != "" ? (
                          <span className="text-danger errorMessage">
                            <FormattedMessage id={error} />
                          </span>
                        ) : (
                          ""
                        )}
                  </div>
                  {/* {values.GroupId > 0 ? (
                    <> */}
                      <div className="mb-1">
                        <FormattedMessage id={"search_by"} /> 
                      </div>
                      <div className="form-group row">
                        <div className="col-6 btn-radioCheckbox">
                          <Field
                            type="radio"
                            value="false"
                            name="SearchByIccid"
                            id="DeviceName"
                            className="nav-link active"
                          />
                          <label htmlFor="DeviceName">
                            <FormattedMessage id="device_name" />
                          </label>
                        </div>

                        <div className="col-6 btn-radioCheckbox">
                          <Field
                            type="radio"
                            value="true"
                            name="SearchByIccid"
                            id="Iccid"
                            className="nav-link active"
                          />
                          <label htmlFor="Iccid">
                            <FormattedMessage id="iccid" />
                          </label>
                        </div>
                      </div>

                      <div className="form-group formSet">
                        <div className=" input-group">
                          <Field
                            data-testid="DeviceName"
                            id="custom"
                            className="form-control"
                            name="DeviceName"
                            placeholder={
                              values.SearchByIccid == "true"
                                ? "iccid"
                                : "device_name"
                            }
                            type={ values.SearchByIccid == "false" ? "text" : "number"}
                            validcharacter={ values.SearchByIccid == "false" ? ["@", "#", "$", "-", "_", "."] : []}
                            as={FormikFormInputFilter}
                          />
                        </div>
                      </div>
                    {/* </>
                  ) : (
                    ""
                  )} */}

                  <div className="d-flex justify-content-end pb-2">
                    <button
                      data-testid="apply"
                      className="btn btn-secondary"
                      type="button"
                      onClick={props.modalClosed}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <FormattedMessage id="button_cancel" />
                    </button>

                    <LoaderButton type="submit" className="ml-2" text="apply" />
                  </div>
                </Form>
              );
            }}
          ></Formik>
        </>
      }
    </React.Fragment>
  );
};

export default observer(DeviceFilterForm);
